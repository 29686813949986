import Vue from 'vue'
import { Ripple } from 'vuetify/lib'
import BaseLinkBox from '~/components/base/link/BaseLinkBox'
import CmsUtilLink from '~/components/cms/util/CmsUtilLink'
import BaseButton from '~/components/base/button/BaseButton'
import BaseCountdown from '~/components/base/countdown/BaseCountdown'

Vue.component('BaseLinkBox', BaseLinkBox)
Vue.component('BaseCountdown', BaseCountdown)
Vue.component('CmsUtilLink', CmsUtilLink)
Vue.component('BaseButton', BaseButton)
Vue.directive('bs-ripple', Ripple)
