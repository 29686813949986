// noinspection JSIgnoredPromiseFromCall

import { effectScope } from '@nuxtjs/composition-api'
import { Capacitor } from '@capacitor/core'
import { INTERCEPTOR_KEYS, useIntercept } from '~/composables'
import {
  convertGtmUaProductItem,
  getGtmLineItemProduct,
  getGtmProductItem,
  getLineItemNetPrice,
  getProductRealPriceNet,
} from '~/helpers'
import { COOKIES } from '~/logic'
import AppTracking from '~/capacitor-plugins/AppTracking'

export default ({ app }) => {
  const scope = effectScope()

  scope.run(() => {
    const { intercept } = useIntercept(app)
    const currency = 'EUR'
    let pageCount = 1
    let lastListView
    let lastListIdView
    let lastPdpListView
    let lastPdpListIdView
    let isInit = false

    const pageAccessedByReload = !!(
      window.performance?.navigation?.type === 1 ||
      window.performance
        ?.getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
    )

    function initGtm() {
      if (isInit) {
        return
      }

      isInit = true

      app.$gtm.push({
        bsPlatform: Capacitor.getPlatform(),
        bsReleaseName: process.env.SENTRY_RELEASE_NAME,
        bsReferrer: pageAccessedByReload
          ? app.$config.appUrl
          : document.referrer,
        bsPageUrl: document.location.href,
        bsPageTitle: document.title,
        bsPagePath: document.location.pathname,
      })

      app.$gtm.init(app.$config.gtmId)
    }

    function handleStartGtm() {
      if (app.$ccm.isCookieAllowed(COOKIES.GTM)) {
        if (Capacitor.isNativePlatform()) {
          AppTracking.isTrackingAllowed().then(({ allowed }) => {
            if (allowed) {
              initGtm()
            }
          })
        } else {
          initGtm()
        }
      }
    }

    handleStartGtm()

    intercept(INTERCEPTOR_KEYS.COOKIE_CHANGES, () => {
      handleStartGtm()
    })

    const gtmService = {
      pushQueue: [],
      preCommitPushQueue: [],
      isPaused: false,
      push(value) {
        if (!this.isPaused) {
          app.$gtm.push(value)
        } else {
          this.pushQueue.push(value)
        }
      },

      setPageType(type, extras = {}) {
        this.addPreCommitMessage({
          bsPageType: type,
          bsPageExtras: extras,
        })
      },

      addPreCommitMessage(value) {
        this.preCommitPushQueue.push(value)
      },

      commit(value) {
        for (const value of this.preCommitPushQueue) {
          app.$gtm.push(value)
        }

        if (value) {
          app.$gtm.push(value)
        }

        for (const value of this.pushQueue) {
          app.$gtm.push(value)
        }

        app.$gtm.push({
          event: 'bs_route_finish',
        })

        this.pushQueue = []
        this.preCommitPushQueue = []
        this.isPaused = false
      },
    }

    app.router.beforeEach((to, from, next) => {
      lastPdpListView = undefined
      lastPdpListIdView = undefined

      gtmService.isPaused = true

      if (to.path !== from.path) {
        app.$gtm.push({
          bsPageType: null,
          bsPageExtras: null,
        })
      }

      next()
    })

    app.router.afterEach((to, from) => {
      setTimeout(() => {
        gtmService.commit({
          event: 'bs_route_change',
          bsReferrer:
            from.name || pageAccessedByReload
              ? app.$config.appUrl + (from?.fullPath || '')
              : document.referrer,
          bsPageUrl: app.$config.appUrl + to.fullPath,
          bsPageFullPath: to.fullPath,
          bsPagePath: to.path,
          bsPageTitle: document.title,
          bsPageCounter: pageCount++,
          bsRouteName: to.name,

          ecommerce: null,
        })
      }, 300)
    })

    intercept(INTERCEPTOR_KEYS.UI_EVENT, ({ type, source, name, path }) => {
      gtmService.push({
        event: 'bs_ui_event',
        ui_event_type: type,
        ui_event_source: source,
        ui_event_name: name,
        ui_event_path: path,
      })
    })

    intercept(
      INTERCEPTOR_KEYS.PAGE_VIEW_PLP,
      ({ category, resourceIdentifier }) => {
        gtmService.setPageType('Listing', {
          categoryId: category.id || resourceIdentifier,
          categoryName: category.translated?.name,
        })
      }
    )

    intercept(
      INTERCEPTOR_KEYS.VIEW_ITEM_LIST,
      ({ products, listId, listName }) => {
        const ga4Products = products?.map((product, index) =>
          getGtmProductItem(product, {
            index,
          })
        )

        gtmService.push({ ecommerce: null })
        gtmService.push({
          event: 'view_item_list',
          ecommerce: {
            item_list_id: listId,
            item_list_name: listName,
            items: ga4Products?.slice(0, 10),
            currency,

            // GA UA
            impressions: ga4Products?.map((p) => {
              return {
                id: p.item_id,
                name: p.item_name,
                brand: p.item_brand,
                price: p.price,
                list: listName,
                position: p.index + 1,
              }
            }),
          },
        })
      }
    )

    intercept(
      INTERCEPTOR_KEYS.SELECT_PRODUCT_ITEM,
      ({ product, index, listId, listName }) => {
        const ga4Product = getGtmProductItem(product, {
          item_list_id: listId,
          item_list_name: listName,
          index,
        })

        lastListView = listName
        lastListIdView = listId

        app.$gtm.push({ ecommerce: null })
        app.$gtm.push({
          event: 'select_item',
          ecommerce: {
            items: [ga4Product],
            item_list_id: listId,
            item_list_name: listName,
            currency,

            // GA UA
            click: {
              actionField: { list: listName },
              products: [
                {
                  id: ga4Product.item_id,
                  name: ga4Product.item_name,
                  brand: ga4Product.item_brand,
                  price: ga4Product.price,
                  list: listName,
                  position: 1,
                },
              ],
            },
          },
        })
      }
    )

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_PDP, ({ product }) => {
      gtmService.setPageType('PDP')
      gtmService.push({ ecommerce: null })
      gtmService.push({
        event: 'view_item',
        bs: {
          productCategoryId: product.seoCategory?.id,
        },
        ecommerce: {
          items: [getGtmProductItem(product)],
          item_list_name: lastListView,
          item_list_id: lastListIdView,
          currency,

          // GA UA
          detail: {
            actionField: { list: lastListView },
            products: [convertGtmUaProductItem(getGtmProductItem(product))],
          },
        },
      })

      lastPdpListView = lastListView
      lastPdpListIdView = lastListIdView
      lastListView = undefined
      lastListIdView = undefined
    })

    intercept(INTERCEPTOR_KEYS.ADD_TO_CART, ({ product }) => {
      const gtmProduct = getGtmProductItem(product, {
        quantity: 1,
        index: 0,
        item_list_name: lastPdpListView,
        item_list_id: lastPdpListIdView,
      })

      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'add_to_cart',
        ecommerce: {
          currency,
          value: getProductRealPriceNet(product),
          items: [gtmProduct],

          // GA UA
          currencyCode: currency,
          add: {
            actionField: { list: lastPdpListView },
            products: [convertGtmUaProductItem(gtmProduct)],
          },
        },
      })
    })

    intercept(INTERCEPTOR_KEYS.REMOVE_FROM_CART, ({ lineItem }) => {
      if (!lineItem) {
        return
      }

      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'remove_from_cart',
        ecommerce: {
          currency,
          value: lineItem.price?.netPrice,
          items: [getGtmLineItemProduct(lineItem)],

          // GA UA
          remove: {
            products: [
              convertGtmUaProductItem(getGtmLineItemProduct(lineItem)),
            ],
          },
        },
      })
    })

    intercept(
      INTERCEPTOR_KEYS.CHANGE_CART_QUANTITY,
      ({ quantity, lineItem }) => {
        if (!lineItem) {
          return
        }

        const changeQuantity = quantity - lineItem.quantity
        const ecommerce = {
          currency,
          value: getLineItemNetPrice(lineItem) * Math.abs(changeQuantity),
          items: [
            getGtmLineItemProduct(lineItem, {
              index: 0,
              quantity: Math.abs(changeQuantity),
            }),
          ],
        }

        app.$gtm.push({ ecommerce: null })

        if (changeQuantity < 0) {
          app.$gtm.push({
            event: 'remove_from_cart',
            ecommerce,
          })
        } else if (changeQuantity > 0) {
          app.$gtm.push({
            event: 'add_to_cart',
            ecommerce,
          })
        }
      }
    )

    intercept(INTERCEPTOR_KEYS.ADD_TO_WISHLIST, ({ product }) => {
      if (!product) {
        return
      }

      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'add_to_wishlist',
        ecommerce: {
          currency,
          items: [
            getGtmProductItem(product, {
              quantity: 1,
              index: 0,
            }),
          ],
        },
      })
    })

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_CART, ({ cart }) => {
      gtmService.setPageType('cart')
      gtmService.push({ ecommerce: null })
      gtmService.push({
        event: 'view_cart',
        ecommerce: {
          currency,
          value: cart?.price?.netPrice,
          items: cart?.lineItems
            .map((lineItem, index) => {
              return {
                ...getGtmLineItemProduct(lineItem, {
                  index,
                }),
              }
            })
            .filter((p) => !!p && Object.keys(p).length > 0),
        },
      })
    })

    intercept(INTERCEPTOR_KEYS.SIDE_CART_OPEN, ({ cart }) => {
      gtmService.push({ ecommerce: null })
      gtmService.push({
        event: 'view_side_cart',
        ecommerce: {
          currency,
          value: cart?.price?.netPrice,
          items: cart?.lineItems
            .map((lineItem, index) => {
              return {
                ...getGtmLineItemProduct(lineItem, {
                  index,
                }),
              }
            })
            .filter((p) => !!p && Object.keys(p).length > 0),
        },
      })
    })

    intercept(INTERCEPTOR_KEYS.ORDER_PLACE, ({ order }) => {
      const coupon = order.lineItems.filter(
        (cartItem) => cartItem.type === 'promotion'
      )?.[0]

      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'purchase',
        bsTransactions: {
          email: order.orderCustomer?.email,
          paymentShortName: order.transactions?.[0]?.paymentMethod.shortName,
          paymentName: order.transactions?.[0]?.paymentMethod.translated.name,
        },
        ecommerce: {
          transaction_id: order.orderNumber,
          currency,
          tax: Number(
            (order.price.totalPrice - order.price.netPrice).toFixed(2)
          ),
          shipping: order.shippingTotal,
          value: order.price.netPrice,
          coupon: coupon?.payload?.code || coupon?.label,
          items: order.lineItems
            .map((lineItem, index) => {
              return {
                ...getGtmLineItemProduct(lineItem, {
                  index,
                }),
              }
            })
            .filter((p) => !!p && Object.keys(p).length > 0),

          // GA UA
          purchase: {
            actionField: {
              id: order.orderNumber,
              affiliation: app.$config.isMaBuBuild
                ? 'MaBu Kids'
                : 'Baby Sweets',
              revenue: order.price.netPrice,
              tax: Number(
                (order.price.totalPrice - order.price.netPrice).toFixed(2)
              ),
              shipping: order.shippingTotal,
              coupon: coupon?.payload?.code || coupon?.label,
            },
            products: order.lineItems
              .map((lineItem, index) => {
                return convertGtmUaProductItem(
                  getGtmLineItemProduct(lineItem, {
                    index,
                  })
                )
              })
              .filter((p) => !!p),
          },
        },

        // GA UA
        transactionId: order.orderNumber,
        transactionTotal: order.price.netPrice,
      })

      app.$gtm.push({ bsTransactions: null })
    })

    intercept(
      INTERCEPTOR_KEYS.PAGE_VIEW_CHECKOUT_SUCCESS_UNIQUE,
      ({ orderDetails }) => {
        const coupon = orderDetails.lineItems.filter(
          (cartItem) => cartItem.type === 'promotion'
        )?.[0]

        const tax = orderDetails.price.taxRules[0]?.taxRate / 100 + 1 || 1

        app.$gtm.push({ ecommerce: null })
        app.$gtm.push({
          event: 'checkout_success_unique',
          bsTransactions: {
            email: orderDetails.orderCustomer?.email,
            paymentShortName:
              orderDetails.transactions?.[0]?.paymentMethod.shortName,
            paymentName:
              orderDetails.transactions?.[0]?.paymentMethod.translated.name,
          },
          ecommerce: {
            transaction_id: orderDetails.orderNumber,
            currency,
            tax: Number(
              (
                orderDetails.price.totalPrice - orderDetails.price.netPrice
              ).toFixed(2)
            ),
            shipping: parseFloat((orderDetails.shippingTotal / tax).toFixed(2)),
            value: orderDetails.price.netPrice,
            coupon: coupon?.payload?.code || coupon?.label,
            positionPrice: parseFloat(
              (orderDetails.positionPrice / tax).toFixed(2)
            ),
            items: orderDetails.lineItems
              .map((lineItem, index) => {
                return {
                  ...getGtmLineItemProduct(lineItem, {
                    index,
                  }),
                }
              })
              .filter((p) => !!p && Object.keys(p).length > 0),
          },
        })
        app.$gtm.push({ ecommerce: null, bsTransactions: null })
      }
    )

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_CHECKOUT, ({ cart }) => {
      gtmService.setPageType('checkout')
      gtmService.push({ ecommerce: null })
      gtmService.push({
        event: 'begin_checkout',
        ecommerce: {
          currency,
          value: cart.price?.netPrice,
          items: cart.lineItems
            .map((lineItem, index) => {
              return {
                ...getGtmLineItemProduct(lineItem, {
                  index,
                }),
              }
            })
            .filter((p) => !!p && Object.keys(p).length > 0),
        },
      })
    })

    intercept(INTERCEPTOR_KEYS.USER_LOGIN, () => {
      gtmService.push({
        event: 'login',
      })
    })

    intercept(INTERCEPTOR_KEYS.USER_REGISTER, () => {
      gtmService.push({
        event: 'sign_up',
      })
    })

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_SEARCH, ({ term }) => {
      gtmService.setPageType('search')

      gtmService.push({
        event: 'search',
        search_term: term,
      })
    })

    intercept(INTERCEPTOR_KEYS.QUICK_SEARCH, ({ term }) => {
      gtmService.push({
        event: 'quick_search',
        search_term: term,
      })
    })

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_CHECKOUT_STEP_LOGIN, () => {
      gtmService.push({
        event: 'checkout_step',
        checkout_step_name: 'login',
      })
    })
    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_CHECKOUT_STEP_ADDRESS, () => {
      gtmService.push({
        event: 'checkout_step',
        checkout_step_name: 'address',
      })
    })
    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_CHECKOUT_STEP_PAYMENT, () => {
      gtmService.push({
        event: 'checkout_step',
        checkout_step_name: 'payment',
      })
    })
    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_CHECKOUT_STEP_CONFIRMATION, () => {
      gtmService.push({
        event: 'checkout_step',
        checkout_step_name: 'confirmation',
      })
    })
  })
}
