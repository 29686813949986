<template>
  <div v-if="shouldShow">
    <nuxt-link :to="localePath(url)" class="text-decoration-none">
      <div
        :style="contentStyle"
        class="marketing-four-for-three-action text-body-2"
      >
        {{ content }}
        <base-button
          v-if="closeButton"
          icon
          style="position: absolute; top: 5px; right: 5px; color: inherit"
          @click.prevent="hide"
        >
          <base-icon mdi="close"></base-icon>
        </base-button>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import { ref, computed } from '@nuxtjs/composition-api'
import BaseIcon from '~/components/base/icon/BaseIcon'
import { usePageConfig } from '~/composables/logic/usePageConfig'
import { getApplicationContext } from '~/composables'
import { getFirstActivePageConfig } from '~/helpers/pageConfig/getFirstActivePageConfig'

export default {
  name: 'MarketingFourForThreeAction',
  components: { BaseIcon },
  props: {
    closeButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const { i18n } = getApplicationContext(root)
    const { pageConfig } = usePageConfig(root)
    const show = ref(true)

    const action = computed(() => {
      return getFirstActivePageConfig(
        pageConfig.value?.fourForThreeActions || []
      )
    })

    const shouldShow = computed(() => {
      return !!action.value && show.value
    })

    const url = computed(() => {
      return action.value?.translated.url
    })

    const contentStyle = computed(() => {
      const backgroundColor = action.value?.translated.backgroundColor
      const textColor = action.value?.translated.textColor

      return {
        backgroundColor: backgroundColor || '#CE0F69',
        color: textColor || '#FFFFFF',
      }
    })

    const content = computed(() => {
      const content = action.value?.translated.content

      return content || i18n.t('commons.fourForThreeAction')
    })

    const hide = () => {
      show.value = false
    }

    return {
      shouldShow,
      url,
      contentStyle,
      content,
      hide,
    }
  },
}
</script>

<style lang="scss">
.marketing-four-for-three-action {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  text-decoration: none;
}
</style>
