import { computed, ComputedRef, ref, Ref } from '@vue/composition-api'
import deepmerge from 'deepmerge'
import { ApplicationVueContext } from '../appContext'
import { parseUrlQuery } from '~/helpers'
import { getApplicationContext, useDefaults, useSentry } from '~/composables'
import { getCmsPage } from '~/shopware-6-client'
import {
  CmsPage,
  PageResolverProductResult,
  PageResolverResult,
} from '~/commons/interfaces/models/content/cms/CmsPage'
import { SearchCriteria } from '~/commons/interfaces/search/SearchCriteria'
import { ShopwareError } from '~/commons/interfaces/errors/ApiError'

export interface IUseCmsPage {
  page: ComputedRef<
    PageResolverProductResult | PageResolverResult<CmsPage> | null
  >
  resourceIdentifier: ComputedRef<string | null>
  loading: Ref<boolean>
  search: (path: string, query?: any) => Promise<void>
  errors: Ref<ShopwareError[]>
  cmsPage: ComputedRef<CmsPage | null | undefined>
}

/**
 * @beta
 */
export function useCmsPage(rootContext: ApplicationVueContext): IUseCmsPage {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useCms'
  )

  const { getDefaults } = useDefaults(rootContext, contextName)
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const errors: Ref<ShopwareError[]> = ref([])
  const loading: Ref<boolean> = ref(false)

  const page: Ref<
    PageResolverProductResult | PageResolverResult<CmsPage> | null
  > = ref(null)

  const resourceIdentifier = computed(() => {
    return page.value && page.value.resourceIdentifier
  })

  const cmsPage = computed(() => {
    // @ts-ignore
    return page.value?.cmsPage
  })

  /**
   * @beta
   */
  const search = async (path: string, query?: any): Promise<void> => {
    loading.value = true
    errors.value = []

    const criteria: SearchCriteria = parseUrlQuery(query)
    const searchCriteria = deepmerge(getDefaults(), criteria)

    try {
      page.value = await getCmsPage(path, searchCriteria, apiInstance)
    } catch (e) {
      errors.value = e.messages

      captureClientApiError('[useCms][search]', e)
    } finally {
      loading.value = false
    }
  }

  return {
    page,
    resourceIdentifier,
    loading,
    search,
    errors,
    cmsPage,
  }
}
