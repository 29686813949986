<script>
import { getCmsSlotConfigProperty, isCmsSlotActive } from '~/helpers'
import CmsUtilLink from '~/components/cms/util/CmsUtilLink'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'CmsElementImage',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props }) {
    function getRatioAspect(aspect) {
      const split = aspect?.split('/')

      if (split && split.length === 2) {
        return Number(split[0]) / Number(split[1])
      }

      return null
    }

    function genResponsive(content, ratioAspect) {
      return ratioAspect
        ? h(
            'div',
            {
              staticClass: 'base-responsive',
              style: {
                aspectRatio:
                  getCmsSlotConfigProperty(props.content, 'ratioAspect') ||
                  undefined,
              },
            },
            [
              h('div', {
                staticClass: 'base-responsive__sizer',
                staticStyle: {
                  paddingBottom: (1 / ratioAspect) * 100 + '%',
                },
              }),
              h(
                'div',
                {
                  staticClass: 'base-responsive__content d-flex',
                },
                [content]
              ),
            ]
          )
        : content
    }

    const content = props.content

    if (!isCmsSlotActive(content)) {
      return null
    }

    const {
      data: { media, url },
    } = content

    const isCover = getCmsSlotConfigProperty(content, 'displayMode') === 'cover'
    const anchor = getCmsSlotConfigProperty(content, 'anchor') || undefined
    const loading =
      getCmsSlotConfigProperty(content, 'disableLazy') || false
        ? 'eager'
        : 'lazy'

    const sizes = getCmsSlotConfigProperty(content, 'sizes') || undefined
    const srcsetSizes =
      getCmsSlotConfigProperty(content, 'srcsetSizes') || undefined

    const proxySrcset = srcsetSizes
      ? srcsetSizes.split(',').map((v) => parseInt(v.trim()))
      : [300, 500, 800, 1000]

    const image = h(BaseImage, {
      staticClass: 'cms-element-image',
      class: {
        'cms-element-image--cover': isCover,
      },
      style: {
        height: isCover
          ? getCmsSlotConfigProperty(content, 'minHeight')
          : undefined,
      },
      props: {
        media,
        useMediaSrcset: true,
        initWidth: 1000,
        proxySrcset,
      },
      attrs: {
        loading,
        id: anchor,
        sizes,
      },
    })

    return genResponsive(
      url
        ? h(
            CmsUtilLink,
            {
              props: {
                url,
              },
            },
            [image]
          )
        : image,
      getRatioAspect(getCmsSlotConfigProperty(content, 'ratioAspect'))
    )
  },
}
</script>
