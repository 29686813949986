import { ApplicationVueContext } from '../appContext'
import {
  useCms,
  getApplicationContext,
  useDefaults,
  createListingComposable,
  IUseListing,
} from '~/composables'

import { ShopwareSearchParams } from '~/commons/interfaces/search/SearchCriteria'
import { Blog } from '~/commons/interfaces/models/content/blog/Blog'
import { getCategoryBlogs } from '~/shopware-6-client/services/blogService'

/**
 * @beta
 */
export const useBlogListing = (
  rootContext: ApplicationVueContext
): IUseListing<Blog> => {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useBlogListing'
  )
  const { getDefaults } = useDefaults(rootContext, contextName)

  const { categoryId } = useCms(rootContext)
  const searchMethod: any = (searchCriteria: Partial<ShopwareSearchParams>) => {
    if (!categoryId.value) {
      throw new Error(
        '[useBlogListing][search] Search category id does not exist.'
      )
    }
    return getCategoryBlogs(categoryId.value, searchCriteria, apiInstance)
  }

  return createListingComposable<Blog>({
    rootContext,
    listingKey: 'blogListing',
    searchMethod,
    searchDefaults: getDefaults(),
  })
}
