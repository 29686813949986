import { ComputedRef, Ref, ref } from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'

import { Order } from '~/commons/interfaces/models/checkout/order/Order'
import { ShopwareError } from '~/commons/interfaces/errors/ApiError'
import { getCustomerOrders } from '~/shopware-6-client'
import { useSentry } from '~/composables'

/**
 * Composable for managing an existing order.
 *
 * @beta
 */
export function useLastOrder(rootContext: ApplicationVueContext): {
  order: ComputedRef<Order | undefined | null>
  loadLastOrder: () => Promise<void>
  loading: Ref<boolean>
  error: Ref<ShopwareError[]>
} {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useLastOrder'
  )
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const order: Ref<Order | null> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<ShopwareError[]> = ref([])

  const loadLastOrder = async (): Promise<void> => {
    loading.value = true
    error.value = []

    try {
      const orders = await getCustomerOrders(
        {
          sort: '-createdAt',
          limit: 1,
          associations: {
            lineItems: {},
            transactions: {
              sort: '-createdAt',
              associations: {
                paymentMethod: {},
              },
            },
            deliveries: {},
          },
        },
        apiInstance
      )

      order.value = orders[0]
    } catch (e) {
      error.value = e.messages
      captureClientApiError(`[${contextName}][loadLastOrder]`, e)
    } finally {
      loading.value = false
    }
  }

  return {
    order,
    loadLastOrder,
    loading,
    error,
  }
}
