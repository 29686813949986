import { AxiosRequestConfig } from 'axios'

export function createRequestTimingInterceptor() {
  return (requestConfig: AxiosRequestConfig | any) => {
    requestConfig.meta = requestConfig.meta || {}
    requestConfig.meta.requestStartedAt = new Date().getTime()

    return requestConfig
  }
}
