import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductProperties, UiProductProperty } from '~/helpers'

export declare interface UiProductPropertyGroup {
  name: string
  values: string[]
}

export function getProductPropertiesGrouped(
  product: Product
): UiProductPropertyGroup[] {
  const properties = getProductProperties({ product })
  const grouped = properties.reduce((rv: any, x: UiProductProperty) => {
    ;(rv[x.name] = rv[x.name] || []).push(x.value)

    return rv
  }, {})

  return Object.entries(grouped)
    .map(([name, values]: [any, any]) => ({
      name,
      values: values.sort(),
    }))
    .sort((a, b) => a.name.localeCompare(b.name)) as UiProductPropertyGroup[]
}
