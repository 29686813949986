import { Product } from '~/commons/interfaces/models/content/product/Product'

/**
 * @beta
 */
export function isProductLowStock(product: Product): boolean {
  return (
    product.availableStock !== null &&
    product.availableStock < 15 &&
    product.availableStock > 0 &&
    product.available
  )
}
