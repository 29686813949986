<template>
  <div>
    <newsletter-dialog v-if="booted" v-model="open"></newsletter-dialog>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from '@nuxtjs/composition-api'
import { INTERCEPTOR_KEYS, useIntercept, useUser } from '~/composables'

export default {
  name: 'NewsletterContainer',
  components: {
    NewsletterDialog: () => import('~/components/newsletter/NewsletterDialog'),
  },
  setup(_, { root }) {
    const open = ref(false)
    const booted = ref(false)
    const { isLoggedIn } = useUser(root)
    const { intercept } = useIntercept(root)

    const openCallback = ({ detail }) => {
      if (detail?.event === 'action' && detail?.payload === 'openNewsletter') {
        open.value = true
      } else if (
        detail?.event === 'action' &&
        detail?.payload === 'openNewsletterKM'
      ) {
        if (!isLoggedIn.value) {
          open.value = true
        }
      }
    }

    onMounted(() => {
      window.addEventListener('bs-event-bus', openCallback)
    })

    onUnmounted(() => {
      window.removeEventListener('bs-event-bus', openCallback)
    })

    watch(
      open,
      (isOpen) => {
        if (isOpen) {
          booted.value = true
        }
      },
      { immediate: true }
    )

    intercept(INTERCEPTOR_KEYS.ACTION_CLICK_EVENT, ({ name }) => {
      if (name === 'newsletter-dialog') {
        open.value = true
      }
    })

    return {
      open,
      booted,
    }
  },
}
</script>
