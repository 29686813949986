import {
  PaymentServiceBeforeOrderResult,
  PaymentServiceInterface,
  PaymentServiceOptionsInterface,
} from '~/services/payments/PaymentServiceInterface'
import { ApplicationVueContext } from '~/composables/appContext'

export const createFallbackPaymentService = (
  _: ApplicationVueContext
): PaymentServiceInterface => {
  const componentName: string = ''

  const isSupported = () => {
    return true
  }

  const inputFields = {}

  const beforeOrderHook = async (
    _: ApplicationVueContext
  ): Promise<PaymentServiceBeforeOrderResult> => {
    return await new Promise((resolve) => {
      resolve({
        isSuccessful: true,
        payload: null,
      })
    })
  }

  const getHandlePaymentPayload = () => {
    return null
  }

  const getOptions = (): PaymentServiceOptionsInterface => ({})

  return {
    componentName,
    isSupported,
    inputFields,
    beforeOrderHook,
    getHandlePaymentPayload,
    getOptions,
  }
}
