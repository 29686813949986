import { Product } from '~/commons/interfaces/models/content/product/Product'

/**
 * @beta
 */
export function getProductName({ product }: { product?: Product } = {}):
  | string
  | null {
  if (!product) {
    return null
  }
  return product.translated?.name || product.name
}
