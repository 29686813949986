<template>
  <div class="px-3 py-7 pa-sm-16 grey-lighten-4 text-center w-full">
    <div
      class="
        text-h3 text-md-h2 text-uppercase
        footer-newsletter-card-text__title
        d-inline-block
      "
    >
      {{ $t('newsletter.signUp') }}
    </div>
    <div class="text-body-2 mt-3 mt-sm-5 mb-5 mb-md-11">
      {{ $t('newsletter.benefit') }}
    </div>
    <div class="d-flex flex-column flex-md-row align-center justify-center">
      <base-button
        :to="localePath(getTechnicalPath(CAT_TECHNICAL_NAME_NEWSLETTER))"
        rounded
        bold
        class="pink-berry white--text"
        large
      >
        {{ $t('newsletter.signNow') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '~/components/base/button/BaseButton'
import { useCategoryPager } from '~/composables'

export default {
  name: 'FooterNewsletterCardText',
  components: { BaseButton },
  setup(_, { root }) {
    const { getTechnicalPath, CAT_TECHNICAL_NAME_NEWSLETTER } =
      useCategoryPager(root)

    return {
      getTechnicalPath,
      CAT_TECHNICAL_NAME_NEWSLETTER,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.footer-newsletter-card-text__title {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    max-width: 290px;
  }
}
</style>
