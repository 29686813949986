<template>
  <component :is="getComponentName" :content="content" :style="slotStyles" />
</template>

<script>
import { getCmsElementComponentName } from '~/logic/cms'
import CmsElementText from '~/components/cms/elements/CmsElementText'
import CmsElementImage from '~/components/cms/elements/CmsElementImage'
import { hydrateWhenVisible } from '~/logic/lazy-hydrate/LazyHydrate'
import CmsElementProductListing from '~/components/cms/elements/CmsElementProductListing.vue'
import CmsElementCategorySidebarFilter from '~/components/cms/elements/CmsElementCategorySidebarFilter.vue'

export default {
  name: 'CmsGenericElement',
  components: {
    CmsElementText,
    CmsElementImage,
    CmsElementProductListing,
    CmsElementCategorySidebarFilter,
    CmsElementImageSlider: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementImageSlider')
    ),
    CmsElementImageHero: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementImageHero')
    ),
    CmsElementCategoryNavigation: () =>
      import('~/components/cms/elements/CmsElementCategoryNavigation'),
    CmsElementCategoryNavigationCircle: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementCategoryNavigationCircle')
    ),
    CmsElementBsBlogListing: () =>
      import('~/components/cms/elements/CmsElementBsBlogListing'),
    CmsElementBsBlogSingleEntry: () =>
      import('~/components/cms/elements/CmsElementBsBlogSingleEntry'),
    CmsElementBsBlogDetail: () =>
      import('~/components/cms/elements/CmsElementBsBlogDetail'),
    CmsElementBsBlogDetailAuthor: () =>
      import('~/components/cms/elements/CmsElementBsBlogDetailAuthor'),
    CmsElementBsBlogSlider: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementBsBlogSlider')
    ),
    CmsElementProductSlider: () =>
      import('~/components/cms/elements/CmsElementProductSlider'),
    CmsElementBsProductGrid: () =>
      import('~/components/cms/elements/CmsElementBsProductGrid'),
    CmsElementCategoryCircleSlider: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementCategoryCircleSlider')
    ),
    CmsElementBsTextExpansion: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementBsTextExpansion')
    ),
    CmsElementImageTextBox: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementImageTextBox')
    ),
    CmsElementBsImageText: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementBsImageText')
    ),
    CmsElementBsImageCircle: () =>
      import('~/components/cms/elements/CmsElementBsImageCircle'),
    CmsElementBsImageCircleSlider: hydrateWhenVisible(() =>
      import('~/components/cms/elements/CmsElementBsImageCircleSlider')
    ),
    CmsElementBsScript: () =>
      import('~/components/cms/elements/CmsElementBsScript'),
    CmsElementBsVideo: () =>
      import('~/components/cms/elements/CmsElementBsVideo'),
    CmsElementBsProductSaleCounter: () =>
      import('~/components/cms/elements/CmsElementBsProductSaleCounter'),
    CmsNoComponent: () => import('~/components/cms/CmsNoComponent'),
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getComponentName() {
      return getCmsElementComponentName(this.content)
    },
    backgroundMediaMode() {
      return this.content.backgroundMediaMode
    },
    slotStyles() {
      const { backgroundMedia } = this.content
      return {
        backgroundImage: backgroundMedia ? `url(${backgroundMedia.url})` : null,
      }
    },
  },
}
</script>
