import { effectScope } from '@nuxtjs/composition-api'
import {
  getApplicationContext,
  INTERCEPTOR_KEYS,
  useIntercept,
} from '~/composables'
import BsTracking from '~/services/tracking/BsTracking'

export default ({ app }) => {
  const scope = effectScope()
  const currency = 'EUR'

  scope.run(() => {
    const { intercept } = useIntercept(app)
    const { apiInstance } = getApplicationContext(app, 'bsTracking')

    const bsTracking = new BsTracking(currency, apiInstance)

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_PDP, ({ product }, { eventId }) => {
      bsTracking.trackViewPdp({ product, eventId })
    })

    intercept(INTERCEPTOR_KEYS.ADD_TO_CART, ({ product }, { eventId }) => {
      bsTracking.trackAddToCart({ product, eventId })
    })

    intercept(INTERCEPTOR_KEYS.ADD_TO_WISHLIST, ({ product }, { eventId }) => {
      if (!product) {
        return
      }

      bsTracking.trackAddToWishlist({ product, eventId })
    })

    intercept(INTERCEPTOR_KEYS.ORDER_PLACE, ({ order }) => {
      bsTracking.trackOrderPlace({ order })
    })

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_CHECKOUT, ({ cart }, { eventId }) => {
      bsTracking.trackViewCheckout({ cart, eventId })
    })

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_SEARCH, ({ term }, { eventId }) => {
      bsTracking.trackViewSearch({ term, eventId })
    })
  })
}
