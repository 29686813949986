import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

/**
 * @beta
 */
export async function createOrderPaypalExpress(
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<any> {
  const resp = await contextInstance.invoke.post(
    '/store-api/paypal/express/create-order',
    {}
  )

  return resp.data
}

/**
 * @beta
 */
export async function prepareCheckoutPaypalExpress(
  token: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<any> {
  const resp = await contextInstance.invoke.post(
    '/store-api/paypal/express/prepare-checkout',
    {
      token,
    }
  )

  return resp.data
}

export interface PrepareCheckoutPaypalPlusResponse {
  approvalUrl: string
  checkoutOrderUrl: string
  contextSwitchUrl: string
  customerCountryIso: string
  customerSelectedLanguage: string
  extensions: any
  handlePaymentUrl: string
  isEnabledParameterName: string
  languageId: string
  mode: string
  orderId: null | string
  paymentMethodId: string
  paypalPaymentId: string
  paypalToken: string
  setPaymentRouteUrl: string
}

/**
 * @beta
 */
export async function prepareCheckoutPaypalPlus(
  orderId?: string,
  domain?: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PrepareCheckoutPaypalPlusResponse> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-paypal-extension/plus/start-session',
    {
      orderId,
      domain,
    }
  )

  return resp.data
}

/**
 * @beta
 */
export async function payPalPlusFinalizeTransaction(
  params: any,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<any> {
  const resp = await contextInstance.invoke.get(
    '/store-api/bs-paypal-extension/plus/finalize-transaction',
    {
      params,
      maxRedirects: 0,
    }
  )

  return resp.data
}

/**
 * @beta
 */
export async function startPAMSession(
  orderId?: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PrepareCheckoutPaypalPlusResponse> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-paypal-extension/apm/start-session',
    {
      orderId,
    }
  )

  return resp.data
}

/**
 * @beta
 */
export async function paypalCreateOrder(
  data: object,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PrepareCheckoutPaypalPlusResponse> {
  const resp = await contextInstance.invoke.post(
    '/store-api/paypal/create-order',
    data
  )

  return resp.data
}

/**
 * @beta
 */
export async function paypalPuiPaymentInstructions(
  transactionId: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<any> {
  const resp = await contextInstance.invoke.get(
    `/store-api/paypal/pui/payment-instructions/${transactionId}`
  )

  return resp.data
}

export interface PayPalPuiDataResponse {
  extensions: any
  paymentMethodId: string
  sandbox: boolean
  sessionIdentifier: string
  websiteIdentifier: string
}

/**
 * @beta
 */
export async function paypalPuiData(
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PayPalPuiDataResponse> {
  const resp = await contextInstance.invoke.post(
    `/store-api/bs-paypal-extension/pui/data`
  )

  return resp.data
}
