import Vue from 'vue'

let decoder
Vue.prototype.$entitiesDecoder = (text) => {
  if (
    text === ' ' ||
    (!text.includes('&') && !text.includes('<') && !text.includes('>'))
  ) {
    return text
  }

  decoder = decoder || document.createElement('div')
  decoder.innerHTML = text

  return decoder.textContent
}
