<template>
  <div class="app-bar-icon" :class="classes">
    <base-button icon :title="title" large no-ripple :to="to" v-on="$listeners">
      <base-icon v-if="icon || svg" large :mdi="icon" :svg="svg"></base-icon>
      <slot></slot>
    </base-button>
    <span class="app-bar-icon__tooltip" :class="tooltipClass">
      {{ tooltip }}
    </span>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'
export default {
  name: 'AppBarIcon',
  components: { BaseButton, BaseIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    svg: {
      type: String,
      default: undefined,
    },
    to: {
      type: String,
      default: undefined,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    tooltipClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    classes() {
      return {
        'app-bar-icon--bottom': this.bottom,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-icon {
  position: relative;
  display: inline-block;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &:hover > &__tooltip {
      opacity: 1;
    }
  }

  &--bottom {
    .app-bar-icon__tooltip {
      opacity: 1 !important;
      top: 38px;
      color: map-get($shades, 'grey-darken-4');
    }
  }

  &__tooltip {
    opacity: 0;
    font-size: 0.65rem;
    white-space: nowrap;
    position: absolute;
    top: -16px;
    left: 50%;
    color: map-get($shades, 'grey-darken-2');
    transform: translateX(-50%);
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
</style>
