<template>
  <div ref="element" :role="disable ? undefined : 'button'" @click="invokeCopy">
    <slot name="default"></slot>
    <input ref="copy" type="hidden" />
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api'
import { getApplicationContext, useNotifications } from '~/composables'

export default {
  name: 'BaseUtilsCopyToClipboard',
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { root }) {
    const { pushSuccess } = useNotifications(root)
    const { i18n } = getApplicationContext(root)

    const element = ref()
    const copy = ref()

    function invokeCopy() {
      if (props.disable) {
        return
      }

      copy.value.value = props.value || element.value.textContent.trim()
      copy.value.setAttribute('type', 'text')
      copy.value.select()

      try {
        if (document.execCommand('copy')) {
          if (props.successMessage) {
            pushSuccess(props.successMessage)
          } else if (props.successMessage === undefined) {
            pushSuccess(i18n.t('notification.copiedToClipboard'))
          }
        }
      } catch (err) {
      } finally {
        window.getSelection().removeAllRanges()
        copy.value.setAttribute('type', 'hidden')
      }
    }

    return {
      element,
      copy,
      invokeCopy,
    }
  },
}
</script>
