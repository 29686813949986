import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

export interface BsUnzerConfigurationResponse {
  publicKey: string
  testData: boolean
}

/**
 * @beta
 */
export async function bsUnzerConfiguration(
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<BsUnzerConfigurationResponse> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs/unzer/configuration',
    {}
  )

  return resp.data
}
