import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { OrderLineItem } from '~/commons/interfaces/models/checkout/order/OrderLineItem'
import {
  getLineItemCalculatedTax,
  getLineItemPriceDefinitionNetPrice,
  getLineItemUnitPrice,
} from '~/helpers'

export function getLineItemNetPrice<T extends LineItem | OrderLineItem>(
  lineItem: T
): number | undefined {
  const price = getLineItemUnitPrice(lineItem)
  const tax =
    getLineItemCalculatedTax(lineItem) ||
    getLineItemPriceDefinitionNetPrice(lineItem)

  const netPrice = Number(price) - Number(tax)

  return isNaN(netPrice) ? price : Number(netPrice.toFixed(2))
}
