<!--<template>-->
<!--  <v-container fluid class="pa-0">-->
<!--    <v-row>-->
<!--      <v-col v-show="isWrapMobile" cols="12" md="4" lg="3">-->
<!--        <cms-generic-block-->
<!--          v-for="cmsBlock in sidebarBlocks"-->
<!--          :key="cmsBlock.id"-->
<!--          :content="cmsBlock"-->
<!--        ></cms-generic-block>-->
<!--      </v-col>-->
<!--      <v-col cols="12" md="8" lg="9">-->
<!--        <cms-generic-block-->
<!--          v-for="cmsBlock in mainBlocks"-->
<!--          :key="cmsBlock.id"-->
<!--          :content="cmsBlock"-->
<!--        ></cms-generic-block>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--  </v-container>-->
<!--</template>-->

<script>
import { VCol, VContainer, VRow } from 'vuetify/lib'
import CmsGenericBlock from '~/components/cms/CmsGenericBlock'

export default {
  name: 'CmsSectionSidebar',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, context) {
    const content = context.props.content

    const filterBlockPositions = (pos) => {
      return (content.blocks || []).filter(
        ({ sectionPosition }) => sectionPosition === pos
      )
    }

    const genGenericBlocks = (blocks) => {
      return blocks.map((block) => {
        return h(CmsGenericBlock, {
          props: {
            content: block,
          },
          key: block.id,
        })
      })
    }

    const genVCol = ({ cols, md, lg }, blocks, staticClass = undefined) => {
      return h(
        VCol,
        {
          staticClass,
          props: {
            cols,
            md,
            lg,
          },
        },
        blocks
      )
    }

    return h(
      VContainer,
      {
        staticClass: 'pa-0',
        class: context.data.staticClass
          ? [context.data.staticClass]
          : undefined,
        props: {
          fluid: true,
        },
      },
      [
        h(VRow, [
          genVCol(
            {
              cols: 12,
              md: 4,
              lg: 3,
            },
            genGenericBlocks(filterBlockPositions('sidebar')),
            content.mobileBehavior !== 'wrap' ? 'hidden-sm-and-down' : ''
          ),
          genVCol(
            {
              cols: 12,
              md: 8,
              lg: 9,
            },
            genGenericBlocks(filterBlockPositions('main'))
          ),
        ]),
      ]
    )
  },
}
</script>
