<script>
import ProductListingFilter from '~/components/product/listing/ProductListingFilter'

export default {
  name: 'CmsElementCategorySidebarFilter',
  render(h) {
    return h(ProductListingFilter)
  },
}
</script>
