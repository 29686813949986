<template>
  <div class="product-listing-filter">
    <div class="d-flex">
      <div class="pa-0" @click="handleFilterClick">
        <lazy-hydrate when-idle>
          <base-input-select
            class="hidden-sm-and-down product-listing-filter__select"
            prepend-inner-icon="$filter"
            :label="$t('commons.filter')"
            hide-details
            readonly
            force-outlined
            style="max-width: 230px"
            hide-selected
          ></base-input-select>
        </lazy-hydrate>
        <div
          class="
            pa-2
            d-flex d-md-none
            flex-column
            align-center
            hidden-md-and-up
          "
          role="button"
        >
          <base-icon large mdi="filter"></base-icon>
          <span class="text-body-4">{{ $t('commons.filter') }}</span>
        </div>
      </div>
      <v-spacer></v-spacer>

      <div
        class="hidden-md-and-up flex-column align-center pa-2"
        role="button"
        style="display: flex"
        @click="handleSortClick"
      >
        <base-icon large mdi="sort"></base-icon>
        <span class="text-body-4">{{ $t('commons.sorting') }}</span>
      </div>
      <base-bottom-sheet-list-card
        v-if="sortBottonSheetBooted"
        v-model="currentSortingOrder"
        :open="sortBottonSheet"
        :title="$t('commons.sorting')"
        :items="sortingList"
        @change:open="sortBottonSheet = $event"
      ></base-bottom-sheet-list-card>

      <client-only>
        <lazy-hydrate when-idle>
          <base-input-select
            v-model="currentSortingOrder"
            class="hidden-sm-and-down"
            :items="sortingList"
            :label="$t('commons.sorting')"
            style="max-width: 230px"
            hide-details
            force-outlined
            hide-selected
            @click="uiEventClickSort"
          ></base-input-select>
        </lazy-hydrate>
      </client-only>
    </div>
    <product-listing-filter-chip-group
      class="mt-4"
      :items="filterItems"
      @click:remove="removeFilter"
      @click:clear="clearFilters"
    ></product-listing-filter-chip-group>
    <client-only>
      <product-listing-filter-drawer
        v-if="drawerBooted"
        v-model="drawer"
        :loading="loading"
        :loading-aggregations="loadingAggregations"
        :available-filters="getAvailableFilters"
        :filter-values="filterValues"
        :filter-items="filterItems"
        :total="getTotal"
        @change="invokeFilter"
        @input:filter="handleInputFilter"
        @click:remove="removeFilter"
        @click:clear="clearFilters"
      ></product-listing-filter-drawer>
    </client-only>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { useListing, useListingFilter, useUiEvent } from '~/composables'
import ProductListingFilterChipGroup from '~/components/product/listing/filter/ProductListingFilterChipGroup'
import BaseInputSelect from '~/components/base/input/BaseInputSelect'
import BaseIcon from '~/components/base/icon/BaseIcon'

// TODO: erst im Listing werden die Daten für useListing gesetzt, dadurch stehen im SSR hier die infos noch nicht zur verfügung
export default {
  name: 'ProductListingFilter',
  components: {
    BaseBottomSheetListCard: () =>
      import('~/components/base/bottomSheet/BaseBottomSheetListCard'),
    BaseIcon,
    ProductListingFilterDrawer: () =>
      import(
        /* webpackPrefetch: true */ '~/components/product/listing/ProductListingFilterDrawer'
      ),
    BaseInputSelect,
    ProductListingFilterChipGroup,
  },
  props: {
    listingType: {
      type: String,
      validator(val) {
        return ['categoryListing', 'productSearchListing'].includes(val)
      },
      default: 'categoryListing',
    },
  },
  setup(props, { root }) {
    const {
      getSortingOrders,
      search,
      getCurrentFilters,
      getTotal,
      loading,
      loadAggregations,
      loadingAggregations,
      getIsAggregationsLoaded,
    } = useListing(root, props.listingType)

    const {
      currentSortingOrder,
      filterValues,
      invokeFilter,
      filterItems,
      clearFilters,
      removeFilter,
      getAvailableFilters,
      hasActiveFilter,
    } = useListingFilter(root, props.listingType)

    onMounted(() => {
      if (hasActiveFilter.value) {
        loadAggregations()
      }
    })

    return {
      search,
      getAvailableFilters,
      getSortingOrders,
      getTotal,
      currentSortingOrder,
      getCurrentFilters,
      filterValues,
      loading,
      invokeFilter,
      filterItems,
      clearFilters,
      removeFilter,
      loadAggregations,
      loadingAggregations,
      getIsAggregationsLoaded,

      uiEventClickFilter: useUiEvent(root, 'filter', 'cms'),
      uiEventClickSort: useUiEvent(root, 'sorting', 'cms'),
    }
  },
  data() {
    return {
      drawerIntern: false,
      drawerBooted: false,
      sortBottonSheetBooted: false,
      sortBottonSheet: false,
    }
  },
  computed: {
    sortingList() {
      return this.getSortingOrders.map(({ key, translated }) => {
        return {
          value: key,
          text: translated.label,
        }
      })
    },
    drawer: {
      get() {
        return this.drawerBooted ? this.drawerIntern : false
      },
      set(value) {
        this.drawerIntern = value
      },
    },
  },
  watch: {
    drawerIntern(newValue) {
      if (newValue) {
        this.drawerBooted = true
      }
    },
    drawer(newValue) {
      if (newValue && !this.getIsAggregationsLoaded) {
        this.loadAggregations()
      }
    },
  },
  methods: {
    handleInputFilter(code, value) {
      this.$set(this.filterValues, code, value)
    },
    handleFilterClick() {
      this.drawer = true
      this.uiEventClickFilter()
    },
    handleSortClick() {
      this.sortBottonSheetBooted = true
      this.sortBottonSheet = true
      this.uiEventClickSort()
    },
  },
}
</script>
