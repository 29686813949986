import Vue from 'vue'
import {
  configure,
  extend,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import {
  // eslint-disable-next-line camelcase
  alpha_spaces,
  max,
  min,
  required,
  numeric,
  email,
  confirmed,
  between,
} from 'vee-validate/dist/rules'
import { zipcode, street } from '~/logic/validation/rules'

extend('alpha_spaces', alpha_spaces)
extend('required', required)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('email', email)
extend('confirmed', confirmed)
extend('zipcode', zipcode)
extend('street', street)
extend('between', between)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default ({ app }) => {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = field
      values._target_ = values?.target

      return app.i18n.t(`validations.${values._rule_}`, values)
    },
  })
}
