<template>
  <div>
    <app-bar-icon
      :title="$t('commons.search')"
      :tooltip="$t('commons.search')"
      icon="magnify"
      class="d-md-none hidden-xs-only mx-1"
      :class="{ 'd-none': isNavigationSearch }"
      @click="toggleNavigationSearch"
    ></app-bar-icon>
    <app-bar-icon
      v-if="$config.isBsDeBuild"
      :title="$t('commons.magazin')"
      :tooltip="$t('commons.magazin')"
      class="hidden-sm-and-down mx-1"
      :to="localePath('/magazin/')"
      bottom
      tooltip-class="text-body-5 text-uppercase"
    >
      <svg
        id="Ebene_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style="font-size: 36px; height: 36px; width: 36px"
        xml:space="preserve"
      >
        <path
          style="fill: #fff5e8"
          d="M20,6.5c0,0.3-0.5,9.3-0.4,10.6c0,0.3-0.4,0.4-0.5,0.4c-0.8,0-6.2-0.3-6.5,1.2c0,0.3-0.5,0.5-0.7,0.3
	C11.2,18.1,4.7,18,4.7,18c-0.2,0-0.2-0.1-0.3-0.2C4.1,17.4,3.9,6.5,4,6.1C4.1,5.7,5,5.7,5.3,5.7C5.2,5.1,5.6,5,5.8,5
	C6.6,5.2,8.7,5.2,9,5.2c1.1,0,2.1,0.2,3,0.6c3.3-0.1,6-0.9,6.2-0.8c0.3,0,0.4,0.2,0.5,0.4c0,0,0.1,0.8,0.1,0.9
	C19,6.3,20.1,6.2,20,6.5L20,6.5z"
        />
        <path
          style="fill: #575757"
          d="M20,6.5c0,0.3-0.5,9.3-0.4,10.6c0,0.3-0.4,0.4-0.5,0.4c-0.8,0-6.2-0.3-6.5,1.2c0,0.3-0.5,0.5-0.7,0.3
	C11.2,18.1,4.8,18,4.8,18c-0.2,0-0.2-0.1-0.3-0.2C4.1,17.4,4,6.5,4,6.2C4.1,5.7,5,5.7,5.3,5.7c0-0.6,0.3-0.6,0.5-0.6
	C6.6,5.2,8.7,5.2,9,5.2c1.1,0,2.1,0.2,3,0.6c3.3-0.1,6-0.9,6.2-0.8c0.3,0,0.4,0.2,0.5,0.4c0,0,0.1,0.8,0.1,0.9
	C19,6.3,20.1,6.2,20,6.5L20,6.5z M4.9,6.4c0,0.3,0.2,10.5,0.2,11c0.8,0,5.9,0.2,6.7,0.7V6.6c-1.2-0.9-4.9-0.7-5.6-0.7c0,0,0,0,0,0
	c0,0.3-0.1,9.3,0,10.1c2.3,0.3,4.4,0.6,5.3,1.1c0.4,0.3-0.2,0.8-0.6,0.5c-0.9-0.7-4.8-0.9-4.9-0.9c-0.5,0-0.6-0.2-0.6-0.6
	c0,0,0-8.7,0-9.8C5.2,6.3,5,6.3,4.9,6.4z M15.8,15.7c0.7-0.3,1.2-0.8,1.5-1.3c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.5,0-0.7,0
	C16.1,14.9,16,15.3,15.8,15.7z M12.5,6.5v10.6c0.1-0.1,0.2-0.2,0.2-0.2c0.5-0.5,1.2-0.7,1.9-0.8c0.1,0,0.1,0,0.2,0
	c0.3-0.5,0.4-1.2,0.5-2.1c0-0.4,0.4-0.4,0.6-0.3c1,0.3,1.6,0,1.6,0c0-0.1,0.4-6,0.2-7.8c0,0,0,0,0,0C17.8,5.8,15.2,6.5,12.5,6.5z
	 M13.2,17.4c2.6-0.7,5.6-0.5,5.6-0.5c0-1.1,0.4-9.6,0.4-10c-0.2,0-0.3,0-0.5,0c0,0.6-0.1,6.2-0.3,6.9c-0.5,1.7-2.1,2.6-4.4,3.2
	C13.7,17,13.2,17.2,13.2,17.4z"
        />
      </svg>
    </app-bar-icon>
    <app-bar-icon
      :title="$t('commons.account')"
      :tooltip="$t('commons.account')"
      icon="account-outline"
      class="mx-1"
      @click="handleAccountButton"
    >
      <div
        v-show="isLoggedIn"
        class="app-bar-icons__badge"
        style="padding: 3px 2px"
      >
        <base-icon
          svg="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
          size="10px"
          class="white--text"
        ></base-icon>
      </div>
    </app-bar-icon>
    <app-bar-icon
      :title="$t('commons.wishlist')"
      :tooltip="$t('commons.wishlist')"
      class="hidden-xs-only mx-1"
      icon="heart-outline"
      @click="openWishlist"
    >
      <div v-show="!!wishlistCount" class="app-bar-icons__badge">
        {{ wishlistCount }}
      </div>
    </app-bar-icon>
    <app-bar-icon
      :title="$t('commons.cart')"
      :tooltip="$t('commons.cart')"
      icon="cart-outline"
      class="hidden-xs-only mx-1"
      @click="toggleCartSidebar"
    >
      <div v-show="!!cartCount" class="app-bar-icons__badge">
        {{ cartCount }}
      </div>
    </app-bar-icon>
  </div>
</template>

<script>
import {
  useCart,
  useUiEvent,
  useUIState,
  useUser,
  useWishlist,
} from '~/composables'
import {
  CART_SIDEBAR_STATE,
  LOGIN_MODAL_STATE,
  NAVIGATION_SEARCH_STATE,
} from '~/services/UiStates'
import AppBarIcon from '~/components/app-bar/AppBarIcon'
import BaseIcon from '~/components/base/icon/BaseIcon'

export default {
  name: 'AppBarIcons',
  components: { BaseIcon, AppBarIcon },
  setup(_, { root }) {
    const { switchState: toggleLoginModal } = useUIState(
      root,
      LOGIN_MODAL_STATE
    )

    const { switchState: toggleNavigationSearch, isOpen: isNavigationSearch } =
      useUIState(root, NAVIGATION_SEARCH_STATE)

    const { switchState: toggleCartSidebar } = useUIState(
      root,
      CART_SIDEBAR_STATE
    )

    const { isLoggedIn } = useUser(root)
    const { count: cartCount } = useCart(root)
    const { count: wishlistCount } = useWishlist(root)

    return {
      toggleLoginModal,
      toggleNavigationSearch,
      isNavigationSearch,
      toggleCartSidebar,
      isLoggedIn,
      cartCount,
      wishlistCount,
      uiEventLogin: useUiEvent(root, 'open_login', 'site'),
    }
  },
  methods: {
    handleAccountButton() {
      if (this.isLoggedIn) {
        this.$router.push(this.localePath('/account'))
      } else {
        this.toggleLoginModal()
        this.uiEventLogin()
      }
    },
    openWishlist() {
      this.$router.push(this.localePath('/wishlist'))
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-icons__badge {
  position: absolute;
  background: map-get($shades, 'frontend-primary');
  border-radius: 10px;
  padding: 3px 5px;
  line-height: 1;
  right: 4px;
  color: #fff;
  height: 16px;
  bottom: -1px;
  min-width: 16px;
  text-align: center;
  display: inline-block;
  font-size: 10px;
}
</style>
