import {
  Ref,
  computed,
  UnwrapRef,
  reactive,
  WritableComputedRef,
} from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import {
  useCart,
  useSessionContext,
  INTERCEPTOR_KEYS,
  useIntercept,
  IInterceptorCallbackFunction,
  useSharedState,
} from '~/composables'
import {
  getAvailableShippingMethods,
  getAvailablePaymentMethods,
  createOrder as createApiOrder,
} from '~/shopware-6-client'
import { ShippingAddress } from '~/commons/interfaces/models/checkout/customer/ShippingAddress'
import { ShippingMethod } from '~/commons/interfaces/models/checkout/shipping/ShippingMethod'
import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import { ClientApiError } from '~/commons/interfaces/errors/ApiError'
import { Order } from '~/commons/interfaces/models/checkout/order/Order'
import { BillingAddress } from '~/commons/interfaces/models/checkout/customer/BillingAddress'

/**
 * interface for {@link useCheckout} composable
 *
 * @beta
 */
export interface IUseCheckout {
  getShippingMethods: (options?: {
    forceReload: boolean
  }) => Promise<Readonly<Ref<readonly ShippingMethod[]>>>
  shippingMethods: Readonly<Ref<readonly ShippingMethod[]>>
  getPaymentMethods: (options?: {
    forceReload: boolean
  }) => Promise<Readonly<Ref<readonly PaymentMethod[]>>>
  paymentMethods: Readonly<Ref<readonly PaymentMethod[]>>
  createOrder: () => Promise<Order>
  shippingAddress: Readonly<Ref<ShippingAddress | undefined>>
  billingAddress: Readonly<Ref<Partial<BillingAddress> | undefined>>
  onOrderPlace: (fn: (params: { order: Order }) => void) => void
  loadings: UnwrapRef<{
    createOrder: boolean
  }>
  currentStep: WritableComputedRef<number | null>
  onStepChange: (fn: any) => void
  offStepChange: (fn: any) => void
}

/**
 * Composable for Checkout management. Options - {@link IUseCheckout}
 *
 * @beta
 */
export const useCheckout = (
  rootContext: ApplicationVueContext
): IUseCheckout => {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useCheckout'
  )
  const { broadcast, intercept } = useIntercept(rootContext)
  const { refreshCart } = useCart(rootContext)
  const { sessionContext } = useSessionContext(rootContext)
  const { sharedRef } = useSharedState(rootContext)
  const storeShippingMethods = sharedRef<ShippingMethod[]>(
    `${contextName}-ShippingMethods`
  )
  const storePaymentMethods = sharedRef<PaymentMethod[]>(
    `${contextName}-PaymentMethods`
  )
  const shredStepperHook = sharedRef<any[]>(`${contextName}-shredStepperHook`)
  const loadings: UnwrapRef<{
    createOrder: boolean
  }> = reactive({
    createOrder: false,
  })

  const internCurrentStep = sharedRef<number>(`${contextName}-currentStep`)

  if (shredStepperHook.value === null) {
    shredStepperHook.value = []
  }

  const shippingMethods = computed(() => storeShippingMethods.value || [])
  const paymentMethods = computed(() => storePaymentMethods.value || [])
  const onOrderPlace = (fn: IInterceptorCallbackFunction) =>
    intercept(INTERCEPTOR_KEYS.ORDER_PLACE, fn)

  const getShippingMethods = async (
    { forceReload } = { forceReload: false }
  ) => {
    if (shippingMethods.value.length && !forceReload) return shippingMethods
    const response = await getAvailableShippingMethods(apiInstance, {
      onlyAvailable: true, // depending on the context, some of them can be hidden due to applied rules describing whether a method can be available
    })
    storeShippingMethods.value = response?.elements || []
    return shippingMethods
  }

  const getPaymentMethods = async (
    { forceReload } = { forceReload: false }
  ) => {
    if (paymentMethods.value.length && !forceReload) return paymentMethods
    const response = await getAvailablePaymentMethods(apiInstance, {
      onlyAvailable: true, // depending on the context, some of them can be hidden due to applied rules describing whether a method can be available
    })
    storePaymentMethods.value = response?.elements || []
    return paymentMethods
  }

  const createOrder = async () => {
    try {
      loadings.createOrder = true
      const order = await createApiOrder(apiInstance)

      try {
        broadcast(INTERCEPTOR_KEYS.ORDER_PLACE, {
          order,
        })
      } catch (e) {
        broadcast(INTERCEPTOR_KEYS.ERROR, {
          methodName: `[${contextName}][createOrder][broadcast]`,
          inputParams: {},
          error: e,
        })
      }

      return order
    } catch (e) {
      const err: ClientApiError = e
      broadcast(INTERCEPTOR_KEYS.ERROR, {
        methodName: `[${contextName}][createOrder]`,
        inputParams: {},
        error: err,
      })
      throw err
    } finally {
      loadings.createOrder = false
      await refreshCart()
    }
  }

  const shippingAddress = computed(
    () => sessionContext.value?.shippingLocation?.address
  )
  const billingAddress = computed(
    () => sessionContext.value?.customer?.activeBillingAddress
  )

  const onStepChange = (fn: any) => {
    shredStepperHook.value?.push(fn)
  }

  const offStepChange = (fn: any) => {
    shredStepperHook.value =
      shredStepperHook.value?.filter((hook) => hook !== fn) || []
  }

  const currentStep: WritableComputedRef<number> = computed({
    get() {
      return internCurrentStep.value || 0
    },
    set(step) {
      internCurrentStep.value = shredStepperHook.value?.some(
        (hook) => hook(step, internCurrentStep.value) === false
      )
        ? internCurrentStep.value
        : step
    },
  })

  return {
    getPaymentMethods,
    paymentMethods,
    getShippingMethods,
    shippingMethods,
    createOrder,
    shippingAddress,
    billingAddress,
    onOrderPlace,
    loadings,
    currentStep,
    onStepChange,
    offStepChange,
  }
}
