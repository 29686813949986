<template>
  <div>
    <error-view v-if="showNormalError"></error-view>
    <cms-page v-else-if="page" :content="page"></cms-page>
    <v-progress-circular
      v-else-if="loading"
      :size="50"
      color="grey-darken-4"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { onMounted } from '@nuxtjs/composition-api'
import { computed } from '@vue/composition-api'
import { useCmsId } from '~/composables/logic/useCmsId'
import CmsPage from '~/components/cms/CmsPage.vue'
import ErrorView from '~/components/views/ErrorView.vue'
import { usePageConfig } from '~/composables/logic/usePageConfig'

export default {
  name: 'ErrorCmsView',
  components: { ErrorView, CmsPage },
  setup(_, { root }) {
    const { page, errors, loading, search } = useCmsId(root)
    const { pageConfig } = usePageConfig(root)

    const cms404Page = computed(() => {
      return pageConfig.value?.basicInformation?.cmsHttp404Page
    })

    const showNormalError = computed(() => {
      return (errors.value && errors.value.length) || !cms404Page.value
    })

    onMounted(() => {
      if (cms404Page.value) {
        try {
          search(cms404Page.value)
        } catch (e) {}
      }
    })

    return {
      page,
      loading,
      showNormalError,
    }
  },
}
</script>
