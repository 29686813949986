export const zipcode = {
  params: ['target'],
  validate(value: any, { target }: { target: any }) {
    switch (target) {
      case 'DE': // Deutschland
        return value?.trim().match(/^[0-9]{5}$/) !== null
      case 'AT': // Österreich
      case 'CH': // Schweiz
        return value?.trim().match(/^[0-9]{4}$/) !== null
    }

    return true
  },
}
