import {
  CmsBlock,
  CmsSlot,
} from '~/commons/interfaces/models/content/cms/CmsPage'
import { getCmsSlots } from '~/helpers'

/**
 * @alpha
 */
export function findCmsSlots(
  content: CmsBlock,
  name: string
): CmsSlot | undefined {
  return getCmsSlots(content).find(({ slot }) => slot === name)
}
