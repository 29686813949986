export function truncate(
  value: string | number | object,
  length = 60,
  clamp = '...'
) {
  const str = String(value)

  if (str.length <= length) {
    return str
  }

  return str.slice(0, length - clamp.length).trim() + clamp
}
