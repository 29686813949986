import { computed, ComputedRef, Ref, ref } from '@vue/composition-api'
import { ApplicationVueContext } from '../appContext'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { PropertyGroup } from '~/commons/interfaces/models/content/property/PropertyGroup'
import { useCms } from '~/composables'
import { PropertyGroupOption } from '~/commons/interfaces/models/content/property/PropertyGroupOption'
import { ProductVariant } from '~/commons/interfaces/models/extensions/swagShopwarePwa/ProductVariant'
import { PropertyProductGroup } from '~/commons/interfaces/models/extensions/swagShopwarePwa/PropertyProductGroup'
import {
  CmsPage,
  PageResolverProductResult,
  PageResolverResult,
} from '~/commons/interfaces/models/content/cms/CmsPage'

/**
 * interface for {@link useProductConfigurator} composable
 * @beta
 */
export interface IUseProductConfigurator {
  /**
   * Handler for action when the selected option is changed
   */
  handleChange: (
    attribute: string,
    option: string,
    onChangeHandled?: Function
  ) => Promise<void>

  /**
   * Indicates if the options are being (re)loaded
   */
  isLoadingOptions: Ref<boolean>
  /**
   * Object of currently selected options
   */
  getSelectedOptions: Ref<{
    [key: string]: string
  }>
  /**
   * All assigned properties which the variant can be made of
   */
  getOptionGroups: Ref<PropertyGroup[]>

  getProductVariants: ComputedRef<any[]>

  productGroups: ComputedRef<PropertyProductGroup[]>
}

/**
 * Product options - {@link IUseAddToCart}
 * @beta
 */
export const useProductConfigurator = (
  rootContext: ApplicationVueContext,
  product: Product,
  selectedPage?:
    | PageResolverProductResult
    | PageResolverResult<CmsPage>
    | null
    | undefined
): IUseProductConfigurator => {
  const { page } = useCms(rootContext)
  const selected = ref({} as any)
  const isLoadingOptions = ref(!!product.options?.length)

  const usedPages = computed(() => {
    return selectedPage || page.value
  })

  const getOptionGroups = computed(
    () => (usedPages.value as any).configurator || []
  )
  const getProductVariants = computed(
    () => (usedPages.value as any).productVariations || []
  )

  const findGroupCodeForOption = (optionId: string) => {
    const group = getOptionGroups.value.find((optionGroup: any) => {
      const optionFound = optionGroup.options.find(
        (option: any) => option.id === optionId
      )
      return !!optionFound
    })

    return group?.translated?.name
  }

  // create a group -> optionId map
  product.optionIds?.forEach((optionId) => {
    const optionGroupCode = findGroupCodeForOption(optionId)
    if (optionGroupCode) {
      selected.value[optionGroupCode] = optionId
    }
  })

  const productGroups = computed(() => {
    return Object.entries(selected.value)
      .map(([key, item]) => {
        const group = getOptionGroups.value.find(
          ({ translated: { name } }: any) => name === key
        )

        if (!group) {
          return null
        }

        const optionIds = Object.values(selected.value).filter(
          (option) => option !== item
        )

        return {
          ...group,
          options: group.options
            .map((option: PropertyGroupOption) => ({
              ...option,
              productVariante: getProductVariants.value.find(
                (variant: ProductVariant) =>
                  // @ts-ignore
                  [...optionIds, option.id].every((optionId: string) =>
                    variant.optionIds?.includes(optionId)
                  )
              ),
            }))
            .filter((option: any) => !!option.productVariante),
        }
      })
      .filter((group) => !!group)
      .sort((a) => {
        // TODO ja extrem billig! bitte ändern :)

        if (['size', 'Größe', 'Taille'].includes(a.translated.name)) {
          return -1
        }

        return 0
      })
  })

  const handleChange = async (
    group: string,
    option: string,
    onChangeHandled?: Function
  ): Promise<void> => {
    selected.value = Object.assign({}, selected.value, {
      [group]: option,
    })
    if (typeof onChangeHandled === 'function') {
      // run passed callback
      await onChangeHandled()
    }
  }

  return {
    handleChange,
    isLoadingOptions,
    getOptionGroups,
    getSelectedOptions: selected,
    getProductVariants,
    productGroups,
  }
}
