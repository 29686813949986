<template>
  <div class="base-alert" :class="classes">
    <div class="base-alert__side">
      <slot name="icon">
        <base-icon :mdi="icon"></base-icon>
      </slot>
    </div>
    <div class="base-alert__content pa-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'

export default {
  name: 'BaseAlert',
  components: { BaseIcon },
  props: {
    type: {
      type: String,
      default: undefined,
      validator(val) {
        return (
          val === undefined ||
          ['info', 'error', 'success', 'warning'].includes(val)
        )
      },
    },
    disableResponsiv: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'base-alert--info': this.type === 'info',
        'base-alert--success': this.type === 'success',
        'base-alert--error': this.type === 'error',
        'base-alert--warning': this.type === 'warning',
        'text-body-3 text-sm-body-2': !this.disableResponsiv,
      }
    },
    icon() {
      switch (this.type) {
        case 'info':
          return 'information-outline'
        case 'success':
          return 'success'
        case 'error':
        case 'warning':
        default:
          return 'error'
      }
    },
  },
}
</script>
