import { computed, ref } from '@vue/composition-api'
import { ComputedRef } from '@nuxtjs/composition-api'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { loadScript as helperLoadScript } from '~/helpers'
import {
  bsUnzerConfiguration,
  BsUnzerConfigurationResponse,
} from '~/shopware-6-client/plugins/services/bsUnzerService'
import { useSentry } from '~/composables'

/**
 * @beta
 **/
export interface IUseUnzerPayment {
  loadScript: () => Promise<void>
  loadConfiguration: () => Promise<void>
  getInstance: () => any
  isSandbox: ComputedRef<boolean>
}

/**
 * @beta
 */
export const useUnzerPayment = (
  rootContext: ApplicationVueContext
): IUseUnzerPayment => {
  const { apiInstance, i18n, contextName } = getApplicationContext(
    rootContext,
    'useUnzerPayment'
  )
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const instance = ref()
  const configuration = ref<BsUnzerConfigurationResponse | null>(null)

  const loadScript = async () => {
    await helperLoadScript(`https://static.unzer.com/v1/unzer.js`)
  }

  const loadConfiguration = async () => {
    try {
      configuration.value = await bsUnzerConfiguration(apiInstance)
    } catch (e) {
      captureClientApiError(
        '[useUnzerPayment][loadConfiguration] Can not load unzer configuration',
        e
      )
    }
  }

  const getInstance = () => {
    if (!instance.value) {
      // @ts-ignore
      // eslint-disable-next-line dot-notation,new-cap
      instance.value = new window['unzer'](configuration.value?.publicKey, {
        locale: i18n.locale,
        showNotify: false,
      })
    }

    return instance.value
  }

  const isSandbox = computed(() => {
    if (instance.value) {
      return getInstance().isSandbox
    }

    return false
  })

  return {
    loadScript,
    loadConfiguration,
    getInstance,
    isSandbox,
  }
}
