import { UiProductOption } from '../ui-interfaces'
import { Product } from '~/commons/interfaces/models/content/product/Product'

/**
 * @beta
 */
export function getProductOptions({
  product,
}: {
  product?: Product
} = {}): UiProductOption[] {
  return (
    product?.options?.map((option) => ({
      label: option.translated?.name || option.name,
      code: option.id,
      value: option.translated?.name || option.name,
      color: option.colorHexCode,
    })) || []
  )
}
