import Vue from 'vue'
import LazyHydrate from '~/logic/lazy-hydrate/LazyHydrate'
// import LazyHydrate from 'vue-lazy-hydration'

// https://github.com/maoberlehner/vue-lazy-hydration
// fixed scroll bug: https://github.com/kedrzu/vue-lazy-hydration/tree/disable-hydration-after-full-load

/*
<template>
  <div class="ArticlePage">
    <LazyHydrate when-idle>
      <ImageSlider/>
    </LazyHydrate>

    <LazyHydrate never>
      <ArticleContent :content="article.content"/>
    </LazyHydrate>

    <LazyHydrate when-visible>
      <AdSlider/>
    </LazyHydrate>

    <!-- `on-interaction` listens for a `focus` event by default ... -->
    <LazyHydrate on-interaction>
      <CommentForm :article-id="article.id"/>
    </LazyHydrate>
    <!-- ... but you can listen for any event you want ... -->
    <LazyHydrate on-interaction="click">
      <CommentForm :article-id="article.id"/>
    </LazyHydrate>
    <!-- ... or even multiple events. -->
    <LazyHydrate :on-interaction="['click', 'touchstart']">
      <CommentForm :article-id="article.id"/>
    </LazyHydrate>
  </div>
</template>
 */
Vue.component('LazyHydrate', LazyHydrate)
