<script>
import CmsGenericSection from '~/components/cms/CmsGenericSection'

export default {
  name: 'CmsPage',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, context) {
    const genSelections = (context.props.content?.sections || []).map(
      (section) => {
        return h(CmsGenericSection, {
          props: {
            content: section,
          },
          key: section.id,
        })
      }
    )

    if (genSelections.length === 1) {
      return genSelections[0]
    }

    return h('div', genSelections)
  },
}
</script>
