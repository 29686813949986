import { CmsSlot } from '~/commons/interfaces/models/content/cms/CmsPage'
import { getCmsSlotConfigProperty } from '~/helpers'

/**
 * @alpha
 */
export function isCmsSlotConfigTrue(
  content: CmsSlot,
  property: string
): boolean {
  return getCmsSlotConfigProperty(content, property) === true
}
