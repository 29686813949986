import { Product } from '~/commons/interfaces/models/content/product/Product'
import { CustomFields } from '~/commons/interfaces/models/common/CustomField'
import { getProductCustomFields } from '~/helpers'
import {
  ATTRIBUTE_PDP_EXTRA_BADGE_CLASSES,
  ATTRIBUTE_PDP_EXTRA_BADGE_TEXT,
  ATTRIBUTE_PDP_EXTRA_BADGE_URL,
} from '~/services/cfAttributes'
import { isValueDisabled } from '~/helpers/isValueDisabled'

export function makeProductExtraBadge(
  product: Product
): null | { content?: string; classes?: string; url?: string } {
  const customFields: CustomFields = getProductCustomFields(product)

  const content = customFields?.[ATTRIBUTE_PDP_EXTRA_BADGE_TEXT] as string

  if (isValueDisabled(content)) {
    return null
  }

  const url = customFields?.[ATTRIBUTE_PDP_EXTRA_BADGE_URL] as string

  return {
    content,
    classes: customFields?.[ATTRIBUTE_PDP_EXTRA_BADGE_CLASSES] as string,
    url: isValueDisabled(url) ? undefined : url,
  }
}
