import BaseIconSvgUse from '~/components/base/icon/BaseIconSvgUse'

const defaultIconsSet = {
  complete: 'mdi-check',
  cancel: 'mdi-close-circle',
  close: 'close',
  delete: 'mdi-close-circle',
  clear: 'mdi-close',
  success: 'mdi-check-circle',
  info: 'mdi-information',
  warning: 'mdi-exclamation',
  error: 'mdi-alert',
  prev: 'mdi-chevron-left',
  next: 'mdi-chevron-right',
  checkboxOn: 'checkbox-on',
  checkboxOff: 'checkbox-off',
  checkboxIndeterminate: 'mdi-minus-box',
  delimiter: 'mdi-circle',
  sort: 'mdi-arrow-up',
  expand: 'mdi-chevron-down',
  menu: 'mdi-menu',
  subgroup: 'mdi-menu-down',
  dropdown: 'chevron-down',
  radioOn: 'mdi-radiobox-marked',
  radioOff: 'mdi-radiobox-blank',
  edit: 'mdi-pencil',
  ratingEmpty: 'mdi-heart-outline',
  ratingFull: 'mdi-heart',
  ratingHalf: 'mdi-heart-half-full',
  loading: 'loading',
  first: 'mdi-page-first',
  last: 'mdi-page-last',
  unfold: 'mdi-unfold-more-horizontal',
  file: 'mdi-paperclip',
  plus: 'plus',
  minus: 'minus',
  check: 'check',
  filter: 'filter',
}

export default function () {
  return {
    icons: {
      values: Object.entries(defaultIconsSet).reduce((acc, [key, value]) => {
        acc[key] = {
          component: BaseIconSvgUse,
          props: {
            icon: value.replace(/^mdi-/, ''),
          },
        }

        return acc
      }, {}),
    },
    theme: {
      disable: true,
    },
  }
}
