<script>
import { mergeData } from 'vue-functional-data-merge'
import BaseIconSvgUse from '~/components/base/icon/BaseIconSvgUse'
import BaseIconSvgPath from '~/components/base/icon/BaseIconSvgPath'

const sizes = {
  xLarge: '40px',
  large: '36px',
  small: '16px',
  xSmall: '12px',
}

export default {
  name: 'BaseIcon',
  functional: true,
  props: {
    mdi: {
      type: String,
      default: '',
    },
    svg: {
      type: String,
      default: undefined,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
  },
  render(h, context) {
    const hasClickListener = !!context.listeners.click
    const color = context.props.color
    let fontSize = context.props.size
    const tag = hasClickListener ? 'button' : 'span'

    if (!fontSize) {
      fontSize = sizes?.[Object.keys(sizes).find((size) => context.props[size])]
    }

    return h(
      tag,
      mergeData(context.data, {
        staticClass: 'base-icon v-icon theme--light',
        class: [
          color ? `${color}--text` : undefined,
          { 'v-icon--link': hasClickListener },
        ],
      }),
      [
        h(context.props.svg ? BaseIconSvgPath : BaseIconSvgUse, {
          props: {
            icon: context.props.mdi,
            svg: context.props.svg,
          },
          style: {
            fontSize,
            height: fontSize,
            width: fontSize,
          },
        }),
      ]
    )
  },
}
</script>
