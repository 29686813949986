import { Category } from '~/commons/interfaces/models/content/category/Category'
import { getCategoryCustomField } from '~/helpers'
import { ATTRIBUTE_CATEGORY_HIDE } from '~/services/cfAttributes'

export const CATEGORY_HIDE_TYPE_ALL = 'all'
export const CATEGORY_HIDE_TYPE_DESKTOP = 'desktop'
export const CATEGORY_HIDE_TYPE_MOBILE = 'mobile'

export function isCategoryHidden(
  category: Category,
  type: 'all' | 'mobile' | 'desktop'
): boolean {
  const attr = getCategoryCustomField(category, ATTRIBUTE_CATEGORY_HIDE)

  return attr === CATEGORY_HIDE_TYPE_ALL
    ? true
    : type === CATEGORY_HIDE_TYPE_ALL
    ? attr === type ||
      attr === CATEGORY_HIDE_TYPE_MOBILE ||
      attr === CATEGORY_HIDE_TYPE_DESKTOP
    : attr === type
}
