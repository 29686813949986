import {
  computed,
  ComputedRef,
  reactive,
  Ref,
  ref,
  UnwrapRef,
} from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import {
  useIntercept,
  useSharedState,
  useDefaults,
  INTERCEPTOR_KEYS,
  useUser,
  useSentry,
} from '~/composables'

import { Order } from '~/commons/interfaces/models/checkout/order/Order'
import { ShippingMethod } from '~/commons/interfaces/models/checkout/shipping/ShippingMethod'
import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import {
  ClientApiError,
  ShopwareError,
} from '~/commons/interfaces/errors/ApiError'
import {
  cancelOrder,
  changeOrderPaymentMethod,
  getOrderDetails,
  getOrderDetailsGuest,
  handlePayment as apiHandlePayment,
} from '~/shopware-6-client'
import { OrderAddress } from '~/commons/interfaces/models/checkout/order/OrderAddress'
import { OrderLineItem } from '~/commons/interfaces/models/checkout/order/OrderLineItem'
import { cancelOrderTransaction } from '~/shopware-6-client/plugins/services/bsOrder'

/**
 * Composable for managing an existing order.
 *
 * @beta
 */
export function useOrderDetails(
  rootContext: ApplicationVueContext,
  order: Order
): {
  order: ComputedRef<Order | undefined | null>
  status: ComputedRef<string | undefined>
  total: ComputedRef<number | undefined>
  subtotal: ComputedRef<number | undefined>
  productTotalPrice: ComputedRef<number>
  shippingCosts: ComputedRef<number | undefined>
  shippingAddress: ComputedRef<OrderAddress | undefined | null>
  billingAddress: ComputedRef<OrderAddress | undefined | null>
  personalDetails: ComputedRef<{
    email: string | undefined
    firstName: string | undefined
    lastName: string | undefined
  }>
  paymentUrl: Ref<null | string>
  shippingMethod: ComputedRef<ShippingMethod | undefined | null>
  paymentMethod: ComputedRef<PaymentMethod | undefined | null>
  lineItems: ComputedRef<OrderLineItem[]>
  totalDiscount: ComputedRef<number>
  errors: UnwrapRef<{
    [key: string]: ShopwareError[]
  }>
  loaders: UnwrapRef<{
    [key: string]: boolean
  }>
  loadOrderDetails: () => void
  loadGuestOrderDetails: ({
    deepLinkCode,
    email,
    zipcode,
  }: {
    deepLinkCode: string
    email: string
    zipcode: string
  }) => Promise<void>
  handlePayment: (
    successUrl?: string,
    errorUrl?: string,
    payload?: object
  ) => void
  cancel: () => Promise<void>
  changePaymentMethod: (paymentMethodId: string) => Promise<void>
  cancelTransaction: () => Promise<void>
} {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useOrderDetails'
  )
  const { getDefaults } = useDefaults(rootContext, 'useOrderDetails')
  const { broadcast } = useIntercept(rootContext)
  const { sharedRef } = useSharedState(rootContext)
  const { refreshUser } = useUser(rootContext)
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const _sharedOrder = sharedRef('useOrderDetails-order', order)
  const errors: UnwrapRef<{
    loadOrderDetails: ShopwareError[]
    handlePayment: ShopwareError[]
    cancel: ShopwareError[]
    changePaymentMethod: ShopwareError[]
  }> = reactive({
    loadOrderDetails: [],
    handlePayment: [],
    cancel: [],
    changePaymentMethod: [],
  })
  const loaders: UnwrapRef<{
    loadOrderDetails: boolean
    handlePayment: boolean
    cancel: boolean
    changePaymentMethod: boolean
  }> = reactive({
    loadOrderDetails: false,
    handlePayment: false,
    cancel: false,
    changePaymentMethod: false,
  })
  let orderId = order?.id

  const paymentMethod = computed(
    () => _sharedOrder.value?.transactions?.[0]?.paymentMethod
  )
  const shippingMethod = computed(
    () => _sharedOrder.value?.deliveries?.[0]?.shippingMethod
  )
  const paymentUrl = ref()

  const personalDetails = computed(() => ({
    email: _sharedOrder.value?.orderCustomer?.email,
    firstName: _sharedOrder.value?.orderCustomer?.firstName,
    lastName: _sharedOrder.value?.orderCustomer?.lastName,
  }))
  const billingAddress = computed(() =>
    _sharedOrder.value?.addresses?.find(
      ({ id }) => id === (_sharedOrder.value as Order).billingAddressId
    )
  )
  const shippingAddress = computed(
    () => _sharedOrder.value?.deliveries?.[0]?.shippingOrderAddress
  )

  const shippingCosts = computed(
    () => _sharedOrder.value?.shippingCosts?.totalPrice
  )

  const lineItems = computed(() => {
    return _sharedOrder.value?.lineItems || []
  })

  const subtotal = computed(() => _sharedOrder.value?.price?.positionPrice)
  const total = computed(() => _sharedOrder.value?.price?.totalPrice)
  const status = computed(() => _sharedOrder.value?.stateMachineState?.name)

  const productTotalPrice = computed(() => {
    return (
      _sharedOrder.value?.lineItems
        ?.filter(
          (itemItem) =>
            itemItem.type !== 'promotion' && itemItem.type !== 'easy-coupon'
        )
        .reduce((acc, cur) => {
          acc += cur.totalPrice || 0

          return acc
        }, 0) || 0
    )
  })

  const totalDiscount = computed(() => {
    return lineItems.value
      .filter(
        (cartItem: OrderLineItem) =>
          cartItem.type === 'promotion' || cartItem.type === 'easy-coupon'
      )
      .reduce((acc, cur) => {
        acc += cur.totalPrice || 0

        return acc
      }, 0)
  })

  /**
   * Get order object including additional associations.
   * useDefaults describes what order object should look like.
   */
  const loadOrderDetails = async () => {
    loaders.loadOrderDetails = true
    errors.loadOrderDetails = []

    try {
      const orderDetailsResponse = await getOrderDetails(
        orderId,
        getDefaults(),
        apiInstance
      )
      _sharedOrder.value = orderDetailsResponse ?? null
      broadcast(INTERCEPTOR_KEYS.ORDER_DETAILS_LOADED, _sharedOrder.value)
    } catch (e) {
      const error: ClientApiError = e
      errors.loadOrderDetails = error.messages
      broadcast(INTERCEPTOR_KEYS.ERROR, error)

      captureClientApiError(`[${contextName}][loadOrderDetails]`, e)
    }
    loaders.loadOrderDetails = false
  }

  const loadGuestOrderDetails = async ({
    deepLinkCode,
    email,
    zipcode,
  }: any) => {
    loaders.loadOrderDetails = true
    errors.loadOrderDetails = []

    try {
      const orderDetailsResponse = await getOrderDetailsGuest(
        deepLinkCode,
        {
          ...getDefaults(),
          email,
          zipcode,
          deepLinkCode,
        },
        apiInstance
      )
      _sharedOrder.value = orderDetailsResponse ?? null

      orderId = _sharedOrder.value?.id || orderId

      broadcast(INTERCEPTOR_KEYS.ORDER_DETAILS_LOADED, _sharedOrder.value)

      await refreshUser()
    } catch (e) {
      const error: ClientApiError = e
      errors.loadOrderDetails = error.messages
      broadcast(INTERCEPTOR_KEYS.ERROR, error)

      captureClientApiError(`[${contextName}][loadGuestOrderDetails]`, e)
    }

    loaders.loadOrderDetails = false
  }

  /**
   * Handle payment for existing error.
   *
   * Pass custom success and error URLs (optionally).
   */
  const handlePayment = async (
    successUrl?: string,
    errorUrl?: string,
    payload?: object
  ) => {
    loaders.handlePayment = true
    try {
      const resp = await apiHandlePayment(
        orderId,
        successUrl,
        errorUrl,
        payload,
        apiInstance
      )

      paymentUrl.value = resp?.redirectUrl
      broadcast(INTERCEPTOR_KEYS.ORDER_HANDLE_PAYMENT, resp)
    } catch (e) {
      const error: ClientApiError = e
      errors.handlePayment = error.messages
      broadcast(INTERCEPTOR_KEYS.ERROR, error)

      captureClientApiError(`[${contextName}][handlePayment]`, e)
    }
    loaders.handlePayment = false
  }

  /**
   * Cancel an order.
   * Action cannot be reverted.
   */
  const cancel = async (): Promise<void> => {
    loaders.cancel = true
    try {
      const response = await cancelOrder(orderId, apiInstance)
      broadcast(INTERCEPTOR_KEYS.ORDER_CANCELLED, response)
    } catch (error) {
      errors.cancel = error.messages
      broadcast(INTERCEPTOR_KEYS.ERROR, error)

      captureClientApiError(`[${contextName}][cancel]`, error)
    }

    loaders.cancel = false

    await loadOrderDetails()
  }

  const changePaymentMethod = async (
    paymentMethodId: string
  ): Promise<void> => {
    loaders.changePaymentMethod = true
    errors.changePaymentMethod = []

    try {
      await changeOrderPaymentMethod(orderId, paymentMethodId, apiInstance)
      broadcast(INTERCEPTOR_KEYS.ORDER_PAYMENT_METHOD_CHANGED, order)
    } catch (error) {
      errors.changePaymentMethod = error.messages
      broadcast(INTERCEPTOR_KEYS.ERROR, error)
      captureClientApiError(`[${contextName}][changePaymentMethod]`, error)
    }

    loaders.changePaymentMethod = false

    await loadOrderDetails()
  }

  const cancelTransaction = async (): Promise<void> => {
    try {
      await cancelOrderTransaction(orderId, apiInstance)
    } catch (error) {
      broadcast(INTERCEPTOR_KEYS.ERROR, error)
      captureClientApiError(`[${contextName}][cancelTransaction]`, error)
    }
  }

  return {
    order: computed(() => _sharedOrder.value),
    status,
    total,
    subtotal,
    productTotalPrice,
    shippingCosts,
    shippingAddress,
    billingAddress,
    personalDetails,
    paymentUrl,
    shippingMethod,
    paymentMethod,
    errors,
    loaders,
    lineItems,
    totalDiscount,
    loadOrderDetails,
    loadGuestOrderDetails,
    handlePayment,
    cancel,
    changePaymentMethod,
    cancelTransaction,
  }
}
