import {
  ComputedRef,
  getCurrentInstance,
  UnwrapRef,
} from '@vue/composition-api'
import { VueConstructor } from 'vue'
import * as SentryTypes from '@sentry/minimal'
import { NuxtCookies } from 'cookie-universal-nuxt'
import VueRouter, { Route } from 'vue-router'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import VueI18n, { IVueI18n } from 'vue-i18n'
import { ShopwareApiInstance } from '~/shopware-6-client/apiService'
import { IInterceptorCallbackFunction } from '~/composables/logic/useIntercept'
import { CacheInterface } from '~/composables/logic/useCaching'

/**
 * @beta
 */
export interface Routing {
  availableDomains: any
  fallbackDomain: string | undefined
  fallbackLocale: string | undefined
  pwaHost: string | undefined
  getCurrentDomain: ComputedRef<string>
  setCurrentDomain: (domainData: any) => void
  getUrl: (path: string) => string
}

interface Process extends NodeJS.Process {
  server: boolean
}

/**
 * Application Context for Shopware PWA. It's an extended Vue instance.
 *
 * @beta
 */
export interface ApplicationVueContext extends VueConstructor {
  $shopwareApiInstance?: ShopwareApiInstance
  shopwareApiInstance?: ShopwareApiInstance
  $router?: VueRouter // Vue router
  router?: VueRouter // Vue router
  $route?: Route // Vue router
  route?: Route // Vue router
  $i18n?: VueI18n & IVueI18n // Vue i18n plugin
  i18n?: VueI18n & IVueI18n // Vue i18n plugin
  $cookies?: NuxtCookies // cookie-universal
  cookies?: NuxtCookies // cookie-universal
  shopwareDefaults?: object // defaults for API
  $shopwareDefaults?: object // defaults for API
  $interceptors?: object
  interceptors?: object
  $sharedStore?: UnwrapRef<object>
  sharedStore?: UnwrapRef<object>
  $isServer?: boolean
  isServer?: boolean
  $sentry: typeof SentryTypes
  sentry: typeof SentryTypes
  $config?: NuxtRuntimeConfig
  lruGlobalCache?: CacheInterface
  $lruGlobalCache?: CacheInterface
  ccm?: any
  $ccm?: any
}

/**
 *
 */
export interface ApplicationContext {
  apiInstance: ShopwareApiInstance
  router: VueRouter // Vue router
  route: Route // Vue router
  i18n: VueI18n & IVueI18n // Vue i18n plugin
  cookies: NuxtCookies // cookie-universal
  shopwareDefaults: { [key: string]: any } // defaults for API
  interceptors: { [key: string]: IInterceptorCallbackFunction[] }
  sharedStore: UnwrapRef<{ [key: string]: any }>
  isServer: boolean
  sentry: typeof SentryTypes
  config: NuxtRuntimeConfig
  contextName: string
  lruGlobalCache: CacheInterface
  ccm: any
}

function checkAppContext(
  key: string,
  rootContext: ApplicationVueContext
): boolean {
  if (!rootContext?.$shopwareApiInstance && !rootContext?.shopwareApiInstance) {
    process.env.NODE_ENV !== 'production' &&
      console.warn(
        `[SECURITY][${key}] Trying to access Application context without Vue instance context. See https://shopware-pwa-docs.vuestorefront.io/landing/fundamentals/security.html#context-awareness`
      )
    return false
  }

  return true
}

/**
 *
 * @beta
 */
export function getApplicationContext(
  rootContext: ApplicationVueContext,
  key: string = 'getApplicationContext'
): ApplicationContext {
  let context = rootContext

  if (!checkAppContext(key, rootContext)) {
    context = getCurrentInstance() as any
  }

  return {
    // @ts-ignore
    apiInstance: context?.$shopwareApiInstance || context?.shopwareApiInstance,
    // @ts-ignore
    router: context?.$router || context?.router,
    // @ts-ignore
    route: context?.$route || context?.route,
    // @ts-ignore
    i18n: context?.$i18n || context?.i18n,
    // @ts-ignore
    cookies: context?.$cookies || context?.cookies,
    // @ts-ignore
    shopwareDefaults: context?.$shopwareDefaults || context?.shopwareDefaults,
    // @ts-ignore
    interceptors: context?.$interceptors || context?.interceptors || {},
    // @ts-ignore
    sharedStore: context?.$sharedStore || context?.sharedStore,
    sentry: context?.$sentry || context?.sentry,
    // @ts-ignore
    config: context?.$config || context?.config,
    isServer: !!(
      context?.$isServer ||
      context?.isServer ||
      /* istanbul ignore next */
      (process as Process)?.server
    ),
    contextName: key,
    // @ts-ignore
    lruGlobalCache: context?.$lruGlobalCache || context?.lruGlobalCache,
    // @ts-ignore
    ccm: context?.$ccm || context?.ccm,
  }
}
