<script>
import { getCmsSectionComponentName } from '~/logic/cms/getCmsSectionComponentName'
import CmsSectionDefault from '~/components/cms/sections/CmsSectionDefault'
import CmsSectionSidebar from '~/components/cms/sections/CmsSectionSidebar'
import CmsNoComponent from '~/components/cms/CmsNoComponent'

const components = {
  CmsSectionDefault,
  CmsSectionSidebar,
  CmsNoComponent,
}

export default {
  name: 'CmsGenericSection',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, context) {
    const content = context.props.content
    const componentName = getCmsSectionComponentName(context.props.content)

    return h(components[componentName], {
      staticClass: content.cssClass,
      props: {
        content,
      },
    })
  },
}
</script>
