import { computed } from '@vue/composition-api'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { useSentry, useSharedState } from '~/composables'
import { useCaching } from '~/composables/logic/useCaching'
import {
  getPageConfig,
  PageConfigResponse,
} from '~/shopware-6-client/plugins/services/bsPageConfig'

const CACHE_TIME = 1000 * 60 * 60 // 1 Stunde Cache time

export function usePageConfig(rootContext: ApplicationVueContext) {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'usePageConfig'
  )
  const { remember, forget, buildApiCacheKey } = useCaching(rootContext)
  const { sharedRef } = useSharedState(rootContext)
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const sharedPageConfig = sharedRef<PageConfigResponse>(contextName)

  const pageConfig = computed(() => {
    return sharedPageConfig.value
  })

  const loadPageConfig = async (force?: boolean): Promise<void> => {
    const cacheKey = buildApiCacheKey(contextName, {})

    if (force) {
      forget(cacheKey)
    }

    sharedPageConfig.value = await remember<any>(
      cacheKey,
      async () => {
        try {
          return await getPageConfig({}, apiInstance)
        } catch (e) {
          captureClientApiError(`[${contextName}][fetch]`, e)
        }

        return null
      },
      CACHE_TIME
    )
  }

  return {
    pageConfig,
    loadPageConfig,
  }
}
