import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductMainTitle } from '~/helpers'

/**
 * @beta
 */
export function getProductListingName(product?: Product): string | null {
  if (!product) {
    return null
  }

  return (
    getProductMainTitle(product) ||
    product.extensions?.bs_product_extension?.name ||
    product.translated?.name ||
    product.name
  )
}
