import { effectScope, reactive, ref, watch } from '@nuxtjs/composition-api'
import { Capacitor } from '@capacitor/core'
import {
  INTERCEPTOR_KEYS,
  useCart,
  useCategoryPager,
  useFooterNavigation,
  useIntercept,
  useMainNavigation,
  useSessionContext,
  useUser,
} from '~/composables'
import { createInstance } from '~/shopware-6-client'
import { usePageConfig } from '~/composables/logic/usePageConfig'
import { useCrossSession } from '~/composables/logic/useCrossSession'

const SW_CONTEXT_TOKEN = 'sw-context-token'
const SW_CONTEXT_TOKEN_CROSS = 'sw-context-token-cross'
const SW_LANGUAGE_ID = 'sw-language-id'

function getSessionContext(name, app) {
  if (Capacitor.getPlatform() === 'ios') {
    return window.localStorage.getItem(name) || ''
  }

  return app.$cookies.get(name) || ''
}

export default async ({ app, $config }, inject) => {
  const scope = effectScope()

  await scope.run(async () => {
    if (!app.$cookies) {
      // eslint-disable-next-line no-throw-literal
      throw 'Error cookie-universal-nuxt module is not applied in nuxt.config.js'
    }

    const contextToken = ref(getSessionContext(SW_CONTEXT_TOKEN, app))
    const languageId = ref(getSessionContext(SW_LANGUAGE_ID, app))

    /**
     * Setup Shopware API client
     */
    const instance = createInstance({
      endpoint:
        process.server || process.static ? $config.shopwareEndpoint : '',
      accessToken: $config.shopwareAccessToken,
      timeout: $config.shopwareTimeout,
      auth: {
        username: $config.shopwareAuthUsername,
        password: $config.shopwareAuthPassword,
      },
      contextToken: contextToken.value,
      languageId: languageId.value,
      timing: {
        server: $config.shopwareTimingServer,
        client: $config.shopwareTimingClient,
      },
    })

    function createFallbackLruStore() {
      return {
        get: () => undefined,
        set: () => undefined,
        del: () => undefined,
        has: () => false,
      }
    }

    if (process.server) {
      const sharedStore = reactive({})
      app.context.ssrContext.nuxt.sharedStore = sharedStore
      inject('sharedStore', sharedStore)

      if (app.context.ssrContext.$globalLruCacheInstance) {
        inject('lruGlobalCache', app.context.ssrContext.$globalLruCacheInstance)
      } else {
        inject('lruGlobalCache', createFallbackLruStore())
      }
    } else {
      // Client side
      const sharedStore = reactive(window.__NUXT__.sharedStore || {})
      inject('sharedStore', sharedStore)

      inject('lruGlobalCache', createFallbackLruStore())
    }

    inject('shopwareApiInstance', instance)
    inject('interceptors', {}) // functionality for useIntercept composable

    /**
     * Save current contextToken when its change
     */
    instance.onConfigChange(({ config }) => {
      try {
        contextToken.value = config.contextToken
        languageId.value = config.languageId

        app.$cookies.set(SW_CONTEXT_TOKEN, config.contextToken, {
          maxAge: 60 * 60 * 24 * 365,
          // Lax macht unter Safari Probleme!
          // sameSite: 'Lax',
          path: '/',
        })
        app.$cookies.set(SW_LANGUAGE_ID, config.languageId, {
          maxAge: 60 * 60 * 24 * 365,
          // Lax macht unter Safari Probleme!
          //  sameSite: 'Lax',
          path: '/',
        })

        app.$cookies.set(SW_CONTEXT_TOKEN_CROSS, config.contextToken, {
          maxAge: 60 * 60 * 24 * 365,
          sameSite: 'none',
          path: '/',
          secure: true,
          httpOnly: false,
        })
      } catch (e) {
        // Sometimes cookie is set on server after request is send, it can fail silently
      }
    })

    // IOS speichert keine Cookies, daher nutzen wir den localStorage
    if (Capacitor.getPlatform() === 'ios') {
      watch(contextToken, (token) => {
        window.localStorage.setItem(SW_CONTEXT_TOKEN, token)
      })

      watch(languageId, (languageId) => {
        window.localStorage.setItem(SW_LANGUAGE_ID, languageId)
      })
    }

    if (process.server || process.static) {
      const { fetchNavigation: fetchMainNav } = useMainNavigation(app)
      const { fetchNavigation: fetchFooterNav } = useFooterNavigation(app)
      const { loadPageConfig } = usePageConfig(app)
      const { refresh: categoryPagerRefresh } = useCategoryPager(app)

      await Promise.all([fetchMainNav(), fetchFooterNav(), loadPageConfig()])

      categoryPagerRefresh()
    }

    if (process.client) {
      const { refreshSessionContext } = useSessionContext(app)
      const { refreshUser } = useUser(app)
      const { refreshCart } = useCart(app)
      const { broadcast } = useIntercept(app)
      const { loadPageConfig } = usePageConfig(app)

      Promise.all([refreshSessionContext(), refreshUser(), refreshCart()]).then(
        () => broadcast(INTERCEPTOR_KEYS.CLIENT_INITIALISATION_FINISH)
      )

      if (
        !Capacitor.isNativePlatform() &&
        $config.crossDomain.enabled &&
        $config.crossDomain.url
      ) {
        const { fetchCrossSessionAndSyncCart } = useCrossSession(app)

        if ($config.crossDomain.url) {
          await fetchCrossSessionAndSyncCart()

          document.addEventListener('visibilitychange', async () => {
            if (document.visibilityState === 'visible') {
              await fetchCrossSessionAndSyncCart()

              await Promise.all([
                refreshSessionContext(),
                refreshUser(),
                refreshCart(),
              ])
            }
          })
        }
      }

      setInterval(() => {
        loadPageConfig()
      }, 1000 * 60 * 60)
    }
  })
}
