import { ListingFilter } from '~/helpers'

const mapping = [
  {
    main: 'Größe',
    from: ['Größen', 'Größe_'],
  },
  {
    main: 'Taille', // Größe
    from: ['Taille_'],
  },
  {
    main: 'Farbe',
    from: ['Farben', 'Farbe_'],
  },
  {
    main: 'Couleur', // Farbe
    from: ['Couleur_'],
  },
  {
    main: 'Motiv',
    from: ['Motive', 'Motiv_'],
  },
  {
    main: 'Thème', // Motiv
    from: ['Thème_'],
  },
  {
    main: 'Schuhgröße',
    from: ['Schuhgrößen', 'Schuhgröße_'],
  },
  {
    main: 'Pointure', // Schugröße
    from: ['Pointure_'],
  },
  {
    main: 'Wert',
    from: ['Werte', 'Wert_'],
  },
  {
    main: 'Prix', // wert
    from: ['Prix_'],
  },
  {
    main: 'Ausführung',
    from: ['Ausführungen', 'Ausführung_'],
  },
  {
    main: 'Détails', // Ausführung
    from: ['Détails_'],
  },
]

/**
 * Die Wawi unterscheidet zwischen Merkmalen und Varianten. In SW6 gibt es da keinen unterschied.
 * Die Wawi braucht aber die harte trennung, da sie sonst die Merkmale nicht aktualisieren kann.
 * Daher werden in der Wawi die Merkmal anders geschrieben als die Varianten, obwohl sie die selben sind.
 * Hier wird dann die Merkmale wieder zusammengeführt und als eins betrachtet.
 * Da sie aber im Backend mehrfach vorhanden sind, muss im Frontend bei einer Auswahl mehrere IDs hingeschickt werden
 *
 * @param listingFilter
 */
export function mergeSameListingFilters(listingFilter: ListingFilter[]) {
  mapping.forEach(({ main, from }) => {
    const foundMains = listingFilter.filter(({ label }) => label === main)

    // FR haben die den gleichen Namen, daher schleife
    foundMains.forEach((foundMain, index) => {
      const foundSub = listingFilter.filter(({ label }) => from.includes(label))

      foundSub.forEach((filter) => (filter.foundMain = true))

      const options = foundSub.map(({ options }) => options)

      foundMains[0].options = [
        ...((index > 0 ? foundMains[0].options : []) as any),
        ...foundMain.options.map((option: any) => ({ ...option })),
        ...options.flat(1),
      ]

      if (index > 0) {
        foundMain.foundMain = true
      }
    })
  })

  return listingFilter
    .filter(({ label, foundMain }) => {
      return (
        !mapping.some(({ from }) => from.includes(label)) || foundMain !== true
      )
    })
    .filter(({ foundMain }) => {
      return foundMain !== true
    })
    .map((filter) => {
      if (filter.code === 'properties') {
        filter.options = Object.values(
          filter.options.reduce((acc: any, cur: any) => {
            if (acc[cur.translated.name]) {
              acc[cur.translated.name].duplicates.push(cur.id)
            } else {
              acc[cur.translated.name] = {
                ...cur,
                duplicates: [cur.id],
              }
            }

            return acc
          }, {})
        )
      }

      return filter
    })
}
