import { ref, Ref } from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import {
  useCart,
  INTERCEPTOR_KEYS,
  useIntercept,
  IInterceptorCallbackFunction,
} from '~/composables'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { ClientApiError } from '~/commons/interfaces/errors/ApiError'

export interface IAddToCardSimpleOptions {
  product: Product
  quantity?: number
  individualisation?: {
    [key: string]: string
  }
}

/**
 * interface for {@link useAddToCart} composable
 * @beta
 */
export interface IUseAddToCartSimple {
  /**
   * Add to cart method
   */
  addToCart: (options: IAddToCardSimpleOptions) => Promise<void>

  /**
   * Adding to cart is in progress
   */
  loading: Ref<boolean>
  /**
   * Error message when adding to cart was not successful
   */
  error: Ref<string>

  /**
   * React on product added to cart
   */
  onAddToCart: (
    fn: (params: { product: Product; quantity: Number }) => void
  ) => void
}

/**
 * Add product to cart. Options - {@link IUseAddToCart}
 *
 * @example
 * Example of possibilities:
 *
 * ```ts
 * const {isInCart, quantity, addToCart} = useAddToCart(root, product)
 * if (!isInCart.value) {
 *    quantity.value = 5
 *    await addToCart()
 * }
 * ```
 * @beta
 */
export const useAddToCartSimple = (
  rootContext: ApplicationVueContext
): IUseAddToCartSimple => {
  const { contextName } = getApplicationContext(
    rootContext,
    'useAddToCartSimple'
  )
  const { addProduct, addProductIndividualisation } = useCart(rootContext)
  const { broadcast, intercept } = useIntercept(rootContext)

  const loading: Ref<boolean> = ref(false)
  const error: Ref<any> = ref(null)

  const addToCart = async ({
    product,
    quantity,
    individualisation,
  }: IAddToCardSimpleOptions): Promise<void> => {
    loading.value = true
    error.value = null

    if (!quantity || individualisation) {
      quantity = 1
    }

    try {
      if (individualisation) {
        await addProductIndividualisation({
          id: product.id,
          payload: {
            individualisation,
          },
        })
      } else {
        await addProduct({ id: product.id, quantity })
      }

      broadcast(INTERCEPTOR_KEYS.ADD_TO_CART, {
        product,
        quantity,
        options: {
          individualisation,
        },
      })
    } catch (e) {
      const err: ClientApiError = e
      error.value = err

      broadcast(INTERCEPTOR_KEYS.ERROR, {
        methodName: `[${contextName}][addToCartSimple]`,
        inputParams: {
          product,
          quantity,
          options: {
            individualisation,
          },
        },
        error: err,
      })
    } finally {
      loading.value = false
    }
  }

  const onAddToCart = (fn: IInterceptorCallbackFunction) =>
    intercept(INTERCEPTOR_KEYS.ADD_TO_CART, fn)

  return {
    addToCart,
    error,
    loading,
    onAddToCart,
  }
}
