import { ref, Ref, computed } from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import {
  useCart,
  INTERCEPTOR_KEYS,
  useIntercept,
  IInterceptorCallbackFunction,
  useSentry,
} from '~/composables'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { ClientApiError } from '~/commons/interfaces/errors/ApiError'

export interface IAddToCardOptions {
  individualisation?: {
    [key: string]: string
  }
}

/**
 * interface for {@link useAddToCart} composable
 * @beta
 */
export interface IUseAddToCart {
  /**
   * Add to cart method
   */
  addToCart: (options?: IAddToCardOptions) => Promise<void>
  /**
   * If you want to add more that 1 product set quantity before invoking `addToCart`
   */
  quantity: Ref<number>
  /**
   * Adding to cart is in progress
   */
  loading: Ref<boolean>
  /**
   * Error message when adding to cart was not successful
   */
  error: Ref<string>
  /**
   * Returns product count in stock
   */
  getStock: Ref<number | null>
  /**
   * Flag if product is already in cart
   */
  isInCart: Ref<boolean>
  /**
   * React on product added to cart
   */
  onAddToCart: (
    fn: (params: { product: Product; quantity: Number }) => void
  ) => void
}

/**
 * Add product to cart. Options - {@link IUseAddToCart}
 *
 * @example
 * Example of possibilities:
 *
 * ```ts
 * const {isInCart, quantity, addToCart} = useAddToCart(root, product)
 * if (!isInCart.value) {
 *    quantity.value = 5
 *    await addToCart()
 * }
 * ```
 * @beta
 */
export const useAddToCart = (
  rootContext: ApplicationVueContext,
  product: Product
): IUseAddToCart => {
  const { contextName } = getApplicationContext(rootContext, 'useAddToCart')
  const { addProduct, addProductIndividualisation, cartItems } =
    useCart(rootContext)
  const { broadcast, intercept } = useIntercept(rootContext)
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: 'useAddToCart',
  })

  const quantity: Ref<number> = ref(1)
  const loading: Ref<boolean> = ref(false)
  const error: Ref<any> = ref(null)

  const addToCart = async (options: IAddToCardOptions = {}): Promise<void> => {
    if (!product || !product.id) {
      error.value =
        'Product has to be passed as a composable argument and needs to have an id property.'
      return
    }

    loading.value = true
    error.value = null

    if (!quantity.value || options.individualisation) {
      quantity.value = 1
    }

    try {
      if (options.individualisation) {
        await addProductIndividualisation({
          id: product.id,
          payload: {
            individualisation: options.individualisation,
          },
        })
      } else {
        await addProduct({ id: product.id, quantity: quantity.value })
      }

      broadcast(INTERCEPTOR_KEYS.ADD_TO_CART, {
        product,
        quantity: quantity.value,
        options,
      })

      quantity.value = 1
    } catch (e) {
      const err: ClientApiError = e
      error.value = err

      captureClientApiError(`[${contextName}][addToCart]`, e, {
        product,
        quantity: quantity.value,
        options,
      })

      broadcast(INTERCEPTOR_KEYS.ERROR, {
        methodName: `[${contextName}][addToCart]`,
        inputParams: {
          product,
          quantity: quantity.value,
          options,
        },
        error: err,
      })
    } finally {
      loading.value = false
    }
  }

  const onAddToCart = (fn: IInterceptorCallbackFunction) =>
    intercept(INTERCEPTOR_KEYS.ADD_TO_CART, fn)

  const getStock = computed(() => product && product.stock)

  const isInCart = computed(
    (): boolean =>
      product &&
      cartItems.value.some((item: any) => item.referencedId === product.id)
  )

  return {
    addToCart,
    quantity,
    error,
    loading,
    getStock,
    isInCart,
    onAddToCart,
  }
}
