import { Ref } from '@nuxtjs/composition-api'
import { ApplicationVueContext } from '../appContext'
import {
  getStoreNavigation,
  GetStoreNavigationParams,
} from '~/shopware-6-client'
import { getApplicationContext, useSentry, useSharedState } from '~/composables'
import { StoreNavigationElement } from '~/commons/interfaces/models/content/navigation/Navigation'
import { useCaching } from '~/composables/logic/useCaching'

/**
 * interface for {@link useMainNavigation} composable
 *
 * @beta
 */
export interface IUseMainNavigation {
  navigationElements: Ref<StoreNavigationElement[] | null>
  fetchNavigation: () => Promise<void>
}

/**
 * Composable for navigation. Options - {@link IUseMainNavigation}
 * @beta
 */
export const useMainNavigation = (
  rootContext: ApplicationVueContext
): IUseMainNavigation => {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useMainNavigation'
  )

  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const { sharedRef } = useSharedState(rootContext)
  const { remember, forget, buildApiCacheKey } = useCaching(rootContext)

  const navigationElements = sharedRef<StoreNavigationElement[] | null>(
    `${contextName}-elements`,
    null
  )

  const fetchNavigation = async (force?: boolean): Promise<void> => {
    const request: GetStoreNavigationParams = {
      requestActiveId: 'main-navigation',
      requestRootId: 'main-navigation',
      depth: 4,
      searchCriteria: {
        configuration: {
          includes: {
            category: [
              'seoUrls',
              'externalLink',
              'name',
              'id',
              'children',
              'media',
              'customFields',
              'translated',
              'type',
            ],
            media: ['id', 'url'],
            seo_url: ['pathInfo', 'seoPathInfo'],
          },
        },
      },
    }

    const cacheKey = buildApiCacheKey('useMainNavigation', request)

    if (force) {
      forget(cacheKey)
    }

    navigationElements.value =
      (await remember(cacheKey, async () => {
        for (let i = 0; i < 3; i++) {
          try {
            const result = await getStoreNavigation(request, apiInstance)

            // es kommt wohl mal vor, dass result undefined oder so ist
            if (Array.isArray(result) && result.length > 0) {
              return result
            }
          } catch (e) {
            captureClientApiError(`[${contextName}][fetch]`, e)
          }
        }

        return undefined
      })) ?? null
  }

  return {
    navigationElements,
    fetchNavigation,
  }
}
