import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

/**
 * @throws ClientApiError
 */
export async function customerDestroy(
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<void> {
  await contextInstance.invoke.delete('/store-api/bs/account/destroy')
}
