<template>
  <div
    class="base-progress-linear"
    :class="classes"
    :style="styles"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-valuenow="value"
  >
    <div
      class="base-progress-linear__background"
      :class="backgroundClass"
    ></div>
    <div
      class="
        base-progress-linear__determinate base-progress-linear__determinate--inc
      "
      :class="foregroundClass"
      :style="subLineStyle"
    ></div>
    <div
      class="
        base-progress-linear__determinate base-progress-linear__determinate--dec
      "
      :class="foregroundClass"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'BaseProgressLinear',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    backgroundClass: {
      type: String,
      default: undefined,
    },
    foregroundClass: {
      type: String,
      default: undefined,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    classes() {
      return {
        'base-progress-linear--indeterminate': this.indeterminate,
      }
    },
    styles() {
      return {
        height: this.height,
      }
    },
    subLineStyle() {
      return {
        width: this.indeterminate ? undefined : `${this.value}%`,
      }
    },
  },
}
</script>
