import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

/**
 * @beta
 */
export async function cancelOrderTransaction(
  orderId: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<any> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-order/cancel-transaction',
    {
      orderId,
    }
  )

  return resp.data
}
