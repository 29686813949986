<template>
  <nav role="navigation">
    <ul class="base-pagination">
      <li>
        <nuxt-link
          v-if="value > 1"
          :to="prevRoute.route"
          tabindex="0"
          :aria-label="prevAriaLabel"
          class="base-pagination__navigation"
          @click.native="$emit('prev')"
        >
          <base-icon mdi="chevron-left"></base-icon>
        </nuxt-link>
        <base-icon
          v-else
          class="
            base-pagination__navigation base-pagination__navigation--disable
          "
          mdi="chevron-left"
        ></base-icon>
      </li>
      <li v-for="(item, index) in routes" :key="`${index}-${item.page}`">
        <nuxt-link
          v-if="item.route"
          :to="item.route"
          tabindex="0"
          :aria-label="`${pageAriaLabel} ${item.page}`"
          :aria-current="value === item.page ? 'page' : undefined"
          class="base-pagination__item"
          :class="{ 'base-pagination__item--active': item.page === value }"
          @click.native="$emit('page')"
          >{{ item.page }}</nuxt-link
        >
        <span v-else class="base-pagination__more">{{ item.page }}</span>
      </li>
      <li>
        <nuxt-link
          v-if="value < length"
          :to="nextRoute.route"
          tabindex="0"
          :aria-label="nextAriaLabel"
          class="base-pagination__navigation"
          @click.native="$emit('next')"
        >
          <base-icon mdi="chevron-right"></base-icon>
        </nuxt-link>
        <base-icon
          v-else
          class="
            base-pagination__navigation base-pagination__navigation--disable
          "
          mdi="chevron-right"
        ></base-icon>
      </li>
    </ul>
  </nav>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
export default {
  name: 'BasePagination',
  components: { BaseIcon },
  props: {
    length: {
      type: Number,
      default: 0,
    },
    totalVisible: {
      type: Number,
      default: 7,
    },
    value: {
      type: Number,
      default: 0,
    },
    queryParam: {
      type: String,
      default: 'p',
    },
    nextAriaLabel: {
      type: String,
      default: 'Next Page',
    },
    prevAriaLabel: {
      type: String,
      default: 'Previous Page',
    },
    pageAriaLabel: {
      type: String,
      default: 'Page',
    },
  },
  computed: {
    prevRoute() {
      return this.genRoute(this.value - 1)
    },
    nextRoute() {
      return this.genRoute(this.value + 1)
    },
    routes() {
      return this.items.map((item) => this.genRoute(item))
    },
    items() {
      const totalVisible = parseInt(this.totalVisible, 10)

      if (totalVisible === 0) {
        return []
      }

      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.length,
        Math.max(0, 0) || this.length,
        this.length
      )

      if (this.length <= maxLength) {
        return this.range(1, this.length)
      }

      const even = maxLength % 2 === 0 ? 1 : 0
      const left = Math.floor(maxLength / 2)
      const right = this.length - left + 1 + even

      if (this.value > left && this.value < right) {
        const firstItem = 1
        const lastItem = this.length
        const start = this.value - left + 2
        const end = this.value + left - 2 - even
        const secondItem = start - 1 === firstItem + 1 ? 2 : '...'
        const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : '...'

        return [
          1,
          secondItem,
          ...this.range(start, end),
          beforeLastItem,
          this.length,
        ]
      } else if (this.value === left) {
        const end = this.value + left - 1 - even
        return [...this.range(1, end), '...', this.length]
      } else if (this.value === right) {
        const start = this.value - left + 1
        return [1, '...', ...this.range(start, this.length)]
      } else {
        return [
          ...this.range(1, left),
          '...',
          ...this.range(right, this.length),
        ]
      }
    },
  },
  methods: {
    range(from, to) {
      const range = []

      from = from > 0 ? from : 1

      for (let i = from; i <= to; i++) {
        range.push(i)
      }

      return range
    },
    genRoute(pageNumber) {
      return {
        page: pageNumber,
        route: isNaN(pageNumber) ? null : this.linkGenerator(pageNumber),
      }
    },
    linkGenerator(pageNumber) {
      const query = {
        ...this.$router.currentRoute.query,
        [this.queryParam]: pageNumber || 1,
      }

      return {
        path: this.$router.currentRoute.path,
        query,
      }
    },
  },
}
</script>
