import { ComputedRef, Ref, ref } from '@vue/composition-api'
import { ApplicationVueContext } from '../appContext'
import {
  getApplicationContext,
  useDefaults,
  createListingComposable,
} from '~/composables'
import { searchSuggestedProducts } from '~/shopware-6-client'
import { ShopwareSearchParams } from '~/commons/interfaces/search/SearchCriteria'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { ListingResult } from '~/commons/interfaces/response/ListingResult'

/**
 * @beta
 */
export interface IUseProductQuickSearch {
  searchTerm: Ref<string>
  loading: ComputedRef<boolean>
  search: (additionalCriteria?: Partial<ShopwareSearchParams>) => Promise<void>
  loadMore: () => Promise<void>
  getProducts: ComputedRef<Product[]>
  getTotal: ComputedRef<number>
  getCurrentListing: ComputedRef<Partial<ListingResult<Product>> | null>
}

/**
 * @beta
 */
export const useProductQuickSearch = (
  rootContext: ApplicationVueContext
): IUseProductQuickSearch => {
  const { getDefaults } = useDefaults(rootContext, 'useProductQuickSearch')
  const { apiInstance } = getApplicationContext(rootContext, 'useListing')

  const searchTerm = ref('')

  const listingKey = 'productQuickSearch'
  const searchMethod = async (
    searchCriteria: Partial<ShopwareSearchParams>
  ) => {
    return await searchSuggestedProducts(searchCriteria, apiInstance)
  }

  const listingComposable = createListingComposable<Product>({
    rootContext,
    listingKey,
    searchMethod,
    searchDefaults: getDefaults(),
  })

  const search = async (
    additionalCriteria: Partial<ShopwareSearchParams> = {}
  ) => {
    const searchCriteria = {
      query: searchTerm.value,
      ...additionalCriteria,
    }
    return await listingComposable.search(searchCriteria, {
      preventRouteChange: true,
    })
  }

  return {
    searchTerm,
    loading: listingComposable.loading,
    search,
    loadMore: listingComposable.loadMore,
    getProducts: listingComposable.getElements,
    getTotal: listingComposable.getTotal,
    getCurrentListing: listingComposable.getCurrentListing,
  }
}
