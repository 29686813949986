import { Product } from '~/commons/interfaces/models/content/product/Product'
import {
  getProductManufacturerName,
  getProductName,
  getProductRealPriceNet,
} from '~/helpers'

export function getGtmProductItem(
  product: Product,
  extensions: object = { index: 0 }
): any {
  return {
    item_id: product.productNumber,
    item_name: getProductName({ product }),
    item_brand: getProductManufacturerName(product),
    price: getProductRealPriceNet(product),
    ...extensions,

    // quantity: 1,
    // item_category: 'T-Shirts',
    // item_variant: 'blue',
    // size: 'M',
  }
}
