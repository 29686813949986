<template>
  <div class="text-h5">
    <span>*{{ $t('commons.vatIncluded') }}</span>
    <nuxt-link
      v-if="$i18n.locale !== 'de-DE'"
      class="link-text"
      :to="localePath(getTechnicalPath(CAT_TECHNICAL_NAME_SHIPPING))"
    >
      {{ $t('commons.plusShipping') }}</nuxt-link
    >

    <div class="mt-3">
      <nuxt-link
        class="mr-4 link-text"
        :to="localePath(getTechnicalPath(CAT_TECHNICAL_NAME_PRIVACY))"
        >{{ $t('commons.privacy') }}</nuxt-link
      >
      <nuxt-link
        class="link-text"
        :to="localePath(getTechnicalPath(CAT_TECHNICAL_NAME_IMPRINT))"
        >{{ $t('commons.imprint') }}</nuxt-link
      >
    </div>
  </div>
</template>

<script>
import { useCategoryPager } from '~/composables'

export default {
  name: 'FooterExtraInfoCard',
  setup(_, { root }) {
    const {
      getTechnicalPath,
      CAT_TECHNICAL_NAME_IMPRINT,
      CAT_TECHNICAL_NAME_PRIVACY,
      CAT_TECHNICAL_NAME_SHIPPING,
    } = useCategoryPager(root)

    return {
      getTechnicalPath,
      CAT_TECHNICAL_NAME_IMPRINT,
      CAT_TECHNICAL_NAME_PRIVACY,
      CAT_TECHNICAL_NAME_SHIPPING,
    }
  },
}
</script>
