import { reactive } from '@vue/composition-api'
import {
  PaymentServiceBeforeOrderResult,
  PaymentServiceInterface,
  PaymentServiceOptionsInterface,
} from '~/services/payments/PaymentServiceInterface'
import { ApplicationVueContext } from '~/composables/appContext'
import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import { usePaypalExpressCheckout } from '~/composables'
import { createFieldInput } from '~/services/FieldInputFactory'

export const createPayPalService = (
  rootContext: ApplicationVueContext
): PaymentServiceInterface => {
  const componentName: string = 'CheckoutPaymentPaypal'

  const { getPaypalToken, clearPaypalToken } =
    usePaypalExpressCheckout(rootContext)

  const isSupported = (payment: PaymentMethod) => {
    // @ts-ignore
    return payment?.shortName === 'pay_pal_payment_handler'
  }

  const inputFields = {
    paypalPaymentId: reactive(
      createFieldInput({ name: 'paypalPaymentId', rules: '' })
    ),
    paypalToken: reactive(createFieldInput({ name: 'paypalToken', rules: '' })),
    isPayPalPlusCheckout: reactive(
      createFieldInput({ name: 'isPayPalPlusCheckout', rules: '' })
    ),
  }

  const beforeOrderHook = async (
    _: ApplicationVueContext
  ): Promise<PaymentServiceBeforeOrderResult> => {
    let isSuccessful = true

    if (!document.location.search.includes('isPayPalExpressCheckout=1')) {
      isSuccessful = inputFields.isPayPalPlusCheckout.value !== undefined
    }

    return await new Promise((resolve) => {
      resolve({
        isSuccessful,
        payload: null,
      })
    })
  }

  const afterOrderHook = async () => {
    await clearPaypalToken()
  }

  const getHandlePaymentPayload = () => {
    if (document.location.search.includes('isPayPalExpressCheckout=1')) {
      const { orderId } = getPaypalToken()

      return {
        isPayPalExpressCheckout: 1,
        paypalOrderId: orderId,
      }
    }

    return {
      paypalPaymentId: inputFields.paypalPaymentId.value || '',
      paypalToken: inputFields.paypalToken.value || '',
      isPayPalPlusCheckout: inputFields.isPayPalPlusCheckout.value || false,
    }
  }

  const getOptions = (): PaymentServiceOptionsInterface => ({})

  return {
    componentName,
    isSupported,
    inputFields,
    beforeOrderHook,
    afterOrderHook,
    getHandlePaymentPayload,
    getOptions,
  }
}
