import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { OrderLineItem } from '~/commons/interfaces/models/checkout/order/OrderLineItem'

export function getLineItemProductIndividualisations<
  T extends LineItem | OrderLineItem
>(lineItems: T[]): { [key: string]: T } {
  return lineItems
    .filter(({ type }) => type === 'product-individualisation')
    .reduce((acc: any, cur: T) => {
      acc[cur.referencedId || ''] = cur

      return acc
    }, {})
}
