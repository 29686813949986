import { reactive } from '@vue/composition-api'
import {
  PaymentServiceBeforeOrderResult,
  PaymentServiceInterface,
  PaymentServiceOptionsInterface,
} from '~/services/payments/PaymentServiceInterface'
import { ApplicationVueContext } from '~/composables/appContext'
import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import { createFieldInput } from '~/services/FieldInputFactory'

export const createPayPalSEPAService = (
  _: ApplicationVueContext
): PaymentServiceInterface => {
  const componentName: string = 'CheckoutPaymentPaypalSEPA'

  const isSupported = (payment: PaymentMethod) => {
    // @ts-ignore
    return payment?.shortName === 's_e_p_a_handler'
  }

  const inputFields = {
    paypalOrderId: reactive(
      createFieldInput({ name: 'paypalOrderId', rules: '' })
    ),
  }

  const beforeOrderHook = async (
    _: ApplicationVueContext
  ): Promise<PaymentServiceBeforeOrderResult> => {
    return await new Promise((resolve) => {
      resolve({
        isSuccessful: inputFields.paypalOrderId.value !== undefined,
        payload: null,
      })
    })
  }

  const getHandlePaymentPayload = () => {
    return {
      paypalOrderId: inputFields.paypalOrderId.value || '',
    }
  }

  const getOptions = (): PaymentServiceOptionsInterface => ({
    hideByButton: true,
  })

  return {
    componentName,
    isSupported,
    inputFields,
    beforeOrderHook,
    getHandlePaymentPayload,
    getOptions,
  }
}
