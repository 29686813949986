/**
 * Implementiere die CMS Komponenten und entferne den entsprechenden Kommentar
 */
export default {
  sections: {
    default: 'CmsSectionDefault',
    sidebar: 'CmsSectionSidebar',
  },
  blocks: {
    // SW6
    'product-listing': 'CmsBlockBsGridFull',

    // Grid Blöcke
    'bs-grid-full': 'CmsBlockBsGridFull',
    'bs-grid-half': 'CmsBlockBsGridHalf',
    'bs-grid-half-fixed': 'CmsBlockBsGridHalf',
    'bs-grid-third': 'CmsBlockBsGridThird',
    'bs-grid-third-fixed': 'CmsBlockBsGridThird',
    'bs-grid-quarter': 'CmsBlockBsGridQuarter',
    'bs-grid-quarter-fixed': 'CmsBlockBsGridQuarter',

    // Default Blöcke
    'bs-text': 'CmsBlockBsGridFull',
    'bs-text-expansion': 'CmsBlockBsGridFull',
    'bs-image': 'CmsBlockBsGridFull',
    'bs-image-slider': 'CmsBlockBsGridFull',
    'bs-image-hero': 'CmsBlockBsGridFull',
    'bs-image-text': 'CmsBlockBsGridFull',
    'bs-image-text-box': 'CmsBlockBsGridFull',
    'bs-image-circle': 'CmsBlockBsGridFull',
    'bs-image-circle-slider': 'CmsBlockBsGridFull',
    'bs-category-circle-slider': 'CmsBlockBsGridFull',
    'bs-category-navigation-circle': 'CmsBlockBsGridFull',
    'bs-sidebar-filter': 'CmsBlockBsGridFull',
    'bs-category-navigation': 'CmsBlockBsGridFull',
    'bs-script': 'CmsBlockBsGridFull',
    'bs-video': 'CmsBlockBsGridFull',

    // Commerce
    'bs-product-slider': 'CmsBlockBsGridFull',
    'bs-product-grid': 'CmsBlockBsGridFull',
    'bs-product-sale-counter': 'CmsBlockBsGridFull',

    // Blog
    'bs-blog-listing': 'CmsBlockBsGridFull',
    'bs-blog-single-entry': 'CmsBlockBsGridFull',
    'bs-blog-detail': 'CmsBlockBsGridFull',
    'bs-blog-detail-author': 'CmsBlockBsGridFull',
    'bs-blog-slider': 'CmsBlockBsGridFull',
  },
  elements: {
    // SW6
    'product-listing': 'CmsElementProductListing',

    // Default Element
    'bs-text': 'CmsElementText',
    'bs-text-expansion': 'CmsElementBsTextExpansion',
    'bs-image': 'CmsElementImage',
    'bs-image-slider': 'CmsElementImageSlider',
    'bs-image-hero': 'CmsElementImageHero',
    'bs-image-text': 'CmsElementBsImageText',
    'bs-image-text-box': 'CmsElementImageTextBox',
    'bs-image-circle': 'CmsElementBsImageCircle',
    'bs-image-circle-slider': 'CmsElementBsImageCircleSlider',
    'bs-category-circle-slider': 'CmsElementCategoryCircleSlider',
    'bs-category-navigation-circle': 'CmsElementCategoryNavigationCircle',
    'bs-category-navigation': 'CmsElementCategoryNavigation',
    'bs-sidebar-filter': 'CmsElementCategorySidebarFilter',
    'bs-script': 'CmsElementBsScript',
    'bs-video': 'CmsElementBsVideo',

    // Commerce
    'bs-product-slider': 'CmsElementProductSlider',
    'bs-product-grid': 'CmsElementBsProductGrid',
    'bs-product-sale-counter': 'CmsElementBsProductSaleCounter',

    // Blog
    'bs-blog-listing': 'CmsElementBsBlogListing',
    'bs-blog-single-entry': 'CmsElementBsBlogSingleEntry',
    'bs-blog-detail': 'CmsElementBsBlogDetail',
    'bs-blog-detail-author': 'CmsElementBsBlogDetailAuthor',
    'bs-blog-slider': 'CmsElementBsBlogSlider',
  },
}
