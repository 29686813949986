import { CmsSlot } from '~/commons/interfaces/models/content/cms/CmsPage'
import { getCmsSlotConfigProperty, isInDateRange } from '~/helpers'

export function isCmsSlotActive(content: CmsSlot): boolean {
  const from = getCmsSlotConfigProperty(content, 'publishFrom') as
    | string
    | null
    | undefined

  const to = getCmsSlotConfigProperty(content, 'publishTo') as
    | string
    | null
    | undefined

  return isInDateRange(from, to)
}
