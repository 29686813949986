import { ComputedRef } from '@nuxtjs/composition-api'
import { computed } from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import { Breadcrumb } from '~/commons/interfaces/models/content/cms/CmsPage'
import { useSharedState } from '~/composables'

/**
 * interface for {@link useBreadcrumbs} composable
 *
 * @beta
 */
export interface IUseBreadcrumbs {
  breadcrumbs: ComputedRef<Breadcrumb[]>
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
  clear: () => void
}

/**
 * @beta
 */
export const useBreadcrumbs = (
  rootContext: ApplicationVueContext
): IUseBreadcrumbs => {
  getApplicationContext(rootContext, 'useBreadcrumbs')

  const { sharedRef } = useSharedState(rootContext)
  const sharedBreadcrumbs: any = sharedRef('useBreadcrumbs', [])

  const clear = () => {
    sharedBreadcrumbs.value = []
  }

  const setBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
    sharedBreadcrumbs.value = breadcrumbs
  }

  const breadcrumbs = computed(() => {
    return sharedBreadcrumbs.value || []
  })

  return {
    clear,
    setBreadcrumbs,
    breadcrumbs,
  }
}
