<template>
  <div>
    <cookie-manager-dialog v-if="dialog"></cookie-manager-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { INTERCEPTOR_KEYS, useIntercept } from '~/composables'

export default {
  name: 'CookieMangerContainer',
  components: {
    CookieManagerDialog: () =>
      import('~/components/cookie/CookieManagerDialog'),
  },

  setup(_, { root }) {
    const { intercept } = useIntercept(root)
    const dialog = ref(false)

    intercept(INTERCEPTOR_KEYS.COOKIE_OPEN_DIALOG, () => {
      dialog.value = true
    })

    return {
      dialog,
    }
  },

  mounted() {
    this.dialog = this.$ccm.shouldShowConsent
  },
}
</script>
