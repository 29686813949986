<template>
  <div class="base-expansion-panel" :class="classes">
    <button
      class="align-center w-full base-expansion-panel__button"
      :class="titleClass"
      :disabled="disabled"
      @click="toggle"
    >
      <slot name="header" :is-active="isActive">
        <span class="base-expansion-panel__button-content"
          ><slot name="title" :is-active="isActive">{{ title }}</slot></span
        ><base-icon
          mdi="chevron-down"
          class="base-expansion-panel__icon ml-auto"
        ></base-icon>
      </slot>
    </button>
    <div class="text-h4 base-expansion-panel__title">{{ title }}</div>
    <v-expand-transition>
      <div
        v-show="isActive"
        v-if="isBooted"
        class="base-expansion-panel__content"
      >
        <slot></slot>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
export default {
  name: 'BaseExpansionPanel',
  components: { BaseIcon },
  inject: {
    group: { default: null },
  },
  props: {
    fixAtMd: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: 'text-h4',
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    initActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: this.initActive,
      isBooted: !this.lazy || this.initActive,
    }
  },
  computed: {
    classes() {
      return {
        'base-expansion-panel--expanded': this.isActive,
        'base-expansion-panel--md-fix': this.fixAtMd,
      }
    },
  },
  watch: {
    isActive(val) {
      if (val) {
        this.isBooted = true
      }
    },
  },
  created() {
    this.group?.register(this)
  },

  beforeDestroy() {
    this.group?.unregister(this)
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive

      this.group?.toggleItemValue(this)
    },
  },
}
</script>
