import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getGtmProductItem, getLineItemNetPrice } from '~/helpers'

export function getGtmLineItemProduct(
  lineItem: LineItem,
  extension = { index: 0 }
): any | undefined {
  if (lineItem.type !== 'product') {
    return undefined
  }

  // @ts-ignore
  const product = lineItem.payload as Product

  return {
    ...getGtmProductItem(product),
    item_name: lineItem.label,
    price: getLineItemNetPrice(lineItem),
    quantity: lineItem.quantity,
    ...extension,
  }
}
