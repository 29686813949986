<script>
import BaseIcon from '~/components/base/icon/BaseIcon'

export default {
  name: 'BaseProductRating',
  functional: true,
  props: {
    value: {
      type: Number,
      default: 0,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    smAndDownSmall: {
      type: Boolean,
      default: false,
    },
  },
  render(h, context) {
    const value = context.props.value

    function genIcon(i) {
      const isFilled = Math.floor(value) > i
      const isHalfFilled = (value - i) % 1 > 0

      return h(BaseIcon, {
        props: {
          mdi: isFilled
            ? 'heart'
            : isHalfFilled
            ? 'heart-half-full'
            : 'heart-outline',
          color:
            isFilled || isHalfFilled
              ? 'frontend-primary-pastel'
              : 'grey-lighten-1',
        },
      })
    }

    return h(
      'div',
      {
        ...context.data,
        class: [
          {
            'base-product-rating': true,
            'base-product-rating--sm-and-down-small':
              context.props.smAndDownSmall,
            'base-product-rating--x-small': context.props.xSmall,
          },
          ...(context.data.class || []),
        ],
      },
      [genIcon(0), genIcon(1), genIcon(2), genIcon(3), genIcon(4)]
    )
  },
}
</script>
