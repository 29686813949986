import { Capacitor } from '@capacitor/core'
import {
  getLineItemCalculatedTax,
  getLineItemNetPrice,
  getProductLineItems,
  getProductManufacturerName,
  getProductName,
  getProductNumber,
  getProductRealPriceNet,
  getProductRealTax,
} from '~/helpers'
import { sendTracking } from '~/shopware-6-client'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { ShopwareApiInstance } from '~/shopware-6-client/apiService'
import { Order } from '~/commons/interfaces/models/checkout/order/Order'
import { Cart } from '~/commons/interfaces/models/checkout/cart/Cart'
import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { OrderLineItem } from '~/commons/interfaces/models/checkout/order/OrderLineItem'

export interface TrackParams {
  eventId: string
}

export interface TrackProductParams extends TrackParams {
  product: Product
}

export interface TrackOrderParams extends TrackParams {
  order: Order
}

export interface TrackCartParams extends TrackParams {
  cart: Cart
}

export interface TrackSearchParams extends TrackParams {
  term: string
}

export default class BsTracking {
  private readonly _currency: string
  private readonly _apiInstance: ShopwareApiInstance

  constructor(currency: string, apiInstance: ShopwareApiInstance) {
    this._currency = currency
    this._apiInstance = apiInstance
  }

  public async trackViewPdp({ product, eventId }: TrackProductParams) {
    await this._sendEvent({
      event: 'view_item',
      id: eventId,
      time: (+new Date() / 1000).toFixed(0),
      ecommerce: this._buildProductEcommerce(product),
    })
  }

  public async trackAddToCart({ product, eventId }: TrackProductParams) {
    await this._sendEvent({
      event: 'add_to_cart',
      id: eventId,
      time: (+new Date() / 1000).toFixed(0),
      ecommerce: this._buildProductEcommerce(product),
    })
  }

  public async trackAddToWishlist({ product, eventId }: TrackProductParams) {
    await this._sendEvent({
      event: 'add_to_wishlist',
      id: eventId,
      time: (+new Date() / 1000).toFixed(0),
      ecommerce: this._buildProductEcommerce(product),
    })
  }

  public async trackOrderPlace({ order }: TrackOrderParams) {
    const coupon = order.lineItems?.filter(
      (cartItem) => cartItem.type === 'promotion'
    )?.[0]?.label

    await this._sendEvent({
      event: 'purchase',
      id: order.orderNumber,
      time: (+new Date() / 1000).toFixed(0),
      ecommerce: {
        order_id: order.orderNumber,
        currency: this._currency,
        tax: (order.price.totalPrice - order.price.netPrice).toFixed(2),
        shipping: order.shippingTotal,
        value: order.price.netPrice,
        coupon,
        items: this._mapLineItems(getProductLineItems(order.lineItems ?? [])),
      },
    })
  }

  public async trackViewCheckout({ cart, eventId }: TrackCartParams) {
    await this._sendEvent({
      event: 'begin_checkout',
      id: eventId,
      time: (+new Date() / 1000).toFixed(0),
      ecommerce: {
        currency: this._currency,
        value: cart.price?.netPrice,
        items: this._mapLineItems(getProductLineItems(cart.lineItems)),
      },
    })
  }

  public async trackViewSearch({ term, eventId }: TrackSearchParams) {
    await this._sendEvent({
      event: 'search',
      id: eventId,
      time: (+new Date() / 1000).toFixed(0),
      search_term: term,
    })
  }

  private _buildProductEcommerce(product: Product): any {
    return {
      items: [
        {
          id: product.id,
          productNumber: getProductNumber(product),
          name: getProductName({ product }),
          brand: getProductManufacturerName(product),
          price: getProductRealPriceNet(product),
          tax: getProductRealTax(product),
        },
      ],
      value: getProductRealPriceNet(product),
      currency: this._currency,
    }
  }

  private _mapLineItems(lineItems: (LineItem | OrderLineItem)[]) {
    return lineItems.map((lineItem) => {
      return {
        id: lineItem.referencedId,
        productNumber: lineItem.payload?.productNumber,
        name: lineItem.label,
        price: getLineItemNetPrice(lineItem),
        quantity: lineItem.quantity,
        tax: getLineItemCalculatedTax(lineItem),
      }
    })
  }

  private async _sendEvent(payload: any) {
    await sendTracking(
      {
        platform: Capacitor.getPlatform(),
        ...payload,
      },
      this._apiInstance
    )
  }
}
