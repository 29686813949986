<template>
  <base-text :content="content" :anchor="anchor"></base-text>
</template>

<script>
import BaseText from '~/components/base/text/BaseText'
export default {
  name: 'CmsUtilText',
  components: { BaseText },
  props: {
    content: {
      type: String,
      required: true,
    },
    anchor: {
      type: String,
      default: undefined,
    },
  },
}
</script>
