import { ComputedRef, Ref, ref, watch } from '@vue/composition-api'
import { validate } from 'vee-validate'

export interface createFieldInputParams {
  name: string
  initValue?: string | undefined
  rules?: string | null
}

export interface FieldInput {
  value: Ref<string | undefined>
  update(value: string): void
  isValid: ComputedRef<boolean>
  errors: Ref<string[]>
  validate(): Promise<void>
}

export function createFieldInput({
  name,
  initValue = undefined,
  rules = null,
}: createFieldInputParams): FieldInput {
  const value = ref(initValue)
  const isValid = ref(false)
  const errors: Ref<string[]> = ref([])
  const update = (val: string) => {
    value.value = val
  }

  if (rules) {
    watch(value, async (newValue) => {
      const result = await validate(newValue, rules, { name })

      isValid.value = result.valid
      errors.value = result.errors
    })
  } else {
    isValid.value = true
  }

  const validateMethod = async () => {
    const result = await validate(value.value, rules ?? '', { name })

    isValid.value = result.valid
    errors.value = result.errors
  }

  return {
    value,
    update,
    isValid,
    errors,
    validate: validateMethod,
  }
}
