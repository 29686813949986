import { effectScope } from '@nuxtjs/composition-api'
import { Capacitor } from '@capacitor/core'
import { INTERCEPTOR_KEYS, useIntercept } from '~/composables'
import { cookieDefinitions } from '~/logic'
import AppTracking from '~/capacitor-plugins/AppTracking'

const BS_COOKIE_CONSENT_TOKEN = 'bs-cookie-consent-token'

export default ({ app }, inject) => {
  const scope = effectScope()

  scope.run(() => {
    const currentVersion = 1

    const { broadcast } = useIntercept(app)

    const ccm = {
      cookieDefinitions,

      settings: [],
      allAllowed: false,

      saveCookies(cookies) {
        this.settings = cookies

        this._save()
      },

      getCookies() {
        return this.settings
      },

      isCookieAllowed(name) {
        return this.allAllowed || this.settings.includes(name)
      },

      getCookieGroups() {
        return this.cookieDefinitions
      },

      allowedAll() {
        this.allAllowed = true

        this._save()
      },

      _loadFrom() {
        if (Capacitor.getPlatform() === 'ios') {
          try {
            return (
              JSON.parse(
                window.localStorage.getItem(BS_COOKIE_CONSENT_TOKEN) || '{}'
              ) || {}
            )
          } catch (e) {
            return {}
          }
        } else {
          return app.$cookies.get(BS_COOKIE_CONSENT_TOKEN) || {}
        }
      },

      _load(sendChangeEvent = false) {
        const { settings, version, allAllowed } = this._loadFrom()

        if (Capacitor.getPlatform() === 'ios') {
          // Wir müssen erstmal den TrackingStatus von ios abwarten
          AppTracking.isTrackingAllowed().then(({ allowed }) => {
            if (allowed) {
              this.allAllowed = !!allAllowed
              this.shouldShowConsent = version !== currentVersion
              this.settings = settings || []
            } else {
              this.allAllowed = false
              this.shouldShowConsent = false
              this.settings = []
            }

            broadcast(INTERCEPTOR_KEYS.COOKIE_CHANGES, {
              cookies: [...this.settings],
            })

            if (sendChangeEvent) {
              if (this.shouldShowConsent) {
                broadcast(INTERCEPTOR_KEYS.COOKIE_OPEN_DIALOG)
              }
            }
          })
        } else {
          this.allAllowed = !!allAllowed
          this.shouldShowConsent = version !== currentVersion
          this.settings = settings || []

          if (sendChangeEvent) {
            broadcast(INTERCEPTOR_KEYS.COOKIE_CHANGES, {
              cookies: [...this.settings],
            })

            if (this.shouldShowConsent) {
              broadcast(INTERCEPTOR_KEYS.COOKIE_OPEN_DIALOG)
            }
          }
        }
      },

      _save() {
        const value = {
          settings: this.settings,
          allAllowed: this.allAllowed,
          version: currentVersion,
        }

        if (Capacitor.getPlatform() === 'ios') {
          window.localStorage.setItem(
            BS_COOKIE_CONSENT_TOKEN,
            JSON.stringify(value)
          )
        } else {
          app.$cookies.set(BS_COOKIE_CONSENT_TOKEN, value, {
            maxAge: 60 * 60 * 24 * 365,
            // Lax macht unter Safari Probleme!
            // sameSite: 'Lax',
            path: '/',
          })
        }

        this.shouldShowConsent = false

        broadcast(INTERCEPTOR_KEYS.COOKIE_CHANGES, {
          cookies: [...this.settings],
        })
      },
    }

    ccm._load()

    inject('ccm', ccm)
  })
}
