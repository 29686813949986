var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"error-view"},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticStyle:{"position":"relative"}},[_c('base-image',{staticClass:"mx-auto",staticStyle:{"max-width":"700px","width":"100%","height":"auto"},attrs:{"url":require("~/assets/images/logos/errorpage.jpg"),"alt":"error","init-width":700,"width":"700","height":"467"}}),_vm._v(" "),_c('div',{staticClass:"white--text text-h4 text-md-h1 error-view__message"},[_vm._v("\n        "+_vm._s(_vm.$t('errorLayout.someThingWentWrong'))+"\n\n        "),_c('base-button',{staticClass:"d-md-flex pink-berry white--text mt-5 hidden-sm-and-down",staticStyle:{"width":"300px"},attrs:{"rounded":"","small":"","to":_vm.localePath('/')}},[_vm._v("\n          "+_vm._s(_vm.$t('errorLayout.backToHome'))+"\n        ")])],1)],1)]),_vm._v(" "),_c('div',{staticClass:"text-center mt-5"},[_c('base-button',{staticClass:"pink-berry white--text hidden-md-and-up",staticStyle:{"width":"300px"},attrs:{"rounded":"","small":"","to":_vm.localePath('/')}},[_vm._v("\n      "+_vm._s(_vm.$t('errorLayout.backToHome'))+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"text-h5 text-md-h4 mt-7 mt-md-11 text-center"},[_vm._v("\n      "+_vm._s(_vm.$t('errorLayout.dontBeSad'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"mt-md-7 d-flex flex-column flex-md-row justify-md-center"},[_c('base-button',{staticClass:"apricot-pastel white--text mx-auto mx-md-0 mb-4",staticStyle:{"width":"200px"},attrs:{"rounded":"","small":"","to":_vm.localePath(
            _vm.$i18n.locale === 'fr-FR'
              ? '/filles/'
              : '/babykleidung/?gender=mädchen'
          )}},[_vm._v(_vm._s(_vm.$t('commons.girls')))]),_vm._v(" "),_c('base-button',{staticClass:"lilac-pastel white--text mx-auto mx-md-7 mb-4",staticStyle:{"width":"200px"},attrs:{"rounded":"","small":"","to":_vm.localePath(
            _vm.$i18n.locale === 'fr-FR'
              ? '/garcons/'
              : '/babykleidung/?gender=jungen'
          )}},[_vm._v(_vm._s(_vm.$t('commons.boys')))]),_vm._v(" "),_c('base-button',{staticClass:"green-pastel white--text mx-auto mx-md-0 mb-4",staticStyle:{"width":"200px"},attrs:{"rounded":"","small":"","to":_vm.localePath(
            _vm.$i18n.locale === 'fr-FR'
              ? '/unisexe/'
              : '/babykleidung/?gender=unisex'
          )}},[_vm._v(_vm._s(_vm.$t('commons.unisex')))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }