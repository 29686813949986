import cmsMap from '~/cmsMap'

interface ICmsMap {
  [x: string]: {
    [y: string]: string
  }
}

const mapping: ICmsMap = cmsMap as ICmsMap

export function resolveComponent(
  blockType: string,
  contentType: string
): () => Promise<any> {
  if (mapping[blockType]?.[contentType]) {
    const path = mapping[blockType][contentType]

    return () => import(`@/components/cms/${blockType}/${path}.vue`)
  }

  return () => import(`@/components/cms/CmsNoComponent.vue`)
}
