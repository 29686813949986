export function convertGtmUaProductItem(product: any | undefined): any {
  if (!product) {
    return undefined
  }

  return {
    id: product.item_id,
    name: product.item_name,
    price: product.price,
    brand: product.item_brand,
    quantity: product.quantity,
  }
}
