<template>
  <div>
    <v-dialog v-if="booted" v-model="dialog" fullscreen scrollable>
      <customer-login-card @click:close="dialog = false"></customer-login-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api'
import { watch } from '@vue/composition-api'
import { useUIState, useUser } from '~/composables'
import { LOGIN_MODAL_STATE } from '~/services/UiStates'

export default {
  name: 'CustomerLoginContainer',
  components: {
    CustomerLoginCard: () =>
      import('~/components/customer/login/CustomerLoginCard'),
  },
  setup(_, { root }) {
    const { isOpen, switchState } = useUIState(root, LOGIN_MODAL_STATE)
    const { onUserLogin, onUserRegister } = useUser(root)

    const booted = ref(false)

    onUserLogin(() => {
      switchState(false)
    })

    onUserRegister(() => {
      switchState(false)
    })

    watch(
      isOpen,
      (isOpen) => {
        if (isOpen) {
          booted.value = true
        }
      },
      {
        immediate: true,
      }
    )

    return {
      toggleModal: switchState,
      isModalOpen: isOpen,
      booted,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.isModalOpen
      },
      set(value) {
        this.toggleModal(value)
      },
    },
  },
  methods: {
    handleSuccessful() {
      this.toggleModal(false)
    },
  },
}
</script>
