<template>
  <div class="error-view">
    <div class="d-flex justify-center">
      <div style="position: relative">
        <base-image
          :url="require(`~/assets/images/logos/errorpage.jpg`)"
          alt="error"
          :init-width="700"
          class="mx-auto"
          width="700"
          height="467"
          style="max-width: 700px; width: 100%; height: auto"
        />
        <div class="white--text text-h4 text-md-h1 error-view__message">
          {{ $t('errorLayout.someThingWentWrong') }}

          <base-button
            rounded
            small
            style="width: 300px"
            :to="localePath('/')"
            class="d-md-flex pink-berry white--text mt-5 hidden-sm-and-down"
          >
            {{ $t('errorLayout.backToHome') }}
          </base-button>
        </div>
      </div>
    </div>

    <div class="text-center mt-5">
      <base-button
        rounded
        small
        style="width: 300px"
        :to="localePath('/')"
        class="pink-berry white--text hidden-md-and-up"
      >
        {{ $t('errorLayout.backToHome') }}
      </base-button>
      <p class="text-h5 text-md-h4 mt-7 mt-md-11 text-center">
        {{ $t('errorLayout.dontBeSad') }}
      </p>

      <div class="mt-md-7 d-flex flex-column flex-md-row justify-md-center">
        <base-button
          rounded
          small
          :to="
            localePath(
              $i18n.locale === 'fr-FR'
                ? '/filles/'
                : '/babykleidung/?gender=mädchen'
            )
          "
          class="apricot-pastel white--text mx-auto mx-md-0 mb-4"
          style="width: 200px"
          >{{ $t('commons.girls') }}</base-button
        >
        <base-button
          rounded
          small
          :to="
            localePath(
              $i18n.locale === 'fr-FR'
                ? '/garcons/'
                : '/babykleidung/?gender=jungen'
            )
          "
          class="lilac-pastel white--text mx-auto mx-md-7 mb-4"
          style="width: 200px"
          >{{ $t('commons.boys') }}</base-button
        >
        <base-button
          rounded
          small
          :to="
            localePath(
              $i18n.locale === 'fr-FR'
                ? '/unisexe/'
                : '/babykleidung/?gender=unisex'
            )
          "
          class="green-pastel white--text mx-auto mx-md-0 mb-4"
          style="width: 200px"
          >{{ $t('commons.unisex') }}</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '~/components/base/button/BaseButton'
import BaseImage from '~/components/base/image/BaseImage'
export default {
  name: 'ErrorView',
  components: { BaseImage, BaseButton },
  head() {
    return {
      title: this.$t('error.title404'),
    }
  },
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.error-view {
  &__message {
    position: absolute;
    top: 5%;
    left: 5%;
    max-width: 270px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      max-width: 425px;
    }
  }
}
</style>
