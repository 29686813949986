import { effectScope } from '@nuxtjs/composition-api'
import { useUser } from '~/composables'

export default async function ({ redirect, app }) {
  const scope = effectScope()

  await scope.run(async () => {
    const { isLoggedIn, refreshUser } = useUser(app)

    await refreshUser()

    if (isLoggedIn && !isLoggedIn.value) {
      redirect(app.localePath('/login'))
    }
  })

  scope.stop()
}
