import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { OrderLineItem } from '~/commons/interfaces/models/checkout/order/OrderLineItem'

export function getLineItemPriceDefinitionNetPrice<
  T extends LineItem | OrderLineItem
>(lineItem: T): number | undefined {
  const price = lineItem.priceDefinition?.price
  const taxRule = lineItem.priceDefinition?.taxRules?.[0]

  if (taxRule && price) {
    return (price / taxRule.percentage) * taxRule.taxRate
  }

  return undefined
}
