import { computed, ComputedRef, Ref, ref } from '@vue/composition-api'
import { Capacitor } from '@capacitor/core'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import { useCheckout, useSessionContext } from '~/composables'
import {
  createOrderPaypalExpress,
  prepareCheckoutPaypalExpress,
} from '~/shopware-6-client/plugins/services/paypalService'
import { loadScript } from '~/helpers'

export interface IUsePaypalExpressCheckout {
  createButton: (container: string | HTMLElement, options: any) => Promise<void>
  getPaypalToken: () => { contextToken: string; orderId: string }
  setPaypalToken: (contextToken: string, orderId: string) => void
  setPaypalAsDefaultPayment: (forceReload?: boolean) => Promise<void>
  isExpressCheckout: ComputedRef<boolean>
  clearPaypalToken: () => void
  setEventListener: (listener: (name: string) => void) => void
}

/**
 * @beta
 */
export function usePaypalExpressCheckout(
  rootContext: ApplicationVueContext
): IUsePaypalExpressCheckout {
  const { setPaymentMethod, paymentMethod } = useSessionContext(rootContext)
  const { getPaymentMethods, paymentMethods } = useCheckout(rootContext)
  const { apiInstance, config, i18n, router } = getApplicationContext(
    rootContext,
    'IUsePaypalExpressCheckout'
  )

  const paypalClientId = config.paypalClientId
  const paypalTokenKey = 'paypalToken'

  const eventListener: Ref<null | ((name: string) => void)> = ref(null)

  const paypalPayment = computed(() => {
    return paymentMethods.value.find(
      ({ shortName }) => shortName === 'pay_pal_payment_handler'
    )
  })

  const isPayPalAsDefaultPayment = computed(() => {
    return paypalPayment.value?.id === paymentMethod.value?.id
  })

  const isExpressCheckout = computed(() => {
    return !!router.currentRoute?.query?.isPayPalExpressCheckout
  })

  const getPaypalInstance = () => {
    // @ts-ignore
    return paypal
  }

  const getPaypalToken = () => {
    const { contextToken, orderId } = JSON.parse(
      sessionStorage.getItem(paypalTokenKey) || '{}'
    )

    return {
      contextToken,
      orderId,
    }
  }

  const setPaypalToken = (contextToken: String, orderId: string) => {
    sessionStorage.setItem(
      paypalTokenKey,
      JSON.stringify({
        contextToken,
        orderId,
      })
    )
  }

  const setPaypalAsDefaultPayment = async (forceReload: boolean = false) => {
    await getPaymentMethods({ forceReload })

    if (!isPayPalAsDefaultPayment.value && paypalPayment.value) {
      await setPaymentMethod(paypalPayment.value)
    }
  }

  const clearPaypalToken = () => {
    sessionStorage.removeItem(paypalTokenKey)
  }

  const setEventListener = (listener: (name: string) => void) => {
    eventListener.value = listener
  }

  const dispatchEvent = (name: string) => {
    if (eventListener.value) {
      eventListener.value(name)
    }
  }

  const createButton = async (
    container: string | HTMLElement,
    options: any = {}
  ) => {
    const locale = i18n.locale === 'fr-FR' ? 'fr_FR' : 'de_DE'

    await loadScript(
      `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=EUR&locale=${locale}`
    )

    /**
     * https://developer.paypal.com/docs/business/javascript-sdk/javascript-sdk-reference/
     * https://developer.paypal.com/docs/business/javascript-sdk/javascript-sdk-configuration/
     * https://developer.paypal.com/docs/business/checkout/reference/style-guide/#customize-the-payment-buttons
     */
    getPaypalInstance()
      .Buttons({
        style: {
          size: 'small',
          shape: 'pill',
          color: 'gold',
          tagline: false,
          layout: 'horizontal',
          label: 'checkout',
          height: 25,
          ...options,
        },

        /**
         * Will be called if the express button is clicked
         */
        createOrder: async () => {
          dispatchEvent('createOrder')

          await setPaypalAsDefaultPayment()

          const result = await createOrderPaypalExpress(apiInstance)

          return result.token
        },

        /**
         * Will be called if the payment process is approved by paypal
         */
        onApprove: async (data: any, actions: any) => {
          dispatchEvent('onApprove')

          const result = await prepareCheckoutPaypalExpress(
            data.orderID,
            apiInstance
          )

          setPaypalToken(result.contextToken, data.orderID)

          if (Capacitor.isNativePlatform()) {
            return actions.redirect(
              'bs-app://open/checkout?isPayPalExpressCheckout=1'
            )
          }

          return actions.redirect(
            // @ts-ignore
            rootContext.$config.appUrl + '/checkout?isPayPalExpressCheckout=1'
          )
        },

        /**
         * Will be called if the user cancels the checkout.
         */
        onCancel: () => {
          dispatchEvent('onCancel')
        },

        /**
         * Will be called if an error occurs during the payment process.
         */
        onError: () => {
          dispatchEvent('onError')
        },
      })
      .render(container)
  }

  return {
    createButton,
    getPaypalToken,
    setPaypalToken,
    setPaypalAsDefaultPayment,
    isExpressCheckout,
    clearPaypalToken,
    setEventListener,
  }
}
