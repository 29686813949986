import { computed, ComputedRef, ssrRef } from '@nuxtjs/composition-api'
import deepmerge from 'deepmerge'
import { ApplicationVueContext } from '../appContext'
import { getListingFilters, ListingFilter } from '~/helpers'
import { getApplicationContext, useSharedState } from '~/composables'
import {
  ShopwareSearchParams,
  Sort,
} from '~/commons/interfaces/search/SearchCriteria'
import { ListingResult } from '~/commons/interfaces/response/ListingResult'

/**
 * Listing interface, can be used to display category products, search products or any other Shopware search interface (ex. orders with pagination)
 *
 * @beta
 */
export interface IUseListing<ELEMENTS_TYPE> {
  getInitialListing: ComputedRef<ListingResult<ELEMENTS_TYPE> | null>
  setInitialListing: (
    initialListing: Partial<ListingResult<ELEMENTS_TYPE>>
  ) => void
  initSearch: (criteria: Partial<ShopwareSearchParams>) => Promise<void>
  search: (
    criteria: Partial<ShopwareSearchParams>,
    options?: {
      preventRouteChange?: boolean
    }
  ) => Promise<void>
  loadMore: () => Promise<void>
  getCurrentListing: ComputedRef<Partial<ListingResult<ELEMENTS_TYPE>> | null>
  getElements: ComputedRef<ELEMENTS_TYPE[]>
  getSortingOrders: ComputedRef<Sort[] | { key: string; label: string }>
  getCurrentSortingOrder: ComputedRef<string | undefined>
  changeCurrentSortingOrder: (order: string | string[]) => Promise<void>
  getCurrentPage: ComputedRef<string | number>
  changeCurrentPage: (pageNumber?: number | string) => Promise<void>
  getTotal: ComputedRef<number>
  getTotalPagesCount: ComputedRef<number>
  getLimit: ComputedRef<number>
  getAvailableFilters: ComputedRef<ListingFilter[]>
  getCurrentFilters: ComputedRef<any>
  loading: ComputedRef<boolean>
  loadingMore: ComputedRef<boolean>
  loadAggregations: () => Promise<void>
  loadingAggregations: ComputedRef<boolean>
  getIsAggregationsLoaded: ComputedRef<boolean>
}

const sharedLoadings = ssrRef({}) as any

/**
 * Factory to create your own listing. By default you can use useListing composable, which provides you predefined listings for category(cms) listing and product search listing.
 * Using factory you can provide our own compatible search method and use it for example for creating listing of orders in my account.
 *
 * @beta
 */
export function createListingComposable<ELEMENTS_TYPE>({
  rootContext,
  searchMethod,
  searchDefaults,
  listingKey,
}: {
  rootContext: ApplicationVueContext
  searchMethod: (
    searchParams: Partial<ShopwareSearchParams>
  ) => Promise<ListingResult<ELEMENTS_TYPE>>
  searchDefaults: ShopwareSearchParams
  listingKey: string
}): IUseListing<ELEMENTS_TYPE> {
  const { router, contextName } = getApplicationContext(
    rootContext,
    'createListingComposable'
  )

  const loading = computed({
    get: () => !!sharedLoadings.value[listingKey],
    set: (state) =>
      (sharedLoadings.value = {
        ...sharedLoadings.value,
        [listingKey]: state,
      }),
  })

  const loadingMore = computed({
    get: () => !!sharedLoadings.value[listingKey + '_more'],
    set: (state) =>
      (sharedLoadings.value = {
        ...sharedLoadings.value,
        [listingKey + '_more']: state,
      }),
  })

  const loadingAggregations = computed({
    get: () => !!sharedLoadings.value[listingKey + '_aggregations'],
    set: (state) =>
      (sharedLoadings.value = {
        ...sharedLoadings.value,
        [listingKey + '_aggregations']: state,
      }),
  })

  const { sharedRef } = useSharedState(rootContext)
  const _storeInitialListing = sharedRef<ListingResult<ELEMENTS_TYPE>>(
    `${contextName}-initialListing-${listingKey}`
  )
  const _storeAppliedListing = sharedRef<Partial<ListingResult<ELEMENTS_TYPE>>>(
    `${contextName}-appliedListing-${listingKey}`
  )
  const _aggregationsLoaded = sharedRef<boolean>(
    `${contextName}-aggregationsLoaded-${listingKey}`
  )

  const getInitialListing = computed(() => _storeInitialListing.value)
  const setInitialListing = (
    initialListing: Partial<ListingResult<ELEMENTS_TYPE>>
  ) => {
    _storeInitialListing.value = initialListing as ListingResult<ELEMENTS_TYPE>
    _storeAppliedListing.value = null
    _aggregationsLoaded.value = false
    loading.value = false
  }

  const initSearch = async (
    criteria: Partial<ShopwareSearchParams>
  ): Promise<void> => {
    loading.value = true
    try {
      const searchCriteria = deepmerge(searchDefaults || {}, criteria)
      const result = await searchMethod(searchCriteria)
      await setInitialListing(result)
    } finally {
      loading.value = false
    }
  }

  const search = async (
    criteria: Partial<ShopwareSearchParams>,
    options?: {
      preventRouteChange?: boolean
    }
  ): Promise<void> => {
    loading.value = true
    const changeRoute = options?.preventRouteChange !== true
    try {
      // replace URL query params with currently selected criteria
      changeRoute &&
        router
          .replace({
            // @ts-ignore
            query: {
              ...criteria,
            },
          })
          .catch(() => {})

      // prepare full criteria using defaults and currently selected criteria
      const searchCriteria = deepmerge.all([
        { order: getCurrentSortingOrder.value },
        searchDefaults || {},
        criteria,
      ])
      _storeAppliedListing.value = await searchMethod(searchCriteria)
    } finally {
      loading.value = false
    }
  }

  const loadMore = async (): Promise<void> => {
    loadingMore.value = true
    try {
      const query = {
        ...router.currentRoute.query,
        p: getCurrentPage.value + 1,
      }

      const searchCriteria = deepmerge(searchDefaults, query)
      const result = await searchMethod(searchCriteria)
      _storeAppliedListing.value = {
        ...getCurrentListing.value,
        page: result.page,
        elements: [
          ...(getCurrentListing.value?.elements || []),
          ...result.elements,
        ],
      }
    } finally {
      loadingMore.value = false
    }
  }

  const loadAggregations = async (): Promise<void> => {
    try {
      loadingAggregations.value = true

      const query = {
        ...router.currentRoute.query,
        'no-aggregations': false,
        'only-aggregations': true,
        'reduce-aggregations': null,
      }

      const searchCriteria = deepmerge(searchDefaults || {}, query)
      const result = await searchMethod(searchCriteria)

      _storeInitialListing.value = {
        ..._storeInitialListing.value,
        aggregations: result.aggregations,
      } as ListingResult<ELEMENTS_TYPE>
      _aggregationsLoaded.value = true
    } catch (e) {
    } finally {
      loadingAggregations.value = false
    }
  }

  const getIsAggregationsLoaded = computed(() => {
    return !!_aggregationsLoaded.value
  })

  const getCurrentListing = computed(() => {
    return _storeAppliedListing.value || getInitialListing.value
  })

  const getElements = computed(() => {
    return getCurrentListing.value?.elements || []
  })
  const getTotal = computed(() => {
    return getCurrentListing.value?.total || 0
  })
  const getLimit = computed(() => {
    return getCurrentListing.value?.limit || searchDefaults?.limit || 10
  })

  const getTotalPagesCount = computed(() =>
    Math.ceil(getTotal.value / getLimit.value)
  )

  const getSortingOrders = computed(() => {
    const oldSortings = Object.values(getCurrentListing.value?.sortings || {}) // before Shopware 6.4
    return getCurrentListing.value?.availableSortings || oldSortings
  })

  const getCurrentSortingOrder = computed(
    () => getCurrentListing.value?.sorting
  )
  const changeCurrentSortingOrder = async (order: string | string[]) => {
    const query = {
      ...router.currentRoute.query,
      order,
    }
    await search(query)
  }

  const getCurrentPage = computed(() => getCurrentListing.value?.page || 1)
  const changeCurrentPage = async (pageNumber?: number | string) => {
    const p = pageNumber || 1

    const currentPage = router.currentRoute.query?.p

    const query = {
      ...router.currentRoute.query,
      p,
    }
    await search(query, {
      preventRouteChange:
        p === 1 &&
        (currentPage === undefined || parseInt(currentPage as string) === 1),
    })
  }

  const getAvailableFilters = computed(() => {
    return getListingFilters(getCurrentListing.value?.aggregations)
  })

  const getCurrentFilters = computed(() => {
    const currentFiltersResult: any = {}
    const currentFilters: any = {
      ...getCurrentListing.value?.currentFilters,
      ...router.currentRoute.query,
    }
    Object.keys(currentFilters).forEach((objectKey) => {
      if (!currentFilters[objectKey]) return
      if (objectKey === 'navigationId') return
      if (objectKey === 'price') {
        if (currentFilters[objectKey].min)
          currentFiltersResult['min-price'] = currentFilters[objectKey].min
        if (currentFilters[objectKey].max)
          currentFiltersResult['max-price'] = currentFilters[objectKey].max
        return
      }
      if (objectKey === 'p') return
      currentFiltersResult[objectKey] = currentFilters[objectKey]
    })
    return currentFiltersResult
  })

  return {
    getInitialListing,
    setInitialListing,
    initSearch,
    search,
    getCurrentListing,
    getElements,
    getSortingOrders,
    getCurrentSortingOrder,
    changeCurrentSortingOrder,
    getCurrentPage,
    changeCurrentPage,
    getTotal,
    getTotalPagesCount,
    getLimit,
    getAvailableFilters,
    getCurrentFilters,
    loading,
    loadMore,
    loadingMore,
    loadAggregations,
    loadingAggregations,
    getIsAggregationsLoaded,
  }
}
