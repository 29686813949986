import { getApplicationContext } from '~/composables'
import { ApplicationVueContext } from '~/composables/appContext'

export interface CacheInterface {
  get: (key: string) => any
  set: (key: string, value: any, maxAge?: number) => void
  del: (key: string) => void
  has: (key: string) => boolean
}

/**
 * @beta
 */
export function useCaching(rootContext: ApplicationVueContext) {
  const { apiInstance, lruGlobalCache } = getApplicationContext(
    rootContext,
    'useCaching'
  )

  const buildCacheKey = (key: string | string[]): string => {
    if (!Array.isArray(key)) {
      return key
    }

    return key.join('::')
  }

  const buildApiCacheKey = (key: string, request: object) => {
    const hash = Array.from(JSON.stringify(request)).reduce(
      (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
      0
    )

    return buildCacheKey([
      key,
      apiInstance?.config.languageId || '',
      apiInstance?.config.accessToken || '',
      hash + '',
    ])
  }

  const forget = (key: string) => {
    lruGlobalCache.del(key)
  }

  const has = (key: string): boolean => {
    return lruGlobalCache.has(key)
  }

  const get = <T>(key: string): T => {
    return lruGlobalCache.get(key)
  }

  const remember = async <T>(
    key: string,
    callback: () => T,
    maxAge?: number
  ): Promise<T> => {
    let value: T = get(key)

    if (value === undefined) {
      value = await callback()

      lruGlobalCache.set(key, value, maxAge)
    }

    return value
  }

  return {
    buildApiCacheKey,

    remember,
    forget,
    has,
    get,
  }
}
