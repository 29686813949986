<template>
  <div
    :class="breadcrumbs.length > 0 ? 'd-flex' : 'd-none'"
    class="align-start"
  >
    <base-button
      icon
      small
      class="grey-darken-4--text"
      :to="localePath('/')"
      :aria-label="$t('commons.home')"
    >
      <base-icon mdi="home" small></base-icon>
    </base-button>

    <div class="text-no-wrap text-truncate">
      <template v-for="item in breadcrumbs">
        <base-icon
          :key="`${item.path}-icon`"
          small
          mdi="chevron-right"
        ></base-icon>
        <nuxt-link
          :key="`${item.path}-link`"
          class="text-body-4 text-no-wrap link-text"
          :to="localePath(item.path)"
          >{{ item.name }}</nuxt-link
        >
      </template>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  useContext,
  useMeta,
} from '@nuxtjs/composition-api'
import { getApplicationContext, useBreadcrumbs } from '~/composables'
import { getBreadcrumbLdJson } from '~/helpers'
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'

export default defineComponent({
  name: 'TheBreadcrumbContainer',
  components: { BaseButton, BaseIcon },
  setup(_, { root }) {
    const { route } = getApplicationContext(root)
    const { breadcrumbs } = useBreadcrumbs(root)
    const { $config } = useContext()

    const isHomepage = computed(() => {
      return route.path === '/'
    })

    useMeta(() => {
      if (isHomepage.value) {
        return {}
      }

      return {
        script: [
          {
            type: 'application/ld+json',
            json: getBreadcrumbLdJson(
              breadcrumbs.value,
              root.$config.appUrl,
              $config.isMabuBuild
            ),
            body: true,
          },
        ],
      }
    })

    return {
      breadcrumbs,
    }
  },
  head: {},
})
</script>
