import { Breadcrumb } from '~/commons/interfaces/models/content/cms/CmsPage'

/**
 * @beta
 */
export function getBreadcrumbLdJson(
  breadcrumbs: Breadcrumb[],
  appUrl: string,
  isMaBu: boolean = false
): any {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: isMaBu ? 'MaBu Kids' : 'Baby Sweets',
        item: appUrl,
      },
      ...breadcrumbs.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        position: index + 2,
        name: breadcrumb.name,
        item: appUrl + breadcrumb.path,
      })),
    ],
  }
}
