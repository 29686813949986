import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

export interface BasicInformation {
  cmsContactPage?: string
  cmsHttp404Page?: string
  cmsImprintPage?: string
  cmsMaintenancePage?: string
  cmsNewsletterPage?: string
  cmsPrivacyPage?: string
  cmsRevocationPage?: string
  cmsShippingPaymentInfoPage?: string
  cmsTosPage?: string
}

export interface PageConfigPromotionHint {
  priority: number
  timeSince: string | null
  timeUntil: string | null
  promotionMinValue: number
  hideCartValue: number
  hideHasPromotion: boolean
  showCartValue: number
  hideHasCodes: string[] | null
  showHasCodes: string[] | null
  voucherCode: string | null
  isCopyVoucherCodeEnabled: boolean
  isCopyVoucherCodeFinalEnabled: boolean

  translated: {
    content: string
    finalContent: string
    textColor: string
    backgroundColor: string
    url: string
  }
}

export interface PageConfigResponse {
  fourForThreeActions: any[]
  globalFooterContents: any[]
  troublemakerConfigs: any[]
  headbangers: any[]
  promotionHints: PageConfigPromotionHint[]
  basicInformation: BasicInformation

  apiAlias: string
}

/**
 * @throws ClientApiError
 * @beta
 */
export async function getPageConfig(
  params?: Object,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PageConfigResponse | undefined> {
  const { data } = await contextInstance.invoke.post(
    '/store-api/bs/pwa/settings/page-config',
    params
  )

  return data
}
