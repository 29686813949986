import { reactive } from '@vue/composition-api'
import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import { isValueEnabled } from '~/helpers'
import {
  preCheckDirectDebit,
  PreCheckResponse,
} from '~/shopware-6-client/plugins/services/bsPayolutionPaymentPwaService'
import { ShopwareApiInstance } from '~/shopware-6-client/apiService'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import {
  PaymentServiceBeforeOrderResult,
  PaymentServiceInterface,
  PaymentServiceOptionsInterface,
} from '~/services/payments/PaymentServiceInterface'
import { createFieldInput } from '~/services/FieldInputFactory'

export interface PayolutionDirectDebitServiceBeforeOrderResult
  extends PaymentServiceBeforeOrderResult {
  payload: PreCheckResponse
}

export const createPayolutionDirectDebitService = (
  context: ApplicationVueContext
): PaymentServiceInterface => {
  const componentName: string = 'CheckoutPaymentPayolutionDirectDebit'
  const { i18n } = getApplicationContext(context)

  const isSupported = (payment: PaymentMethod) => {
    return isValueEnabled(
      // @ts-ignore
      payment?.customFields?.payolution_is_direct_debit_payment
    )
  }

  const inputFields = {
    owner: reactive(
      createFieldInput({
        name: i18n.t('fields.owner') as string,
        rules: 'required',
      })
    ),
    iban: reactive(
      createFieldInput({
        name: i18n.t('fields.iban') as string,
        rules: 'required',
      })
    ),
    day: reactive(
      createFieldInput({
        name: i18n.t('fields.day') as string,
        rules: 'required',
      })
    ),
    month: reactive(
      createFieldInput({
        name: i18n.t('fields.month') as string,
        rules: 'required',
      })
    ),
    year: reactive(
      createFieldInput({
        name: i18n.t('fields.year') as string,
        rules: 'required',
      })
    ),
    agree: reactive(
      createFieldInput({
        name: i18n.t('fields.agb') as string,
        rules: 'required',
      })
    ),
    agree2: reactive(
      createFieldInput({
        name: i18n.t('fields.agb') as string,
        rules: 'required',
      })
    ),
  }

  const beforeOrderHook = async (
    _: ApplicationVueContext,
    apiInstance: ShopwareApiInstance | undefined
  ): Promise<PayolutionDirectDebitServiceBeforeOrderResult> => {
    const result = await preCheckDirectDebit(
      {
        payolutionBirthdayDay: inputFields.day.value || '',
        payolutionBirthdayMonth: inputFields.month.value || '',
        payolutionBirthdayYear: inputFields.year.value || '',
        payolutionDirectDebitMandateIban: inputFields.iban.value || '',
        payolutionDirectDebitMandateOwner: inputFields.owner.value || '',
      },
      apiInstance
    )

    return {
      isSuccessful: result.isSuccessful,
      payload: result,
    }
  }

  const getHandlePaymentPayload = (
    beforeOrderResult: PayolutionDirectDebitServiceBeforeOrderResult | null
  ) => {
    if (!beforeOrderResult) {
      return null
    }

    return {
      payolutionTransactionId: beforeOrderResult.payload.transactionId,
      payolutionPreCheckId: beforeOrderResult.payload.preCheckId,
      payolutionIsB2b: '0',
      payolutionDirectDebitMandateOwner: inputFields.iban.value || '',
      payolutionDirectDebitMandateIban: inputFields.owner.value || '',
      payolutionBirthdayDay: inputFields.day.value || '',
      payolutionBirthdayMonth: inputFields.month.value || '',
      payolutionBirthdayYear: inputFields.year.value || '',
      payolutionDirectDebitAcceptCreditCheck: inputFields.agree.value
        ? 'on'
        : '',
      payolutionDirectDebitAcceptMandate: inputFields.agree2.value ? 'on' : '',
    }
  }

  const getOptions = (): PaymentServiceOptionsInterface => ({})

  return {
    componentName,
    isSupported,
    inputFields,
    beforeOrderHook,
    getHandlePaymentPayload,
    getOptions,
  }
}
