import {
  ref,
  Ref,
  computed,
  ComputedRef,
  watch,
  reactive,
} from '@vue/composition-api'
import { validate } from 'vee-validate'
import {
  ApplicationContext,
  ApplicationVueContext,
  getApplicationContext,
} from '../appContext'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductCustomField, isValueEnabled } from '~/helpers'

const INDIVIDUALISATION_MAP: {
  [key: string]: (
    product: Product,
    context: ApplicationContext
  ) => IIndividualisationField
} = {
  text: factoryName,
  textTwo: factoryNameTwo,
  textThree: factoryNameThree,
  date: factoryDate,
  color: factoryColor,
  font: factoryFont,
  size: factorySize,
  weight: factoryWeight,
  time: factoryTime,
}

function factoryName(_: Product, { i18n }: ApplicationContext) {
  return createIndividualisationField({
    id: 'text',
    field: i18n.t('fields.name') as string,
    rules: 'required|max:12|alpha_spaces',
  })
}

function factoryNameTwo(_: Product, { i18n }: ApplicationContext) {
  return createIndividualisationField({
    id: 'textTwo',
    field: i18n.t('fields.name') as string,
    rules: 'max:12|alpha_spaces',
  })
}

function factoryNameThree(_: Product, { i18n }: ApplicationContext) {
  return createIndividualisationField({
    id: 'textThree',
    field: i18n.t('fields.name') as string,
    rules: 'max:12|alpha_spaces',
  })
}

function factorySize(_: Product, { i18n }: ApplicationContext) {
  return createIndividualisationField({
    id: 'size',
    field: i18n.t('fields.size') as string,
    rules: 'between:1,200',
    formatter: (value: string): string => {
      if (!value) {
        return value
      }

      return i18n.n(Number(value), 'short') + ' cm'
    },
  })
}

function factoryWeight(_: Product, { i18n }: ApplicationContext) {
  return createIndividualisationField({
    id: 'weight',
    field: i18n.t('fields.weight') as string,
    rules: 'between:1,100000',
    formatter: (value: string): string => {
      if (!value) {
        return value
      }

      return i18n.n(Number(value), 'short') + ' g'
    },
  })
}

function factoryTime(_: Product, { i18n }: ApplicationContext) {
  return createIndividualisationField({
    id: 'time',
    field: i18n.t('fields.time') as string,
    formatter: (value: string): string => {
      if (!value) {
        return value
      }

      const [h, m] = value.split(':')

      return i18n.t('productDetailPage.individualProduct.timeFieldFormat', [
        h,
        m,
      ]) as string
    },
  })
}

function factoryDate(_: Product, { i18n }: ApplicationContext) {
  return createIndividualisationField({
    id: 'date',
    field: i18n.t('fields.date') as string,
    formatter: (value: string): string => {
      const date = new Date(value)

      return !isNaN(date.getTime()) ? i18n.d(new Date(value), 'short') : ''
    },
  })
}

function factoryColor(product: Product, { i18n }: ApplicationContext) {
  const colorDefinition = getProductCustomField(
    product,
    'brl_custom_color',
    'brl_custom_color'
  )

  const initItems = colorDefinition.split(',').map((color: string) => {
    const [name, hex] = color.split('|')

    return {
      value: name,
      text: name,
      color: hex,
    }
  })

  return createIndividualisationField({
    id: 'color',
    field: i18n.t('fields.color') as string,
    initItems,
    initValue: initItems[0]?.value,
  })
}

function factoryFont(_: Product, { i18n }: ApplicationContext) {
  return createIndividualisationField({
    id: 'font',
    field: i18n.t('fields.font') as string,
    rules: '',
    initItems: [
      {
        text: i18n.t(
          'productDetailPage.individualProduct.font.lucida_handwriting'
        ),
        value: i18n.t(
          'productDetailPage.individualProduct.font.lucida_handwriting'
        ),
        font: 'lucida_handwriting',
      },
      {
        text: i18n.t('productDetailPage.individualProduct.font.script_mt_bold'),
        value: i18n.t(
          'productDetailPage.individualProduct.font.script_mt_bold'
        ),
        font: 'script_mt_bold',
      },
      {
        text: i18n.t(
          'productDetailPage.individualProduct.font.berlin_sans_fbregular'
        ),
        value: i18n.t(
          'productDetailPage.individualProduct.font.berlin_sans_fbregular'
        ),
        font: 'berlin_sans_fbregular',
      },
    ],
    initValue: 'Verspielt',
  })
}

export interface IIndividualisationField {
  id: string
  field: string
  value: Ref<string>
  isValid: Ref<boolean>
  errors: Ref<string[]>
  items: Ref<any[]>
  formattedValue: ComputedRef<string>
}

function createIndividualisationField({
  id,
  field,
  rules = null,
  initItems = [],
  initValue = '',
  formatter = null,
}: {
  id: string
  field: string
  rules?: string | null
  initItems?: any[]
  initValue?: string
  formatter?: Function | null
}): IIndividualisationField {
  const value = ref(initValue)
  const isValid = ref(false)
  const errors: Ref<string[]> = ref([])
  const items = ref(initItems)

  if (rules) {
    watch(value, async (newValue) => {
      const result = await validate(newValue, rules, { name: field })

      isValid.value = result.valid
      errors.value = result.errors
    })
  } else {
    isValid.value = true
  }

  const formattedValue = computed(() =>
    formatter ? formatter(value.value) : value.value
  )

  return {
    id,
    field,
    value,
    isValid,
    errors,
    items,
    formattedValue,
  }
}

/**
 * interface for {@link useProductIndividualisation} composable
 * @beta
 */
export interface IUseProductIndividualisation {
  isProductIndividualisationEnabled: ComputedRef<boolean>
  isIndividualisationActive: Ref<boolean>
  isValid: ComputedRef<boolean>
  fields: IIndividualisationField[]
  getPayload: any
  renderType: ComputedRef<string>
  isIndividualisationRequired: ComputedRef<boolean>
  individualisationCost: ComputedRef<Number>
}

/**
 * Product options - {@link IUseProductIndividualisation}
 * @beta
 */
export const useProductIndividualisation = (
  context: ApplicationVueContext,
  product: Product
): IUseProductIndividualisation => {
  const appContext = getApplicationContext(context)
  const isProductIndividualisationEnabled = computed(() => {
    return isValueEnabled(getProductCustomField(product, 'brl_custom_enable'))
  })

  const fieldDefinitions = getProductCustomField(
    product,
    'brl_custom_fields',
    ''
  )

  const _isIndividualisationRequired = isValueEnabled(
    getProductCustomField(product, 'brl_custom_required', '0')
  )

  const isIndividualisationActive = ref(_isIndividualisationRequired)

  const isValid = computed(() => {
    return !fields.some((field: IIndividualisationField) => {
      return !field.isValid
    })
  })

  const fields: IIndividualisationField[] = fieldDefinitions
    .split(',')
    .map((field: string) => {
      // eslint-disable-next-line no-prototype-builtins
      if (INDIVIDUALISATION_MAP.hasOwnProperty(field)) {
        return reactive(INDIVIDUALISATION_MAP[field](product, appContext))
      }

      return null
    })
    .filter((value: any) => value !== null)

  const getPayload = () => {
    return fields.reduce((acc: any, cur: IIndividualisationField) => {
      acc[cur.id] = cur.formattedValue

      return acc
    }, {})
  }

  const renderType = computed(() => {
    return getProductCustomField(product, 'brl_custom_render_type', 'default')
  })

  const isIndividualisationRequired = computed(() => {
    return isValueEnabled(
      getProductCustomField(product, 'brl_custom_required', '0')
    )
  })

  const individualisationCost = computed(() => {
    const costStr = getProductCustomField(product, 'brl_custom_cost', '5')

    if (typeof costStr === 'string') {
      const price = parseFloat(costStr.trim().replace(',', '.'))

      if (!isNaN(price) && isFinite(price)) {
        return price
      }
    }

    return 5.0
  })

  return {
    isProductIndividualisationEnabled,
    isIndividualisationActive,
    isValid,
    fields,
    getPayload,
    renderType,
    isIndividualisationRequired,
    individualisationCost,
  }
}
