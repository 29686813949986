<script>
import { mergeData } from 'vue-functional-data-merge'

export default {
  name: 'BaseAvatar',
  functional: true,
  props: {
    size: {
      type: String,
      default: undefined,
    },
  },
  render(h, { props, data, children }) {
    return h(
      'div',
      mergeData(data, {
        staticClass: 'base-avatar',
        style: {
          height: props.size,
          width: props.size,
        },
      }),
      children
    )
  },
}
</script>
