import {
  getProductEndpoint,
  getProductDetailsEndpoint,
  getProductListingEndpoint,
  getProductCrossSellingEndpoint,
} from '../endpoints'
import { convertSearchCriteria, ApiType } from '../helpers/searchConverter'
import { defaultInstance, ShopwareApiInstance } from '../apiService'
import { ProductListingResult } from '~/commons/interfaces/response/ProductListingResult'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import {
  SearchCriteria,
  ShopwareSearchParams,
} from '~/commons/interfaces/search/SearchCriteria'
import { SearchResult } from '~/commons/interfaces/response/SearchResult'
import { ProductResponse } from '~/commons/interfaces/response/ProductResult'
import { EntityResult } from '~/commons/interfaces/response/EntityResult'
import { CrossSellingElement } from '~/commons/interfaces/models/content/cross-selling/CrossSellingElement'
import { ProductReview } from '~/commons/interfaces/models/content/product/ProductReview'

/**
 * Get default amount of products' ids
 *
 * @deprecated method is no longer supported
 *
 * @throws ClientApiError
 * @alpha
 */
export const getProductsIds = async function (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options?: any,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<SearchResult<string[]>> {
  const resp = await contextInstance.invoke.post(getProductEndpoint(), {
    includes: [{ product: ['id'] }],
  })
  return resp.data
}

/**
 * Get default amount of products
 *
 * @throws ClientApiError
 * @beta
 */
export const getProducts = async function (
  searchCriteria?: SearchCriteria,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<EntityResult<'product', Product[]>> {
  const resp = await contextInstance.invoke.post(
    `${getProductEndpoint()}`,
    convertSearchCriteria({ searchCriteria, config: contextInstance.config })
  )
  return resp.data
}

/**
 * Get default amount of products and listing configuration for given category
 * @deprecated use getCategoryProducts instead
 * @throws ClientApiError
 * @beta
 */
export const getCategoryProductsListing = async function (
  categoryId: string,
  searchCriteria?: SearchCriteria,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<ProductListingResult> {
  const resp = await contextInstance.invoke.post(
    `${getProductListingEndpoint(categoryId)}`,
    convertSearchCriteria({
      searchCriteria,
      apiType: ApiType.store,
      config: contextInstance.config,
    })
  )
  return resp.data
}

/**
 * Get default amount of products and listing configuration for given category
 *
 * @throws ClientApiError
 * @beta
 */
export const getCategoryProducts = async function (
  categoryId: string,
  criteria?: ShopwareSearchParams,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<ProductListingResult> {
  const resp = await contextInstance.invoke.post(
    `${getProductListingEndpoint(categoryId)}`,
    criteria
  )
  return resp.data
}

/**
 * Get the product with passed productId
 *
 * @throws ClientApiError
 * @beta
 */
export async function getProduct(
  productId: string,
  params: any = null,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<ProductResponse> {
  const resp = await contextInstance.invoke.post(
    getProductDetailsEndpoint(productId),
    {
      params,
    }
  )
  return resp.data
}

/**
 * Add a review to specific product by its ID
 *
 * @throws ClientApiError
 * @beta
 */
export async function addProductReview(
  productId: string,
  productReviewData: {
    title: string
    content: string
    points: number
  },
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<void> {
  await contextInstance.invoke.post(
    `${getProductDetailsEndpoint(productId)}/review`,
    productReviewData
  )
}

export async function getProductReviews(
  productId: string,
  criteria?: ShopwareSearchParams,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<EntityResult<'product_review', ProductReview[]>> {
  const resp = await contextInstance.invoke.post(
    `${getProductDetailsEndpoint(productId)}/reviews`,
    criteria
  )

  return resp.data
}

export async function getProductCrossSelling(
  productId: string,
  params: any = null,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<CrossSellingElement[]> {
  const resp = await contextInstance.invoke.post(
    getProductCrossSellingEndpoint(productId),
    params
  )

  return resp.data
}
