import { computed, ComputedRef, Ref, ref } from '@vue/composition-api'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { useSessionContext } from '~/composables'
import { startSession as startSessionApi } from '~/shopware-6-client/plugins/services/bsKlarnaPaymentService'
import { loadScript as helperLoadScript } from '~/helpers'

/**
 * @beta
 **/
export interface KlarnaSession {
  cartHash: string
  clientToken: string
  customerData: any
  extensions: any[]
  klarnaCartHash: string
  klarnaCartToken: string
  paymentMethodCategories: any
  selectedPaymentMethodCategory: string | null
  sessionId: string
}

/**
 * @beta
 **/
export interface KlarnaLoadResult {
  // eslint-disable-next-line camelcase
  show_form: boolean
}

/**
 * @beta
 **/
export interface KlarnaAuthorizeResult {
  // eslint-disable-next-line camelcase
  authorization_token?: string
  approved: boolean
  // eslint-disable-next-line camelcase
  show_form: boolean
  error?: any
}

/**
 * @beta
 **/
export interface IUseKlarnaPayment {
  type: ComputedRef<string>
  startSession: () => Promise<boolean>
  init: () => boolean
  load: (containerId: string) => Promise<boolean>
  authorize: () => Promise<KlarnaAuthorizeResult | null>
  refresh: (containerId: string) => Promise<boolean>
  loadScript: () => Promise<void>
  isKlarnaPayment: ComputedRef<boolean>
  isAuthorizeApproved: ComputedRef<boolean>
  session: Ref<KlarnaSession | null>
  authorizeResult: Ref<KlarnaAuthorizeResult | null>
  loadResult: Ref<KlarnaLoadResult | null>
  error: Ref
}

/**
 * @beta
 */
export const useKlarnaPayment = (
  rootContext: ApplicationVueContext,
  orderId?: string
): IUseKlarnaPayment => {
  const { apiInstance } = getApplicationContext(rootContext, 'useKlarnaPayment')
  const { paymentMethod } = useSessionContext(rootContext)

  const session: Ref<KlarnaSession | null> = ref(null)
  const authorizeResult: Ref<KlarnaAuthorizeResult | null> = ref(null)
  const loadResult: Ref<KlarnaLoadResult | null> = ref(null)
  const error = ref(null)

  const getKlarnaInstance = () => {
    // @ts-ignore
    return Klarna
  }

  const type = computed(() => {
    switch (paymentMethod.value?.id) {
      case 'ede05b719b214143a4cb1c0216b852de':
        return 'pay_later'
      case 'ad4ca642046b40248444eba38bb8f5e8':
        return 'pay_over_time'
      case '9f4ac7bef3394487b0ab9298d12eb1bd':
        return 'direct_debit'
      case 'a03b53a6e3d34836b150cc6eeaf6d97d':
        return 'direct_bank_transfer'
      case 'd245c39e8707e85f053e806abffcbb36':
        return 'card'
      case 'f1ef36538c594dc580b59e28206a1297':
        return 'pay_now'
    }

    return ''
  })

  const startSession = async (): Promise<boolean> => {
    try {
      session.value = await startSessionApi(orderId, apiInstance)

      return true
    } catch (e) {
      session.value = null

      console.error('[useKlarnaPayment][authorize]', e)
    }

    return false
  }

  const init = (): boolean => {
    if (!session.value || !session.value.clientToken) {
      return false
    }

    try {
      getKlarnaInstance().Payments.init({
        client_token: session.value.clientToken,
      })

      return true
    } catch (e) {
      console.error('[useKlarnaPayment][init]', e)
    }

    return false
  }

  const load = (containerId: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        // @ts-ignore
        document.getElementById(containerId)?.innerHTML = ''

        getKlarnaInstance().Payments.load(
          {
            container: '#' + containerId,
            payment_method_category: type.value,
          },
          (result: KlarnaLoadResult) => {
            loadResult.value = result

            resolve(result && result.show_form)
          }
        )
      } catch (e) {
        loadResult.value = null
        reject(e)

        console.error('[useKlarnaPayment][load]', e)
      }
    })
  }

  const authorize = (): Promise<KlarnaAuthorizeResult | null> => {
    return new Promise((resolve, reject) => {
      if (!session.value || !session.value.clientToken) {
        reject(new Error('Session is null'))

        return
      }

      try {
        getKlarnaInstance().Payments.authorize(
          {
            auto_finalize: true,
            payment_method_category: type.value,
          },
          session.value.customerData,
          (result: any) => {
            authorizeResult.value = result

            resolve(result)
          }
        )
      } catch (e) {
        authorizeResult.value = null

        reject(e)

        console.error('[useKlarnaPayment][authorize]', e)
      }
    })
  }

  const refresh = async (containerId: string): Promise<boolean> => {
    if (!(await startSession())) {
      return false
    }

    if (!init()) {
      return false
    }

    return await load(containerId)
  }

  const loadScript = async () => {
    await helperLoadScript(`https://x.klarnacdn.net/kp/lib/v1/api.js`)
  }

  const isKlarnaPayment = computed(() => {
    // @ts-ignore
    return paymentMethod.value?.shortName === 'klarna_payments_payment_handler'
  })

  const isAuthorizeApproved = computed(() => {
    return authorizeResult.value?.approved === true
  })

  return {
    type,
    startSession,
    init,
    load,
    authorize,
    refresh,
    loadScript,

    isKlarnaPayment,
    isAuthorizeApproved,

    session,
    authorizeResult,
    loadResult,
    error,
  }
}
