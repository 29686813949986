<template>
  <div class="product-troublemaker">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ProductTroublemaker',
}
</script>

<style lang="scss">
.product-troublemaker {
  height: 100%;
  width: 100%;
}
</style>
