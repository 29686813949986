<template>
  <nuxt-link :to="routerLink" class="product-card grey-darken-4--text">
    <product-image
      :media="cover"
      :alt="name"
      effect
      gradient
      :loading="eager ? 'eager' : 'lazy'"
      :sizes="sizes"
      :init-width="initImageWidth"
      :proxy-srcset="proxySrcset"
    >
      <product-card-extra-badge
        v-if="extraBadge"
        :class="extraBadge.classes"
        :to="extraBadge.url"
        class="text-overline"
        >{{ extraBadge.content }}</product-card-extra-badge
      >
      <base-button
        v-show="isInWishlist"
        class="product-card__wishlist"
        :aria-label="$t('commons.addWishlist')"
        icon
        small
        :class="isInWishlist ? 'pink-berry--text' : ''"
        @click.stop.prevent="$emit('click:wishlist', product)"
      >
        <base-icon v-show="isInWishlist" mdi="heart"></base-icon>
        <base-icon v-show="!isInWishlist" mdi="heart-outline"></base-icon>
      </base-button>
      <slot name="action"></slot>
      <product-card-badges :badges="badges" :max-badges="2">
        <product-badge v-if="productDiscountPercentage > 0" type="discount"
          >{{ productDiscountPercentage }} %</product-badge
        >
      </product-card-badges>
    </product-image>

    <div class="pa-3 pt-2 d-flex flex-column" style="height: 120px">
      <div>
        <div class="align-baseline mt-n1 d-flex justify-end">
          <base-product-rating
            x-small
            :value="product.ratingAverage"
            :style="ratingStyles"
          ></base-product-rating>
          <span
            v-show="product.ratingAverage"
            class="text-h6 grey-lighten-1--text pl-1"
          >
            <span class="text-body-2">⌀</span>
            {{ product.ratingAverage | rating }}</span
          >
        </div>
        <div
          class="text-overline text-uppercase text-truncate"
          style="height: 15px"
        >
          {{ manufacturer }}
        </div>
        <div class="text-h6 text-truncate mt-1">{{ name }}</div>
        <div class="text-body-4 text-truncate mt-1">{{ subTitle }}</div>
      </div>
      <div class="mt-auto">
        <span class="text-price-3 pr-2">
          {{ productPrice | price }}
        </span>
        <span v-if="productDiscountPercentage > 0" class="text-price-discount-3"
          >{{ productUvpPrice | price }}
        </span>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import ProductImage from '~/components/product/ProductImage'
import ProductCardBadges from '~/components/product/card/ProductCardBadges'
import ProductBadge from '~/components/product/badge/ProductBadge'
import {
  getProductListingName,
  getProductManufacturerName,
  getProductParentCoverMedia,
  getProductParentUrl,
  getProductRealDiscountPercentage,
  getProductRealPrice,
  getProductRealUvpPrice,
  getProductSubTitle,
  isProductManufacturerPrio,
} from '~/helpers'
import { makeProductBadges } from '~/logic/product/makeProductBadges'
import BaseProductRating from '~/components/base/product/rating/BaseProductRating'
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'
import { getProductListingRealPrice } from '~/helpers/product/getProductListingRealPrice'
import { getProductListingRealUvpPrice } from '~/helpers/product/getProductListingRealUvpPrice'
import { getProductListingRealDiscountPercentage } from '~/helpers/product/getProductListingRealDiscountPercentage'
import ProductCardExtraBadge from '~/components/product/card/ProductCardExtraBadge.vue'
import { makeProductExtraBadge } from '~/logic/product/makeProductExtraBadge'

export default {
  name: 'ProductCard',
  components: {
    ProductCardExtraBadge,
    BaseButton,
    BaseIcon,
    BaseProductRating,
    ProductBadge,
    ProductCardBadges,
    ProductImage,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: false,
    },
    sizes: {
      type: String,
      default: '',
    },
    proxySrcset: {
      type: Array,
      default: undefined,
    },
    initImageWidth: {
      type: Number,
      default: 400,
    },
    useListingPrice: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      return getProductListingName(this.product)
    },
    cover() {
      return getProductParentCoverMedia(this.product)
    },
    manufacturer() {
      return isProductManufacturerPrio(this.product)
        ? getProductManufacturerName(this.product)
        : ''
    },
    subTitle() {
      return getProductSubTitle(this.product)
    },
    rating() {
      return this.product.ratingAverage
    },
    ratingStyles() {
      return { visibility: this.rating ? 'visible' : 'hidden' }
    },
    routerLink() {
      return this.localePath(getProductParentUrl(this.product))
    },
    badges() {
      return makeProductBadges(this.product).map((badge) => ({
        ...badge,
        name: this.$t(`listing.badges.${badge.name}`),
      }))
    },
    productPrice() {
      if (this.useListingPrice) {
        return getProductListingRealPrice(this.product)
      }

      return getProductRealPrice(this.product)
    },
    productUvpPrice() {
      if (this.useListingPrice) {
        return getProductListingRealUvpPrice(this.product)
      }

      return getProductRealUvpPrice(this.product)
    },
    productDiscountPercentage() {
      const percent = this.useListingPrice
        ? getProductListingRealDiscountPercentage(this.product)
        : getProductRealDiscountPercentage(this.product)

      return percent ? Math.floor(percent) : undefined
    },
    extraBadge() {
      return makeProductExtraBadge(this.product)
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.product-card {
  text-decoration: none;

  &:hover {
    .product-card__wishlist {
      display: block !important;
    }
  }

  &__wishlist {
    bottom: 12px;
    right: 12px;
    position: absolute !important;
    background-color: #ffffffcc;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      display: block !important;
    }

    .base-icon {
      font-size: 20px !important;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 14px !important;
      }
    }

    &:active {
      .base-icon {
        font-size: 15px !important;

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          font-size: 20px !important;
        }
      }
    }
  }
}
</style>
