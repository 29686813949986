import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import { getCustomFieldValue } from '~/helpers/customField/getCustomFieldValue'

export function getPaymentName(payment: PaymentMethod): string | null {
  const overrideName = getCustomFieldValue<string | null>(
    payment,
    'bs_payment_name_override'
  )

  return overrideName || payment.translated?.name || payment.name
}
