import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { getProductLineItems } from '~/helpers'
import { OrderLineItem } from '~/commons/interfaces/models/checkout/order/OrderLineItem'

export function sumLineItemProductQuantity<T extends LineItem | OrderLineItem>(
  lineItems: T[]
): number {
  return getProductLineItems(lineItems).reduce((acc, cur) => {
    acc += cur.quantity
    return acc
  }, 0)
}
