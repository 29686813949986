import {
  ATTRIBUTE_BRL_CUSTOM_ENABLE,
  ATTRIBUTE_BRL_EXCLUSIVE_ENABLE,
  ATTRIBUTE_BRL_HANDMADE_ENABLE,
  ATTRIBUTE_BRL_SALE_ENABLE,
} from '~/services/cfAttributes'

export interface ProductBadge {
  key: string // unique string key
  name: string // name
  color: string
  priority: number
}

export const PRODUCT_BADGE_BIO_ENABLED: ProductBadge = {
  key: 'brl_bio_enable',
  name: 'bio',
  color: 'blue',
  priority: 0,
}

export const PRODUCT_BADGE_BAMBUS_ENABLED: ProductBadge = {
  key: 'brl_bio_bambus_enable',
  name: 'bambus',
  color: 'green',
  priority: 0,
}

export const PRODUCT_BADGE_MARK_AS_TOPSELLER: ProductBadge = {
  key: 'markAsTopseller',
  name: 'topseller',
  color: 'red',
  priority: 3,
}

export const PRODUCT_BADGE_IS_NEW: ProductBadge = {
  key: 'isNew',
  name: 'new',
  color: 'red',
  priority: 5,
}

export const PRODUCT_BADGE_INDIVIDUALISATION: ProductBadge = {
  key: ATTRIBUTE_BRL_CUSTOM_ENABLE,
  name: 'individualisation',
  color: 'red',
  priority: 4,
}

export const PRODUCT_BADGE_EXCLUSIVE: ProductBadge = {
  key: ATTRIBUTE_BRL_EXCLUSIVE_ENABLE,
  name: 'exclusive',
  color: 'red',
  priority: 3,
}

export const PRODUCT_BADGE_HANDMADE: ProductBadge = {
  key: ATTRIBUTE_BRL_HANDMADE_ENABLE,
  name: 'handmade',
  color: 'red',
  priority: 3,
}

export const PRODUCT_BADGE_SALE: ProductBadge = {
  key: ATTRIBUTE_BRL_SALE_ENABLE,
  name: 'sale',
  color: 'red',
  priority: 4,
}

export const PRODUCT_BADGES: ProductBadge[] = [
  PRODUCT_BADGE_IS_NEW,
  PRODUCT_BADGE_INDIVIDUALISATION,
  PRODUCT_BADGE_MARK_AS_TOPSELLER,
  PRODUCT_BADGE_EXCLUSIVE,
  PRODUCT_BADGE_BIO_ENABLED,
  PRODUCT_BADGE_BAMBUS_ENABLED,
  PRODUCT_BADGE_HANDMADE,
  PRODUCT_BADGE_SALE,
]
