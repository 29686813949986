import {
  computed,
  ComputedRef,
  reactive,
  UnwrapRef,
  watch,
} from '@nuxtjs/composition-api'
import { WritableComputedRef } from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'

import { useListing } from '~/composables'
import {
  debounce,
  getListingFilters,
  ListingFilter,
  mergeSameListingFilters,
} from '~/helpers'

/**
 * @beta
 */
export interface IUseListingFilter {
  currentSortingOrder: WritableComputedRef<string | undefined>
  filterValues: UnwrapRef<any>
  filterItems: ComputedRef<any>
  invokeFilter: () => Promise<void>
  removeFilter: ({ id, type }: { id: string; type: string }) => Promise<void>
  clearFilters: () => Promise<void>
  getAvailableFilters: ComputedRef<ListingFilter[]>
  hasActiveFilter: ComputedRef<boolean>
}

// function sortSizeProperties(listingFilters: ListingFilter[]): ListingFilter[] {
//   for (const listingFilter of listingFilters) {
//     if (listingFilter.code === 'properties') {
//       // if (listingFilter.label === 'Größe') {
//       //   listingFilter.options.sort((a: any, b: any) => {
//       //     const sizeA =
//       //       a.translated.name.match(/^(\d+)/)?.[1] || a.translated.name
//       //     const sizeB =
//       //       b.translated.name.match(/^(\d+)/)?.[1] || a.translated.name
//       //
//       //     return sizeA - sizeB
//       //   })
//       // }
//       if (listingFilter.label === 'Taille') {
//         listingFilter.options.sort((a: any, b: any) => {
//           // 2-18M (80-86 cm)
//
//           const sizeA = a.translated.name.match(
//             /\((\d+)(?:\s*-\s*\d+)?\s*cm\)$/
//           )?.[1]
//           const sizeB = b.translated.name.match(
//             /\((\d+)(?:\s*-\s*\d+)?\s*cm\)$/
//           )?.[1]
//
//           if (sizeA && sizeB) {
//             const result = sizeA - sizeB
//
//             if (result !== 0) {
//               return result
//             }
//           }
//
//           return a.translated.name.localeCompare(b.translated.name)
//         })
//       }
//     }
//   }
//
//   return listingFilters
// }

function groupSizeProperties(listingFilters: ListingFilter[]): ListingFilter[] {
  for (const listingFilter of listingFilters) {
    if (listingFilter.code === 'properties') {
      if (listingFilter.label === 'Taille') {
        const grouped = listingFilter.options.reduce((acc: any, cur: any) => {
          const key =
            cur.translated.name.match(/^(.*?)(?:\s*\(|$)/)?.[1] ||
            cur.translated.name

          ;(acc[key] = acc[key] || []).push(cur)

          return acc
        }, {})

        listingFilter.options = Object.entries(grouped)
          .map(([key, value]: [any, any]) => {
            return {
              ...value[0],
              duplicates: value
                .map(({ duplicates }: any) => duplicates)
                .flat(1),
              name: key,
              orgName: value[0].translated.name,
              translated: {
                ...value[0].translated,
                name: key,
              },
            }
          })
          .sort((a: any, b: any) => {
            // 2-18M (80-86 cm)

            const sizeA = a.orgName.match(/\((\d+)(?:\s*-\s*\d+)?\s*cm\)$/)?.[1]
            const sizeB = b.orgName.match(/\((\d+)(?:\s*-\s*\d+)?\s*cm\)$/)?.[1]

            if (sizeA && sizeB) {
              let result = sizeA - sizeB

              if (result !== 0) {
                return result
              }

              const sizeASub = a.orgName.match(/(\d+)\s*cm\)$/)?.[1] || 0
              const sizeBSub = b.orgName.match(/(\d+)\s*cm\)$/)?.[1] || 0

              result = sizeASub - sizeBSub

              if (result !== 0) {
                return result
              }
            }

            return a.orgName.localeCompare(b.orgName)
          })
      }
    }
  }

  return listingFilters
}

/**
 * @beta
 */
export const useListingFilter = (
  rootContext: ApplicationVueContext,
  listingKey: 'productSearchListing' | 'categoryListing' = 'categoryListing'
): IUseListingFilter => {
  const { router } = getApplicationContext(rootContext, 'useListingFilter')

  const { getCurrentSortingOrder, getCurrentFilters, getInitialListing } =
    useListing(rootContext, listingKey)

  const getAvailableFilters = computed(() => {
    return getInitialListing.value
      ? groupSizeProperties(
          mergeSameListingFilters(
            getListingFilters(getInitialListing.value.aggregations)
          ).filter((filter) => {
            return !(
              filter.code === 'price' &&
              (filter.min === null || filter.max === null)
            )
          })
        )
      : []
  })

  const currentSortingOrder = computed({
    get: () => getCurrentSortingOrder.value,
    // @ts-ignore
    set: (order) => {
      const query = {
        ...router.currentRoute.query,
        order,
        p: '1',
      }

      router
        .replace({
          // @ts-ignore
          query: {
            ...query,
          },
        })
        .catch(() => {})
    },
  })

  const mapFilters = (): any => {
    const map: any = {}

    if (getCurrentFilters.value.manufacturer) {
      map.manufacturer = !Array.isArray(getCurrentFilters.value.manufacturer)
        ? getCurrentFilters.value.manufacturer.split('|')
        : getCurrentFilters.value.manufacturer
    }

    if (getCurrentFilters.value.properties) {
      map.properties = !Array.isArray(getCurrentFilters.value.properties)
        ? getCurrentFilters.value.properties.split('|')
        : getCurrentFilters.value.properties
    }

    if (
      getCurrentFilters.value['max-price'] ||
      getCurrentFilters.value['min-price']
    ) {
      map.price = [
        parseFloat(getCurrentFilters.value['min-price']),
        parseFloat(getCurrentFilters.value['max-price']),
      ]
    } else {
      map.price = [undefined, undefined]
    }

    return map
  }

  const filterValues = reactive({
    ...getAvailableFilters.value.reduce((acc: any, cur) => {
      acc[cur.code] = null
      return acc
    }, {}),
    ...mapFilters(),
  })

  const hasActiveFilter = computed(() => {
    const filterEntryKeys = [
      'manufacturer',
      'properties',
      'price',
      'min-price',
      'max-price',
    ]

    return Object.entries(getCurrentFilters.value).some(([key, value]) => {
      return (
        filterEntryKeys.includes(key) &&
        value !== undefined &&
        value !== null &&
        value !== '' &&
        (Array.isArray(value) ? value.length > 0 : true)
      )
    })
  })

  watch(getInitialListing, () => {
    const localFilterValues = {
      ...getAvailableFilters.value.reduce((acc: any, cur) => {
        acc[cur.code] = null
        return acc
      }, {}),
      ...mapFilters(),
    }

    Object.entries(localFilterValues).forEach(([key, value]) => {
      filterValues[key] = value
    })
  })

  watch(getCurrentFilters, () => {
    const localFilterValues = {
      ...getAvailableFilters.value.reduce((acc: any, cur) => {
        acc[cur.code] = null
        return acc
      }, {}),
      ...mapFilters(),
    }

    Object.entries(localFilterValues).forEach(([key, value]) => {
      filterValues[key] = value
    })
  })

  const removeFilter = async ({ id, type }: { id: string; type: string }) => {
    if (type === 'price') {
      filterValues[type][id === 'min-price' ? 0 : 1] = ''
      filterValues[type] = [...filterValues[type]]

      await invokeFilter()

      return
    }

    const index = filterValues[type]?.indexOf(id)

    if (index >= 0) {
      filterValues[type]?.splice(index, 1)

      await invokeFilter()
    }
  }

  const clearFilters = async () => {
    Object.keys(filterValues).forEach((key) => {
      if (Array.isArray(filterValues[key])) {
        filterValues[key] = []
      } else {
        filterValues[key] = null
      }
    })

    await invokeFilter()
  }

  const getPropertyValues = (properties: any) => {
    if (!properties) {
      return null
    }

    const props: any = []

    // Schaut ob es bei der Ausgewählten Property noch mehr gibt, wenn ja löst er sie hier auf
    return getAvailableFilters.value
      .reduce((acc, cur) => {
        if (cur.code === 'properties') {
          cur.options.forEach(({ id, duplicates }: any) => {
            if (properties.includes(id)) {
              acc.push(duplicates ?? id)
            }
          })
        }

        return acc
      }, props)
      .flat(1)
  }

  const invokeFilter = debounce(async () => {
    const criteria = {
      // Im Frontend gibt es Blau nur einmal aber im Backend kann es mehrfach geben
      properties: getPropertyValues(filterValues.properties) ?? undefined,
      manufacturer: filterValues.manufacturer ?? undefined,
      'max-price': filterValues.price?.[1] || undefined,
      'min-price': filterValues.price?.[0] || undefined,
      query: getCurrentFilters.value?.search || undefined,
      p: '1',
    }

    await router
      .replace({
        // @ts-ignore
        query: {
          ...criteria,
          manufacturer:
            criteria.manufacturer && criteria.manufacturer.length > 0
              ? criteria.manufacturer.join('|')
              : undefined,
          properties:
            criteria.properties && criteria.properties.length > 0
              ? criteria.properties.join('|')
              : undefined,
        },
      })
      .catch(() => {})

    // await search(criteria)
  }, 500)

  const filterItems = computed(() => {
    const list: { id: string; value: string; type: string }[] = []

    if (filterValues.manufacturer) {
      filterValues.manufacturer.forEach((id: string) => {
        const entity = getAvailableFilters.value
          .find(({ code }) => code === 'manufacturer')
          ?.entities.find((entity: { id: string }) => {
            return entity.id === id
          })

        if (entity) {
          list.push({
            id: entity.id,
            value: entity.translated?.name ?? entity.name,
            type: 'manufacturer',
          })
        }
      })
    }

    if (filterValues.properties?.length) {
      filterValues.properties.forEach((id: string) => {
        getAvailableFilters.value
          .filter(({ code }) => code === 'properties')
          .forEach((property) => {
            const option = property.options.find((option: { id: string }) => {
              return option.id === id
            })

            if (option) {
              let name = option.translated?.name ?? option.name

              // Für Nachhaltig und co, wo es nur "Ja" gibt.
              // FIXME Übersetzungen müssen dynamisch sein
              if (['Ja', 'Oui'].includes(name)) {
                name = (property.translated?.name ?? property.name).replace(
                  /^_*(.*?)_*$/,
                  '$1'
                )
              }

              list.push({
                id: option.id,
                value: name,
                type: 'properties',
              })
            }
          })
      })
    }

    if (filterValues.price?.[0]) {
      list.push({
        id: 'min-price',
        value: filterValues.price[0],
        type: 'price',
      })
    }

    if (filterValues.price?.[1]) {
      list.push({
        id: 'max-price',
        value: filterValues.price[1],
        type: 'price',
      })
    }

    return list
  })

  return {
    currentSortingOrder,
    filterValues,
    filterItems,
    invokeFilter,
    removeFilter,
    clearFilters,
    getAvailableFilters,
    hasActiveFilter,
  }
}
