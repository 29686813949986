import { Category } from '~/commons/interfaces/models/content/category/Category'

export function createCategoryBreadcrumb(
  category: Category,
  divider: string = ' > '
): string {
  const cat = category.translated?.breadcrumb.slice(1) || []

  return cat.join(divider)
}
