<template>
  <footer id="the-footer" class="w-full" :style="styles">
    <footer-newsletter-card-text></footer-newsletter-card-text>

    <footer-usp-icons
      style="height: 110px"
      class="grey-lighten-3"
    ></footer-usp-icons>

    <div class="grey-lighten-4 px-3 py-10 pa-sm-12 pa-md-7 w-full">
      <footer-contact-info>
        <template #bottom>
          <footer-social-icons class="mt-10"></footer-social-icons>
        </template>
      </footer-contact-info>

      <v-container class="pa-0 px-lg-3">
        <v-row class="mt-8" :no-gutters="$vuetify.breakpoint.smAndDown">
          <v-col cols="12" md="3">
            <footer-navigation-card
              v-for="link in col1"
              :key="link.routePath"
              :title="link.routeLabel"
              :navigations="link.children"
            ></footer-navigation-card>
            <footer-extra-info-card
              class="hidden-sm-and-down"
            ></footer-extra-info-card>
          </v-col>
          <v-col cols="12" md="3">
            <footer-navigation-card
              v-for="link in col2"
              :key="link.routePath"
              :title="link.routeLabel"
              :navigations="link.children"
            ></footer-navigation-card>
          </v-col>
          <v-col cols="12" md="3">
            <footer-payment-methods></footer-payment-methods>
          </v-col>
          <v-col cols="12" md="3">
            <div class="the-footer__extras">
              <div
                v-if="$i18n.locale === 'de-DE'"
                class="text-h4 d-none d-md-flex align-center"
                style="height: 44px"
              >
                {{ $t('commons.security') }}
              </div>
              <footer-extra-info-card
                class="hidden-md-and-up mt-10"
              ></footer-extra-info-card>
              <footer-trusted-shops
                class="mt-10 mt-md-2"
              ></footer-trusted-shops>

              <div
                v-if="$i18n.locale === 'de-DE'"
                class="pa-4 mt-3 mx-auto mx-md-0 white d-flex"
                style="max-width: 190px"
              >
                <base-image
                  style="width: 100%"
                  width="158"
                  height="27"
                  :init-width="158"
                  :url="require('~/assets/images/footer/efre.png')"
                  loading="lazy"
                  alt="EFRE Logo"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <footer-global-content></footer-global-content>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
import { useFooterNavigation } from '~/composables'
import FooterNewsletterCardText from '~/components/footer/FooterNewsletterCardText'
import FooterContactInfo from '~/components/footer/FooterContactInfo'
import FooterSocialIcons from '~/components/footer/FooterSocialIcons'
import FooterNavigationCard from '~/components/footer/FooterNavigationCard'
import FooterExtraInfoCard from '~/components/footer/FooterExtraInfoCard'
import FooterPaymentMethods from '~/components/footer/FooterPaymentMethods'
import FooterTrustedShops from '~/components/footer/FooterTrustedShops'
import FooterUspIcons from '~/components/footer/FooterUspIcons'
import FooterGlobalContent from '~/components/footer/FooterGlobalContent'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'TheFooter',
  components: {
    BaseImage,
    FooterGlobalContent,
    FooterUspIcons,
    FooterTrustedShops,
    FooterPaymentMethods,
    FooterExtraInfoCard,
    FooterNavigationCard,
    FooterSocialIcons,
    FooterContactInfo,
    FooterNewsletterCardText,
  },
  setup(_, { root }) {
    const { navigationLinks } = useFooterNavigation(root)

    return {
      navigationLinks,
    }
  },
  computed: {
    navigations() {
      return this.navigationLinks || []
    },
    col1() {
      return this.filterNavigations('col_1')
    },
    col2() {
      return this.filterNavigations('col_2')
    },
    styles() {
      return {
        marginBottom: `${this.$vuetify.application.bottom}px`,
      }
    },
  },
  methods: {
    filterNavigations(column) {
      return this.navigations.filter(
        // eslint-disable-next-line camelcase
        ({ customFields }) => customFields?.footer_col === column
      )
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

#the-footer {
  .the-footer__extras {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      text-align: center;
    }
  }
}
</style>
