export default function ({ $passwordProtect, route, redirect, req }) {
  if (process.server && req) {
    $passwordProtect.registerRedirectCallback((opts) => {
      const ip =
        req.headers['x-forwarded-for']?.split(',').pop() ||
        req.connection?.remoteAddress ||
        req.socket?.remoteAddress ||
        req.connection?.socket.remoteAddress

      if (opts.options.excludeIPs?.includes(ip)) {
        $passwordProtect.authorise(opts.options.password)

        return
      }

      if (opts.options.userAgents?.includes(req.headers?.['user-agent'])) {
        $passwordProtect.authorise(opts.options.password)

        return
      }

      redirect(opts.options.formPath, { previousPath: route.fullPath })
    })
  }
}
