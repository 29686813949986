import { CmsSlot } from '~/commons/interfaces/models/content/cms/CmsPage'

/**
 * @alpha
 */
export function getCmsSlotConfigProperty(
  content: CmsSlot,
  property: string
): boolean | null | string | object | undefined | number {
  return content.config?.[property]?.value
}
