<script>
import ProductListing from '~/components/product/ProductListing'

export default {
  name: 'CmsElementProductListing',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h) {
    return h(ProductListing, {
      staticClass: 'mb-10',
      props: {
        initialListing: this.content.data.listing,
        troublemakerConfigs: this.content.data.troublemakerConfigs || [],
        listingType: 'categoryListing',
      },
    })
  },
}
</script>
