<script>
import { renderHtml } from 'html-to-vue'

export default {
  name: 'BaseText',
  functional: true,
  props: {
    content: {
      type: String,
      default: '',
    },
    anchor: {
      type: String,
      default: undefined,
    },
  },

  render(h, context) {
    const config = {
      textTransformer: context.parent.$entitiesDecoder,
      extraComponentsMap: {
        link: {
          conditions(node) {
            return (
              node.type === 'tag' &&
              node.name === 'a' &&
              !node.attrs?.class?.match(/btn\s?/) &&
              !node.attrs?.class?.match(/link-box\s?/)
            )
          },
          renderer(node, children, createElement) {
            return createElement(
              'cms-util-link',
              {
                staticClass: node.attrs?.class,
                style: node.attrs?.style,
                attrs: {
                  target: node.attrs?.target,
                  url: node.attrs?.href,
                  rel: node.attrs?.rel,
                },
              },
              [...children]
            )
          },
        },
        button: {
          conditions(node) {
            return (
              node.type === 'tag' &&
              node.name === 'a' &&
              node.attrs?.class?.match(/btn\s?/)
            )
          },
          renderer(node, children, createElement) {
            return createElement(
              'base-button',
              {
                staticClass: node.attrs?.class.replace('btn', ''),
                attrs: {
                  target: node.attrs?.target,
                },
                props: {
                  to: node.attrs?.href,
                },
              },
              [...children]
            )
          },
        },
        linkBox: {
          conditions(node) {
            return (
              node.type === 'tag' &&
              node.name === 'a' &&
              node.attrs?.class?.match(/link-box\s?/)
            )
          },
          renderer(node, children, createElement) {
            return createElement(
              'base-link-box',
              {
                staticClass: node.attrs?.class.replace('link-box', ''),
                attrs: {
                  target: node.attrs?.target,
                },
                props: {
                  to: node.attrs?.href,
                },
              },
              [...children]
            )
          },
        },
        countdown: {
          conditions(node) {
            return (
              node.type === 'tag' &&
              node.name === 'div' &&
              node.attrs?.class?.match(/base-countdown\s?/)
            )
          },
          renderer(node, children, createElement) {
            return createElement(
              'base-countdown',
              {
                staticClass: node.attrs?.class.replace('base-countdown', ''),
                props: {
                  endDate: node.attrs?.['data-end-date'],
                  hideDay: !!node.attrs?.['data-hide-day'],
                },
              },
              [...children]
            )
          },
        },
      },
    }

    const rawHtml = context.props.content
    const anchor = context.props.anchor

    context.data.staticClass = `base-text ${
      context.data.staticClass || ''
    }`.trim()
    context.data.attrs = {
      ...context.data.attrs,
      id: anchor,
    }

    return renderHtml(rawHtml ?? '<div></div>', config, h, context)
  },
}
</script>
