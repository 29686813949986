import { PRODUCT_BADGES, ProductBadge } from './productBadges'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { CustomFields } from '~/commons/interfaces/models/common/CustomField'
import { getProductCustomFields, isValueEnabled } from '~/helpers'

export function makeProductBadges(product: Product): ProductBadge[] {
  const customFields: CustomFields = getProductCustomFields(product)
  const foreProduct: any = product

  return PRODUCT_BADGES.map((badge: ProductBadge) => {
    if (
      isValueEnabled(foreProduct?.[badge.key]) ||
      isValueEnabled(customFields?.[badge.key])
    ) {
      return { ...badge }
    }

    return null
  })
    .filter((badge) => badge !== null)
    .sort((a: any, b: any) => b.priority - a.priority) as ProductBadge[]
}
