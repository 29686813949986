import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d0e67886 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _dc83b42c = () => interopDefault(import('../pages/account/index/order/_id/index.vue' /* webpackChunkName: "pages/account/index/order/_id/index" */))
const _03e1e763 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _70077668 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _07ceb136 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _32798f2b = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))

const _bf070bf0 = () => interopDefault(import('../pages/wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _245b2104 = () => interopDefault(import('../pages/checkout/failure.vue' /* webpackChunkName: "pages/checkout/failure" */))
const _00871916 = () => interopDefault(import('../pages/checkout/lyra/index.vue' /* webpackChunkName: "pages/checkout/lyra/index" */))
const _2c493dac = () => interopDefault(import('../pages/checkout/paypal/index.vue' /* webpackChunkName: "pages/checkout/paypal/index" */))
const _5d464afd = () => interopDefault(import('../pages/checkout/success.vue' /* webpackChunkName: "pages/checkout/success" */))
const _af9e296a = () => interopDefault(import('../pages/account/recover/password.vue' /* webpackChunkName: "pages/account/recover/password" */))
const _721a720d = () => interopDefault(import('../pages/p/_.vue' /* webpackChunkName: "pages/p/_" */))
const _a3110ca8 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _d0e67886,
    name: "account___de-DE",
    children: [{
      path: "order/:id",
      component: _dc83b42c,
      name: "account-index-order-id___de-DE"
    }]
  }, {
    path: "/cart",
    component: _03e1e763,
    name: "cart___de-DE"
  }, {
    path: "/checkout",
    component: _70077668,
    name: "checkout___de-DE"
  }, {
    path: "/login",
    component: _07ceb136,
    name: "login___de-DE"
  }, {
    path: "/search",
    component: _32798f2b,
    name: "search___de-DE"
  }, {
    path: "/warenkorb",
    redirect: "/cart",
    name: "Warenkorb"
  }, {
    path: "/wishlist",
    component: _bf070bf0,
    name: "wishlist___de-DE"
  }, {
    path: "/checkout/failure",
    component: _245b2104,
    name: "checkout-failure___de-DE"
  }, {
    path: "/checkout/lyra",
    component: _00871916,
    name: "checkout-lyra___de-DE"
  }, {
    path: "/checkout/paypal",
    component: _2c493dac,
    name: "checkout-paypal___de-DE"
  }, {
    path: "/checkout/success",
    component: _5d464afd,
    name: "checkout-success___de-DE"
  }, {
    path: "/account/recover/password",
    component: _af9e296a,
    name: "account-recover-password___de-DE"
  }, {
    path: "/p/*",
    component: _721a720d,
    name: "p-all___de-DE"
  }, {
    path: "/",
    component: _a3110ca8,
    name: "PageIndex___de-DE"
  }, {
    path: "/*",
    component: _a3110ca8,
    name: "all___de-DE"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
