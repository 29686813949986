export function getActivePageConfigs(
  pageConfigs: any[],
  now: any = new Date()
): any[] {
  return [...pageConfigs]
    .sort((a, b) => b.priority - a.priority)
    .filter((marker) => {
      let condition = true

      if (marker.timeSince) {
        condition &&= now >= new Date(marker.timeSince)
      }

      if (marker.timeUntil) {
        condition &&= now < new Date(marker.timeUntil)
      }

      return condition
    })
}
