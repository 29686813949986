<script>
import CmsGenericElement from '~/components/cms/CmsGenericElement'

export default {
  name: 'CmsBlockBsGridFull',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, context) {
    return h(
      'div',
      {
        staticClass: `row cms-block-bs-grid-full ${
          context.data.staticClass || ''
        }`.trim(),
      },
      [
        h(
          'div',
          {
            staticClass: 'col col-12',
          },
          [
            h(CmsGenericElement, {
              props: {
                content: context.props.content.slots?.[0],
              },
            }),
          ]
        ),
      ]
    )
  },
}
</script>
