<script>
export default {
  name: 'ProductBadge',
  functional: true,
  props: {
    type: {
      type: String,
      default: 'normal',
    },
  },
  render(h, context) {
    const type = context.props.type

    return h(
      'span',
      {
        ...context.data,
        class: [
          {
            'product-badge': true,
            'text-uppercase': true,
            'product-badge--normal': type === 'normal',
            'text-overline': type === 'normal',
            'product-badge--discount': type === 'discount',
            'text-body-4': type === 'discount',
          },
          ...(context.data.class || []),
        ],
      },
      context.children
    )
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.product-badge {
  padding: 4px 8px;

  &.product-badge--normal {
    background-color: white;
  }
  &.product-badge--discount {
    background-color: map-get($shades, 'pink-berry');
    color: white;
  }
}
</style>
