import { buildUrl } from '~/helpers'

const REPLACE_MAP = {
  'https://api.baby-sweets.de': '@bsb',
  'https://www.baby-sweets.de': '@bs',
  'https://www.baby-sweets.fr': '@bsf',
  'https://www.mabu-kids.de': '@mb',
  'https://www.mabu-kids.fr': '@mbf',
  'https://babysweets-sw6.sw.brl-demo.de': '@bsb-stage',
  'https://bs-sw6-frontend.sw.brl-demo.de': '@bs-stage',
  'http://bs-sw6.test': '@local-stage',
}

export function buildProxyImageUrl(
  config: any,
  imageUrl: string,
  options: { w?: any; h?: any }
): string {
  if (!imageUrl) {
    return ''
  }

  if (imageUrl && !imageUrl.startsWith('http')) {
    imageUrl = buildUrl(config.appUrl, imageUrl)
  }

  Object.entries(REPLACE_MAP).forEach(([key, value]) => {
    if (imageUrl.startsWith(key)) {
      imageUrl = imageUrl.replace(key, value)
    }
  })

  if (imageUrl.startsWith('http')) {
    imageUrl = imageUrl.replace(/https?:\/\//, '@')
  }

  const fragments = Object.entries(options)
    .filter(([_, value]) => !!value)
    .map((entry) => entry.join(':'))
    .join('/')

  return buildUrl(
    config.imageProxyUrl,
    fragments ? 'u/' + fragments : 'u',
    imageUrl
  )
}
