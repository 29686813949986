<template>
  <v-app>
    <base-icon-sprit-container></base-icon-sprit-container>

    <v-main class="mb-7 mb-md-10">
      <checkout-appbar></checkout-appbar>
      <v-container class="px-5 px-sm-12 px-md-8">
        <nuxt />
      </v-container>
    </v-main>
    <checkout-footer class="mt-auto"></checkout-footer>
  </v-app>
</template>

<script>
import BaseIconSpritContainer from '~/components/base/icon/BaseIconSpritContainer'

export default {
  name: 'LayoutCheckout',
  components: {
    BaseIconSpritContainer,
    CheckoutFooter: () => import('~/components/checkout/footer/CheckoutFooter'),
    CheckoutAppbar: () => import('~/components/checkout/appbar/CheckoutAppbar'),
  },
}
</script>
