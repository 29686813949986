<script>
import { VSelect } from 'vuetify/lib'

export default {
  name: 'BaseInputSelect',
  extends: VSelect,
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    forceOutlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        ...VSelect.options.computed.classes.call(this),
        'base-input-select': true,
        'base-input-select--force-outlined': this.forceOutlined,
        'v-text-field--solo-flat': this.solo,
      }
    },
    $_menuProps() {
      return {
        ...VSelect.options.computed.$_menuProps.call(this),
        nudgeBottom: 10,
      }
    },
  },
  methods: {
    selectItem(item) {
      // FIXME: ist von Vuetify Version >= v2.5.9, wir setzten noch auf v2.5.6 (https://github.com/vuetifyjs/vuetify/commit/7f5901b85c402b5d510af1d9b24259ffef403b6c)

      if (!this.multiple) {
        this.setValue(this.returnObject ? item : this.getValue(item))
        this.isMenuActive = false
      } else {
        const internalValue = (this.internalValue || []).slice()
        const i = this.findExistingIndex(item)

        i !== -1 ? internalValue.splice(i, 1) : internalValue.push(item)
        this.setValue(
          internalValue.map((i) => {
            return this.returnObject ? i : this.getValue(i)
          })
        )

        // When selecting multiple
        // adjust menu after each
        // selection
        this.$nextTick(() => {
          this.$refs.menu && this.$refs.menu.updateDimensions()
        })

        // There is no item to re-highlight
        // when selections are hidden
        if (this.hideSelected) {
          this.setMenuIndex(-1)
        } else {
          const index = this.allItems.indexOf(item)
          if (~index) {
            this.$nextTick(() => this.$refs.menu.getTiles())
            setTimeout(() => this.setMenuIndex(index))
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.base-input-select {
  &.v-text-field--outlined {
    &:not(.v-input--is-focused):not(.v-input--is-label-active):not(.base-input-select--force-outlined) {
      > .v-input__control {
        > .v-input__slot {
          background: map-get($shades, 'grey-lighten-4');

          &:hover {
            background: map-get($shades, 'grey-lighten-3');
          }
        }
      }

      fieldset {
        border: none !important;
      }
    }

    &:not(.v-input--is-focused):not(.v-input--has-state) {
      fieldset {
        color: map-get($shades, 'grey-lighten-1') !important;
      }
    }

    &.error--text fieldset {
      border-color: map-get($shades, 'pink-berry');
    }
  }

  .v-progress-linear {
    background: map-get($shades, 'grey-lighten-1') !important;
  }

  .v-label {
    font-size: 13px;
  }
}
</style>
