import { Ref } from '@nuxtjs/composition-api'
import { ApplicationVueContext } from '../appContext'
import { getApplicationContext, useSentry, useSharedState } from '~/composables'
import {
  getStoreNavigation,
  GetStoreNavigationParams,
} from '~/shopware-6-client'
import { getStoreNavigationRoutes, StoreNavigationRoute } from '~/helpers'
import { useCaching } from '~/composables/logic/useCaching'

/**
 * interface for {@link useFooterNavigation} composable
 *
 * @beta
 */
export interface IUseFooterNavigation {
  navigationLinks: Ref<StoreNavigationRoute[] | null>
  fetchNavigation: () => Promise<void>
}

/**
 * Composable for footer navigation. Options - {@link IUseFooterNavigation}
 * @beta
 *
 * @todo refactor configuration to defaultApi
 */
export const useFooterNavigation = (
  rootContext: ApplicationVueContext
): IUseFooterNavigation => {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useFooterNavigation'
  )

  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const { sharedRef } = useSharedState(rootContext)
  const { remember, forget, buildApiCacheKey } = useCaching(rootContext)

  const navigationLinks = sharedRef<StoreNavigationRoute[] | null>(
    `${contextName}-elements`,
    null
  )

  const fetchNavigation = async (force?: boolean): Promise<void> => {
    const request: GetStoreNavigationParams = {
      requestActiveId: 'footer-navigation',
      requestRootId: 'footer-navigation',
      searchCriteria: {
        configuration: {
          includes: {
            category: [
              'seoUrls',
              'externalLink',
              'name',
              'id',
              'children',
              'customFields',
              'translated',
              'type',
            ],
            seo_url: ['pathInfo', 'seoPathInfo'],
          },
        },
      },
    }

    const cacheKey = buildApiCacheKey('useFooterNavigation', request)

    if (force) {
      forget(cacheKey)
    }

    navigationLinks.value =
      (await remember(cacheKey, async () => {
        for (let i = 0; i < 3; i++) {
          try {
            const result = await getStoreNavigation(request, apiInstance)

            if (Array.isArray(result) && result.length > 0) {
              return getStoreNavigationRoutes(result)
            }
          } catch (e) {
            captureClientApiError(`[${contextName}][fetch]`, e)
          }
        }

        return undefined
      })) ?? null
  }

  return {
    navigationLinks,
    fetchNavigation,
  }
}
