import { ApplicationVueContext } from '../appContext'
import {
  useCms,
  getApplicationContext,
  useDefaults,
  createListingComposable,
  IUseListing,
} from '~/composables'
import { getCategoryProducts, searchProducts } from '~/shopware-6-client'

import { ShopwareSearchParams } from '~/commons/interfaces/search/SearchCriteria'
import { Product } from '~/commons/interfaces/models/content/product/Product'

/**
 * @beta
 */
export type listingKey = 'productSearchListing' | 'categoryListing'

/**
 * @beta
 */
export const useListing = (
  rootContext: ApplicationVueContext,
  listingKey: listingKey = 'categoryListing'
): IUseListing<Product> => {
  const { getDefaults } = useDefaults(rootContext, 'useListing')
  const { apiInstance } = getApplicationContext(rootContext, 'useListing')

  let searchMethod
  if (listingKey === 'productSearchListing') {
    searchMethod = (searchCriteria: Partial<ShopwareSearchParams>) => {
      return searchProducts(searchCriteria, apiInstance)
    }
  } else {
    const { categoryId } = useCms(rootContext)
    searchMethod = (searchCriteria: Partial<ShopwareSearchParams>) => {
      if (!categoryId.value) {
        throw new Error(
          '[useListing][search] Search category id does not exist.'
        )
      }
      return getCategoryProducts(categoryId.value, searchCriteria, apiInstance)
    }
  }

  return createListingComposable<Product>({
    rootContext,
    listingKey,
    searchMethod,
    searchDefaults: getDefaults(),
  })
}
