import { effectScope, watch } from '@nuxtjs/composition-api'
import {
  getApplicationContext,
  INTERCEPTOR_KEYS,
  useIntercept,
  useWishlist,
  useWishlistLocal,
  useUser,
} from '~/composables'
import { mergeProductWishlist } from '~/shopware-6-client'

export default ({ app }) => {
  const scope = effectScope()

  scope.run(() => {
    const { intercept } = useIntercept(app)
    const { apiInstance } = getApplicationContext(app, 'useWishlist')
    const { productIdStorage, refreshWishlist: refreshWishlistLocal } =
      useWishlistLocal(app)
    const { refreshWishlist } = useWishlist(app)
    const { user } = useUser(app)
    let userStateChanged = false

    intercept(INTERCEPTOR_KEYS.USER_LOGIN, async () => {
      if (productIdStorage.value.length > 0) {
        await mergeProductWishlist(productIdStorage.value, apiInstance)
        await refreshWishlist()
      }
    })
    intercept(INTERCEPTOR_KEYS.USER_LOGOUT, async () => {
      await refreshWishlistLocal()
    })

    watch(user, async () => {
      if (!userStateChanged) {
        await refreshWishlist()
        userStateChanged = true
      }
    })
  })
}
