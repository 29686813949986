import { Order } from '~/commons/interfaces/models/checkout/order/Order'

/**
 * @alpha
 */
export function getOrderTrackingCodes(order: Order): string[] {
  return (
    order.deliveries?.map((delivery) => delivery.trackingCodes || []).flat(1) ||
    []
  )
}
