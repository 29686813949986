import { computed, ComputedRef, Ref, ref } from '@nuxtjs/composition-api'

const sharedBottomHeight: Ref<{ [key: string]: number }> = ref({})

/**
 * interface for {@link useBottomNavigator} composable
 * @beta
 */
export interface IUseBottomNavigation {
  height: ComputedRef<number>

  setHeight(uid: string, height: number): void

  removeHeight(uid: string): void
}

/**
 * Allow to adjust the bottom padding
 *
 * @beta
 */
export const useBottomNavigator = (): IUseBottomNavigation => {
  const height: ComputedRef<number> = computed(() => {
    return Object.values(sharedBottomHeight.value).reduce(
      (acc: number, cur: number): number => acc + cur,
      0
    )
  })

  const setHeight = (uid: string, height: number) => {
    sharedBottomHeight.value = {
      ...sharedBottomHeight.value,
      [uid]: height,
    }
  }

  const removeHeight = (uid: string) => {
    sharedBottomHeight.value[uid] = 0
  }

  return {
    height,
    setHeight,
    removeHeight,
  }
}
