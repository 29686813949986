import { getCustomFields } from '~/helpers/customField/getCustomFields'

export function getCustomFieldValue<T = any>(
  entry: any,
  key: string,
  defaultValue: any = null
): T {
  // @ts-ignore
  return getCustomFields(entry)?.[key] ?? defaultValue
}
