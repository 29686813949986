<script>
import ProductBadge from '~/components/product/badge/ProductBadge'

export default {
  name: 'ProductCardBadges',
  functional: true,
  props: {
    badges: {
      type: Array,
      default: () => [],
    },
    maxBadges: {
      type: Number,
      default: 0,
    },
  },
  render(h, context) {
    const badges = context.props.badges
    const maxBadges = context.props.maxBadges

    const filteredBadges = () => {
      return maxBadges ? badges.slice(0, maxBadges) : badges
    }

    const content = filteredBadges().map((badge) => {
      return h(
        ProductBadge,
        {
          staticClass: 'py-1 mb-1',
        },
        badge.name
      )
    })

    return h(
      'div',
      {
        ...context.data,
        class: ['product-card-badges', ...(context.data.class || [])],
      },
      [...content, ...context.children]
    )
  },
}
</script>

<style lang="scss">
.product-card-badges {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
