<script>
import { VContainer } from 'vuetify/lib'
import CmsGenericBlock from '~/components/cms/CmsGenericBlock'

export default {
  name: 'CmsSectionDefault',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, context) {
    const content = context.props.content

    const genericBlocks = (content.blocks || []).map((block) => {
      return h(CmsGenericBlock, {
        props: {
          content: block,
        },
        key: block.id,
      })
    })

    return h(
      VContainer,
      {
        staticClass: 'pa-0',
        props: {
          fluid: true,
        },
      },
      genericBlocks
    )
  },
}
</script>
