import { effectScope } from '@nuxtjs/composition-api'
import {
  getApplicationContext,
  INTERCEPTOR_KEYS,
  useIntercept,
  useNotifications,
} from '~/composables'

export default ({ app }) => {
  const scope = effectScope()

  scope.run(() => {
    const { i18n } = getApplicationContext(app)
    const { intercept } = useIntercept(app)
    const { pushSuccess } = useNotifications(app)

    intercept(INTERCEPTOR_KEYS.ADD_TO_WISHLIST, () => {
      pushSuccess(i18n.t(`commons.addedToWhishlist`))
    })

    intercept(INTERCEPTOR_KEYS.REMOVE_FROM_WISHLIST, () => {
      pushSuccess(i18n.t(`commons.removedFromWhishlist`))
    })
  })
}
