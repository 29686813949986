<template>
  <div>
    <a
      v-for="icon in icons"
      :key="icon.to"
      :title="icon.title"
      target="_blank"
      rel="noopener noreferrer"
      :href="icon.to"
      class="mx-3"
    >
      <img
        :alt="icon.alt"
        height="40"
        width="40"
        loading="lazy"
        :src="require(`~/assets/images/footer/${icon.icon}`)"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'FooterSocialIcons',
  computed: {
    icons() {
      if (process.env.NUXT_ENV_BS_BUILD_MODULE === 'mabu') {
        return [
          {
            to: 'https://www.facebook.com/mabukidsde/',
            title: this.$t('footer.facebook'),
            alt: 'Facebook-Logo',
            icon: 'facebook_logo.svg',
          },
          {
            to: 'https://www.instagram.com/mabukids_de/',
            title: this.$t('footer.instagram'),
            alt: 'Instagram-Logo',
            icon: 'instagram_logo.svg',
          },
        ]
      } else {
        // TODO: Hier sollten wir auf die Build env gehen

        if (this.$i18n.locale === 'fr-FR') {
          return [
            {
              to: 'https://www.facebook.com/babysweetsfr/',
              title: this.$t('footer.facebook'),
              alt: 'Facebook-Logo',
              icon: 'facebook_logo.svg',
            },
            {
              to: 'https://www.instagram.com/babysweets_fr/',
              title: this.$t('footer.instagram'),
              alt: 'Instagram-Logo',
              icon: 'instagram_logo.svg',
            },
          ]
        }

        return [
          {
            to: 'https://www.facebook.com/babysweets2/',
            title: this.$t('footer.facebook'),
            alt: 'Facebook-Logo',
            icon: 'facebook_logo.svg',
          },
          {
            to: 'https://www.instagram.com/babysweets_de/',
            title: this.$t('footer.instagram'),
            alt: 'Instagram-Logo',
            icon: 'instagram_logo.svg',
          },
          {
            to: 'https://www.pinterest.de/baby_sweets/',
            title: this.$t('footer.pinterest'),
            alt: 'Pinterest-Logo',
            icon: 'pinterest_logo.svg',
          },
          {
            to: 'https://www.tiktok.com/@babysweets_de',
            title: this.$t('footer.tiktok'),
            alt: 'TikTok-Logo',
            icon: 'tiktok_logo.svg',
          },
        ]
      }
    },
  },
}
</script>
