import { Category } from '~/commons/interfaces/models/content/category/Category'
import { getCategoryCustomField } from '~/helpers'
import { ATTRIBUTE_REDIRECT_URL } from '~/services/cfAttributes'

/**
 * Get URL for category.
 *
 * @beta
 */
export const getCategoryUrl = (category: Partial<Category>): string => {
  if (!category) return '/'

  // @ts-ignore
  const externalLink = getCategoryCustomField(category, ATTRIBUTE_REDIRECT_URL)

  switch (category.type) {
    case 'link':
      return category.translated?.externalLink || category.externalLink || '/'
    case 'folder':
      return '/'
    default:
      return (
        externalLink ||
        (category.seoUrls?.[0]?.seoPathInfo
          ? `/${category.seoUrls[0].seoPathInfo}`
          : category.id
          ? `/navigation/${category.id}`
          : '/')
      )
  }
}

/**
 *
 * @beta
 */
export const isLinkCategory = (category: Partial<Category>): boolean =>
  category?.type === 'link'
