import {
  getCmsPageEndpoint,
  getPageResolverEndpoint,
  getSeoUrlEndpoint,
} from '../endpoints'
import { defaultInstance, ShopwareApiInstance } from '../apiService'
import { convertSearchCriteria } from '../helpers/searchConverter'
import {
  SearchCriteria,
  ShopwareSearchParams,
} from '~/commons/interfaces/search/SearchCriteria'
import {
  PageResolverResult,
  PageResolverProductResult,
  CmsPage,
} from '~/commons/interfaces/models/content/cms/CmsPage'

/**
 * @throws ClientApiError
 * @beta
 */
export async function getPage(
  path: string,
  searchCriteria?: SearchCriteria,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PageResolverResult<CmsPage>> {
  const resp = await contextInstance.invoke.post(getPageResolverEndpoint(), {
    path,
    ...convertSearchCriteria({
      searchCriteria,
      config: contextInstance.config,
    }),
  })

  return resp.data
}

/**
 * @throws ClientApiError
 * @beta
 */
export async function getCmsPage(
  path: string,
  criteria?: ShopwareSearchParams,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PageResolverResult<CmsPage>> {
  const resp = await contextInstance.invoke.post(getPageResolverEndpoint(), {
    path,
    ...criteria,
  })

  return resp.data
}

/**
 * @throws ClientApiError
 * @beta
 */
export async function getCmsPageFromId(
  cmsId: string,
  criteria?: ShopwareSearchParams,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PageResolverResult<CmsPage>> {
  const resp = await contextInstance.invoke.post(getCmsPageEndpoint(cmsId), {
    ...criteria,
  })

  return resp.data
}

/**
 * @throws ClientApiError
 * @beta
 */
export async function getProductPage(
  path: string,
  searchCriteria?: SearchCriteria,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PageResolverProductResult> {
  const resp = await contextInstance.invoke.post(getPageResolverEndpoint(), {
    path,
    ...convertSearchCriteria({
      searchCriteria,
      config: contextInstance.config,
    }),
  })

  return resp.data
}

/**
 * Returns an array of SEO URLs for given entity
 * Can be used for other languages as well by providing the languageId
 *
 * @beta
 */
export async function getSeoUrls(
  entityId: string,
  languageId?: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<
  {
    apiAlias: string
    seoPathInfo: string
  }[]
> {
  if (languageId) {
    contextInstance.defaults.headers['sw-language-id'] = languageId
  }
  const resp = await contextInstance.invoke.post(getSeoUrlEndpoint(), {
    filter: [
      {
        type: 'equals',
        field: 'foreignKey',
        value: entityId,
      },
    ],
    includes: {
      seo_url: ['seoPathInfo'],
    },
  })

  return resp.data
}
