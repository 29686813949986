<template>
  <a :href="action" @click.stop.prevent="clickEvent"><slot></slot></a>
</template>

<script>
import { INTERCEPTOR_KEYS, useIntercept } from '~/composables'

export default {
  name: 'CmsUtilAction',
  props: {
    action: {
      type: String,
      default: '',
    },
  },
  setup(props, { root }) {
    const { broadcast } = useIntercept(root)

    const [, name, params] = props.action.match(/^#a:([\w-]+)(?:,(.*))?$/) ?? []

    const clickEvent = () => {
      broadcast(INTERCEPTOR_KEYS.ACTION_CLICK_EVENT, {
        name,
        params,
      })
    }

    return {
      clickEvent,
    }
  },
}
</script>
