import { StateMachineState } from '~/commons/interfaces/models/system/state-machine/StateMachineState'

/**
 * @alpha
 */
export function getOrderStateMachineStateColor(
  state: StateMachineState
): string | undefined {
  switch (state.technicalName) {
    case 'open':
    case 'paid_partially':
    case 'refunded':
    case 'refunded_partially':
    case 'reminded':
    case 'shipped_partially':
    case 'returned':
    case 'returned_partially':
      return 'checkout-primary'
    case 'cancelled':
    case 'failed':
      return 'pink-berry'
    case 'done': //
    case 'paid':
    case 'shipped':
    case 'completed':
      return 'green-smaragd'
    case 'in_progress':
      return 'checkout-primary'
    default:
      return 'checkout-primary'
  }
}
