<template>
  <lazy-hydrate when-visible>
    <div v-bind="$attrs" class="elevation-3 white">
      <app-bar-category-card-type-normal
        v-if="!asCircle"
        :routes="filteredRoutes"
        :category="category"
      ></app-bar-category-card-type-normal>
      <app-bar-category-card-type-circle
        v-else
        class="pa-10"
        :routes="filteredRoutes"
      >
      </app-bar-category-card-type-circle>
      <app-bar-category-card-actions
        v-if="shouldShowActionBar"
        :categories="childRoutes"
      ></app-bar-category-card-actions>
    </div>
  </lazy-hydrate>
</template>

<script>
import {
  ATTRIBUTE_CATEGORY_AS_CIRCLE,
  ATTRIBUTE_CATEGORY_SHOW_ACTION_BAR,
} from '~/services/cfAttributes'
import {
  getCategoryUrl,
  isCategoryHidden,
  isCategoryCustomFieldEnabled,
  CATEGORY_HIDE_TYPE_DESKTOP,
} from '~/helpers'
import AppBarCategoryCardTypeNormal from '~/components/app-bar/AppBarCategoryCardTypeNormal'
import AppBarCategoryCardActions from '~/components/app-bar/AppBarCategoryCardActions'
import AppBarCategoryCardTypeCircle from '~/components/app-bar/AppBarCategoryCardTypeCircle'

export default {
  name: 'AppBarCategoryCard',
  components: {
    AppBarCategoryCardTypeCircle,
    AppBarCategoryCardActions,
    AppBarCategoryCardTypeNormal,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    asCircle() {
      return isCategoryCustomFieldEnabled(
        this.category,
        ATTRIBUTE_CATEGORY_AS_CIRCLE
      )
    },
    shouldShowActionBar() {
      return isCategoryCustomFieldEnabled(
        this.category,
        ATTRIBUTE_CATEGORY_SHOW_ACTION_BAR
      )
    },
    childRoutes() {
      return this.category.children.map((children) => {
        const url = getCategoryUrl(children)

        return {
          ...children,
          url,
          name: children.translated?.name || children.name,
          isActive: this.breadcrumbs.some(({ link }) => link === url),
        }
      })
    },
    filteredRoutes() {
      return this.childRoutes.filter((category) => {
        return !isCategoryHidden(category, CATEGORY_HIDE_TYPE_DESKTOP)
      })
    },
  },
}
</script>
