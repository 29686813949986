import languagesMap from '~/languages.json'

const changeShopwareLanguage = ({ app, newLocale }) => {
  const defaultLocale = app.i18n.defaultLocale

  let locale = newLocale || defaultLocale

  if (!languagesMap[locale]) {
    locale = defaultLocale
  }

  const fromMap = languagesMap[locale]

  app.$shopwareApiInstance.update({ languageId: fromMap && fromMap.id })
}

export default ({ app, store, params }) => {
  changeShopwareLanguage({ app, store, newLocale: params.lang })

  app.i18n.onLanguageSwitched = (_oldLocale, newLocale) => {
    changeShopwareLanguage({ app, store, newLocale })
  }
}
