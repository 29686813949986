import CmsGenericElement from '~/components/cms/CmsGenericElement'

export function genGenericElement(h, content) {
  return h(CmsGenericElement, {
    props: {
      content,
    },
  })
}

export function genVCol(h, staticClass, slot) {
  return h(
    'div',
    {
      staticClass,
    },
    [genGenericElement(h, slot)]
  )
}
