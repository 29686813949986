export function isInDateRange(
  from: string | null | undefined,
  to: string | null | undefined
): boolean {
  if (from) {
    const isFrom = new Date(from) < new Date()

    if (isFrom) {
      if (to) {
        return new Date(to) > new Date()
      }

      return true
    }

    return false
  } else if (to) {
    return new Date(to) > new Date()
  }

  return true
}
