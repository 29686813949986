<template>
  <div class="d-flex flex-wrap justify-center">
    <category-navigation-circle
      v-for="navigation in routes"
      :key="navigation.id"
      :navigation="navigation"
      class="mx-5 mb-5"
    ></category-navigation-circle>
  </div>
</template>

<script>
import CategoryNavigationCircle from '~/components/category-navigation/CategoryNavigationCircle'
export default {
  name: 'AppBarCategoryCardTypeCircle',
  components: { CategoryNavigationCircle },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
