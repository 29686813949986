import Vue from 'vue'

export default ({ app }) => {
  app.setup = () => {
    // FIXME: Erstmal fest auf de-DE
    Vue.filter('price', (price) =>
      new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(price)
    )
  }
}
