export const COOKIES = {
  CSRF_TOKEN: 'csrf_token',
  COOKIE_PREFERENCES: 'cookie_preferences',
  SESSION: 'session',
  TIMEZONE: 'timezone',
  KLARNA: 'klarna',
  PAYPAL: 'paypal',
  WISHLIST: 'wishlist',

  KAMELEEON: 'kameleeon',

  FACEBOOK: 'facebook',
  GTM: 'gtm',
  GA: 'ga',
}

export const COOKIE_GROUPS = {
  TECHNICAL: 'technical',
  COMFORT: 'comfort',
  PERSONALISATION: 'personalisation',
  STATISTICS_TRACKING: 'statistics_tracking',
}

export const cookieDefinitions = [
  {
    id: COOKIE_GROUPS.TECHNICAL,
    required: true,
    cookies: [
      COOKIES.CSRF_TOKEN,
      COOKIES.COOKIE_PREFERENCES,
      COOKIES.SESSION,
      COOKIES.TIMEZONE,
      COOKIES.KLARNA,
      COOKIES.PAYPAL,
      COOKIES.WISHLIST,
    ],
  },
  {
    id: COOKIE_GROUPS.PERSONALISATION,
    cookies: [COOKIES.KAMELEEON],
  },
  {
    id: COOKIE_GROUPS.STATISTICS_TRACKING,
    cookies: [COOKIES.FACEBOOK, COOKIES.GTM, COOKIES.GA],
  },
]
