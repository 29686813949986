<template>
  <div
    v-if="headbanger"
    class="headbanger"
    :class="headbangerClasses"
    :style="{ backgroundColor: headbanger.translated.globalBackgroundColor }"
  >
    <v-container class="headbanger__container">
      <v-row class="headbanger__wrapper">
        <v-col
          v-for="(slot, index) in slots"
          :key="index"
          :cols="12 / slots.length"
          :style="slot.style"
          class="headbanger__item"
        >
          <cms-util-link
            :url="slot.url"
            class="headbanger__link"
            @click.native="handleLinkClick(index)"
          ></cms-util-link>
          <lazy-hydrate when-idle>
            <base-text :content="slot.content"></base-text>
          </lazy-hydrate>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, watch } from '@vue/composition-api'
import { ref } from '@nuxtjs/composition-api'
import { usePageConfig } from '~/composables/logic/usePageConfig'
import BaseText from '~/components/base/text/BaseText'
import { getApplicationContext, useUiEvent } from '~/composables'
import { getFirstActivePageConfig } from '~/helpers/pageConfig/getFirstActivePageConfig'

export default {
  name: 'TheHeadbanger',
  components: { BaseText },
  setup(_, { root }) {
    const { isServer } = getApplicationContext(root)
    const { pageConfig } = usePageConfig(root)

    const now = ref(new Date())

    const headbanger = computed(() => {
      return getFirstActivePageConfig(
        pageConfig.value?.headbangers || [],
        now.value
      )
    })

    const slots = computed(() => {
      if (!headbanger.value) {
        return []
      }

      const slots = [...headbanger.value.translated.slots]
        .filter((slot) => slot.active)
        .slice(0, 3)

      if (slots.length > 1) {
        slots.push({
          ...slots[0],
        })
      }

      return slots.map((slot) => ({
        ...slot,
        style: {
          backgroundColor: slot.backgroundColor,
          color: slot.textColor,
        },
      }))
    })

    const headbangerClasses = computed(() => {
      if (!headbanger.value) {
        return undefined
      }

      return [
        `headbanger--item-${slots.value.length}`,
        {
          'headbanger--slider': headbanger.value.translated.desktopSlider,
        },
      ]
    })

    const handleLinkClick = (index) => {
      let pos = index + 1

      if (slots.value.length > 1 && pos === slots.value.length) {
        pos = 1
      }

      useUiEvent(root, 'headbanger', 'slot-' + pos)()
    }

    const globalHeadbangerClass = 'global-headbanger'

    let metaApp

    if (!isServer) {
      metaApp = root.$nuxt?.$meta()?.addApp('the-headbanger')

      watch(
        headbanger,
        () => {
          if (headbanger.value) {
            metaApp?.set({
              bodyAttrs: {
                class: [globalHeadbangerClass],
              },
            })
          } else {
            metaApp?.remove()
          }
        },
        {
          immediate: true,
        }
      )
    }

    let intervalId = 0

    onMounted(() => {
      intervalId = setInterval(() => {
        now.value = new Date()
      }, 1000 * 60 * 5) // Alle 5 minuten checken
    })

    onUnmounted(() => {
      clearInterval(intervalId)
      metaApp?.remove()
    })

    return {
      headbanger,
      slots,
      headbangerClasses,
      handleLinkClick,
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.headbanger {
  height: 40px;

  &__container {
    padding: 0;
    overflow: hidden;
  }

  &__item {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  &__wrapper {
    animation-iteration-count: infinite;
    margin: 0;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.headbanger--item-4 {
    .headbanger__wrapper {
      width: 400%;
      animation-duration: 12s;
      animation-name: headbanger__keyframe4;
    }
  }

  &.headbanger--item-3 {
    .headbanger__wrapper {
      width: 300%;
      animation-duration: 8s;
      animation-name: headbanger__keyframe3;
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &--slider {
      .headbanger__container {
        max-width: 100% !important;
      }
    }

    &:not(.headbanger--slider) {
      .headbanger__wrapper {
        animation: none !important;
        width: 100%;
      }

      &.headbanger--item-4 {
        .headbanger__item {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;

          &:first-child {
            justify-content: flex-start;
          }

          &:nth-child(3) {
            justify-content: flex-end;
          }

          &:last-child {
            display: none;
          }
        }
      }

      &.headbanger--item-3 {
        .headbanger__item {
          flex: 0 0 50%;
          max-width: 50%;

          &:last-child {
            display: none;
          }
        }
      }

      .headbanger__item {
        background-color: transparent !important;
      }
    }
  }
}

@keyframes headbanger__keyframe3 {
  0% {
    transform: translate3d(-33.333%, 0, 0);
  }
  40% {
    transform: translate3d(-33.333%, 0, 0);
  }
  50% {
    transform: translate3d(0%, 0, 0);
  }
  50.001% {
    transform: translate3d(-66.666%, 0, 0);
  }
  90% {
    transform: translate3d(-66.666%, 0, 0);
  }
  100% {
    transform: translate3d(-33.333%, 0, 0);
  }
}

@keyframes headbanger__keyframe4 {
  0% {
    transform: translate3d(-50%, 0, 0);
  }
  26.66% {
    transform: translate3d(-50%, 0, 0);
  }
  33.33% {
    transform: translate3d(-25%, 0, 0);
  }
  60% {
    transform: translate3d(-25%, 0, 0);
  }
  66.66% {
    transform: translate3d(0%, 0, 0);
  }
  93.3333% {
    transform: translate3d(0%, 0, 0);
  }
  93.3334% {
    transform: translate3d(-75%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
</style>
