<template>
  <lazy-hydrate v-if="isActive" when-visible>
    <cms-util-text :content="text" :anchor="anchor"></cms-util-text>
  </lazy-hydrate>
</template>

<script>
import { getCmsSlotConfigProperty, isCmsSlotActive } from '~/helpers'
import CmsUtilText from '~/components/cms/util/CmsUtilText'

export default {
  name: 'CmsElementText',
  components: { CmsUtilText },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isActive() {
      return isCmsSlotActive(this.content)
    },
    text() {
      return this.content?.data?.content
    },
    anchor() {
      return getCmsSlotConfigProperty(this.content, 'anchor') || undefined
    },
  },
}
</script>
