<template>
  <div>
    <div
      v-for="content in contents"
      :key="content.id"
      class="text-body-4 grey-darken-3--text"
    >
      {{ content.translated.content }}
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from '@nuxtjs/composition-api'
import { usePageConfig } from '~/composables/logic/usePageConfig'
import { getActivePageConfigs } from '~/helpers/pageConfig/getActivePageConfigs'

export default {
  name: 'FooterGlobalContent',
  setup(_, { root }) {
    const { pageConfig } = usePageConfig(root)
    const now = ref(new Date())

    const contents = computed(() => {
      return getActivePageConfigs(
        pageConfig.value?.globalFooterContents || [],
        now.value
      )
    })

    let intervalId = 0

    onMounted(() => {
      intervalId = setInterval(() => {
        now.value = new Date()
      }, 1000 * 60 * 5) // Alle 5 minuten checken
    })

    onUnmounted(() => {
      clearInterval(intervalId)
    })

    return {
      contents,
    }
  },
}
</script>
