<template>
  <div class="d-flex flex-column align-center app-bar-logo">
    <component
      :is="logoQuer"
      v-if="$config.isMaBuBuild"
      class="hidden-md-and-up"
    />
    <component
      :is="logoSrc"
      :class="$config.isMaBuBuild ? 'hidden-sm-and-down' : ''"
    />

    <div
      v-if="$i18n.locale === 'fr-FR'"
      class="text-body-5 text-md-body-4 app-bar-logo__logo"
    >
      <span>
        {{ $t('commons.slogan') }}
      </span>
    </div>
  </div>
</template>

<script>
// TODO: MaBu - Slogan sollte nicht ausgespielt werden

export default {
  name: 'AppBarLogo',
  computed: {
    logoSrc() {
      if (process.env.NUXT_ENV_BS_BUILD_MODULE === 'mabu') {
        return require(`~/assets/images/logos/mabu_kids_de_logo.svg?inline`)
      } else {
        return require(`~/assets/images/logos/baby_sweets_logo.svg?inline`)
      }
    },
    logoQuer() {
      if (process.env.NUXT_ENV_BS_BUILD_MODULE === 'mabu') {
        return require(`~/assets/images/logos/mabu_kids_de_logo_quer.svg?inline`)
      }

      return null
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-logo {
  &__logo {
    padding-top: 2px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding-top: 0;

      span {
        font-size: 0.541rem !important;
      }
    }
  }
}
</style>
