import { ref } from '@nuxtjs/composition-api'
import { computed, ComputedRef, Ref } from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import {
  PaymentAfterOrderHookResult,
  PaymentServiceBeforeOrderResult,
  PaymentServiceInterface,
  PaymentServiceOptionsInterface,
} from '~/services/payments/PaymentServiceInterface'
import { createPayolutionInvoiceService } from '~/services/payments/PayolutionInvoiceService'
import { createFallbackPaymentService } from '~/services/payments/FallbackPaymentService'
import { createPayolutionDirectDebitService } from '~/services/payments/PayolutionDirectDebitService'
import { useSessionContext } from '~/composables'
import { createKlarnaService } from '~/services/payments/KlarnaService'
import { createPayPalService } from '~/services/payments/PayPalService'
import { createPayolutionInstallmentsService } from '~/services/payments/PayolutionInstallmentsService'
import { createUnzerCreditCardService } from '~/services/payments/UnzerCreditCardPaymentService'
import { createPayPalACDCService } from '~/services/payments/PayPalACDCService'
import { createPayPalPuiService } from '~/services/payments/PayPalPuiService'
import { Order } from '~/commons/interfaces/models/checkout/order/Order'
import { createPayPalSEPAService } from '~/services/payments/PayPalSEPAService'

const paymentFactories: ((
  rootContext: ApplicationVueContext
) => PaymentServiceInterface)[] = [
  createPayolutionInvoiceService,
  createPayolutionDirectDebitService,
  createPayolutionInstallmentsService,
  createKlarnaService,
  createPayPalService,
  createUnzerCreditCardService,
  createPayPalACDCService,
  createPayPalPuiService,
  createPayPalSEPAService,
]

/**
 * @beta
 **/
export interface IUsePaymentHandlers {
  setPaymentMethode(paymentMethod: PaymentMethod): void
  paymentService: Ref<PaymentServiceInterface | null | undefined>
  isValid: ComputedRef<boolean>
  validate(): Promise<void>
  beforeOrder(): Promise<boolean>
  beforeOrderResult: Ref<PaymentServiceBeforeOrderResult | null>
  getHandlePaymentPayload(): any
  componentName: ComputedRef<string | null>
  handlerInputFields: ComputedRef
  options: ComputedRef<PaymentServiceOptionsInterface>
  afterOrder: (
    order: Order
  ) => Promise<PaymentAfterOrderHookResult | null | undefined | void>
}

/**
 * @beta
 */
export const usePaymentHandlers = (
  rootContext: ApplicationVueContext
): IUsePaymentHandlers => {
  const { apiInstance } = getApplicationContext(
    rootContext,
    'useCheckoutPayments'
  )

  const { paymentMethod } = useSessionContext(rootContext)

  const paymentService: Ref<PaymentServiceInterface> = ref(
    createFallbackPaymentService(rootContext)
  )

  const beforeOrderResult: Ref<PaymentServiceBeforeOrderResult | null> =
    ref(null)

  const setPaymentMethode = (paymentMethod: PaymentMethod | null) => {
    if (paymentMethod === null) {
      paymentService.value = createFallbackPaymentService(rootContext)

      return
    }

    paymentService.value = (
      paymentFactories.find((factory) => {
        return factory(rootContext).isSupported(paymentMethod) ? factory : null
      }) ?? createFallbackPaymentService
    )(rootContext)
  }

  const componentName = computed(() => {
    return paymentService.value.componentName
  })

  const handlerInputFields = computed(() => {
    return paymentService.value.inputFields
  })

  const isValid = computed(() => {
    return !Object.values(paymentService.value.inputFields as any).some(
      (field: any) => {
        return !field.isValid
      }
    )
  })

  const options = computed(() => {
    return paymentService.value.getOptions()
  })

  const validate = async () => {
    for (const field of Object.values(
      paymentService.value.inputFields as any
    )) {
      await (field as any).validate()
    }
  }

  const beforeOrder = async () => {
    beforeOrderResult.value = await paymentService.value.beforeOrderHook(
      rootContext,
      apiInstance
    )

    return beforeOrderResult.value.isSuccessful
  }

  const afterOrder = async (order: Order) => {
    if (paymentService.value.afterOrderHook) {
      return await paymentService.value.afterOrderHook(order)
    }

    return null
  }

  const getHandlePaymentPayload = () => {
    return paymentService.value.getHandlePaymentPayload(beforeOrderResult.value)
  }

  setPaymentMethode(paymentMethod.value)

  return {
    setPaymentMethode,
    paymentService,
    componentName,
    isValid,
    validate,
    beforeOrder,
    beforeOrderResult,
    afterOrder,
    getHandlePaymentPayload,
    handlerInputFields,
    options,
  }
}
