import { Ref, computed, ComputedRef, ref } from '@nuxtjs/composition-api'
import { getApplicationContext, useCart } from '~/composables'
import { ApplicationVueContext } from '~/composables/appContext'

/**
 * interface for {@link useFreeShippingHint} composable
 * @beta
 */
export interface IUseFreeShippingHint {
  hasShippingCost: ComputedRef<boolean>
  freeShipping: ComputedRef<number>
  shouldOnlyShowIfFreeShipping: Ref<boolean>
  shouldShowComponent: ComputedRef<boolean>

  /**
   * Der Wert der noch gekauft werden muss bis VKF
   */
  progressValue: ComputedRef<number>
  progress: ComputedRef<number>
}

/**
 * Allow to adjust the bottom padding
 *
 * @beta
 */
export const useFreeShippingHint = (
  rootContext: ApplicationVueContext
): IUseFreeShippingHint => {
  const { i18n } = getApplicationContext(rootContext, 'useFreeShippingHint')
  const { subtotal, shippingTotal, cart } = useCart(rootContext)

  // Sollen die FreeShipping Componenten nur angezeigt werden, wenn Versand frei ist?
  const shouldOnlyShowIfFreeShipping = ref(true)

  // TODO: vll aus dem Backend?
  const freeShipping = computed(() => {
    if (i18n.locale !== 'de-DE') {
      return 50.0
    }

    return 20.0
  })

  const hasShippingCost: ComputedRef<boolean> = computed(() => {
    return (
      shippingTotal.value > 0.0 || (cart.value?.deliveries?.length ?? 0) === 0
    )
  })

  const progressValue: ComputedRef<number> = computed(() => {
    return subtotal.value === 0
      ? freeShipping.value
      : hasShippingCost.value
      ? Math.max(freeShipping.value - subtotal.value, 0)
      : 0
  })

  const progress: ComputedRef<number> = computed(() => {
    return subtotal.value === 0
      ? 0
      : hasShippingCost.value
      ? Math.min((subtotal.value / freeShipping.value) * 100, 100)
      : 100
  })

  const shouldShowComponent = computed(() => {
    return shouldOnlyShowIfFreeShipping.value === false
      ? true
      : progress.value >= 100 && !hasShippingCost.value
  })

  return {
    hasShippingCost,
    freeShipping,
    progressValue,
    progress,
    shouldOnlyShowIfFreeShipping,
    shouldShowComponent,
  }
}
