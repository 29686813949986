export const ATTRIBUTE_CATEGORY_HIDE = 'bs_cat_hide'
export const ATTRIBUTE_CATEGORY_AS_CIRCLE = 'bs_cat_as_circle'
export const ATTRIBUTE_CATEGORY_SHOW_ACTION_BAR = 'bs_cat_show_action_bar'
export const ATTRIBUTE_CATEGORY_TECHNICAL_NAME = 'bs_cat_technical_name'

export const ATTRIBUTE_CATEGORY_BIG_ACTION_ENABLE = 'bs_cat_action_enable'
export const ATTRIBUTE_CATEGORY_BIG_ACTION_COLOR = 'bs_cat_action_color'
export const ATTRIBUTE_CATEGORY_BIG_ACTION_IMAGE_URL = 'bs_cat_action_image_url'
export const ATTRIBUTE_CATEGORY_BIG_ACTION_URL = 'bs_cat_action_url'
export const ATTRIBUTE_CATEGORY_BIG_ACTION_TEXT = 'bs_cat_action_text'
export const ATTRIBUTE_CATEGORY_BIG_ACTION_TEXT_COLOR =
  'bs_cat_action_text_color'

export const ATTRIBUTE_REDIRECT_URL = 'bs_redirect_url'
export const ATTRIBUTE_REDIRECT_DATE = 'bs_redirect_date'
export const ATTRIBUTE_REDIRECT_DATE_UNTIL = 'bs_redirect_date_until'

export const ATTRIBUTE_CATEGORY_META_ROBOTS = 'bs_meta_robots'

export const ATTRIBUTE_FOOTER_CATEGORY_SHOW_IN_DRAWER = 'footer_show_in_drawer'

export const ATTRIBUTE_BRL_CUSTOM_ENABLE = 'brl_custom_enable'
export const ATTRIBUTE_BRL_HANDMADE_ENABLE = 'brl_handmade_enable'
export const ATTRIBUTE_BRL_BIO_ENABLE = 'brl_bio_enable'
export const ATTRIBUTE_BRL_BIO_BAMBUS_ENABLE = 'brl_bambus_enable'
export const ATTRIBUTE_BRL_MADE_EU_ENABLE = 'brl_made_eu_enable'
export const ATTRIBUTE_BRL_EXCLUSIVE_ENABLE = 'brl_exclusive_enable'
export const ATTRIBUTE_BRL_SALE_ENABLE = 'brl_sale_enable'

export const ATTRIBUTE_PDP_HIDE_DESCRIPTION = 'bs_pdp_hide_description'
export const ATTRIBUTE_PDP_VIDEOS = 'bs_pdp_videos'
export const ATTRIBUTE_PDP_CARPET_HINT = 'bs_pdp_carpet_hint'

export const ATTRIBUTE_PDP_EXTRA_BADGE_TEXT = 'bs_pdp_extra_badge_text'
export const ATTRIBUTE_PDP_EXTRA_BADGE_CLASSES = 'bs_pdp_extra_badge_classes'
export const ATTRIBUTE_PDP_EXTRA_BADGE_URL = 'bs_pdp_extra_badge_url'
export const ATTRIBUTE_PRODUCT_REORDERED = 'bs_product_reordered'
export const ATTRIBUTE_PRODUCT_SPLIT_SHIPPING = 'bs_product_split_shipping'
