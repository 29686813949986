<script>
import { mergeData } from 'vue-functional-data-merge'

export default {
  name: 'BaseIconSvgPath',
  functional: true,
  props: {
    svg: {
      type: String,
      default: '',
    },
  },
  render(h, { props, data }) {
    delete data.nativeOn

    return h(
      'svg',
      mergeData(data, {
        staticClass: 'v-icon__svg',
        attrs: {
          xmlns: 'http://www.w3.org/2000/svg',
          viewBox: '0 0 24 24',
          role: 'img',
          'aria-hidden': true,
        },
      }),
      [
        h('path', {
          attrs: {
            d: props.svg,
          },
        }),
      ]
    )
  },
}
</script>
