import {
  getWishlistEndpoint,
  getWishlistDeleteEndpoint,
  getWishlistAddEndpoint,
  getWishlistMergeEndpoint,
} from '../endpoints'
import { defaultInstance, ShopwareApiInstance } from '../apiService'
import { CustomerWishlistResult } from '~/commons/interfaces/response/CustomerWishlistResult'
import { ShopwareSearchParams } from '~/commons/interfaces/search/SearchCriteria'

export async function getProductsWishlist(
  searchCriteria?: ShopwareSearchParams,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<CustomerWishlistResult> {
  const resp = await contextInstance.invoke.post(
    getWishlistEndpoint(),
    searchCriteria
  )
  return resp.data
}

export async function addProductWishlist(
  productId: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<CustomerWishlistResult> {
  const resp = await contextInstance.invoke.post(
    getWishlistAddEndpoint(productId)
  )
  return resp.data
}

export async function deleteProductWishlist(
  productId: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<CustomerWishlistResult> {
  const resp = await contextInstance.invoke.delete(
    getWishlistDeleteEndpoint(productId)
  )
  return resp.data
}

export async function mergeProductWishlist(
  productIds: string[],
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<CustomerWishlistResult> {
  const resp = await contextInstance.invoke.post(getWishlistMergeEndpoint(), {
    productIds,
  })
  return resp.data
}
