import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductRealCalculatedPrice } from '~/helpers'

export function getProductListingRealDiscountPercentage(
  product: Product
): number | undefined {
  return (
    product.calculatedCheapestPrice?.listPrice?.percentage ||
    getProductRealCalculatedPrice(product)?.listPrice?.percentage
  )
}
