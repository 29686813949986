import { effectScope } from '@nuxtjs/composition-api'
import { useBreadcrumbs, useCms } from '~/composables'
import { getCategoryCustomField, isInDateRange } from '~/helpers'
import {
  ATTRIBUTE_REDIRECT_DATE,
  ATTRIBUTE_REDIRECT_DATE_UNTIL,
  ATTRIBUTE_REDIRECT_URL,
} from '~/services/cfAttributes'

export default async function ({ app, route, from, redirect }) {
  const scope = effectScope()

  await scope.run(async () => {
    if (!route.name.match(/^PageIndex|all_|p-all_/)) {
      return
    }

    const { setBreadcrumbs } = useBreadcrumbs(app)
    const { search, page, currentSearchPathKey } = useCms(app)

    const path = route.path || '/'

    if (currentSearchPathKey.value !== path || route.name !== from?.name) {
      await search(path, route.query)

      const category = page.value?.category

      if (category) {
        const redirectUrl = getCategoryCustomField(
          category,
          ATTRIBUTE_REDIRECT_URL
        )?.trim()

        const redirectDate = getCategoryCustomField(
          category,
          ATTRIBUTE_REDIRECT_DATE
        )?.trim()

        const redirectDateUntil = getCategoryCustomField(
          category,
          ATTRIBUTE_REDIRECT_DATE_UNTIL
        )?.trim()

        if (redirectUrl) {
          if (isInDateRange(redirectDate, redirectDateUntil)) {
            return redirect(redirectUrl)
          }
        }
      }

      setBreadcrumbs(Object.values(page.value?.breadcrumb || []))
    }
  })

  scope.stop()
}
