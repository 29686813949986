<template>
  <div
    class="category-navigation-circle text-md-h6 text-body-4"
    :class="classes"
  >
    <nuxt-link :to="url" :title="linkTitle">
      <div class="category-navigation-circle__avatar">
        <base-image
          v-if="imageUrl"
          :url="imageUrl"
          :init-width="100"
          loading="lazy"
          :alt="name"
          height="100"
          width="100"
        />
      </div>
    </nuxt-link>
    <div
      class="
        category-navigation-circle__text
        text-truncate text-center
        mt-2 mt-md-3
      "
    >
      <div v-if="subName" class="grey-darken-1--text">{{ subName }}</div>
      {{ name }}
    </div>
  </div>
</template>

<script>
import { getCategoryUrl, getSmallestMediaThumbnailUrl } from '~/helpers'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'CategoryNavigationCircle',
  components: { BaseImage },
  props: {
    navigation: {
      type: Object,
      default: () => {},
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    showBreadcrumbs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'category-navigation-circle--no-border': this.hideBorder,
      }
    },
    url() {
      return getCategoryUrl(this.navigation)
    },
    imageUrl() {
      return this.navigation.media
        ? getSmallestMediaThumbnailUrl(this.navigation.media)
        : null
    },
    name() {
      if (this.showBreadcrumbs) {
        return this.navigation.breadcrumb?.[
          this.navigation.breadcrumb.length - 1
        ]
      }

      return this.navigation.translated?.name || this.navigation.name
    },
    subName() {
      if (!this.showBreadcrumbs) {
        return null
      }

      return this.navigation.breadcrumb?.[this.navigation.breadcrumb.length - 2]
    },
    linkTitle() {
      return `${this.name} - ${this.$t('commons.categories')}`
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.category-navigation-circle {
  &__avatar {
    border: 1px solid map-get($shades, 'grey-lighten-3');
    width: 80px;
    min-width: 80px;
    height: 80px;
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    line-height: normal;
    position: relative;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 100px;
      min-width: 100px;
      height: 100px;
    }

    > img {
      height: auto;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .v-image {
    transition: all 0.8s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &--no-border {
    .category-navigation-circle__avatar {
      border: none;
    }
  }

  &__text {
    width: 80px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 100px;
    }
  }
}
</style>
