<template>
  <nuxt-link
    :to="to"
    class="
      beige-pastel-light
      text-h4 text-md-h3
      px-3
      py-4 py-md-5
      text-center
      d-block
      grey-darken-4--text
      text-decoration-none
    "
    style="position: relative"
  >
    <slot></slot>
    <base-icon
      mdi="arrow-right"
      style="position: absolute; right: 12px"
    ></base-icon>
  </nuxt-link>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
export default {
  name: 'BaseLinkBox',
  components: { BaseIcon },
  props: {
    to: {
      type: String,
      default: '',
    },
  },
}
</script>
