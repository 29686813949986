import VueI18n, { IVueI18n } from 'vue-i18n'
import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'

// TODO: i18n
const mapping = {
  text: 'Text',
  textTwo: 'Name 2',
  textThree: 'Name 3',
  date: 'Datum',
  font: 'Font',
  color: 'Farbe',
  size: 'Größe',
  weight: 'Gewicht',
  time: 'Uhrzeit',
}

export function getCartItemProductIndividualisationPayload(
  item: LineItem,
  i18n: VueI18n & IVueI18n
): Array<{ key: string; value: string; text: string }> {
  if (item.type !== 'product-individualisation') {
    return []
  }

  const payload: { [key: string]: string } = item.payload as any

  if (!payload) {
    return []
  }

  const values: Array<{ key: string; value: string; text: string }> = []

  Object.keys(mapping).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      values.push({
        key,
        value: i18n.t(
          `productDetailPage.individualProduct.fields.${key}`
        ) as string,
        text: payload[key],
      })
    }
  })

  return values
}
