import { ref, Ref, computed, WritableComputedRef } from '@vue/composition-api'
import { ComputedRef } from '@nuxtjs/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import {
  INTERCEPTOR_KEYS,
  useIntercept,
  IInterceptorCallbackFunction,
  useSharedState,
} from '~/composables'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { IUseWishlist } from '~/composables/logic/useWishlist'
import { getProducts } from '~/shopware-6-client'

/**
 * interface for {@link useWishlistLocal} composable
 * @beta
 */
export interface IUseWishlistLocal extends IUseWishlist {
  setItemsToStore: (items: string[]) => void
  productIdStorage: ComputedRef<string[]>
}

/**
 * @beta
 */
export const useWishlistLocal = (
  rootContext: ApplicationVueContext,
  product?: Product
): IUseWishlistLocal => {
  const wishlistItemsKey = 'sw-wishlist-items'

  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useWishlistLocal'
  )
  const { intercept } = useIntercept(rootContext)
  const { sharedRef } = useSharedState(rootContext)

  const _wishlistLocal = sharedRef<[]>(
    `${contextName}--localWishlistItems`,
    []
  ) as WritableComputedRef<string[]>

  const error: Ref<any> = ref(null)
  const loading: Ref<boolean> = ref(false)
  const products: Ref<Product[]> = ref([])

  const _productId = product?.parentId || product?.id

  const isInWishlist = computed(() => {
    return !!_productId && _wishlistLocal.value?.includes(_productId)
  })

  const count = computed(() => _wishlistLocal.value?.length || 0)
  const productIdStorage = computed(() => {
    return _wishlistLocal.value || []
  })

  const onAddToWishlist = (fn: IInterceptorCallbackFunction) =>
    intercept(INTERCEPTOR_KEYS.ADD_TO_WISHLIST, fn)

  // update wishlist in localstorage
  const updateStorage = (): void => {
    localStorage.setItem(wishlistItemsKey, JSON.stringify(_wishlistLocal.value))
  }
  /* istanbul ignore next */
  const getFromStorage = () => {
    if (typeof window !== 'undefined' && localStorage) {
      return JSON.parse(localStorage.getItem(wishlistItemsKey) ?? '[]')
    }
  }
  // removes item from the list
  const removeFromWishlist = async (itemId: string): Promise<void> => {
    const id = _productId || itemId

    if (!id) {
      return
    }

    _wishlistLocal.value = _wishlistLocal.value.filter(
      (itemId: string) => itemId !== id
    )

    await updateStorage()
    await refreshWishlist()
  }

  const getIsInWishlist = (itemId: string) => {
    return !!_wishlistLocal.value?.includes(itemId)
  }

  // add product id to wishlist array and trigger to update localstorage
  const addToWishlist = async (itemId?: string) => {
    const id = _productId || itemId

    if (!id) {
      return
    }

    if (!_wishlistLocal.value.includes(id)) {
      _wishlistLocal.value.push(id)
      await updateStorage()
    }
  }

  const loadProductsByItemIds = async (productIds: string[]) => {
    if (productIds.length !== 0) {
      loading.value = true
      try {
        const result = await getProducts(
          {
            configuration: {
              ids: productIds,
            },
          },
          apiInstance
        )
        if (result) {
          products.value = result.elements.filter((element) => {
            return element.available
          })
        }
      } catch (e) {
        console.error(e)
      }
      loading.value = false
    } else {
      products.value = []
    }
  }

  const refreshWishlist = async () => {
    try {
      const currentWishlist = getFromStorage()
      if (Array.isArray(currentWishlist)) {
        _wishlistLocal.value = currentWishlist
      }
    } catch (error) {
      console.error('useWishlist:getFromStorage', error)
    }
    await loadProductsByItemIds(_wishlistLocal.value)
  }

  const setItemsToStore = (items: string[]): void => {
    localStorage.setItem(wishlistItemsKey, JSON.stringify(items))
  }

  return {
    addToWishlist,
    removeFromWishlist,
    isInWishlist,
    products,
    count,
    onAddToWishlist,
    error,
    loading,
    refreshWishlist,
    setItemsToStore,
    productIdStorage,
    getIsInWishlist,
  }
}
