import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductRealCalculatedPrice } from '~/helpers'

export function getProductListingRealUvpPrice(
  product: Product
): number | undefined {
  return (
    product.calculatedCheapestPrice?.listPrice?.price ||
    getProductRealCalculatedPrice(product)?.listPrice?.price
  )
}
