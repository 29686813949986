import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductUrl } from '~/helpers'

/**
 * @beta
 */
export function getProductParentUrl(product?: Product): string {
  if (!product) return '/'

  if (
    !product.parentId ||
    product.extensions?.bs_product_extension?.isExpressionForListing
  ) {
    return getProductUrl(product)
  }

  const seoUrl = product.extensions?.bs_product_extension?.seoUrl?.seoPathInfo

  return seoUrl ? `/${seoUrl}` : `/detail/${product.parentId}`
}
