<template>
  <v-container class="px-sm-12 px-md-3 py-0">
    <header
      class="app-bar"
      :class="{ 'app-bar--search-open': isNavigationSearch }"
    >
      <div class="app-bar__container d-flex align-center">
        <div class="app-bar__menu">
          <base-button
            icon
            :aria-label="$t('commons.openMenu')"
            @click="toggleNavigationDrawer"
          >
            <base-icon mdi="menu" large></base-icon>
          </base-button>
        </div>
        <div
          class="app-bar__logo ml-sm-3 ml-md-0"
          :class="{ 'app-bar__logo--small': $i18n.locale === 'fr-FR' }"
        >
          <nuxt-link
            :to="localePath('/')"
            :aria-label="$t('commons.homepage')"
            class="text-decoration-none grey-darken-4--text"
          >
            <app-bar-logo></app-bar-logo>
          </nuxt-link>
        </div>
        <div class="app-bar__search">
          <lazy-hydrate when-idle>
            <app-bar-search></app-bar-search>
          </lazy-hydrate>
        </div>
        <lazy-hydrate when-idle>
          <app-bar-icons class="app-bar__actions"></app-bar-icons>
        </lazy-hydrate>
      </div>

      <lazy-hydrate when-visible>
        <app-bar-extension class="app-bar__extensions"></app-bar-extension>
      </lazy-hydrate>
    </header>
  </v-container>
</template>

<script>
import { ref } from '@vue/composition-api'
import { watch } from '@nuxtjs/composition-api'
import { useUiEvent, useUIState } from '~/composables'
import {
  NAVIGATION_DRAWER_STATE,
  NAVIGATION_SEARCH_STATE,
} from '~/services/UiStates'
import AppBarLogo from '~/components/app-bar/AppBarLogo'
import AppBarSearch from '~/components/app-bar/search/AppBarSearch'
import AppBarIcons from '~/components/app-bar/AppBarIcons'
import AppBarExtension from '~/components/app-bar/AppBarExtension'
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'
import { debounce } from '~/helpers'

export default {
  name: 'AppBar',
  components: {
    BaseButton,
    BaseIcon,
    AppBarExtension,
    AppBarIcons,
    AppBarSearch,
    AppBarLogo,
  },
  setup(_, { root }) {
    const {
      switchState: toggleNavigationSearch,
      isOpen: isNavigationSearchState,
    } = useUIState(root, NAVIGATION_SEARCH_STATE)

    const { switchState: toggleNavigationDrawer } = useUIState(
      root,
      NAVIGATION_DRAWER_STATE
    )

    const isNavigationSearch = ref(false)

    const openNavigationSearchDelay = debounce((state) => {
      isNavigationSearch.value = state
    }, 150)

    const uiEventMenu = useUiEvent(root, 'menu_top', 'site')

    watch(isNavigationSearchState, openNavigationSearchDelay)

    return {
      toggleNavigationSearch,
      isNavigationSearch,
      toggleNavigationDrawer: () => {
        toggleNavigationDrawer()
        uiEventMenu()
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar {
  height: 170px;

  &__container {
    height: calc(100% - 48px);
  }

  &__menu {
    display: none;
  }

  &__logo {
    flex: 1;
    display: flex;
    justify-content: center;

    svg {
      width: 150px;
      max-height: 70px;
    }
  }

  &__search {
    flex: 1;
    display: flex;
  }

  &__actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  &__extensions {
    height: 48px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 72px;

    &__container {
      height: 100%;
    }

    &__menu {
      display: flex;
      width: 52px;
    }

    &__logo {
      svg {
        width: 125px;
        max-height: 50px;
      }
    }

    &__search {
      display: none;
      margin-right: 20px;
    }

    &__actions {
      flex: 0;
    }

    &.app-bar--search-open {
      .app-bar__logo {
        display: none;
      }
      .app-bar__search {
        display: flex;
      }
    }

    &__extensions {
      display: none !important;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    &__logo {
      justify-content: flex-start;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 50px;

    &__logo {
      svg {
        max-height: 40px;
      }

      &--small {
        svg {
          width: 100px;
        }
      }
    }
  }
}
</style>
