import { Product } from '~/commons/interfaces/models/content/product/Product'

/**
 * @alpha
 */
export function getProductUrl(product: Product | null): string {
  if (!product) return '/'
  // TODO change after fixing URL resolver
  const seoUrl = product.seoUrls?.[0]?.seoPathInfo
  return seoUrl ? `/${seoUrl}` : `/detail/${product.id}`
}
