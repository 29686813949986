import {
  ref,
  Ref,
  UnwrapRef,
  computed,
  ComputedRef,
  reactive,
} from '@vue/composition-api'
import { ApplicationVueContext, getApplicationContext } from '../appContext'
import {
  IInterceptorCallbackFunction,
  INTERCEPTOR_KEYS,
  useCart,
  useIntercept,
  useSentry,
  useSessionContext,
  useSharedState,
} from '~/composables'
import {
  login as apiLogin,
  logout as apiLogout,
  register as apiRegister,
  updatePassword as apiUpdatePassword,
  resetPassword as apiResetPassword,
  confirmPasswordReset as apiConfirmPasswordReset,
  updateEmail as apiUpdateEmail,
  getCustomer,
  getCustomerOrders,
  getCustomerOrderDetails,
  getCustomerAddresses,
  getUserCountry,
  getUserSalutation,
  setDefaultCustomerBillingAddress,
  setDefaultCustomerShippingAddress,
  deleteCustomerAddress,
  createCustomerAddress,
  updateProfile,
  CustomerUpdateProfileParam,
  CustomerUpdatePasswordParam,
  CustomerUpdateEmailParam,
  CustomerResetPasswordParam,
  updateCustomerAddress,
  setDefaultPaymentMethod,
  CustomerResetPasswordConfirmParam,
} from '~/shopware-6-client'
import { Customer } from '~/commons/interfaces/models/checkout/customer/Customer'
import { Order } from '~/commons/interfaces/models/checkout/order/Order'
import {
  CustomerAddress,
  AddressType,
} from '~/commons/interfaces/models/checkout/customer/CustomerAddress'
import { CustomerRegistrationParams } from '~/commons/interfaces/request/CustomerRegistrationParams'
import {
  ClientApiError,
  ShopwareError,
} from '~/commons/interfaces/errors/ApiError'
import { Country } from '~/commons/interfaces/models/system/country/Country'
import { Salutation } from '~/commons/interfaces/models/system/salutation/Salutation'
import { customerDestroy } from '~/shopware-6-client/plugins/services/bsAccount'

/**
 * interface for {@link useUser} composable
 *
 * @beta
 */
export interface IUseUser {
  login: ({
    username,
    password,
  }: {
    username?: string
    password?: string
  }) => Promise<boolean>
  register: (params: CustomerRegistrationParams) => Promise<boolean>
  user: ComputedRef<Partial<Customer> | null>
  orders: Ref<Order[] | null>
  addresses: Ref<CustomerAddress[] | null>
  loading: Ref<boolean>
  error: Ref<any>
  errors: UnwrapRef<{
    login: ShopwareError[]
    register: ShopwareError[]
    resetPassword: ShopwareError[]
    updatePassword: ShopwareError[]
    updateEmail: ShopwareError[]
    confirmPassword: ShopwareError[]
  }>
  isLoggedIn: ComputedRef<boolean>
  isCustomerSession: ComputedRef<boolean>
  isGuestSession: ComputedRef<boolean>
  country: Ref<Country | null>
  salutation: Ref<Salutation | null>
  refreshUser: () => Promise<void>
  logout: () => Promise<void>
  destroy: () => Promise<boolean>
  loadOrders: () => Promise<void>
  getOrderDetails: (orderId: string) => Promise<Order | undefined>
  loadAddresses: () => Promise<void>
  loadCountry: (countryId: string) => Promise<void>
  loadSalutation: (salutationId: string) => Promise<void>
  addAddress: (params: Partial<CustomerAddress>) => Promise<string | undefined>
  updateAddress: (
    params: Partial<CustomerAddress>
  ) => Promise<string | undefined>
  deleteAddress: (addressId: string) => Promise<boolean>
  updatePersonalInfo: (
    personals: CustomerUpdateProfileParam
  ) => Promise<boolean>
  updateEmail: (updateEmailData: CustomerUpdateEmailParam) => Promise<boolean>
  updatePassword: (
    updatePasswordData: CustomerUpdatePasswordParam
  ) => Promise<boolean>
  resetPassword: (
    resetPasswordData: CustomerResetPasswordParam
  ) => Promise<boolean>
  confirmPasswordReset: (
    confirmPasswordResetData: CustomerResetPasswordConfirmParam
  ) => Promise<boolean>
  markAddressAsDefault: ({
    addressId,
    type,
  }: {
    addressId?: string
    type?: AddressType
  }) => Promise<string | boolean>

  updateDefaultPaymentMethod: (paymentMethodId: string) => Promise<boolean>

  /**
   * React on user logout
   */
  onLogout: (fn: () => void) => void
  onUserLogin: (fn: (params: { customer: Customer }) => void) => void
  onUserRegister: (fn: () => void) => void
}

/**
 * Composable for user management. Options - {@link IUseUser}
 *
 * @beta
 */
export const useUser = (rootContext: ApplicationVueContext): IUseUser => {
  const { contextName, apiInstance } = getApplicationContext(
    rootContext,
    'useUser'
  )
  const { broadcast, intercept } = useIntercept(rootContext)
  const { refreshSessionContext } = useSessionContext(rootContext)
  const { refreshCart } = useCart(rootContext)

  const { sharedRef } = useSharedState(rootContext)
  const storeUser = sharedRef<Partial<Customer>>(`${contextName}-user`)
  const storeAddresses = sharedRef<CustomerAddress[]>(
    `${contextName}-addresses`
  )
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: 'useUser',
  })

  const loading: Ref<boolean> = ref(false)
  const error: Ref<any> = ref(null)
  const errors: UnwrapRef<{
    login: ShopwareError[]
    register: ShopwareError[]
    resetPassword: ShopwareError[]
    updatePassword: ShopwareError[]
    updateEmail: ShopwareError[]
    confirmPassword: ShopwareError[]
  }> = reactive({
    login: [],
    register: [],
    resetPassword: [],
    updatePassword: [],
    updateEmail: [],
    confirmPassword: [],
  })

  const orders: Ref<Order[] | null> = ref(null)
  const addresses = computed(() => storeAddresses.value)
  const country: Ref<Country | null> = ref(null)
  const salutation: Ref<Salutation | null> = ref(null)
  const user = computed(() => storeUser.value)

  const login = async ({
    username,
    password,
  }: { username?: string; password?: string } = {}): Promise<boolean> => {
    loading.value = true
    error.value = null
    errors.login = [] as any
    try {
      await apiLogin({ username, password }, apiInstance)
      await refreshUser()

      broadcast(INTERCEPTOR_KEYS.USER_LOGIN, {
        user: user.value,
      })

      return true
    } catch (e) {
      errors.login = e.messages

      broadcast(INTERCEPTOR_KEYS.ERROR, {
        methodName: `[${contextName}][login]`,
        inputParams: {},
        error: e,
      })
      return false
    } finally {
      loading.value = false
      await refreshUser()
      await refreshSessionContext()
      await refreshCart()
    }
  }

  const register = async (
    params: CustomerRegistrationParams
  ): Promise<boolean> => {
    loading.value = true
    errors.register = []
    try {
      const userObject = await apiRegister(params, apiInstance)
      broadcast(INTERCEPTOR_KEYS.USER_REGISTER)
      storeUser.value = (userObject as any) || {} // TODO change returning tyoe to customer
      refreshSessionContext()
      return true
    } catch (e) {
      errors.register = e.messages
      broadcast(INTERCEPTOR_KEYS.ERROR, {
        methodName: `[${contextName}][register]`,
        inputParams: {},
        error: e,
      })
      return false
    } finally {
      loading.value = false
    }
  }

  const logout = async (): Promise<void> => {
    try {
      await apiLogout(apiInstance)
      broadcast(INTERCEPTOR_KEYS.USER_LOGOUT)
    } catch (e) {
      error.value = e.messages
      broadcast(INTERCEPTOR_KEYS.ERROR, {
        methodName: `[${contextName}][logout]`,
        inputParams: {},
        error: e,
      })
    } finally {
      await refreshUser()
    }
  }

  const destroy = async (): Promise<boolean> => {
    try {
      await customerDestroy(apiInstance)
      broadcast(INTERCEPTOR_KEYS.USER_DELETE)

      return true
    } catch (e) {
      error.value = e.messages
      broadcast(INTERCEPTOR_KEYS.ERROR, {
        methodName: `[${contextName}][destroy]`,
        inputParams: {},
        error: e,
      })

      return false
    } finally {
      await refreshUser()
    }
  }

  const onLogout = (fn: IInterceptorCallbackFunction) =>
    intercept(INTERCEPTOR_KEYS.USER_LOGOUT, fn)

  const onUserLogin = (fn: IInterceptorCallbackFunction) => {
    intercept(INTERCEPTOR_KEYS.USER_LOGIN, fn)
  }

  const onUserRegister = (fn: IInterceptorCallbackFunction) =>
    intercept(INTERCEPTOR_KEYS.USER_REGISTER, fn)

  const refreshUser = async (): Promise<void> => {
    try {
      const user = await getCustomer(apiInstance)
      storeUser.value = user || {}
    } catch (e) {
      storeUser.value = {}
      captureClientApiError(`[${contextName}][refreshUser]`, e)
    }
  }

  const loadOrders = async (): Promise<void> => {
    const fetchedOrders = await getCustomerOrders(
      {
        sort: '-createdAt',
        associations: {
          lineItems: {},
          transactions: {
            sort: '-createdAt',
            associations: {
              paymentMethod: {},
            },
          },
          deliveries: {},
        },
      },
      apiInstance
    )
    orders.value = fetchedOrders
  }

  const getOrderDetails = async (orderId: string): Promise<Order | undefined> =>
    await getCustomerOrderDetails(orderId, apiInstance)

  const loadAddresses = async (): Promise<void> => {
    try {
      const response = await getCustomerAddresses(apiInstance)
      storeAddresses.value = response?.elements
    } catch (e) {
      error.value = e.messages
      captureClientApiError(`[${contextName}][loadAddresses]`, e)
    }
  }

  const loadCountry = async (userId: string): Promise<void> => {
    try {
      country.value = await getUserCountry(userId, apiInstance)
    } catch (e) {
      error.value = e.messages
      captureClientApiError(`[${contextName}][loadCountry]`, e)
    }
  }

  const loadSalutation = async (salutationId: string): Promise<void> => {
    try {
      salutation.value = await getUserSalutation(salutationId, apiInstance)
    } catch (e) {
      error.value = e.messages
      captureClientApiError(`[${contextName}][loadCountry]`, e)
    }
  }

  const markAddressAsDefault = async ({
    addressId,
    type,
  }: {
    addressId?: string
    type?: AddressType
  }): Promise<boolean> => {
    if (!addressId || !type) {
      return false
    }

    try {
      switch (type) {
        case AddressType.billing:
          await setDefaultCustomerBillingAddress(addressId, apiInstance)
          break
        case AddressType.shipping:
          await setDefaultCustomerShippingAddress(addressId, apiInstance)
          break
        default:
          return false
      }
      await refreshUser()
    } catch (e) {
      error.value = e.messages
      captureClientApiError(`[${contextName}][markAddressAsDefault]`, e, {
        addressId,
        type,
      })
      return false
    }

    return true
  }

  const updateDefaultPaymentMethod = async (
    paymentMethodId: string
  ): Promise<boolean> => {
    try {
      await setDefaultPaymentMethod(paymentMethodId, apiInstance)
      await refreshUser()
    } catch (e) {
      const err: ClientApiError = e
      error.value = err.messages
      captureClientApiError(`[${contextName}][updateDefaultPaymentMethod]`, e, {
        paymentMethodId,
      })
      return false
    }

    return true
  }

  const updateAddress = async (
    params: Partial<CustomerAddress>
  ): Promise<string | undefined> => {
    try {
      const { id } = await updateCustomerAddress(params, apiInstance)
      return id
    } catch (e) {
      error.value = e.messages
      captureClientApiError(`[${contextName}][updateAddress]`, e, { params })
    }
  }

  const addAddress = async (
    params: Partial<CustomerAddress>
  ): Promise<string | undefined> => {
    try {
      const { id } = await createCustomerAddress(params, apiInstance)
      return id
    } catch (e) {
      error.value = e.messages
      captureClientApiError(`[${contextName}][addAddress]`, e, { params })
    }
  }

  const deleteAddress = async (addressId: string): Promise<boolean> => {
    try {
      await deleteCustomerAddress(addressId, apiInstance)
      return true
    } catch (e) {
      error.value = e.messages
      captureClientApiError(`[${contextName}][deleteAddress]`, e, { addressId })
    }

    return false
  }

  const updatePersonalInfo = async (
    personals: CustomerUpdateProfileParam
  ): Promise<boolean> => {
    try {
      await updateProfile(personals, apiInstance)
    } catch (e) {
      error.value = e.messages
      captureClientApiError(
        `[${contextName}][updatePersonalInfo]`,
        e,
        personals
      )
      return false
    }
    return true
  }

  const updatePassword = async (
    updatePasswordData: CustomerUpdatePasswordParam
  ): Promise<boolean> => {
    try {
      await apiUpdatePassword(updatePasswordData, apiInstance)
    } catch (e) {
      errors.updatePassword = e.messages
      captureClientApiError(`[${contextName}][updatePassword]`, e)
      return false
    }
    return true
  }

  const resetPassword = async (
    resetPasswordData: CustomerResetPasswordParam
  ): Promise<boolean> => {
    try {
      await apiResetPassword(resetPasswordData, apiInstance)
    } catch (e) {
      errors.resetPassword = e.messages
      captureClientApiError(`[${contextName}][resetPassword]`, e)
      return false
    }
    return true
  }

  const confirmPasswordReset = async (
    confirmPasswordResetData: CustomerResetPasswordConfirmParam
  ): Promise<boolean> => {
    try {
      await apiConfirmPasswordReset(confirmPasswordResetData, apiInstance)
    } catch (e) {
      errors.confirmPassword = e.messages
      captureClientApiError(`[${contextName}][resetPassword]`, e)
      return false
    }
    return true
  }

  const updateEmail = async (
    updateEmailData: CustomerUpdateEmailParam
  ): Promise<boolean> => {
    try {
      await apiUpdateEmail(updateEmailData, apiInstance)
    } catch (e) {
      errors.updateEmail = e.messages
      captureClientApiError(`[${contextName}][updateEmail]`, e)
      return false
    }
    return true
  }

  const isLoggedIn = computed(() => !!user.value?.id)
  const isCustomerSession = computed(
    () => !!user.value?.id && !user.value.guest
  )
  const isGuestSession = computed(() => !!user.value?.guest)

  return {
    login,
    register,
    user,
    error,
    loading,
    isLoggedIn,
    isCustomerSession,
    isGuestSession,
    refreshUser,
    logout,
    orders,
    loadOrders,
    getOrderDetails,
    loadAddresses,
    addresses,
    markAddressAsDefault,
    updateEmail,
    updatePersonalInfo,
    updatePassword,
    resetPassword,
    addAddress,
    updateAddress,
    deleteAddress,
    loadSalutation,
    salutation,
    loadCountry,
    country,
    errors,
    onLogout,
    onUserLogin,
    onUserRegister,
    updateDefaultPaymentMethod,
    confirmPasswordReset,
    destroy,
  }
}
