import { effectScope } from '@nuxtjs/composition-api'
import { Capacitor } from '@capacitor/core'

export default ({ app }) => {
  if (Capacitor.isNativePlatform()) {
    // Firebase tracking wird in der App gemacht

    return
  }

  const scope = effectScope()

  let pageCounter = 0

  scope.run(() => {
    async function invokeMessagingToken() {
      const { initializeApp } = await import(
        /* webpackChunkName: "firebase" */ 'firebase/app'
      )
      const { getMessaging, getToken } = await import(
        /* webpackChunkName: "firebase" */ 'firebase/messaging'
      )

      initializeApp({
        ...app.$config.firebase,
      })

      const messaging = getMessaging()
      getToken(messaging, {
        vapidKey: app.$config.firebase.vapidKey,
      })
        .then((currentToken) => {
          if (currentToken) {
            window.localStorage.setItem('bs_msg_token', currentToken)
          }
        })
        .catch()
    }

    app.router.afterEach(() => {
      if (pageCounter++ === 3) {
        try {
          if (app.$config.firebase?.apiKey && app.$config.firebase?.appId) {
            invokeMessagingToken().then().catch()
          }
        } catch (e) {}
      }
    })
  })
}
