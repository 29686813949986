import { Product } from '~/commons/interfaces/models/content/product/Product'
import {
  getProductMainImageUrl,
  getProductName,
  getProductNumber,
  getProductRealPrice,
  getProductUrl,
} from '~/helpers'

function getRating(product: Product, reviewCount: number) {
  if (!product.ratingAverage) {
    return null
  }

  return {
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: product.ratingAverage,
      reviewCount,
    },
  }
}

function getBrand(product: Product) {
  if (!(product.manufacturer as any)?.translated.name) {
    return null
  }

  return {
    brand: {
      '@type': 'Brand',
      name: (product.manufacturer as any)?.translated.name,
    },
  }
}

export function getProductLdJson(
  product: Product,
  reviewCount: number,
  appUrl: string
) {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: getProductName({ product }),
    image: [getProductMainImageUrl(product)],
    description: product.description,
    sku: getProductNumber(product),
    gtin13: product.ean,
    ...getBrand(product),
    ...getRating(product, reviewCount),
    offers: {
      '@type': 'Offer',
      url: appUrl + getProductUrl(product),
      priceCurrency: 'EUR',
      sku: getProductNumber(product),
      price: getProductRealPrice(product),
      availability: product.available
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
    },
  }
}
