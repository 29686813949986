import { computed, ComputedRef, ref, Ref } from '@vue/composition-api'
import deepmerge from 'deepmerge'
import { ApplicationVueContext } from '../../appContext'
import { parseUrlQuery } from '~/helpers'
import {
  getApplicationContext,
  useDefaults,
  useSentry,
  useSharedState,
} from '~/composables'
import { getCmsPage } from '~/shopware-6-client'
import {
  CmsPage,
  PageResolverProductResult,
  PageResolverResult,
} from '~/commons/interfaces/models/content/cms/CmsPage'
import { SearchCriteria } from '~/commons/interfaces/search/SearchCriteria'

/**
 * @beta
 */
export function useCms(rootContext: ApplicationVueContext): {
  page: ComputedRef<
    PageResolverProductResult | PageResolverResult<CmsPage> | null
  >
  categoryId: ComputedRef<string | null>
  loading: Ref<boolean>
  search: (path: string, query?: any) => Promise<void>
  error: ComputedRef<any>
  currentSearchPathKey: ComputedRef<string | null>
  setInitialData: (
    page: PageResolverProductResult | PageResolverResult<CmsPage> | null,
    errors: any
  ) => void
} {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useCms'
  )

  const { sharedRef } = useSharedState(rootContext)
  const _storePage = sharedRef<
    PageResolverProductResult | PageResolverResult<CmsPage>
  >(`${contextName}-page`)
  const _searchPath = sharedRef<string>(`${contextName}-searchPath`)
  const _cmsError = sharedRef<any>(`${contextName}-cmsError`)

  const { getDefaults } = useDefaults(rootContext, 'useCms')
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: 'useCms',
  })

  const loading: Ref<boolean> = ref(false)
  const page = computed(() => _storePage.value)
  // TODO: rename it to something more obvious, or just leav as resourceIdentifier
  // TODO: https://github.com/vuestorefront/shopware-pwa/issues/1308
  const categoryId = computed(() => {
    // each cms page is in relation one-to-one with categoryId (resourceIdentifier)
    return page.value && page.value.resourceIdentifier
  })

  /**
   * @beta
   */
  const search = async (path: string, query?: any): Promise<void> => {
    loading.value = true
    _cmsError.value = null
    _searchPath.value = path

    const criteria: SearchCriteria = parseUrlQuery(query)
    const searchCriteria = deepmerge(getDefaults(), criteria)

    try {
      const result = await getCmsPage(path, searchCriteria, apiInstance)
      _storePage.value = result
    } catch (e) {
      _cmsError.value = e

      captureClientApiError('[useCms][search]', e)
    } finally {
      loading.value = false
    }
  }

  const setInitialData = (initialPage: any, initialError: any) => {
    _storePage.value = initialPage
    _cmsError.value = initialError
  }

  return {
    page,
    categoryId,
    loading,
    search,
    currentSearchPathKey: computed(() => _searchPath.value),
    error: computed(() => _cmsError.value),
    setInitialData,
  }
}
