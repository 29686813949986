<template>
  <div v-show="items.length">
    <base-chip v-for="item in items" :key="item.id" small class="mr-2 my-1">
      {{ formatValue(item) }}
      <base-icon mdi="close" @click="$emit('click:remove', item)"></base-icon>
    </base-chip>
    <base-chip
      small
      outlined
      class="pink-berry--text"
      @click="$emit('click:clear')"
      >{{ $t('listing.filterReset') }}</base-chip
    >
  </div>
</template>

<script>
import BaseChip from '~/components/base/chip/BaseChip'
import BaseIcon from '~/components/base/icon/BaseIcon'

export default {
  name: 'ProductListingFilterChipGroup',
  components: {
    BaseIcon,
    BaseChip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatValue(item) {
      if (item.type === 'price') {
        const price = this.$options.filters.price(parseFloat(item.value))

        return item.id === 'min-price' ? `min. ${price}` : `max. ${price}`
      }

      return item.value
    },
  },
}
</script>
