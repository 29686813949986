<script>
import { mergeData } from 'vue-functional-data-merge'
import CmsUtilAction from '~/components/cms/util/CmsUtilAction'

function getLinkData({ url }, { target }) {
  return {
    attrs: {
      href: url,
      target: target || '_blank',
    },
  }
}

function getActionData({ url }) {
  return {
    attrs: {
      action: url,
    },
  }
}

function getNuxtData({ url }) {
  return {
    props: {
      to: url,
    },
  }
}

export default {
  name: 'CmsUtilLink',
  functional: true,
  props: {
    url: {
      type: String,
      default: '',
    },
    wrapped: {
      type: String,
      default: null,
    },
  },
  render(h, { data, props, children }) {
    if (!props.url) {
      if (props.wrapped) {
        return h(props.wrapped, data, children)
      }

      return children
    }

    const intern = props.url.match(/^\//)
    const isAction = props.url.match(/^#a:/)

    return h(
      intern ? 'nuxt-link' : isAction ? CmsUtilAction : 'a',
      mergeData(data, {
        ...(intern
          ? getNuxtData(props)
          : isAction
          ? getActionData(props)
          : getLinkData(props, data.attrs || {})),
      }),
      children
    )
  },
}
</script>
