import { ApplicationVueContext } from '~/composables/appContext'
import {
  useFooterNavigation,
  useMainNavigation,
  useSharedState,
} from '~/composables'
import { Category } from '~/commons/interfaces/models/content/category/Category'
import {
  getCategoryCustomField,
  getCategoryUrl,
  StoreNavigationRoute,
} from '~/helpers'
import { ATTRIBUTE_CATEGORY_TECHNICAL_NAME } from '~/services/cfAttributes'

const CAT_TECHNICAL_NAME_IMPRINT = 'imprint'
const CAT_TECHNICAL_NAME_PRIVACY = 'privacy'
const CAT_TECHNICAL_NAME_SHIPPING = 'shipping'
const CAT_TECHNICAL_NAME_AGB = 'agb'
const CAT_TECHNICAL_NAME_RIGHT_OF_WITHDRAWAL = 'right_of_withdrawal'
const CAT_TECHNICAL_NAME_NEWSLETTER = 'newsletter'
const CAT_TECHNICAL_NAME_SALE = 'sale'
const CAT_TECHNICAL_NAME_MAGAZIN = 'magazin'
const CAT_TECHNICAL_NAME_NEU = 'neu'
const CAT_TECHNICAL_NAME_EIGENKOLLEKTION = 'eigenkollektion'
const CAT_TECHNICAL_NAME_DISNEY = 'disney'
const CAT_TECHNICAL_NAME_PERSONALISATION = 'personalisation'
const CAT_TECHNICAL_NAME_FALL_WINTER = 'fall_winter'
const CAT_TECHNICAL_NAME_BABY_GIFT = 'baby_gift'
const CAT_TECHNICAL_NAME_BESTSELLER = 'bestseller'

export function useCategoryPager(rootContext: ApplicationVueContext) {
  const { navigationElements: mainCategories } = useMainNavigation(rootContext)
  const { navigationLinks: footerCategories } = useFooterNavigation(rootContext)
  const { sharedRef } = useSharedState(rootContext)

  const _technicalCategories = sharedRef<any>('useCategoryPager', {})

  const refresh = () => {
    const mapping: any = {}

    function parseMainCategories(categories: Category[]) {
      for (let i = 0; i < categories.length; i++) {
        const category: Category = categories[i]
        const technicalName = getCategoryCustomField(
          category,
          ATTRIBUTE_CATEGORY_TECHNICAL_NAME
        )

        if (technicalName) {
          mapping[technicalName] = {
            name: category.translated?.name || category.name,
            path: getCategoryUrl(category),
          }
        }

        if (category.children && category.children.length) {
          parseMainCategories(category.children)
        }
      }
    }

    function parseFooterCategories(categories: StoreNavigationRoute[]) {
      for (let i = 0; i < categories.length; i++) {
        const category: StoreNavigationRoute = categories[i]
        const technicalName = getCategoryCustomField(
          category as any,
          ATTRIBUTE_CATEGORY_TECHNICAL_NAME
        )

        if (technicalName) {
          mapping[technicalName] = {
            name: category.routeLabel,
            path: category.routePath,
          }
        }

        if (category.children && category.children.length) {
          parseFooterCategories(category.children)
        }
      }
    }

    parseMainCategories(mainCategories.value ?? ([] as any))
    parseFooterCategories(footerCategories.value ?? ([] as any))

    _technicalCategories.value = mapping
  }

  const getCategory = (
    technicalName: string
  ): {
    name: string
    path: string
  } | null => {
    return _technicalCategories.value[technicalName]
  }

  const getTechnicalPath = (technicalName: string): string => {
    return _technicalCategories.value[technicalName]?.path || '/'
  }

  return {
    refresh,
    getCategory,
    getTechnicalPath,

    CAT_TECHNICAL_NAME_IMPRINT,
    CAT_TECHNICAL_NAME_PRIVACY,
    CAT_TECHNICAL_NAME_SHIPPING,
    CAT_TECHNICAL_NAME_AGB,
    CAT_TECHNICAL_NAME_RIGHT_OF_WITHDRAWAL,
    CAT_TECHNICAL_NAME_NEWSLETTER,
    CAT_TECHNICAL_NAME_SALE,
    CAT_TECHNICAL_NAME_MAGAZIN,
    CAT_TECHNICAL_NAME_NEU,
    CAT_TECHNICAL_NAME_EIGENKOLLEKTION,
    CAT_TECHNICAL_NAME_DISNEY,
    CAT_TECHNICAL_NAME_PERSONALISATION,
    CAT_TECHNICAL_NAME_FALL_WINTER,
    CAT_TECHNICAL_NAME_BABY_GIFT,
    CAT_TECHNICAL_NAME_BESTSELLER,
  }
}
