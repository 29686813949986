import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductRealPrice } from '~/helpers'
import { getProductRealTax } from '~/helpers/product/getProductRealTax'

export function getProductRealPriceNet(product: Product): number | undefined {
  const price = getProductRealPrice(product)
  const tax = getProductRealTax(product)

  const netPrice = Number(price) - Number(tax)

  return isNaN(netPrice) ? price : netPrice
}
