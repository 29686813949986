<script>
import { mergeData } from 'vue-functional-data-merge'

export default {
  name: 'BaseChip',
  functional: true,
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: undefined,
    },
  },
  render(h, { props, data, children }) {
    return h(
      props.to ? 'nuxt-link' : 'span',
      mergeData(data, {
        props: {
          to: props.to,
        },
        staticClass: 'base-chip',
        class: {
          'base-chip--small': props.small,
          'base-chip--outlined': props.outlined,
          'base-chip--clickable': !!data.on?.click,
        },
      }),
      children
    )
  },
}
</script>
