<template>
  <navigator-drawer
    v-if="drawerBooted"
    v-model="internalValue"
    :init-nav-url="initNavUrl"
    @opened="initNavUrl = null"
  ></navigator-drawer>
</template>

<script>
import { onMounted, ref, useRouter } from '@nuxtjs/composition-api'
import { computed, watch } from '@vue/composition-api'
import { INTERCEPTOR_KEYS, useIntercept, useUIState } from '~/composables'
import { NAVIGATION_DRAWER_STATE } from '~/services/UiStates'

export default {
  name: 'NavigatorDrawerContainer',
  components: {
    NavigatorDrawer: () =>
      import(
        /* webpackPrefetch: true */ '~/components/navigator-drawer/NavigatorDrawer'
      ),
  },
  setup(_, { root }) {
    const { switchState, isOpen } = useUIState(root, NAVIGATION_DRAWER_STATE)
    const { intercept } = useIntercept(root)
    const router = useRouter()

    const drawerBooted = ref(false)
    const initNavUrl = ref(null)

    const internalValue = computed({
      get() {
        return isOpen.value
      },
      set(value) {
        switchState(value)
      },
    })

    watch(isOpen, (isOpen) => {
      if (isOpen) {
        drawerBooted.value = true
      }
    })

    onMounted(() => {
      intercept(INTERCEPTOR_KEYS.ACTION_CLICK_EVENT, ({ name, params }) => {
        if (name === 'nav') {
          const vuetify = root.$vuetify || root.vuetify

          if (vuetify && vuetify.breakpoint.xs) {
            initNavUrl.value = params
            switchState(true)
          } else if (params) {
            router.push(
              params.startsWith('/') ? params : `/navigation/${params}`
            )
          }
        }
      })
    })

    return {
      isOpen,
      drawerBooted,
      internalValue,
      initNavUrl,
    }
  },
}
</script>
