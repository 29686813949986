<script>
import { getCmsBlockComponentName } from '~/logic/cms'
import CmsBlockBsGridFull from '~/components/cms/blocks/CmsBlockBsGridFull'
import CmsBlockBsGridHalf from '~/components/cms/blocks/CmsBlockBsGridHalf'
import CmsBlockBsGridQuarter from '~/components/cms/blocks/CmsBlockBsGridQuarter'
import CmsBlockBsGridThird from '~/components/cms/blocks/CmsBlockBsGridThird'
import CmsNoComponent from '~/components/cms/CmsNoComponent'

const components = {
  CmsBlockBsGridFull,
  CmsBlockBsGridHalf,
  CmsBlockBsGridQuarter,
  CmsBlockBsGridThird,
  CmsNoComponent,
}

export default {
  name: 'CmsGenericBlock',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, context) {
    const content = context.props.content
    const componentName = getCmsBlockComponentName(context.props.content)

    return h(components[componentName], {
      staticClass: content.cssClass,
      props: {
        content,
      },
    })
  },
}
</script>
