import { ref } from '@vue/composition-api'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { INTERCEPTOR_KEYS, useIntercept } from '~/composables'

export const useUiEvent = (
  rootContext: ApplicationVueContext,
  name: string,
  source: string,
  once: boolean = false,
  type: string = 'click'
): Function => {
  const { route } = getApplicationContext(rootContext, 'useUiEvent')
  const { broadcast } = useIntercept(rootContext)
  const isFired = ref(false)

  return () => {
    if (once && isFired.value) {
      return
    }

    isFired.value = true
    broadcast(INTERCEPTOR_KEYS.UI_EVENT, {
      type,
      source,
      name,
      path: route?.path,
    })
  }
}
