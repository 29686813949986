import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getProductCustomFields } from '~/helpers'

export function getProductCustomField(
  product: Product,
  key: string,
  defaultValue: any = null
): any {
  return getProductCustomFields(product)?.[key] ?? defaultValue
}
