<template>
  <div>
    <div class="text-center">
      <base-progress-linear
        style="border-radius: 32px; max-width: 450px"
        background-class="grey-lighten-2"
        foreground-class="frontend-primary-pastel"
        :value="progress"
        height="8px"
        :aria-label="$t('listing.viewedProducts')"
        class="mx-auto"
      ></base-progress-linear>
      <i18n tag="div" path="listing.youViewedProducts" class="text-body-4 mt-3">
        <span class="font-weight-bold">{{ current }}</span>
        <span class="font-weight-bold">{{ total }}</span>
      </i18n>
    </div>
  </div>
</template>

<script>
import BaseProgressLinear from '~/components/base/progress/BaseProgressLinear'
export default {
  name: 'ProductListingProgressBar',
  components: { BaseProgressLinear },
  props: {
    current: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    progress() {
      return this.total ? (this.current / this.total) * 100 : 0
    },
  },
}
</script>
