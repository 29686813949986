<template>
  <v-container fluid class="product-listing pa-0">
    <v-row
      id="listing-anker"
      style="position: relative"
      class="product-listing__row"
    >
      <v-overlay absolute :value="loading" color="white" :opacity="0.6">
        <v-progress-circular indeterminate color="purple" :size="64">
          <base-icon mdi="heart"></base-icon>
        </v-progress-circular>
      </v-overlay>

      <v-col
        v-for="(item, index) in listingItems"
        :key="item.key"
        cols="6"
        sm="4"
        lg="3"
      >
        <lazy-hydrate when-visible>
          <product-card
            v-if="item.type === 'product'"
            :product="item.item"
            :is-in-wishlist="item.isInWishlist"
            :eager="index < 2"
            :sizes="imageSizes"
            :init-image-width="400"
            :proxy-srcset="[200, 300, 400]"
            @click:wishlist="handleWishlist"
            @click.native="selectProduct(item, index)"
          >
          </product-card>
          <product-troublemaker-container
            v-else
            :troublemaker="item.item"
          ></product-troublemaker-container>
        </lazy-hydrate>
      </v-col>
    </v-row>
    <v-row v-if="getTotalPagesCount" class="mt-12">
      <v-col class="text-center">
        <product-listing-progress-bar
          class="mx-2"
          :current="viewedArtikels"
          :total="getTotal"
        ></product-listing-progress-bar>

        <base-button
          v-if="currentPage < getTotalPagesCount"
          large
          :to="nextRoute"
          class="mt-5 pink-berry white--text font-weight-bold"
          rounded
          @click.native="uiEventNextPage"
          >{{ $t('actions.nextPage') }}
        </base-button>

        <base-pagination
          :key="$route.fullPath"
          :value="currentPage"
          :length="getTotalPagesCount"
          :total-visible="7"
          class="mt-10"
          @page="uiEventPagination"
          @next="uiEventPagination"
          @prev="uiEventPagination"
        ></base-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { watch } from '@nuxtjs/composition-api'
import {
  INTERCEPTOR_KEYS,
  useCms,
  useIntercept,
  useListing,
  useUiEvent,
  useWishlist,
} from '~/composables'
import ProductCard from '~/components/product/card/ProductCard'
import ProductListingProgressBar from '~/components/product/listing/ProductListingProgressBar'
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'
import BasePagination from '~/components/base/pagination/BasePagination'
import ProductTroublemakerContainer from '~/components/product/troublemaker/ProductTroublemakerContainer'
import { getFirstActivePageConfig } from '~/helpers/pageConfig/getFirstActivePageConfig'

export default {
  name: 'ProductListing',
  components: {
    ProductTroublemakerContainer,
    BasePagination,
    BaseButton,
    BaseIcon,
    ProductListingProgressBar,
    ProductCard,
  },
  props: {
    initialListing: {
      type: Object,
      default: null,
    },
    listingType: {
      type: String,
      validator(val) {
        return ['categoryListing', 'productSearchListing'].includes(val)
      },
      default: 'categoryListing',
    },
    layout: {
      type: String,
      default: 'standard',
      validator(val) {
        return ['standard', 'minimal', 'image'].includes(val)
      },
    },
    troublemakerConfigs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const listingType = props.listingType

    const {
      getElements,
      setInitialListing,
      getCurrentPage,
      changeCurrentPage,
      getTotalPagesCount,
      getTotal,
      loading,
      getLimit,
    } = useListing(root, listingType)

    const { broadcast } = useIntercept(root)

    const { categoryId, page } = useCms(root)

    const { getIsInWishlist, removeFromWishlist, addToWishlist } =
      useWishlist(root)

    if (props.initialListing) {
      setInitialListing(props.initialListing)
      watch(
        () => props.initialListing,
        () => {
          const initialListing = props.initialListing || []
          setInitialListing(initialListing)
        }
      )
    }

    return {
      getTotalPagesCount,
      changeCurrentPage,
      loading,
      getElements,
      getCurrentPage,
      getTotal,
      getLimit,
      getIsInWishlist,
      removeFromWishlist,
      addToWishlist,
      broadcast,
      categoryId,
      page,

      uiEventAddToWishlist: useUiEvent(root, 'add_to_wishlist', 'listing'),
      uiEventNextPage: useUiEvent(root, 'next_page', 'listing'),
      uiEventPagination: useUiEvent(root, 'pagination', 'listing'),
    }
  },
  computed: {
    viewedArtikels() {
      return Math.min(this.currentPage * this.getLimit, this.getTotal)
    },
    category() {
      return this.page?.category
    },
    currentPage: {
      get() {
        return this.getCurrentPage
      },
      set(page) {
        this.changePage(page)
      },
    },
    nextRoute() {
      const query = {
        ...this.$router.currentRoute.query,
        p: this.getCurrentPage + 1,
      }

      return {
        path: this.$router.currentRoute.path,
        query,
      }
    },
    troublemakerPostionOne() {
      return this.getTroublemakerAtPosition('position-1')
    },
    troublemakerPostionTwo() {
      return this.getTroublemakerAtPosition('position-2')
    },
    listingItems() {
      const elements = this.getElements.map((product) => {
        return {
          type: 'product',
          key: product.id,
          item: product,
          isInWishlist: this.getIsInWishlist(product.parentId || product.id),
        }
      })

      if (elements.length > 8 && this.troublemakerPostionOne) {
        elements.splice(7, 0, {
          type: 'troublemaker',
          key: 'troublemaker-1',
          item: this.troublemakerPostionOne,
        })
      }

      if (elements.length > 17 && this.troublemakerPostionTwo) {
        elements.splice(16, 0, {
          type: 'troublemaker',
          key: 'troublemaker-2',
          item: this.troublemakerPostionTwo,
        })
      }

      return elements
    },
    imageSizes() {
      return `
        (min-width: 1264px) 224px,
        (min-width: 960px) calc(22vw - 24px),
        (min-width: 600px) calc(calc(100vw - 96px) / 3 ),
        50vw
      `
    },
  },
  watch: {
    $route(to, old) {
      const page = parseInt(to.query?.p || 1)

      this.changePage(page, page !== parseInt(old?.query?.p || 1))
    },
    getElements: {
      handler(products) {
        this.broadcast(INTERCEPTOR_KEYS.VIEW_ITEM_LIST, {
          products,
          listId:
            this.listingType === 'productSearchListing' ? 'search' : 'category',
          listName:
            this.listingType === 'productSearchListing' ? 'Suche' : 'Kategorie',
        })
      },
      immediate: true,
    },
  },
  methods: {
    async changePage(page, scroll = true) {
      // noinspection ES6MissingAwait
      const action = [this.changeCurrentPage(page)]

      if (scroll) {
        action.unshift(this.$vuetify.goTo('#listing-anker', 'linear'))
      }

      await Promise.all(action)
    },
    handleWishlist(product) {
      this.uiEventAddToWishlist()

      const id = product.parentId || product.id

      if (this.getIsInWishlist(id)) {
        this.removeFromWishlist(id)
      } else {
        this.addToWishlist(id)
      }
    },
    selectProduct(product, index) {
      if (!product || product.type !== 'product') {
        return
      }

      this.broadcast(INTERCEPTOR_KEYS.SELECT_PRODUCT_ITEM, {
        product: product.item,
        index: index + this.getLimit * (this.currentPage - 1),
        listId:
          this.listingType === 'productSearchListing' ? 'search' : 'category',
        listName:
          this.listingType === 'productSearchListing' ? 'Suche' : 'Kategorie',
      })
    },
    getTroublemakerAtPosition(pos) {
      return getFirstActivePageConfig(
        this.troublemakerConfigs.filter(({ position }) => position === pos)
      )
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.product-listing {
  &__row {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: -4px;

      & > .col,
      & > [class*='col-'] {
        padding: 4px;
      }
    }
  }
}
</style>
