import cmsMap from '~/cmsMap'

interface ICmsMap {
  [x: string]: {
    [y: string]: string
  }
}

const mapping: ICmsMap = cmsMap as ICmsMap

export function resolveComponentName(
  blockType: string,
  contentType: string
): string {
  if (mapping[blockType]?.[contentType]) {
    return mapping[blockType][contentType]
  }

  return 'CmsNoComponent'
}
