import { computed, ComputedRef, Ref } from '@vue/composition-api'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { ApplicationVueContext } from '~/composables/appContext'
import {
  IInterceptorCallbackFunction,
  useUser,
  useWishlistLocal,
  useWishlistCustomer,
  INTERCEPTOR_KEYS,
  useIntercept,
} from '~/composables'

export interface IUseWishlist {
  removeFromWishlist: (id: string) => void
  addToWishlist: (itemId: string) => void
  onAddToWishlist: (fn: (params: { product: Product }) => void) => void
  isInWishlist: ComputedRef<boolean>
  products: ComputedRef<Product[]>
  count: ComputedRef<number>
  loading: Ref<boolean>
  refreshWishlist: () => void
  error: ComputedRef<string>
  getIsInWishlist: (itemId: string) => boolean
}

export const useWishlist = (
  rootContext: ApplicationVueContext,
  product?: Product
): IUseWishlist => {
  const { isCustomerSession } = useUser(rootContext)
  const { intercept, broadcast } = useIntercept(rootContext)

  const {
    addToWishlist: addToWishlistCustomer,
    removeFromWishlist: removeFromWishlistCustomer,
    isInWishlist: isInWishlistCustomer,
    products: productsCustomer,
    count: countCustomer,
    error: errorCustomer,
    loading: loadingCustomer,
    refreshWishlist: refreshWishlistCustomer,
    getIsInWishlist: getIsInWishlistCustomer,
  } = useWishlistCustomer(rootContext, product)

  const {
    addToWishlist: addToWishlistLocal,
    removeFromWishlist: removeFromWishlistLocal,
    isInWishlist: isInWishlistLocal,
    products: productsLocal,
    count: countLocal,
    error: errorLocal,
    loading: loadingLocal,
    refreshWishlist: refreshWishlistLocal,
    setItemsToStore,
    getIsInWishlist: getIsInWishlistLocal,
  } = useWishlistLocal(rootContext, product)

  const addToWishlist = async (itemId: string) => {
    if (isCustomerSession.value) {
      await addToWishlistCustomer(itemId)
      await syncWishlist()
    } else {
      await addToWishlistLocal(itemId)
    }
    broadcast(INTERCEPTOR_KEYS.ADD_TO_WISHLIST, {
      product,
    })
  }

  const removeFromWishlist = async (itemId: string): Promise<void> => {
    if (isCustomerSession.value) {
      await removeFromWishlistCustomer(itemId)
      await syncWishlist()
    } else {
      await removeFromWishlistLocal(itemId)
    }
    broadcast(INTERCEPTOR_KEYS.REMOVE_FROM_WISHLIST, {
      product,
    })
  }

  const getIsInWishlist = (itemId: string): boolean => {
    if (isCustomerSession.value) {
      return getIsInWishlistCustomer(itemId)
    } else {
      return getIsInWishlistLocal(itemId)
    }
  }

  const isInWishlist = computed(() => {
    if (isCustomerSession.value) {
      return isInWishlistCustomer.value
    } else {
      return isInWishlistLocal.value
    }
  })

  const products = computed(() => {
    if (isCustomerSession.value) {
      return productsCustomer.value
    } else {
      return productsLocal.value
    }
  })

  const count = computed(() => {
    if (isCustomerSession.value) {
      return countCustomer.value
    } else {
      return countLocal.value
    }
  })

  const onAddToWishlist = (fn: IInterceptorCallbackFunction) =>
    intercept(INTERCEPTOR_KEYS.ADD_TO_WISHLIST, fn)

  const error = computed(() => {
    if (isCustomerSession.value) {
      return errorCustomer.value
    } else {
      return errorLocal.value
    }
  })

  const loading = computed(() => {
    if (isCustomerSession.value) {
      return loadingCustomer.value
    } else {
      return loadingLocal.value
    }
  })

  // anzeige aktualisieren
  const refreshWishlist = () => {
    if (isCustomerSession.value) {
      refreshWishlistCustomer()
    } else {
      refreshWishlistLocal()
    }
  }

  const syncWishlist = async (): Promise<void> => {
    await setItemsToStore(products.value.map(({ id }) => id))
  }

  return {
    addToWishlist,
    removeFromWishlist,
    isInWishlist,
    products,
    count,
    onAddToWishlist,
    error,
    loading,
    refreshWishlist,
    getIsInWishlist,
  }
}
