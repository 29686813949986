import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

/**
 * @beta
 */
export interface PreCheckResponse {
  isBirthdayValid: boolean
  isSuccessful: boolean
  preCheckId: string | null
  transactionId: string | null
}

/**
 * @beta
 */
export interface PreCheckParam {
  payolutionBirthdayDay: string
  payolutionBirthdayMonth: string
  payolutionBirthdayYear: string
}

/**
 * @beta
 */
export async function preCheck(
  params: PreCheckParam,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PreCheckResponse> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-payolution-extension/pre-check',
    params
  )

  return resp.data
}

/**
 * @beta
 */
export interface PreCheckDirectDebitParam extends PreCheckParam {
  payolutionDirectDebitMandateIban: string
  payolutionDirectDebitMandateOwner: string
}

/**
 * @beta
 */
export async function preCheckDirectDebit(
  params: PreCheckDirectDebitParam,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PreCheckResponse> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-payolution-extension/pre-check-direct-debit',
    params
  )

  return resp.data
}

/**
 * @beta
 */
export interface PreCheckInstallmentsParam extends PreCheckParam {
  payolutionAccountHolder: string
  payolutionAccountIban: string
}

/**
 * @beta
 */
export async function preCheckInstallments(
  params: PreCheckInstallmentsParam,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<PreCheckResponse> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-payolution-extension/pre-check-installments',
    params
  )

  return resp.data
}

export interface CalculateInstallmentsResponse {
  calculationId: string
  isSuccessful: boolean
  details: {
    [key: number]: {
      creditInformationUrl: string
      currency: string
      duration: number
      effectiveInterestRate: number
      installments: [{ amount: number; due: string }]
      interestRate: number
      originalAmount: number
      standardCreditInformationUrl: string
      totalAmount: number
    }
  }
}

/**
 * @beta
 */
export async function calculateInstallments(
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<CalculateInstallmentsResponse> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-payolution-extension/calculate-installments',
    {}
  )

  return resp.data
}
