import { Blog } from '~/commons/interfaces/models/content/blog/Blog'

/**
 * @alpha
 */
export function getBlogUrl(blog: Blog | null): string {
  if (!blog) return '/'

  // TODO change after fixing URL resolver
  const seoUrl = blog.seoUrls?.[0]?.seoPathInfo
  return seoUrl ? `/${seoUrl}` : `/bs_blog/${blog.id}`
}
