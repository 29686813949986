const onLoad = (callback, delay = 1) => {
  // missed the load event, run now
  if (document.readyState === 'complete') {
    setTimeout(() => callback(), delay)
  } else {
    window.addEventListener('load', function () {
      setTimeout(() => callback(), delay)
    })
  }
}

export default ({ $config }) => {
  if (!$config.trustedShopsId) {
    return
  }

  onLoad(() => {
    // manually add the script to the DOM
    window._tsConfig = {
      yOffset: '0' /* offset from page bottom */,
      variant: 'reviews' /* default, reviews, custom, custom_reviews */,
      customElementId: '' /* required for variants custom and custom_reviews */,
      trustcardDirection:
        '' /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
      disableResponsive: 'false' /* deactivate responsive behaviour */,
      disableTrustbadge: 'true' /* deactivate trustbadge */,
      responsive: { variant: 'floating_reviews' },
    }

    const _ts = document.createElement('script')
    _ts.type = 'text/javascript'
    _ts.async = true
    _ts.src = `https://widgets.trustedshops.com/js/${$config.trustedShopsId}.js`

    const __ts = document.getElementsByTagName('script')[0]
    __ts.parentNode.insertBefore(_ts, __ts)
  }, 3500)
}
