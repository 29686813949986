<template>
  <base-alert type="error">
    {{ elementType }}<i> {{ getElementType }} </i>
    {{ $t('cmsElement.notImplement') }}
  </base-alert>
</template>

<script>
import BaseAlert from '~/components/base/alert/BaseAlert'
export default {
  name: 'CmsNoComponent',
  components: { BaseAlert },
  props: {
    content: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    elementType() {
      if (this.content.pageId) return 'Section'
      return this.content.sectionId ? `Block` : `Slot`
    },
    getElementType() {
      return this.content ? this.content.type : ''
    },
  },
}
</script>
