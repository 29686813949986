<template>
  <div class="text-center">
    <slot></slot>
    <div>
      <i18n path="footer.supportText" tag="div"><br /></i18n>
    </div>
    <div class="mt-4 mt-sm-2">
      <a
        class="text--primary"
        title="Support E-Mail"
        :href="`mailto:${
          $config.isMaBuBuild
            ? $t('commons.supportMailMabu')
            : $t('commons.supportMail')
        }`"
        >{{
          $config.isMaBuBuild
            ? $t('commons.supportMailMabu')
            : $t('commons.supportMail')
        }}</a
      >
    </div>
    <div v-if="$t('footer.telefonTechnical')" class="mt-2 mt-sm-2">
      <a :href="$t('footer.telefonTechnical')" class="link-normal">{{
        $t('footer.telefon')
      }}</a>
    </div>
    <div v-if="$t('footer.openingTime')" class="mt-5 mt-sm-3">
      {{ $t('footer.openingTime') }}
    </div>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
export default {
  name: 'FooterContactInfo',
}
</script>
