<template>
  <div class="app-bar-category-card-type-normal">
    <ul class="app-bar-category-card-type-normal__left">
      <li v-for="child in routes" :key="child.id">
        <nuxt-link
          :to="child.url"
          :class="{ 'nuxt-link-active': child.isActive }"
          >{{ child.name }}</nuxt-link
        >
      </li>
    </ul>
    <div class="app-bar-category-card-type-normal__right">
      <nuxt-link :to="bigAction.to" class="text-decoration-none">
        <base-avatar
          v-show="shouldShowBigActionButton"
          class="app-bar-category-card-type-normal__action"
          :style="bigAction.avatarStyle"
        >
          <base-image
            v-if="bigAction.imageUrl"
            :url="bigAction.imageUrl"
            :style="bigAction.actionStyle"
            loading="lazy"
            :alt="bigAction.text"
            height="250"
            width="250"
            :init-width="250"
          />
          <div
            class="d-flex justify-center align-center w-full h-full text-h1"
            style="position: absolute"
            :style="bigAction.actionStyle"
          >
            {{ bigAction.text }}
          </div>
        </base-avatar>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { getCategoryCustomField, isCategoryCustomFieldEnabled } from '~/helpers'
import {
  ATTRIBUTE_CATEGORY_BIG_ACTION_COLOR,
  ATTRIBUTE_CATEGORY_BIG_ACTION_ENABLE,
  ATTRIBUTE_CATEGORY_BIG_ACTION_IMAGE_URL,
  ATTRIBUTE_CATEGORY_BIG_ACTION_TEXT,
  ATTRIBUTE_CATEGORY_BIG_ACTION_TEXT_COLOR,
  ATTRIBUTE_CATEGORY_BIG_ACTION_URL,
} from '~/services/cfAttributes'
import BaseAvatar from '~/components/base/avatar/BaseAvatar'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'AppBarCategoryCardTypeNormal',
  components: { BaseImage, BaseAvatar },
  props: {
    category: {
      type: Object,
      required: true,
    },
    routes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    bigAction() {
      const actionColor = this.getCustomField(
        ATTRIBUTE_CATEGORY_BIG_ACTION_COLOR,
        '#fdf5f0'
      )

      const imageUrl = this.getCustomField(
        ATTRIBUTE_CATEGORY_BIG_ACTION_IMAGE_URL
      )

      return {
        to: this.getCustomField(ATTRIBUTE_CATEGORY_BIG_ACTION_URL, '/'),
        text: this.getCustomField(ATTRIBUTE_CATEGORY_BIG_ACTION_TEXT),
        imageUrl,
        avatarStyle: {
          background: actionColor,
        },
        actionStyle: {
          color: this.getCustomField(
            ATTRIBUTE_CATEGORY_BIG_ACTION_TEXT_COLOR,
            '#fff'
          ),
          background: imageUrl ? undefined : actionColor,
        },
      }
    },
    shouldShowBigActionButton() {
      return isCategoryCustomFieldEnabled(
        this.category,
        ATTRIBUTE_CATEGORY_BIG_ACTION_ENABLE
      )
    },
  },
  methods: {
    getCustomField(attr, defaultValue = null) {
      return getCategoryCustomField(this.category, attr, defaultValue)
    },
  },
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-category-card-type-normal {
  padding: 40px 72px;
  display: flex;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    padding: 40px 50px;
  }

  &__left {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    list-style: none;
    align-content: flex-start;

    li {
      flex: 0 0 50%;
      max-width: 50%;
      padding-bottom: 16px;
    }

    a {
      font-size: 15px;
      text-decoration: none;
      letter-spacing: 0.45px;
      color: map-get($shades, 'grey-darken-4');

      &:hover {
        color: map-get($shades, 'grey-darken-2');
      }

      &.nuxt-link-active {
        color: map-get($shades, 'frontend-primary');
      }
    }
  }

  &__right {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  &__action {
    height: 250px;
    min-width: 250px;
    width: 250px;

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      height: 200px;
      min-width: 200px;
      width: 200px;
    }
  }
}
</style>
