import { Media } from '~/commons/interfaces/models/content/media/Media'

/**
 * get the thumbnail image URL with the smallest width but not smaller as the given param minWidth
 *
 * @beta
 */
export function getSmallestMediaThumbnailUrl(
  media: Media,
  minWidth: number = 0
): string {
  const thumbnailImage = media.thumbnails?.reduce((res: any, thumb) => {
    const resWidth = res !== null ? parseInt(res.width) : null
    const thumbWidth = parseInt(thumb.width)

    return resWidth === null || minWidth > resWidth
      ? minWidth > thumbWidth
        ? null
        : thumb
      : thumbWidth < resWidth && minWidth <= thumbWidth
      ? thumb
      : res
  }, null)

  return thumbnailImage?.url || media.url
}
