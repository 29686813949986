<template>
  <a
    v-if="hasTrustedShops"
    class="trusted_shops_image d-block"
    :href="href"
    rel="nofollow"
    ><img
      :src="src"
      width="190"
      height="191"
      loading="lazy"
      alt="Trusted Shops"
  /></a>
</template>

<script>
export default {
  name: 'FooterTrustedShops',
  computed: {
    hasTrustedShops() {
      return !!this.$config.trustedShopsId
    },
    href() {
      if (this.$i18n.locale === 'fr-FR') {
        return `https://www.trustedshops.fr/evaluation/info_${this.$config.trustedShopsId}.html`
      } else {
        return `https://www.trustedshops.de/bewertung/info_${this.$config.trustedShopsId}.html`
      }
    },
    src() {
      return `https://www.trustedshops.com/bewertung/widget/widgets/${this.$config.trustedShopsId}.gif`
    },
  },
}
</script>
