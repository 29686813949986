import { reactive } from '@vue/composition-api'
import {
  PaymentServiceBeforeOrderResult,
  PaymentServiceInterface,
  PaymentServiceOptionsInterface,
} from '~/services/payments/PaymentServiceInterface'
import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import { ApplicationVueContext } from '~/composables/appContext'
import { createFieldInput } from '~/services/FieldInputFactory'
import { ShopwareApiInstance } from '~/shopware-6-client/apiService'

export interface KlarnaServiceBeforeOrderResult
  extends PaymentServiceBeforeOrderResult {}

export const createKlarnaService = (
  _: ApplicationVueContext
): PaymentServiceInterface => {
  const componentName: string = 'CheckoutPaymentKlarna'

  const isSupported = (payment: PaymentMethod) => {
    // @ts-ignore
    return payment?.shortName === 'klarna_payments_payment_handler'
  }

  const inputFields = {
    // authorization_token
    authorization_token: reactive(
      createFieldInput({ name: 'authorization_token', rules: '' })
    ),
    cartHash: reactive(
      createFieldInput({ name: 'cartHash', rules: 'required' })
    ),
    customer_data: reactive(
      createFieldInput({ name: 'customer_data', rules: 'required' })
    ),
  }

  const beforeOrderHook = async (
    _: ApplicationVueContext,
    __: ShopwareApiInstance | undefined
  ): Promise<KlarnaServiceBeforeOrderResult> => {
    return await new Promise((resolve) => {
      resolve({
        isSuccessful: true,
        payload: null,
      })
    })
  }

  const getHandlePaymentPayload = () => {
    return {
      klarnaAuthorizationToken: inputFields.authorization_token.value || '',
      klarnaCartHash: inputFields.cartHash.value || '',
      klarnaCustomerData: JSON.stringify(inputFields.customer_data.value || ''),
    }
  }

  const getOptions = (): PaymentServiceOptionsInterface => ({})

  return {
    componentName,
    isSupported,
    inputFields,
    beforeOrderHook,
    getHandlePaymentPayload,
    getOptions,
  }
}
