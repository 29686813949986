<template>
  <product-troublemaker
    class="d-flex justify-start align-start"
    style="overflow: hidden"
  >
    <cms-util-link :url="url" style="width: 100%" @click.native="uiEventClick">
      <base-image
        v-if="media"
        style="width: 100%; height: auto"
        loading="lazy"
        alt="troublemaker-1"
        height="413px"
        width="225px"
        :media="media"
        :init-width="300"
        :proxy-srcset="[200, 300, 400]"
        use-media-srcset
        sizes="
        (min-width: 1264px) 224px,
        (min-width: 960px) calc(22vw - 24px),
        (min-width: 600px) calc(calc(100vw - 96px) / 3 ),
        50vw
      "
      />
    </cms-util-link>
  </product-troublemaker>
</template>

<script>
import ProductTroublemaker from '~/components/product/troublemaker/ProductTroublemaker'
import { useUiEvent } from '~/composables'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'ProductTroublemakerContainer',
  components: { BaseImage, ProductTroublemaker },
  props: {
    troublemaker: {
      type: Object,
      required: true,
    },
  },
  setup(props, { root }) {
    return {
      uiEventClick: useUiEvent(
        root,
        'troublemaker-' + props.troublemaker.position,
        'listing'
      ),
    }
  },
  computed: {
    url() {
      return this.troublemaker.translated.url
    },
    media() {
      return this.troublemaker.media
    },
  },
}
</script>
