import { effectScope } from '@nuxtjs/composition-api'
import { INTERCEPTOR_KEYS, useIntercept, useUser } from '~/composables'

export default ({ app }) => {
  const scope = effectScope()

  scope.run(() => {
    const { intercept } = useIntercept(app)
    const { user, isLoggedIn } = useUser(app)

    const setUser = () => {
      if (isLoggedIn.value) {
        app.$sentry.setUser({
          id: user.value.id,
          email: user.value.email,
        })
      } else {
        app.$sentry.setUser(null)
      }
    }

    intercept(INTERCEPTOR_KEYS.CLIENT_INITIALISATION_FINISH, () => {
      setUser()
    })

    intercept(INTERCEPTOR_KEYS.USER_LOGIN, () => {
      setUser()
    })
    intercept(INTERCEPTOR_KEYS.USER_LOGOUT, () => {
      setUser()
    })
    intercept(INTERCEPTOR_KEYS.USER_REGISTER, () => {
      setUser()
    })
  })
}
