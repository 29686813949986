<template>
  <div v-if="shouldShow">
    <div
      :style="contentStyle"
      class="marketing-promotion-hint text-body-2"
      @click="handleHintClick"
    >
      <base-utils-copy-to-clipboard
        :value="voucherCode"
        :disable="!isCopyToClipboardEnabled"
        :success-message="$t('notification.siteCart.voucherCodeCopied')"
      >
        {{ content }}
      </base-utils-copy-to-clipboard>
      <base-button
        v-if="closeButton"
        icon
        style="position: absolute; top: 5px; right: 5px; color: inherit"
        @click.prevent="hide"
      >
        <base-icon mdi="close"></base-icon>
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseIcon from '~/components/base/icon/BaseIcon'
import { useMarketingPromotionHint } from '~/composables/logic/useMarketingPromotionHint'
import BaseUtilsCopyToClipboard from '~/components/base/utils/BaseUtilsCopyToClipboard.vue'
import { useUiEvent } from '~/composables'

export default {
  name: 'MarketingPromotionHint',
  components: { BaseUtilsCopyToClipboard, BaseIcon },
  props: {
    closeButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const {
      contentStyle,
      shouldShow,
      content,
      voucherCode,
      isCopyToClipboardEnabled,
      hide,
    } = useMarketingPromotionHint(root)

    const uiEvent = useUiEvent(root, 'promotion_hint', 'cart_overlay')

    function handleHintClick() {
      uiEvent()
    }

    return {
      contentStyle,
      content,
      shouldShow,
      voucherCode,
      isCopyToClipboardEnabled,
      hide,
      handleHintClick,
    }
  },
}
</script>

<style lang="scss">
.marketing-promotion-hint {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  text-decoration: none;
}
</style>
