import { effectScope } from '@nuxtjs/composition-api'
import { Capacitor } from '@capacitor/core'
import { INTERCEPTOR_KEYS, useIntercept, useUser } from '~/composables'
import {
  getProductLineItems,
  getProductName,
  getProductRealPriceNet,
  sumLineItemProductQuantity,
} from '~/helpers'
import { COOKIES } from '~/logic'

export default ({ app }) => {
  if (Capacitor.isNativePlatform()) {
    // Facebook tracking wird in der App gemacht

    return
  }

  const scope = effectScope()
  const currency = 'EUR'
  const CONTENT_TYPE_PRODUCT = 'product'

  function generateEventId() {
    return (Math.random() + 1).toString(36).substring(4)
  }

  scope.run(() => {
    const { intercept } = useIntercept(app)
    const { user } = useUser(app)

    const fb = {
      isInit: false,
      isLoading: false,
      enabled: !!app.$config.facebookEnable,
      debug: !!app.$config.facebookDebug,
      queue: [],

      init(id) {
        const customer = this._getCustomerData()

        this._log('init', customer)

        if (!app.$ccm.isCookieAllowed(COOKIES.GTM)) {
          return
        }

        if (this.enabled) {
          if (!this.isInit) {
            if (this.isLoading) {
              return
            }

            this.isLoading = true
            this.loadScript().then(() => {
              this._log('init', { id, customer })

              fbq('init', id, customer)
              fbq('track', 'PageView')

              this.isInit = true
              this.queue.forEach((queue) => queue())
              this.queue = []
            })
          }
        } else {
          this.isInit = true
          this.queue.forEach((queue) => queue())
          this.queue = []
        }
      },

      track(name, data, eventID = generateEventId()) {
        this._trackHandler('track', name, data, {
          eventID,
        })
      },

      trackCustom(name, data, eventID = generateEventId()) {
        this._trackHandler('trackCustom', name, data, {
          eventID,
        })
      },

      loadScript() {
        return new Promise((resolve) =>
          setTimeout(() => {
            ;(function (f, b, e, v, n, t, s) {
              if (f.fbq) return
              n = f.fbq = function () {
                n.callMethod
                  ? n.callMethod.apply(n, arguments)
                  : n.queue.push(arguments)
              }
              if (!f._fbq) f._fbq = n
              n.push = n
              n.loaded = !0
              n.version = '2.0'
              n.queue = []
              t = b.createElement(e)
              t.async = !0
              t.src = v
              s = b.getElementsByTagName(e)[0]
              s.parentNode.insertBefore(t, s)
            })(
              window,
              document,
              'script',
              'https://connect.facebook.net/en_US/fbevents.js'
            )

            resolve()
          }, 3000)
        )
      },

      _trackHandler(type, name, data, extras) {
        if (!this.isInit) {
          this.queue.push(() => this._send(type, name, data, extras))
        } else {
          this._send(type, name, data, extras)
        }
      },

      _send(type, name, data, extras) {
        if (this.enabled) {
          window.fbq(type, name, data, extras)
        }

        this._log('track', { type, name, data, extras })
      },

      _getCustomerData() {
        if (!user.value || !user.value.email) {
          return null
        }

        return {
          em: user.value.email.toLowerCase(),
          external_id: user.value.email.toLowerCase(),
        }
      },

      _log(...args) {
        if (this.debug) {
          const logStyle =
            'background: #2E495E;border-radius: 0.5em;color: white;font-weight: bold;padding: 2px 0.5em;'

          // eslint-disable-next-line
          console.log('%cFB', logStyle, ...args)
        }
      },
    }

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_PDP, ({ product }, { eventId }) => {
      const eventData = {
        content_type: CONTENT_TYPE_PRODUCT,
        content_name: getProductName({ product }),
        contents: [
          {
            id: product.productNumber,
            quantity: 1,
          },
        ],
        currency,
        value: getProductRealPriceNet(product),
      }

      fb.track('ViewContent', eventData, eventId)
      fb.trackCustom('ViewProduct', eventData, eventId)
    })

    intercept(INTERCEPTOR_KEYS.ADD_TO_CART, ({ product }, { eventId }) => {
      const eventData = {
        content_type: CONTENT_TYPE_PRODUCT,
        content_name: getProductName({ product }),
        value: getProductRealPriceNet(product),
        currency,
        contents: [
          {
            id: product.productNumber,
            quantity: 1,
          },
        ],
      }

      fb.track('AddToCart', eventData, eventId)
    })

    intercept(INTERCEPTOR_KEYS.ORDER_PLACE, ({ order }) => {
      const products = getProductLineItems(order.lineItems)
      const eventData = {
        content_type: CONTENT_TYPE_PRODUCT,
        value: order.price.netPrice,
        currency,
        contents: products.map((lineItem) => ({
          id: lineItem.payload.productNumber,
          quantity: lineItem.quantity,
        })),
        num_items: sumLineItemProductQuantity(order.lineItems),
      }

      fb.track('Purchase', eventData, order.orderNumber)
    })

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_CHECKOUT, ({ cart }, { eventId }) => {
      const products = getProductLineItems(cart.lineItems)
      const eventData = {
        content_type: CONTENT_TYPE_PRODUCT,
        value: cart.price?.netPrice,
        currency,
        contents: products.map((lineItem) => ({
          id: lineItem.payload.productNumber,
          quantity: lineItem.quantity,
        })),
        num_items: sumLineItemProductQuantity(cart.lineItems),
      }

      fb.track('InitiateCheckout', eventData, eventId)
    })

    intercept(INTERCEPTOR_KEYS.PAGE_VIEW_SEARCH, ({ term }, { eventId }) => {
      fb.track(
        'Search',
        {
          search_string: term,
        },
        eventId
      )
    })

    intercept(INTERCEPTOR_KEYS.ADD_TO_WISHLIST, ({ product }, { eventId }) => {
      if (!product) {
        return
      }

      const eventData = {
        content_type: CONTENT_TYPE_PRODUCT,
        content_name: getProductName({ product }),
        value: getProductRealPriceNet(product),
        currency,
        contents: [
          {
            id: product.productNumber,
            quantity: 1,
          },
        ],
      }

      fb.track('AddToWishlist', eventData, eventId)
    })

    intercept(INTERCEPTOR_KEYS.CLIENT_INITIALISATION_FINISH, () => {
      fb.init(app.$config.facebookId)
    })

    intercept(INTERCEPTOR_KEYS.COOKIE_CHANGES, () => {
      fb.init(app.$config.facebookId)
    })

    intercept(INTERCEPTOR_KEYS.USER_LOGIN, () => {
      fb.init(app.$config.facebookId)
    })
  })
}
