<template>
  <ul class="app-bar-extension">
    <li
      v-for="category in mappedNavigations"
      :key="category.id"
      class="app-bar-extension__list"
    >
      <nuxt-link
        :to="category.url"
        :class="{ 'nuxt-link-active': category.isActive }"
        >{{ category.name }}</nuxt-link
      >
      <app-bar-category-card
        v-if="category.children.length"
        class="app-bar-category-card"
        :category="category"
        :breadcrumbs="breadcrumbs"
      ></app-bar-category-card>
    </li>
  </ul>
</template>

<script>
import { useBreadcrumbs, useMainNavigation } from '~/composables'
import {
  CATEGORY_HIDE_TYPE_DESKTOP,
  getCategoryUrl,
  isCategoryHidden,
} from '~/helpers'
import AppBarCategoryCard from '~/components/app-bar/AppBarCategoryCard'

export default {
  name: 'AppBarExtension',
  components: { AppBarCategoryCard },
  setup(_, { root }) {
    const { breadcrumbs } = useBreadcrumbs(root)
    const { navigationElements } = useMainNavigation(root)

    return { breadcrumbs, navigationElements }
  },
  computed: {
    navigations() {
      return this.navigationElements || []
    },
    mappedNavigations() {
      return this.navigations
        .filter(
          (category) => !isCategoryHidden(category, CATEGORY_HIDE_TYPE_DESKTOP)
        )
        .map((navigation) => {
          const url = getCategoryUrl(navigation)

          return {
            ...navigation,
            url,
            name: navigation.translated?.name || navigation.name,
            isActive: this.breadcrumbs.some(({ link }) => link === url),
          }
        })
    },
  },
  watch: {
    $route() {
      this.setDisableClass(true)

      setTimeout(this.setDisableClass, 25)
    },
  },
  mounted() {
    this.setDisableClass(false)
  },
  methods: {
    setDisableClass(enable) {
      this.$el?.classList[enable ? 'add' : 'remove'](
        'app-bar-extension--disable'
      )
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-extension {
  display: flex;
  position: relative;
  list-style: none;
  justify-content: space-between;
  padding: 0 !important;

  &:not(.app-bar-extension--disable) {
    & > .app-bar-extension__list:hover {
      & > .app-bar-category-card {
        display: block !important;
      }
    }
  }

  &__list {
    > a {
      font-size: 15px;
      font-weight: 700;
      color: map-get($shades, 'grey-darken-4');
      text-transform: capitalize;
      text-decoration: none;
      position: relative;
      letter-spacing: 0.45px;
      line-height: 47px;
      padding-bottom: 15px;
      white-space: nowrap;

      &:hover {
        color: map-get($shades, 'grey-darken-2');
      }

      &.nuxt-link-active {
        color: map-get($shades, 'frontend-primary');
      }
    }
  }

  .app-bar-category-card {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    display: none;
  }
}
</style>
