<template>
  <div class="app-bar-category-card-actions beige-pastel-light">
    <nuxt-link
      v-for="action in actions"
      :key="action.to"
      :to="getTechnicalUrl(action.to)"
    >
      <base-icon x-large :mdi="action.mdi"></base-icon>
      <span class="text-body-4">{{ action.text }}</span>
    </nuxt-link>
  </div>
</template>

<script>
import { ATTRIBUTE_CATEGORY_TECHNICAL_NAME } from '~/services/cfAttributes'
import { getCategoryCustomField, getCategoryUrl } from '~/helpers'
import BaseIcon from '~/components/base/icon/BaseIcon'

export default {
  name: 'AppBarCategoryCardActions',
  components: { BaseIcon },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    actions() {
      return [
        {
          to: 'bestseller',
          mdi: 'bestseller',
          text: this.$t('commons.bestseller'),
        },
        {
          to: 'handmade',
          mdi: 'handmade',
          text: this.$t('commons.handMade'),
        },
        {
          to: 'bio',
          mdi: 'bio',
          text: this.$t('commons.bioCotton'),
        },
        {
          to: 'gift-idea',
          mdi: 'gift-idea',
          text: this.$t('commons.giftIdea'),
        },
      ]
    },
  },
  methods: {
    getTechnicalUrl(technicalName) {
      return getCategoryUrl(this.findRoute(technicalName))
    },
    findRoute(technicalName) {
      return this.categories.find((category) => {
        return (
          getCategoryCustomField(
            category,
            ATTRIBUTE_CATEGORY_TECHNICAL_NAME
          ) === technicalName
        )
      })
    },
  },
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-category-card-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 auto;
    text-decoration: none;
    color: map-get($shades, 'grey-darken-4');
    padding: 4px;

    &:hover {
      color: map-get($shades, 'beige-dark');

      > span {
        color: inherit;
      }
    }
  }
}
</style>
