import { Product } from '~/commons/interfaces/models/content/product/Product'
import { Media } from '~/commons/interfaces/models/content/media/Media'

/**
 * @beta
 */
export function getProductParentCoverMedia(product?: Product): Media | null {
  if (!product) {
    return null
  }

  return product.extensions?.bs_product_extension?.cover || product.cover?.media
}
