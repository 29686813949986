/**
 * Upgrade Guide:
 * von BS hinzugefügt bei allen Produkten:
 *  - available
 *
 * @param _
 * @param inject
 */
export default (_, inject) => {
  inject('shopwareDefaults', {
    useCms: {
      limit: 28,
      'bs-magic-includes': true,
      'no-aggregations': true,
    },
    useProductListing: {
      limit: 28,
      includes: {
        product: [
          'name',
          'ratingAverage',
          'calculatedPrice',
          'calculatedPrices',
          'calculatedListingPrice',
          'calculatedCheapestPrice',
          'cover',
          'id',
          'parentId',
          'translated',
          'options',
          'seoUrls',
          'available',
          'markAsTopseller',
          'isNew',
          'productNumber',

          'bs_product_extension',
        ],
        product_media: ['media'],
        media: ['id', 'thumbnails', 'width', 'height', 'url', 'alt', 'title'],
        calculated_price: ['unitPrice', 'quantity', 'listPrice'],
        product_group_option: ['name', 'id', 'group', 'translated'],
        product_group: ['id', 'name', 'options', 'translated'],
        property_group: ['id', 'translated', 'options'],
        property_group_option: [
          'translated',
          'id',
          'colorHexCode',
          'media',
          'group',
        ],
      },
    },
    useProductQuickSearch: {
      limit: 10,
      includes: {
        product: [
          'name',
          'ratingAverage',
          'calculatedPrice',
          'calculatedPrices',
          'calculatedListingPrice',
          'calculatedCheapestPrice',
          'cover',
          'id',
          'parentId',
          'translated.name',
          'seoUrls',
          'available',
          'productNumber',

          'bs_product_extension',
        ],
        seo_url: ['pathInfo', 'routeName', 'seoPathInfo'],
        product_media: ['media'],
        media: ['id', 'thumbnails', 'width', 'height', 'url', 'alt', 'title'],
        calculated_price: ['unitPrice', 'quantity', 'listPrice'],
      },
    },
    useListing: {
      limit: 28,
      'bs-magic-includes': true,
      'no-aggregations': true,
    },
    useProduct: {
      associations: {
        crossSellings: {
          associations: {
            assignedProducts: {
              associations: {
                product: {
                  associations: {
                    media: {},
                    cover: {},
                    seoUrls: {},
                  },
                },
              },
            },
          },
        },
      },
      includes: {
        product: [
          'name',
          'ratingAverage',
          'calculatedPrice',
          'calculatedPrices',
          'calculatedListingPrice',
          'calculatedCheapestPrice',
          'cover',
          'id',
          'parentId',
          'translated',
          'media',
          'seoUrls',
          'crossSellings',
          'available',
          'markAsTopseller',
          'isNew',
          'productNumber',
          'availableStock',

          'bs_product_extension',
        ],
        product_media: ['media'],
        media: ['url'],
        media_thumbnail: ['url', 'width', 'height', 'id'],
        calculated_price: ['unitPrice', 'quantity', 'listPrice'],
        product_group_option: ['name', 'id', 'group', 'translated'],
        product_group: ['id', 'name', 'translated'],
      },
    },
    useWishlistCustomer: {
      limit: 28,
    },
    useOrderDetails: {
      limit: 1,
      associations: {
        lineItems: {
          associations: {
            cover: {},
          },
        },
        addresses: {
          associations: {
            country: {},
          },
        },
        billingAddress: {
          associations: {
            country: {},
            salutation: {},
          },
        },
        transactions: {
          sort: '-createdAt',
          associations: {
            paymentMethod: {},
          },
        },
        deliveries: {
          associations: {
            shippingMethod: {},
            shippingOrderAddress: {
              associations: {
                country: {},
                salutation: {},
              },
            },
          },
        },
      },
    },
    useProductCrossSelling: {
      'bs-magic-includes': true,
    },
    useBlogListing: {
      limit: 28,
      'bs-magic-includes': true,
    },
  })
}
