import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import {
  createRequestTimingInterceptor,
  createResponseErrorTimingInterceptor,
  createResponseInterceptor,
  createResponseTimingInterceptor,
  errorInterceptor,
} from './interceptors'
import { ClientSettings, defaultConfig } from './settings'
import { getQueryString } from './helpers/queryParamsBuilder'
/**
 * @beta
 */
export interface ConfigChangedArgs {
  config: ClientSettings
}

/**
 * @beta
 */
export interface ShopwareApiInstance {
  onConfigChange: (fn: (context: ConfigChangedArgs) => void) => void
  config: ClientSettings
  setup: (config?: ClientSettings) => void
  update: (config?: ClientSettings) => void

  invoke: {
    post: AxiosInstance['post']
    get: AxiosInstance['get']
    put: AxiosInstance['put']
    patch: AxiosInstance['patch']
    delete: AxiosInstance['delete']
  }
  defaults: AxiosInstance['defaults']
  setExternContextTokens: (externContextToken?: string) => void
}

/**
 * Internal method for creating new instance, exported only for tests, not exported by package
 */
export function _createInstance(initialConfig: ClientSettings = {}) {
  const callbackMethods: ((context: ConfigChangedArgs) => void)[] = []
  let clientConfig: ClientSettings = {}
  const apiService: AxiosInstance = axios.create()

  function reloadConfiguration() {
    apiService.defaults.baseURL = clientConfig.endpoint
    if (clientConfig.timeout) {
      apiService.defaults.timeout =
        (typeof clientConfig.timeout === 'number' && clientConfig.timeout) ||
        (typeof clientConfig.timeout === 'string' &&
          parseInt(clientConfig.timeout)) ||
        0
    }
    apiService.defaults.headers.common['sw-include-seo-urls'] = true
    apiService.defaults.headers.common['sw-access-key'] =
      clientConfig.accessToken
    // convert SearchCriteria into query string
    apiService.defaults.paramsSerializer = getQueryString
    if (clientConfig.contextToken) {
      apiService.defaults.headers.common['sw-context-token'] =
        clientConfig.contextToken
    } else {
      delete apiService.defaults.headers.common['sw-context-token']
    }
    if (clientConfig.languageId) {
      apiService.defaults.headers.common['sw-language-id'] =
        clientConfig.languageId
    } else {
      delete apiService.defaults.headers.common['sw-language-id']
    }

    if (clientConfig.externContextTokens) {
      apiService.defaults.headers.common['bs-extern-context-tokens'] =
        clientConfig.externContextTokens
    } else {
      delete apiService.defaults.headers.common['bs-extern-context-tokens']
    }
  }

  function onConfigChange(fn: (context: ConfigChangedArgs) => void): void {
    callbackMethods.push(fn)
  }

  const setup = function (config: ClientSettings = {}): void {
    clientConfig = Object.assign(clientConfig, defaultConfig, config)
    reloadConfiguration()
  }
  setup(initialConfig)

  const update = function (
    config: ClientSettings,
    responseConfig?: AxiosResponse<AxiosRequestConfig>['config']
  ): void {
    clientConfig = Object.assign(clientConfig, config)
    if (
      process.env.NODE_ENV !== 'production' &&
      !callbackMethods.length &&
      responseConfig
    ) {
      console.warn(
        `[shopware-6-api] After calling API method ${responseConfig.url} there is no "onConfigChange" listener. See https://shopware-pwa-docs.vuestorefront.io/landing/fundamentals/security.html#context-awareness`
      )
    }
    callbackMethods.forEach((fn) => fn({ config: clientConfig }))
    reloadConfiguration()
  }

  const setExternContextTokens = (externContextTokens?: string) => {
    clientConfig.externContextTokens = externContextTokens

    reloadConfiguration()
  }

  const invoke = {
    post: apiService.post,
    put: apiService.put,
    get: apiService.get,
    patch: apiService.patch,
    delete: apiService.delete,
  }

  if (
    clientConfig.timing &&
    ((clientConfig.timing.server && process.server) ||
      (clientConfig.timing.client && !process.server))
  ) {
    apiService.interceptors.request.use(createRequestTimingInterceptor())

    apiService.interceptors.response.use(
      createResponseTimingInterceptor(),
      createResponseErrorTimingInterceptor()
    )
  }

  apiService.interceptors.response.use(
    createResponseInterceptor(update),
    errorInterceptor
  )

  return {
    onConfigChange,
    config: clientConfig,
    setup,
    update,
    invoke,
    defaults: apiService.defaults,
    setExternContextTokens,
  }
}

/**
 *
 * @beta
 */
export function createInstance(
  initialConfig: ClientSettings = {}
): ShopwareApiInstance {
  const {
    onConfigChange,
    config,
    setup,
    update,
    invoke,
    defaults,
    setExternContextTokens,
  } = _createInstance(initialConfig)

  return {
    onConfigChange,
    config,
    setup,
    update: (config: ClientSettings = {}): void => {
      update(config)
    },
    invoke,
    defaults,
    setExternContextTokens,
  }
}

export const defaultInstance = createInstance()
