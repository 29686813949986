<template>
  <div class="base-responsive product-image" :class="classes" v-on="$listeners">
    <div class="base-responsive__sizer" style="padding-bottom: 130%"></div>
    <div class="base-responsive__content">
      <base-image
        :media="imageMedia"
        class="product-image__img"
        :alt="altTag"
        :loading="loading"
        :sizes="sizes"
        :init-width="initWidth"
        :proxy-srcset="proxySrcset"
        :use-media-srcset="useMediaSrcset"
      ></base-image>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { getProductName } from '~/helpers'
import BaseImage from '~/components/base/image/BaseImage'
import { getProductFallbackImageUrl } from '~/helpers/product/getProductFallbackImageUrl'

export default {
  name: 'ProductImage',
  components: { BaseImage },
  props: {
    src: {
      type: String,
      default: undefined,
    },
    alt: {
      type: String,
      default: undefined,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    /**
     * Wenn kein src order alt prop angegeben wurde, werden die Daten aus dem produkt genommen
     */
    product: {
      type: Object,
      default: () => null,
    },
    media: {
      type: Object,
      default: () => null,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    effect: {
      type: Boolean,
      default: false,
    },
    sizes: {
      type: String,
      default: '',
    },
    proxySrcset: {
      type: Array,
      default: null,
    },
    initWidth: {
      type: Number,
      default: 400,
    },
    useMediaSrcset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fallbackMedia() {
      return {
        url: `${this.$config.appUrl}${getProductFallbackImageUrl()}`,
      }
    },
    imageMedia() {
      if (this.src) {
        return {
          url: this.src,
        }
      } else if (this.product) {
        return this.product.cover?.media || this.fallbackMedia
      } else if (this.media) {
        return this.media || this.fallbackMedia
      } else {
        return this.fallbackMedia
      }
    },
    altTag() {
      return (
        this.alt ||
        (this.product &&
          getProductName({
            product: this.product,
          })) ||
        undefined
      )
    },
    classes() {
      return {
        'product-image--gradient': this.gradient,
        'product-image--effect': this.effect,
      }
    },
  },
}
</script>
