<template>
  <client-only>
    <error-view v-if="statusCode !== 404"></error-view>
    <error-cms-view v-else></error-cms-view>
  </client-only>
</template>

<script>
import ErrorView from '~/components/views/ErrorView'
import ErrorCmsView from '~/components/views/ErrorCmsView.vue'

export default {
  name: 'Error',
  components: { ErrorCmsView, ErrorView },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  computed: {
    statusCode() {
      return this.error?.statusCode || 500
    },
  },
}
</script>
