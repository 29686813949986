import { Breadcrumb, CaptureContext } from '@sentry/types'
import { Severity } from '@sentry/types/dist/severity'
import { Capacitor } from '@capacitor/core'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { ClientApiError } from '~/commons/interfaces/errors/ApiError'

/**
 * @beta
 */
export interface IUseSentry {
  captureException: (
    exception: any,
    methode?: string,
    captureContext?: CaptureContext
  ) => void
  captureClientApiError: (
    message: string,
    error: ClientApiError | Error,
    input?: any
  ) => void
  captureMessage: (message: string, input: any, level?: Severity) => void
  addBreadcrumb: (payload: Breadcrumb) => void
}

export interface SentryScopeContext {
  module: 'composable'
  name: string
}

function extractSentryData(error: ClientApiError, clientMessage: string) {
  const { code, detail, title } = error.messages?.[0] ?? {}

  let message

  if (error.statusCode === 408 && detail?.includes('timeout of')) {
    message = detail
  } else if (String(code) === '0') {
    message = `${clientMessage} ${detail}`
  } else {
    message = `${clientMessage} ${code}`
  }

  return {
    message,
    tags: {
      'api.status': error.statusCode,
      'api.code': code,
      'api.title': title,
    },
  }
}

/**
 * @beta
 */
export const useSentry = (
  rootContext: ApplicationVueContext,
  sentryContext: SentryScopeContext
): IUseSentry => {
  // Durch lazy Loading wichtig, immer die neuste zu nehmen
  const getSentryInstance = () => {
    const { sentry } = getApplicationContext(rootContext, 'useSentry')

    return sentry
  }

  const getTags = () => ({
    'bs.module': sentryContext.module,
    'bs.name': sentryContext.name,
    'bs.platform': Capacitor.getPlatform(),
  })

  const captureException = (
    exception: any,
    methode?: string,
    captureContext?: CaptureContext
  ) => {
    if (!captureContext) {
      captureContext = {
        tags: {
          ...getTags(),
          methode,
        },
      }
    }

    getSentryInstance().captureException(exception, captureContext)
  }

  const captureClientApiError = (
    message: string,
    error: ClientApiError | Error,
    input?: any
  ) => {
    if (error instanceof Error) {
      getSentryInstance().captureException(error, {
        tags: {
          ...getTags(),
        },
      })
    } else {
      const { message: title, tags } = extractSentryData(error, message)

      getSentryInstance().captureMessage(title, {
        level: Severity.Error,
        extra: {
          statusCode: error.statusCode,
          errorMessages: error.messages,
          input,
        },
        tags: {
          ...getTags(),
          ...tags,
        },
      })
    }
  }

  const captureMessage = (
    message: string,
    input: any = {},
    level = Severity.Error
  ) => {
    getSentryInstance().captureMessage(message, {
      level,
      extra: {
        ...input,
      },
      tags: {
        ...getTags(),
      },
    })
  }

  const addBreadcrumb = (payload: Breadcrumb) => {
    getSentryInstance().addBreadcrumb(payload)
  }

  return {
    captureException,
    captureClientApiError,
    captureMessage,
    addBreadcrumb,
  }
}
