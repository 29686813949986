import { ShopwareSearchParams } from '~/commons/interfaces/search/SearchCriteria'
import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'
import { getBlogListingEndpoint } from '~/shopware-6-client'
import { BlogListingResult } from '~/commons/interfaces/response/BlogListingResult'

/**
 * @throws ClientApiError
 * @beta
 */
export const getCategoryBlogs = async function (
  categoryId: string,
  criteria?: ShopwareSearchParams,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<BlogListingResult> {
  const resp = await contextInstance.invoke.post(
    `${getBlogListingEndpoint(categoryId)}`,
    criteria
  )
  return resp.data
}
