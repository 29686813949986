import { reactive } from '@vue/composition-api'
import {
  PaymentAfterOrderHookResult,
  PaymentServiceBeforeOrderResult,
  PaymentServiceInterface,
  PaymentServiceOptionsInterface,
} from '~/services/payments/PaymentServiceInterface'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { PaymentMethod } from '~/commons/interfaces/models/checkout/payment/PaymentMethod'
import { createFieldInput } from '~/services/FieldInputFactory'
import { Order } from '~/commons/interfaces/models/checkout/order/Order'
import { paypalPuiPaymentInstructions } from '~/shopware-6-client'

export const createPayPalPuiService = (
  _: ApplicationVueContext
): PaymentServiceInterface => {
  const componentName: string = 'CheckoutPaymentPaypalPui'

  const isSupported = (payment: PaymentMethod) => {
    // @ts-ignore
    return payment?.shortName === 'p_u_i_handler'
  }

  const inputFields = {
    payPalPuiCustomerPhoneNumber: reactive(
      createFieldInput({ name: 'payPalPuiCustomerPhoneNumber', rules: '' })
    ),
    payPalPuiCustomerBirthdayDay: reactive(
      createFieldInput({ name: 'payPalPuiCustomerBirthdayDay', rules: '' })
    ),
    payPalPuiCustomerBirthdayMonth: reactive(
      createFieldInput({ name: 'payPalPuiCustomerBirthdayMonth', rules: '' })
    ),
    payPalPuiCustomerBirthdayYear: reactive(
      createFieldInput({ name: 'payPalPuiCustomerBirthdayYear', rules: '' })
    ),
    payPalPuiFraudnetSessionId: reactive(
      createFieldInput({ name: 'payPalPuiFraudnetSessionId', rules: '' })
    ),
  }

  const beforeOrderHook = async (
    _: ApplicationVueContext
  ): Promise<PaymentServiceBeforeOrderResult> => {
    return await new Promise((resolve) => {
      resolve({
        isSuccessful:
          inputFields.payPalPuiFraudnetSessionId.value !== undefined,
        payload: null,
      })
    })
  }

  const afterOrderHook = (
    order: Order
  ): Promise<PaymentAfterOrderHookResult> => {
    return new Promise((resolve) =>
      resolve({
        type: 'polling',
        poll: async () => {
          const { apiInstance } = getApplicationContext(_)

          for (let i = 0; i < 10; i++) {
            try {
              await paypalPuiPaymentInstructions(
                order.transactions?.[0]?.id!,
                apiInstance
              )

              return true
            } catch (e) {
              // Alles okay
              if (e.statusCode === 417) {
                await new Promise((resolve) => setTimeout(resolve, 2000))
              } else {
                throw e
              }
            }
          }

          return true
        },
      })
    )
  }

  const getHandlePaymentPayload = () => {
    return {
      payPalPuiCustomerPhoneNumber:
        inputFields.payPalPuiCustomerPhoneNumber.value || '',
      payPalPuiCustomerBirthday: {
        day: inputFields.payPalPuiCustomerBirthdayDay.value || '',
        month: inputFields.payPalPuiCustomerBirthdayMonth.value || '',
        year: inputFields.payPalPuiCustomerBirthdayYear.value || '',
      },
      payPalPuiFraudnetSessionId:
        inputFields.payPalPuiFraudnetSessionId.value || '',
    }
  }

  const getOptions = (): PaymentServiceOptionsInterface => ({})

  return {
    componentName,
    isSupported,
    inputFields,
    beforeOrderHook,
    afterOrderHook,
    getHandlePaymentPayload,
    getOptions,
  }
}
