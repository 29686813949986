<script>
import { getSmallestMediaThumbnailUrl } from '~/helpers'
import { buildProxyImageUrl } from '~/helpers/proxy/buildProxyImageUrl'

export default {
  name: 'BaseImage',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'img',
    },
    url: {
      type: String,
      default: '',
    },
    media: {
      type: Object,
      default: null,
    },
    useMediaSrcset: {
      type: Boolean,
      default: false,
    },
    initWidth: {
      type: Number,
      default: 0,
    },
    initHeight: {
      type: Number,
      default: 0,
    },
    disableProxy: {
      type: Boolean,
      default: false,
    },
    proxySrcset: {
      type: Array,
      default: undefined,
    },
  },
  render(h, { props, data, parent }) {
    const rawSrc = props.media ? props.media.url : props.url
    let src = rawSrc
    let srcset = ''

    if (!props.disableProxy) {
      src = buildProxyImageUrl(parent.$config, src, {
        w: props.initWidth,
        h: props.initHeight,
      })

      if (props.proxySrcset) {
        srcset = props.proxySrcset
          .map(
            (size) =>
              buildProxyImageUrl(parent.$config, rawSrc, { w: size }) +
              ` ${size}w`
          )
          .join(', ')
      }
    } else if (props.media) {
      if (props.useMediaSrcset) {
        srcset = (props.media?.thumbnails || [])
          .map((thumbnail) => `${thumbnail.url} ${thumbnail.width}w`)
          .join(', ')
      }

      if (props.initWidth) {
        src = getSmallestMediaThumbnailUrl(props.media, props.initWidth)
      } else if (props.initHeight) {
        src = getSmallestMediaThumbnailUrl(props.media, props.initHeight)
      }
    }

    const attrs = {
      ...(data.attrs || {}),
      src,
    }

    if (srcset !== undefined) {
      attrs.srcset = srcset
    }

    return h(props.tag, {
      ...data,
      attrs,
    })
  },
}
</script>
