import Vue from 'vue'
import { computed, ref } from '@nuxtjs/composition-api'
import { ApplicationVueContext } from '~/composables/appContext'
import { usePageConfig } from '~/composables/logic/usePageConfig'
import { getActivePageConfigs } from '~/helpers/pageConfig/getActivePageConfigs'
import { useCart } from '~/composables'
import { PageConfigPromotionHint } from '~/shopware-6-client/plugins/services/bsPageConfig'

export function useMarketingPromotionHint(rootContext: ApplicationVueContext) {
  const { pageConfig } = usePageConfig(rootContext)
  const { productTotalPrice, appliedPromotionCodes } = useCart(rootContext)

  const show = ref(true)

  const promotionHintEntry = computed<PageConfigPromotionHint | null>(() => {
    const configs = getActivePageConfigs(pageConfig.value?.promotionHints || [])

    return (
      configs.filter((config) => {
        let condition = true

        condition &&= productTotalPrice.value < config.hideCartValue

        condition &&= productTotalPrice.value >= config.showCartValue

        if (config.hideHasPromotion && appliedPromotionCodes.value.length > 0) {
          condition = false
        }

        if (config.hideHasCodes && config.hideHasCodes.length > 0) {
          condition &&=
            appliedPromotionCodes.value.filter((promotion) =>
              // @ts-ignore
              config.hideHasCodes.includes(promotion.payload.code)
            ).length === 0
        }

        if (config.showHasCodes && config.showHasCodes.length > 0) {
          condition &&=
            appliedPromotionCodes.value.filter((promotion) =>
              // @ts-ignore
              config.showHasCodes.includes(promotion.payload.code)
            ).length > 0
        }

        return condition
      })?.[0] || null
    )
  })

  const shouldShow = computed(() => {
    return !!promotionHintEntry.value && show.value
  })

  const restValue = computed(() => {
    const val =
      (promotionHintEntry.value?.promotionMinValue || 0.0) -
      productTotalPrice.value

    return val <= 0.0 ? 0.0 : val
  })

  const contentStyle = computed(() => {
    const backgroundColor = promotionHintEntry.value?.translated.backgroundColor
    const textColor = promotionHintEntry.value?.translated.textColor

    return {
      backgroundColor: backgroundColor || '#CE0F69',
      color: textColor || '#FFFFFF',
      url: promotionHintEntry.value?.translated.url,
    }
  })

  const replaceContent = (content: string) => {
    return content.replace('{d}', Vue.filter('price')(restValue.value))
  }

  const content = computed(() => {
    let str = replaceContent(promotionHintEntry.value?.translated.content ?? '')

    if (restValue.value <= 0.0) {
      if (promotionHintEntry.value?.translated.finalContent) {
        str = replaceContent(promotionHintEntry.value?.translated.finalContent)
      }
    }

    return str
  })

  const voucherCode = computed(() => {
    return promotionHintEntry.value?.voucherCode
  })

  const isCopyToClipboardEnabled = computed(() => {
    if (!voucherCode.value) {
      return false
    }

    return restValue.value > 0.0
      ? !!promotionHintEntry.value?.isCopyVoucherCodeEnabled
      : !!promotionHintEntry.value?.isCopyVoucherCodeFinalEnabled
  })

  const hide = () => {
    show.value = false
  }

  return {
    promotionHintEntry,
    shouldShow,
    contentStyle,
    content,
    voucherCode,
    isCopyToClipboardEnabled,

    hide,
  }
}
