import { effectScope } from '@nuxtjs/composition-api'
import { useCart } from '~/composables'

export default async function ({ redirect, app }) {
  const scope = effectScope()

  await scope.run(async () => {
    const { count, refreshCart } = useCart(app)

    await refreshCart()

    if (count.value < 1) {
      redirect(app.localePath('/cart'))
    }
  })

  scope.stop()
}
