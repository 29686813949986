<template>
  <v-menu
    v-model="isSuggestBoxOpen"
    class="app-bar-search__menu"
    :close-on-content-click="false"
    :close-on-click="false"
    offset-y
    max-width="430px"
    allow-overflow
    max-height="80vh"
    :content-class="contentClass"
    eager
  >
    <template #activator="{ attrs }">
      <v-text-field
        ref="searchBar"
        v-model="typingQuery"
        dense
        class="app-bar-search__search-field ml-4 ml-md-auto text-body-2 pl-n2"
        :placeholder="$t('commons.search')"
        color="grey-darken-2"
        single-line
        :aria-label="$t('commons.search')"
        hide-details
        v-bind="attrs"
        autocomplete="off"
        @keyup.enter="performSearch"
        @focus="handleFocus"
        @blur="handleBlur"
      >
        <template #prepend>
          <base-icon mdi="magnify"></base-icon>
        </template>
        <template #append>
          <base-icon
            v-if="typingQuery"
            color="grey-darken-4"
            small
            :aria-label="$t('commons.focusSearch')"
            mdi="close"
            @click="handleReset"
          ></base-icon>
        </template>
      </v-text-field>
    </template>
    <app-bar-search-result-card
      v-if="isSuggestBoxOpen"
      v-click-outside="{
        handler: handleClickOutside,
        include: handleClickAwayInclude,
      }"
      :total="getTotal"
      :categories="searchCategories"
      :products="getProducts"
      :blogs="searchBlogs"
      :typing-query="typingQuery"
      :loading="loading"
      @click:close="handleMobileClose"
      @click:search="performSearch"
    ></app-bar-search-result-card>
  </v-menu>
</template>

<script>
import { computed, ref, watch } from '@nuxtjs/composition-api'
import { NAVIGATION_SEARCH_STATE } from '~/services/UiStates'
import {
  getApplicationContext,
  INTERCEPTOR_KEYS,
  useIntercept,
  useProductQuickSearch,
  useUiEvent,
  useUIState,
} from '~/composables'
import { debounce, getExtensionsElements } from '~/helpers'
import BaseIcon from '~/components/base/icon/BaseIcon'

export default {
  name: 'AppBarSearch',
  components: {
    BaseIcon,
    AppBarSearchResultCard: () =>
      import('~/components/app-bar/search/AppBarSearchResultCard'),
  },
  setup(_, { root }) {
    const { switchState: toggleNavigationSearch, isOpen: isSearchActive } =
      useUIState(root, NAVIGATION_SEARCH_STATE)

    const { broadcast } = useIntercept(root)
    const { config } = getApplicationContext(root)

    const {
      searchTerm,
      search,
      getProducts,
      getTotal,
      loading,
      getCurrentListing,
    } = useProductQuickSearch(root)

    const typingQuery = ref('')
    const isSuggestBoxOpen = ref(false)

    const performSuggestSearch = debounce((value) => {
      searchTerm.value = value
      if (searchTerm.value.length > 0) {
        search({
          limit: 15,
          'with-search-extension': true,
        })
        isSuggestBoxOpen.value = true
      } else {
        isSuggestBoxOpen.value = false
      }
    }, 300)

    watch(getProducts, (products) => {
      broadcast(INTERCEPTOR_KEYS.QUICK_SEARCH, {
        products,
        term: searchTerm.value,
      })

      broadcast(INTERCEPTOR_KEYS.VIEW_ITEM_LIST, {
        products,
        listId: 'quick_search',
        listName: 'quick_search',
      })
    })

    const searchCategories = computed(() => {
      return getExtensionsElements(getCurrentListing.value, 'search_categories')
    })

    const searchBlogs = computed(() => {
      return getExtensionsElements(getCurrentListing.value, 'search_blogs')
    })

    const contentClass = computed(() => {
      return [
        'app-bar-search__mobile-search',
        config.featureFlags.isBsMbSwitchEnabled
          ? 'app-bar-search__mobile-search--extra'
          : '',
      ].join(' ')
    })

    return {
      toggleNavigationSearch,
      getProducts,
      getTotal,
      isSuggestBoxOpen,
      typingQuery,
      performSuggestSearch,
      loading,
      searchCategories,
      searchBlogs,
      isSearchActive,
      contentClass,
      uiEventFocusSearch: useUiEvent(root, 'search', 'site'),
    }
  },
  watch: {
    $route() {
      this.isSuggestBoxOpen = false
    },
    typingQuery(value) {
      if (value && value.length > 2) {
        this.performSuggestSearch(value)
      }
    },
    isSuggestBoxOpen(isOpen) {
      const classList = document.documentElement.classList
      if (isOpen) {
        classList.add('overflow-y-hidden')
      } else {
        classList.remove('overflow-y-hidden')
      }
    },
    isSearchActive(isOpen) {
      if (isOpen && this.$vuetify.breakpoint.xsOnly) {
        this.$vuetify.goTo(this.$refs.searchBar, {
          offset: 50,
          easing: 'linear',
        })

        setTimeout(() => {
          this.$nextTick(this.$refs.searchBar.focus)
        }, 200)
      }
    },
  },
  methods: {
    performSearch() {
      if (this.typingQuery && this.typingQuery.length > 1) {
        this.$refs.searchBar.blur()

        this.$router.push(this.localePath(`/search?query=${this.typingQuery}`))
      }
    },
    focus() {
      this.$refs.searchBar.focus()
    },
    handleFocus() {
      setTimeout(() => {
        if (this.typingQuery.length > 0) {
          this.isSuggestBoxOpen = true
        }
      }, 100)

      this.uiEventFocusSearch()
    },
    handleClickAwayInclude() {
      return [this.$refs.searchBar.$el]
    },
    handleClickOutside() {
      if (this.isSuggestBoxOpen) {
        this.toggleNavigationSearch(false)
      }

      this.isSuggestBoxOpen = false
    },
    handleBlur() {
      if (this.$vuetify.breakpoint.xsOnly && this.typingQuery === '') {
        this.toggleNavigationSearch(false)
      }
    },
    handleReset() {
      this.typingQuery = ''
      this.isSuggestBoxOpen = false
    },
    handleMobileClose() {
      this.handleReset()
      this.toggleNavigationSearch(false)
    },
    handleShowMoreToggle() {
      this.shouldShowMoreItems = !this.shouldShowMoreItems
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-search {
  &__search-field {
    > .v-input__control {
      border-bottom: 1px solid silver;
    }

    .v-input__append-inner {
      align-self: center !important;
    }
  }

  &__mobile-search {
    margin-top: 8px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 100% !important;
      box-shadow: none !important;
      bottom: 0 !important;
      right: 0 !important;
      left: 0 !important;
      max-height: 100vh !important;
      top: 50px !important;
      margin-top: 0;

      &--extra {
        top: 74px !important;
      }
    }
  }
}

body.global-headbanger {
  .app-bar-search__mobile-search {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      top: 90px !important;

      &--extra {
        top: 114px !important;
      }
    }
  }
}
</style>
