import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

/**
 * @throws ClientApiError
 * @beta
 */
export async function bsSyncCart(
  params?: Object,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<void> {
  const { data } = await contextInstance.invoke.post(
    '/store-api/bs-cart-sync/sync',
    params
  )

  return data
}

export async function bsCrossSession(
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<string> {
  const { data } = await contextInstance.invoke.get(`/cross-session`, {
    withCredentials: true,
  })

  return data
}
