<template>
  <v-container class="px-0 px-sm-12 px-md-3 py-0 the-header-switch">
    <v-row no-gutters>
      <v-col cols="12" offset-lg="8" offset-md="6" md="6" lg="4">
        <div class="d-flex the-header-switch__container">
          <nuxt-link
            to="/"
            class="
              the-header-switch__box
              grey-darken-4--text
              text-decoration-none text-center
              d-flex
              justify-center
              align-center
            "
          >
            {{ settings.active }}
          </nuxt-link>
          <a
            :href="settings.url"
            :class="settings.class"
            class="
              the-header-switch__box
              white--text
              text-decoration-none text-center
              d-flex
              justify-center
              align-center
            "
          >
            <span
              class="
                the-header-switch__animation
                the-header-switch__animation--primary
              "
            >
              {{ settings.passiv }}
            </span>
            <span
              class="
                hidden-sm-and-up
                the-header-switch__animation
                the-header-switch__animation--secondary
              "
            >
              {{ settings.passivSlogan }}
            </span>
            <span
              class="
                font-weight-regular
                pl-2
                text-overline text-uppercase
                hidden-xs-only
              "
            >
              {{ settings.passivSlogan }}
            </span>
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TheHeaderSwitch',
  computed: {
    settings() {
      if (process.env.NUXT_ENV_BS_BUILD_MODULE === 'mabu') {
        return {
          url: this.$config.babySweetsDeUrl,
          class: ['pink-berry'],
          active: 'MaBu Kids',
          passiv: 'Baby Sweets',
          passivSlogan: 'Babymode',
        }
      } else {
        return {
          url: this.$config.mabuKidsDeUrl,
          class: ['lilac-dark'],
          active: 'Baby Sweets',
          passiv: 'MaBu Kids',
          passivSlogan: 'Kindermode',
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.the-header-switch {
  &__container {
    height: 36px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: 24px;
    }
  }

  &__box {
    position: relative;
    width: 100%;

    // .text-h5
    font-size: 0.813rem !important;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.46px !important;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      // .text-overline
      font-size: 0.625rem !important;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 1.5px !important;
    }
  }

  &__animation {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      position: absolute;
      animation-duration: 10s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &--primary {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        animation-name: switch-animation-primary;
      }
    }
    &--secondary {
      display: none;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        display: inline-block;
        opacity: 0;
        animation-name: switch-animation-secondary;
      }
    }
  }
}

@keyframes switch-animation-primary {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes switch-animation-secondary {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
