<template>
  <div v-if="notification" class="the-notification" :style="styles">
    <div class="the-notification__wrapper blue-pastel-dark">
      <div class="the-notification__content white--text text-body-3 pa-5">
        {{ notification.message }}
      </div>
      <div class="the-notification__action">
        <base-button
          icon
          class="white--text"
          @click="removeOne(notification.id)"
        >
          <base-icon mdi="close"></base-icon>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { useNotifications } from '~/composables'
import BaseButton from '~/components/base/button/BaseButton'
import BaseIcon from '~/components/base/icon/BaseIcon'

export default {
  name: 'TheNotification',
  components: { BaseIcon, BaseButton },
  setup(_, { root }) {
    const { notifications, removeOne } = useNotifications(root)

    return {
      notifications,
      removeOne,
    }
  },

  computed: {
    notification() {
      return this.notifications?.[0]
    },
    styles() {
      const { bottom, footer, insetFooter } = this.$vuetify.application

      return {
        paddingBottom: bottom + footer + insetFooter + 'px',
      }
    },
  },
}
</script>

<style lang="scss">
.the-notification {
  align-items: flex-end;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  left: 0;
  pointer-events: none;
  right: 0;
  top: auto;
  width: 100%;

  &__wrapper {
    align-items: center;
    border-color: currentColor !important;
    display: flex;
    margin: 8px;
    max-width: 672px;
    min-height: 48px;
    min-width: 344px;
    padding: 0;
    pointer-events: auto;
    position: relative;
    transition-duration: 0.15s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  &__content {
    flex-grow: 1;
    margin-right: auto;
  }

  &__action {
    align-items: center;
    display: flex;
    margin-right: 8px;
  }
}
</style>
