<template>
  <div class="base-countdown" :class="classes">
    <div v-if="!hideDay" class="base-countdown__box">
      <div class="base-countdown__time">{{ days }}</div>
      <div class="base-countdown__title">{{ $t('base.countdown.days') }}</div>
    </div>
    <div v-if="!hideDay" class="base-countdown__space">
      {{ $t('base.countdown.space') }}
    </div>
    <div class="base-countdown__box">
      <div class="base-countdown__time">{{ hours }}</div>
      <div class="base-countdown__title">{{ $t('base.countdown.hours') }}</div>
    </div>
    <div class="base-countdown__space">{{ $t('base.countdown.space') }}</div>
    <div class="base-countdown__box">
      <div class="base-countdown__time">{{ minutes }}</div>
      <div class="base-countdown__title">
        {{ $t('base.countdown.minutes') }}
      </div>
    </div>
    <div class="base-countdown__space">{{ $t('base.countdown.space') }}</div>
    <div class="base-countdown__box">
      <div class="base-countdown__time">{{ seconds }}</div>
      <div class="base-countdown__title">
        {{ $t('base.countdown.seconds') }}
      </div>
    </div>
  </div>
</template>

<script>
const MILLISECONDS_SECOND = 1000
const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND
const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE
const MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR
const EVENT_VISIBILITY_CHANGE = 'visibilitychange'

export default {
  name: 'BaseCountdown',
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDay: {
      type: Boolean,
      default: false,
    },
    endDate: {
      type: String,
      default: '',
    },
    interval: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      counting: false,
      endTime: 0,
      totalMilliseconds: 0,
      requestId: 0,
    }
  },
  computed: {
    classes() {
      return {
        'base-countdown--border': this.border,
        'base-countdown--dense': this.dense,
      }
    },
    days() {
      return this.format(Math.floor(this.totalMilliseconds / MILLISECONDS_DAY))
    },

    hours() {
      return this.format(
        Math.floor(
          (this.totalMilliseconds % MILLISECONDS_DAY) / MILLISECONDS_HOUR
        )
      )
    },

    minutes() {
      return this.format(
        Math.floor(
          (this.totalMilliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE
        )
      )
    },

    seconds() {
      return this.format(
        Math.floor(
          (this.totalMilliseconds % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND
        )
      )
    },
  },
  watch: {
    endDate: {
      immediate: true,

      handler() {
        this.endTime = +new Date(this.endDate)
        this.totalMilliseconds = this.endTime - Date.now()

        if (!this.$isServer) {
          this.start()
        }
      },
    },
  },
  mounted() {
    document.addEventListener(
      EVENT_VISIBILITY_CHANGE,
      this.handleVisibilityChange
    )
  },

  beforeUnmount() {
    document.removeEventListener(
      EVENT_VISIBILITY_CHANGE,
      this.handleVisibilityChange
    )
    this.pause()
  },
  methods: {
    format(time) {
      return String(isNaN(time) ? '' : time).padStart(2, '0')
    },
    start() {
      if (this.counting) {
        return
      }

      this.counting = true

      if (document.visibilityState === 'visible') {
        this.continue()
      }
    },
    continue() {
      if (!this.counting) {
        return
      }

      const delay = Math.min(this.totalMilliseconds, this.interval)

      if (delay > 0) {
        let init
        let prev

        const step = (now) => {
          if (!init) {
            init = now
          }

          if (!prev) {
            prev = now
          }

          const range = now - init

          if (
            range >= delay ||
            // Avoid losing time about one second per minute (now - prev ≈ 16ms)
            range + (now - prev) / 2 >= delay
          ) {
            this.progress()
          } else {
            this.requestId = requestAnimationFrame(step)
          }

          prev = now
        }

        this.requestId = requestAnimationFrame(step)
      } else {
        this.end()
      }
    },
    progress() {
      if (!this.counting) {
        return
      }

      this.totalMilliseconds -= this.interval

      this.continue()
    },
    update() {
      if (this.counting) {
        this.totalMilliseconds = Math.max(0, this.endTime - Date.now())
      }
    },
    pause() {
      cancelAnimationFrame(this.requestId)
    },
    end() {
      if (!this.counting) {
        return
      }

      this.pause()
      this.totalMilliseconds = 0
      this.counting = false
    },
    handleVisibilityChange() {
      switch (document.visibilityState) {
        case 'visible':
          this.update()
          this.continue()
          break

        case 'hidden':
          this.pause()
          break

        default:
      }
    },
  },
}
</script>
