import { UiProductProperty } from '../ui-interfaces'
import { Product } from '~/commons/interfaces/models/content/product/Product'

/**
 * @alpha
 */
export function getProductProperties({
  product,
}: { product?: Product } = {}): UiProductProperty[] {
  const propertyList = product?.properties?.map((property) => ({
    name: property.group?.translated?.name || '',
    value: property.translated?.name || property.name,
  }))

  return propertyList || []
}
