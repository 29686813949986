import { AxiosResponse } from 'axios'

const timingLoggerMessage = (
  startedAt: any,
  status: any,
  url: any,
  isServer: boolean = process.server
): string => {
  return `[AXIOS][${isServer ? 'SERVER' : 'CLIENT'}][${status}][URL: ${url}] ${
    new Date().getTime() - startedAt
  } ms`
}

export function createResponseTimingInterceptor() {
  return function (response: AxiosResponse | any) {
    const statedAt = response.config.meta.requestStartedAt

    console.log(
      timingLoggerMessage(
        statedAt,
        response.status,
        response.config.baseURL + response.config.url
      )
    )

    return response
  }
}

export function createResponseErrorTimingInterceptor() {
  return function (error: any) {
    const statedAt = error.config.meta.requestStartedAt

    console.log(
      timingLoggerMessage(
        statedAt,
        error.response?.status || 500,
        error.config.baseURL + error.config.url
      )
    )

    return Promise.reject(error)
  }
}
