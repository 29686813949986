import { reactive, Ref, ref, UnwrapRef } from '@nuxtjs/composition-api'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { ShopwareError } from '~/commons/interfaces/errors/ApiError'
import {
  calculateInstallments as calculateInstallmentsApi,
  CalculateInstallmentsResponse,
} from '~/shopware-6-client/plugins/services/bsPayolutionPaymentPwaService'

/**
 * @beta
 **/
export interface IUsePayolutionPayment {
  loadCalculateInstallments: () => Promise<boolean>
  calculateInstallments: Ref<CalculateInstallmentsResponse | null>
  handlerId: Ref<string>
  errors: UnwrapRef<{
    [key: string]: ShopwareError[]
  }>
  loaders: UnwrapRef<{
    [key: string]: boolean
  }>
}

/**
 * @beta
 */
export const usePayolutionPayment = (
  rootContext: ApplicationVueContext
): IUsePayolutionPayment => {
  const { apiInstance, config } = getApplicationContext(
    rootContext,
    'useKlarnaPayment'
  )

  const errors: UnwrapRef<{
    loadCalculateInstallments: ShopwareError[]
  }> = reactive({
    loadCalculateInstallments: [],
  })

  const loaders: UnwrapRef<{
    loadCalculateInstallments: boolean
  }> = reactive({
    loadCalculateInstallments: false,
  })

  const calculateInstallments: Ref<CalculateInstallmentsResponse | null> =
    ref(null)

  // TODO: Händler ID setzten
  const handlerId = ref(config.payolutionHandlerId)

  const loadCalculateInstallments = async () => {
    loaders.loadCalculateInstallments = true
    try {
      calculateInstallments.value = await calculateInstallmentsApi(apiInstance)

      return true
    } catch (e) {
      errors.loadCalculateInstallments = e.message
    } finally {
      loaders.loadCalculateInstallments = false
    }

    return false
  }

  return {
    calculateInstallments,
    loadCalculateInstallments,
    loaders,
    errors,
    handlerId,
  }
}
