import {
  defaultInstance,
  ShopwareApiInstance,
} from '~/shopware-6-client/apiService'

/**
 * @beta
 */
export async function startSession(
  orderId?: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<any> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-klarna-extension/start-session',
    {
      orderId,
    }
  )

  return resp.data
}

/**
 * @beta
 */
export async function updateSession(
  sessionId: string,
  contextInstance: ShopwareApiInstance = defaultInstance
): Promise<any> {
  const resp = await contextInstance.invoke.post(
    '/store-api/bs-klarna-extension/update-session',
    {
      sessionId,
    }
  )

  return resp.data
}
