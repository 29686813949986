<script>
import { findCmsSlots } from '~/helpers'
import { genVCol } from '~/components/cms/blocks/CmsBlockBsGridRenderHelper'

export default {
  name: 'CmsBlockBsGridHalf',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, context) {
    const content = context.props.content
    const classes =
      content.type === 'bs-grid-half-fixed' ? 'col col-6' : 'col-lg-6 col-12'

    return h(
      'div',
      {
        staticClass: `row ${context.data.staticClass || ''}`.trim(),
      },
      [
        genVCol(h, classes, findCmsSlots(content, 'one')),
        genVCol(h, classes, findCmsSlots(content, 'two')),
      ]
    )
  },
}
</script>
