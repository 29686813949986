import {
  CmsBlock,
  CmsSlot,
} from '~/commons/interfaces/models/content/cms/CmsPage'

/**
 * @alpha
 */
export function getCmsSlots(content: CmsBlock): CmsSlot[] {
  return content?.slots || []
}
