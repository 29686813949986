import { computed, ComputedRef, ref, Ref } from '@nuxtjs/composition-api'
import { ApplicationVueContext } from '../appContext'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { getApplicationContext, useDefaults, useSentry } from '~/composables'
import { addProductReview, getProductReviews } from '~/shopware-6-client'
import { ProductReview } from '~/commons/interfaces/models/content/product/ProductReview'

export interface ProductReviewData {
  title: string
  content: string
  points: number
}

/**
 * interface for {@link useProductReview} composable
 *
 * @beta
 */
export interface IUseProductReview {
  fetch: () => Promise<void>
  /**
   * Adding to product review is in progress
   */
  loading: Ref<boolean>
  /**
   * Error message when adding to product review was not successful
   */
  error: Ref<string>

  /**
   * The product reviews
   */
  reviews: ComputedRef<ProductReview[]>

  /**
   * Adding a product review
   *
   * @param productReviewData
   */
  addReview: (productReviewData: ProductReviewData) => Promise<boolean>
}

/**
 * Composable for navigation. Options - {@link IUseProductReview}
 * @beta
 */
export const useProductReview = (
  rootContext: ApplicationVueContext,
  product: Product
): IUseProductReview => {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useProductReview'
  )
  const { getDefaults } = useDefaults(rootContext, contextName)
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const loading: Ref<boolean> = ref(false)
  const error: Ref<any> = ref(null)
  const _reviews: Ref<ProductReview[] | null> = ref(null)

  const getLanguageId = () => {
    return (
      apiInstance?.defaults?.headers?.common['sw-language-id'] ??
      '2fbb5fe2e29a4d70aa5854ce7ce3e20b'
    )
  }

  const fetch = async () => {
    loading.value = true
    error.value = null

    try {
      const result = await getProductReviews(
        product.parentId || product.id,
        {
          ...getDefaults(),
          // @ts-ignore
          filter: [
            {
              type: 'equals',
              field: 'languageId',
              value: getLanguageId(),
            },
          ],
        },
        apiInstance
      )

      _reviews.value = result.elements
    } catch (e) {
      captureClientApiError(`[${contextName}][fetch]`, e)
      error.value = e.messages
    } finally {
      loading.value = false
    }
  }

  const addReview = async (
    productReviewData: ProductReviewData
  ): Promise<boolean> => {
    loading.value = true
    error.value = null

    try {
      await addProductReview(product.id, productReviewData, apiInstance)

      return true
    } catch (e) {
      error.value = e
    } finally {
      loading.value = false
    }

    return false
  }

  return {
    fetch,
    addReview,
    reviews: computed(() => _reviews.value || []),
    error,
    loading,
  }
}
