import { StoreNavigationElement } from '~/commons/interfaces/models/content/navigation/Navigation'

export function findNavigationElement(
  searchId: string,
  navigations: StoreNavigationElement[]
): StoreNavigationElement | null {
  for (const element of navigations) {
    if (element.id === searchId) {
      return element
    }

    if (element.children && element.children.length > 0) {
      const found = findNavigationElement(searchId, element.children)

      if (found !== null) {
        return found
      }
    }
  }

  return null
}
