<template>
  <div class="footer-payment-methods">
    <div class="text-h4 d-flex align-center mb-2" style="height: 44px">
      {{ $t('commons.paymentMethods') }}
    </div>
    <div class="d-flex flex-wrap footer-payment-methods__icons">
      <div class="grey-lighten-3 mr-1 mb-1">
        <img
          src="~assets/images/payment/PayPal_200x104_V2.svg"
          width="77"
          height="40"
          loading="lazy"
          alt="PayPal Logo"
        />
      </div>
      <div v-if="$i18n.locale === 'de-DE'" class="grey-lighten-3 mr-1 mb-1">
        <img
          src="~assets/images/payment/Klarna_200x104.svg"
          width="77"
          height="40"
          loading="lazy"
          alt="Klarna Logo"
        />
      </div>
      <div v-if="$i18n.locale === 'fr-FR'" class="grey-lighten-3 mr-1 mb-1">
        <img
          src="~assets/images/payment/Kreditkarte_200x104_V2.svg"
          width="77"
          height="40"
          loading="lazy"
          alt="CC Logo"
        />
      </div>
      <div
        v-if="$i18n.locale === 'de-DE'"
        class="d-flex justify-center align-center text-h5 mr-1 mb-1"
        style="background: #bde9c9"
      >
        Rechnung
      </div>
      <div
        v-if="$i18n.locale === 'de-DE'"
        class="d-flex justify-center align-center text-h5 mr-1 mb-1"
        style="background: #f3cfb3"
      >
        Vorkasse
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPaymentMethods',
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.footer-payment-methods {
  &__icons {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 180px;
    }

    > div {
      height: 40px;
      width: 77px;
    }
  }
}
</style>
