import { Product } from '~/commons/interfaces/models/content/product/Product'
import { CalculatedPrice } from '~/commons/interfaces/models/checkout/cart/price/CalculatedPrice'

export function getProductRealCalculatedPrice(
  product: Product
): CalculatedPrice {
  const pricesLength = product.calculatedPrices?.length || 0

  if (pricesLength) {
    return product.calculatedPrices[pricesLength - 1]
  }

  return product.calculatedPrice
}
