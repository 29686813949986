import { Category } from '~/commons/interfaces/models/content/category/Category'

/**
 * Gibt den Wert eines CustomFields zurück, wenn dieser nicht existiert oder null ist, wird der defautlValue zurückgeben
 *
 * @param category
 * @param key
 * @param defaultValue
 *
 * @beta
 */
export function getCategoryCustomField(
  category: Category,
  key: string,
  defaultValue: any = null
): any {
  return category?.customFields?.[key as any] ?? defaultValue
}
