import { ref, Ref, computed, ComputedRef } from '@vue/composition-api'
import { ApplicationVueContext } from '../../appContext'
import { broadcastErrors } from '../../internalHelpers/errorHandler'
import {
  getApplicationContext,
  INTERCEPTOR_KEYS,
  useIntercept,
  useSentry,
  useSharedState,
} from '~/composables'
import {
  getCart,
  addProductToCart,
  addProductIndividualisationToCart,
  addPromotionCode,
  removeCartItem,
  changeCartItemQuantity,
} from '~/shopware-6-client'
import { ClientApiError } from '~/commons/interfaces/errors/ApiError'
import { Cart } from '~/commons/interfaces/models/checkout/cart/Cart'
import { EntityError } from '~/commons/interfaces/models/common/EntityError'
import { LineItem } from '~/commons/interfaces/models/checkout/cart/line-item/LineItem'
import { getProductLineItems } from '~/helpers'

/**
 * interface for {@link useCart} composable
 *
 * @beta
 */
export interface IUseCart {
  addProduct: ({
    id,
    quantity,
  }: {
    id: string
    quantity?: number
  }) => Promise<void>
  addProductIndividualisation: ({
    id,
    payload,
  }: {
    id: string
    payload: any
  }) => Promise<void>
  addPromotionCode: (promotionCode: string) => Promise<void>
  appliedPromotionCodes: ComputedRef<LineItem[]>
  cart: ComputedRef<Cart | null>
  cartItems: ComputedRef<LineItem[]>
  changeProductQuantity: ({
    id,
    quantity,
  }: {
    id: string
    quantity: number
  }) => void
  count: ComputedRef<number>
  error: ComputedRef<string>
  loading: ComputedRef<boolean>
  refreshCart: () => Promise<void>
  removeItem: ({ id }: LineItem) => Promise<void>
  totalPrice: ComputedRef<number>
  shippingTotal: ComputedRef<number>
  subtotal: ComputedRef<number>
  productTotalPrice: ComputedRef<number>
  cartErrors: ComputedRef<EntityError[]>
  cartProductItems: ComputedRef<LineItem[]>
}

/**
 * Composable for cart management. Options - {@link IUseCart}
 *
 * @beta
 */
export const useCart = (rootContext: ApplicationVueContext): IUseCart => {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useCart'
  )
  const { broadcast } = useIntercept(rootContext)
  const { captureClientApiError, captureException } = useSentry(rootContext, {
    module: 'composable',
    name: 'useCart',
  })

  const loading: Ref<boolean> = ref(false)
  const error: Ref<any> = ref(null)

  const { sharedRef } = useSharedState(rootContext)
  const _storeCart = sharedRef<Cart>(`${contextName}-cart`)

  async function refreshCart(): Promise<void> {
    loading.value = true
    try {
      const result = await getCart(apiInstance)
      broadcastUpcomingErrors(result)
      _storeCart.value = result
    } catch (e) {
      const err: ClientApiError = e
      error.value = err.messages
      captureClientApiError('[useCart][refreshCart]', e)
    } finally {
      loading.value = false
    }
  }

  async function addProduct({
    id,
    quantity,
  }: {
    id: string
    quantity?: number
  }) {
    const addToCartResult = await addProductToCart(id, quantity, apiInstance)
    broadcastUpcomingErrors(addToCartResult)
    _storeCart.value = addToCartResult
  }

  async function addProductIndividualisation({
    id,
    payload,
  }: {
    id: string
    payload: any
  }) {
    const result = await addProductIndividualisationToCart(
      id,
      payload,
      apiInstance
    )
    broadcastUpcomingErrors(result)
    _storeCart.value = result
  }

  async function removeItem({ id }: LineItem) {
    const lineItem = cartItems.value.find(
      (lineItem: LineItem) => lineItem.referencedId === id
    )

    const result = await removeCartItem(id, apiInstance)
    broadcastUpcomingErrors(result)
    _storeCart.value = result

    broadcast(INTERCEPTOR_KEYS.REMOVE_FROM_CART, {
      result,
      id,
      lineItem,
    })
  }

  async function changeProductQuantity({ id, quantity }: any) {
    const lineItem = cartItems.value.find(
      (lineItem: LineItem) => lineItem.referencedId === id
    )

    const result = await changeCartItemQuantity(id, quantity, apiInstance)
    broadcastUpcomingErrors(result)
    _storeCart.value = result

    broadcast(INTERCEPTOR_KEYS.CHANGE_CART_QUANTITY, {
      result,
      id,
      quantity,
      lineItem,
    })
  }

  async function submitPromotionCode(promotionCode: string) {
    if (promotionCode) {
      const result = await addPromotionCode(promotionCode, apiInstance)
      broadcastUpcomingErrors(result)
      _storeCart.value = result
      broadcast(INTERCEPTOR_KEYS.ADD_PROMOTION_CODE, {
        result,
        promotionCode,
        success: Object.values(result?.errors || {}).some(
          (error) => error.messageKey === 'promotion-discount-added'
        ),
      })
    }
  }

  function broadcastUpcomingErrors(cartResult: Cart): void {
    if (!cartResult) {
      return
    }

    try {
      const cartErrorsKeys = Object.keys(_storeCart.value?.errors || {})
      const cartResultErrorKeys = Object.keys(cartResult.errors || {})
      const upcomingErrorsKeys = cartResultErrorKeys.filter(
        (resultErrorKey) => !cartErrorsKeys.includes(resultErrorKey)
      )
      const entityErrors: EntityError[] = Object.values(
        cartResult.errors || {}
      ).filter((entityError) => upcomingErrorsKeys.includes(entityError.key))

      broadcastErrors(entityErrors, `[${contextName}][cartError]`, rootContext)
    } catch (error) {
      console.error('[useCart][broadcastUpcomingErrors]', error)
      captureException(error, 'broadcastUpcomingErrors')
    }
  }

  const appliedPromotionCodes = computed(() => {
    return cartItems.value.filter(
      (cartItem: LineItem) =>
        cartItem.type === 'promotion' || cartItem.type === 'easy-coupon'
    )
  })

  const cartProductItems = computed(() => {
    return getProductLineItems(cartItems.value)
  })

  const cart: ComputedRef<Cart | null> = computed(() => _storeCart.value)

  const cartItems: ComputedRef<LineItem[]> = computed(() => {
    return cart.value ? cart.value.lineItems || [] : []
  })

  const count = computed(() => {
    return cartItems.value.reduce(
      (accumulator: number, lineItem: LineItem) =>
        lineItem.type === 'product'
          ? lineItem.quantity + accumulator
          : accumulator,
      0
    )
  })

  const totalPrice = computed(() => {
    const cartPrice =
      cart.value && cart.value.price && cart.value.price.totalPrice
    return cartPrice || 0
  })

  const shippingTotal = computed(() => {
    return (
      cart.value?.deliveries?.reduce((acc, cur) => {
        acc += cur.shippingCosts?.totalPrice || 0

        return acc
      }, 0) || 0
    )
  })

  const subtotal = computed(() => {
    const cartPrice = cart.value?.price?.positionPrice
    return cartPrice || 0
  })

  const productTotalPrice = computed(() => {
    return (
      cart.value?.lineItems
        ?.filter(
          (itemItem) =>
            itemItem.type !== 'promotion' && itemItem.type !== 'easy-coupon'
        )
        .reduce((acc, cur) => {
          acc += cur.price?.totalPrice || 0

          return acc
        }, 0) || 0
    )
  })

  const cartErrors: ComputedRef<EntityError[]> = computed(
    () => (cart.value?.errors && Object.values(cart.value.errors)) || []
  )

  return {
    addProduct,
    addProductIndividualisation,
    addPromotionCode: submitPromotionCode,
    appliedPromotionCodes,
    cart,
    cartItems,
    changeProductQuantity,
    count,
    error,
    loading,
    refreshCart,
    removeItem,
    totalPrice,
    shippingTotal,
    subtotal,
    productTotalPrice,
    cartErrors,
    cartProductItems,
  }
}
