import { Order } from '~/commons/interfaces/models/checkout/order/Order'

/**
 * get payment method's id from open transaction
 *
 * @alpha
 */
export function getOrderShippingMethodName(order: Order): string | undefined {
  const names = order?.deliveries?.map(
    ({ shippingMethod }) => shippingMethod?.translated?.name
  )
  return names?.join(', ') || ''
}
