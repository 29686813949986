import { computed, ComputedRef, ref, Ref } from '@vue/composition-api'
import {
  addProductWishlist,
  deleteProductWishlist,
  getProductsWishlist,
} from '~/shopware-6-client'

import { ApplicationVueContext } from '~/composables/appContext'
import {
  getApplicationContext,
  IInterceptorCallbackFunction,
  INTERCEPTOR_KEYS,
  useDefaults,
  useIntercept,
  useSharedState,
} from '~/composables'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { CustomerWishlistResult } from '~/commons/interfaces/response/CustomerWishlistResult'
import { IUseWishlist } from '~/composables/logic/useWishlist'

export interface IUseWishlistCustomer extends IUseWishlist {}

export const useWishlistCustomer = (
  rootContext: ApplicationVueContext,
  product?: Product
): IUseWishlistCustomer => {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useWishlistCustomer'
  )

  const { intercept } = useIntercept(rootContext)
  const { getDefaults } = useDefaults(rootContext, contextName)
  const { sharedRef } = useSharedState(rootContext)

  const error: Ref = ref(null)
  const loading: Ref<boolean> = ref(false)
  const productId: Ref<string | undefined> = ref(
    product?.parentId || product?.id
  )
  const _storeWishlist = sharedRef<CustomerWishlistResult | null>(
    `${contextName}--customerWishlistItems`
  )

  const wishlist: ComputedRef<CustomerWishlistResult | null> = computed(
    () => _storeWishlist.value
  )
  const products = computed(() => wishlist.value?.products?.elements || [])
  const count = computed(() => wishlist.value?.products.total || 0)
  const wishlistProductIds = computed(() => {
    return products.value.map(({ id }) => id)
  })
  const isInWishlist = computed(() => {
    return getIsInWishlist(productId.value ?? '')
  })

  const onAddToWishlist = (fn: IInterceptorCallbackFunction) =>
    intercept(INTERCEPTOR_KEYS.ADD_TO_WISHLIST, fn)

  const addToWishlist = async function (itemId: string) {
    try {
      await addProductWishlist(itemId, apiInstance)
      await refreshWishlist()
    } catch (e) {
      error.value = e.messages
    }
  }

  const removeFromWishlist = async function (itemId: string) {
    try {
      await deleteProductWishlist(itemId, apiInstance)
      await refreshWishlist()
    } catch (e) {
      error.value = e.messages
    }
  }

  const getIsInWishlist = (itemId: string) => {
    return wishlistProductIds.value.includes(itemId ?? '')
  }

  const refreshWishlist = async () => {
    loading.value = true
    try {
      _storeWishlist.value = await getProductsWishlist(
        getDefaults(),
        apiInstance
      )
    } catch (e) {
      error.value = e.messages
    } finally {
      loading.value = false
    }
  }

  return {
    addToWishlist,
    removeFromWishlist,
    onAddToWishlist,
    refreshWishlist,
    loading,
    products,
    count,
    error,
    isInWishlist,
    getIsInWishlist,
  }
}
