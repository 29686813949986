import { Ref } from '@vue/composition-api'
import { ref } from '@nuxtjs/composition-api'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { Product } from '~/commons/interfaces/models/content/product/Product'
import { ShopwareError } from '~/commons/interfaces/errors/ApiError'
import { getProductCrossSelling } from '~/shopware-6-client'
import { CrossSellingElement } from '~/commons/interfaces/models/content/cross-selling/CrossSellingElement'
import { useDefaults, useSentry } from '~/composables'

/**
 * interface for {@link useProductConfigurator} composable
 * @beta
 */
export interface IUseProductCrossSelling {
  loading: Ref<boolean>
  error: Ref<ShopwareError[] | null>
  crossSelling: Ref<CrossSellingElement[] | null>
  fetch: (productId?: string) => Promise<void>
}

/**
 * @beta
 */
export const useProductCrossSelling = (
  rootContext: ApplicationVueContext,
  product: Partial<Product>
): IUseProductCrossSelling => {
  const { apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useProductCrossSelling'
  )
  const { getDefaults } = useDefaults(rootContext, contextName)
  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const _productId: string = (product.parentId || product.id) as string
  const loading = ref(false)
  const error = ref(null)
  const crossSelling: Ref<CrossSellingElement[] | null> = ref(null)

  const fetch = async (productId?: string) => {
    if (!productId) {
      productId = _productId
    }

    loading.value = true
    error.value = null

    try {
      crossSelling.value = await getProductCrossSelling(
        productId,
        getDefaults(),
        apiInstance
      )
    } catch (e) {
      captureClientApiError(`[${contextName}][fetch]`, e)
      error.value = e.messages
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    error,
    crossSelling,
    fetch,
  }
}
