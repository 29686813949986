<template>
  <div id="bottom-navigation" class="hidden-sm-and-up px-0">
    <div class="d-flex justify-space-between align-center" style="width: 100%">
      <base-button
        class="bottom-navigation__btn"
        :to="localePath('/')"
        :aria-label="$t('commons.home')"
        no-hover
      >
        <base-icon mdi="home"></base-icon>
        {{ $t('commons.home') }}
      </base-button>
      <base-button
        class="bottom-navigation__btn"
        :aria-label="$t('commons.search')"
        no-hover
        @click="toggleNavigationSearch(true)"
      >
        <base-icon mdi="magnify"></base-icon>
        {{ $t('commons.search') }}
      </base-button>
      <base-button
        class="bottom-navigation__btn"
        :aria-label="$t('commons.menu')"
        no-hover
        @click="toggleNavigationDrawer()"
      >
        <base-icon mdi="menu"></base-icon>
        {{ $t('commons.menu') }}
      </base-button>
      <base-button
        class="bottom-navigation__btn"
        :aria-label="$t('commons.wishlist')"
        :to="localePath('/wishlist')"
        no-hover
        @click.native="uiEventWishlist"
      >
        <base-icon mdi="heart-outline"></base-icon>
        {{ $t('commons.wishlist') }}
        <div
          v-if="!!wishlistCount"
          class="bottom-navigation__badge bottom-navigation__badge--top"
        >
          {{ wishlistCount }}
        </div>
      </base-button>
      <div class="bottom-navigation__btn"></div>
    </div>
    <base-button
      fab
      class="white elevation-4"
      style="position: absolute; top: -28px; right: 16px"
      :aria-label="$t('commons.cart')"
      no-hover
      @click="toggleCartSidebar()"
    >
      <base-icon x-large mdi="cart-outline"></base-icon>
      <div v-if="!!cartCount" class="bottom-navigation__badge">
        {{ cartCount }}
      </div>
    </base-button>
  </div>
</template>

<script>
import applicationable from 'vuetify/lib/mixins/applicationable'
import { useCart, useUiEvent, useUIState, useWishlist } from '~/composables'
import {
  CART_SIDEBAR_STATE,
  NAVIGATION_DRAWER_STATE,
  NAVIGATION_SEARCH_STATE,
} from '~/services/UiStates'
import { useBottomNavigator } from '~/composables/logic/useBottomNavigator'
import BaseIcon from '~/components/base/icon/BaseIcon'
import BaseButton from '~/components/base/button/BaseButton'

export default {
  name: 'TheBottomNavigator',
  components: { BaseButton, BaseIcon },
  mixins: [applicationable('bottom', ['extraHeight', 'shouldShowNavigator'])],
  setup(_, { root }) {
    const uiEventSearch = useUiEvent(root, 'search', 'bottom-navigator')
    const uiEventWishlist = useUiEvent(root, 'wishlist', 'bottom-navigator')
    const uiEventCart = useUiEvent(root, 'cart', 'bottom-navigator')
    const uiEventMenu = useUiEvent(root, 'menu_bottom', 'site')

    const { switchState: toggleNavigationDrawer } = useUIState(
      root,
      NAVIGATION_DRAWER_STATE
    )

    const { switchState: toggleNavigationSearch } = useUIState(
      root,
      NAVIGATION_SEARCH_STATE
    )

    const { switchState: toggleCartSidebar } = useUIState(
      root,
      CART_SIDEBAR_STATE
    )

    const { count: cartCount } = useCart(root)
    const { count: wishlistCount } = useWishlist(root)

    const { height: extraHeight } = useBottomNavigator()

    return {
      cartCount,
      toggleNavigationSearch: () => {
        toggleNavigationSearch()
        uiEventSearch()
      },
      toggleCartSidebar: () => {
        toggleCartSidebar()
        uiEventCart()
      },
      extraHeight,
      wishlistCount,
      toggleNavigationDrawer: () => {
        toggleNavigationDrawer()
        uiEventMenu()
      },
      uiEventWishlist,
    }
  },
  data() {
    return {
      height: 53,
    }
  },
  computed: {
    shouldShowNavigator() {
      return this.$vuetify.breakpoint.smAndDown
    },
    applicationProperty() {
      return 'bottom'
    },
  },
  methods: {
    updateApplication() {
      return this.$vuetify.breakpoint.xsOnly
        ? this.height + this.extraHeight
        : this.$vuetify.breakpoint.smOnly
        ? this.extraHeight
        : 0
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

#bottom-navigation {
  height: 53px;
  align-items: center;
  display: flex;
  flex: 0 1 auto !important;
  flex-wrap: wrap;
  padding: 6px 16px;
  position: fixed;
  z-index: 4;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);

  .v-btn--active::before,
  .v-btn:hover::before {
    opacity: 0;
  }

  .bottom-navigation__btn {
    flex: 1 1 100%;
    font-size: 9px;
    padding: 0;

    .base-button__content {
      text-transform: capitalize;
      flex-direction: column;
      height: inherit;
    }
  }

  .bottom-navigation__badge {
    position: absolute;
    background: map-get($shades, 'frontend-primary');
    border-radius: 10px;
    padding: 4px 6px;
    line-height: 1;
    right: -1px;
    color: #fff;
    height: 20px;
    bottom: -10px;
    min-width: 20px;
    display: inline-block;
    font-size: 12px;

    &--top {
      top: -5px;
      right: calc(50% - 25px);
    }
  }
}
</style>
