<template>
  <cart-side-drawer v-if="drawerBooted" v-model="internalValue">
    <template #top>
      <v-expand-transition>
        <div
          v-show="showAddMessage"
          class="green-smaragd white--text text-body-4 text-center"
        >
          <div class="py-3">{{ $t('notification.siteCart.addToCart') }}</div>
        </div>
      </v-expand-transition>
      <marketing-four-for-three-action
        close-button
      ></marketing-four-for-three-action>
      <marketing-promotion-hint></marketing-promotion-hint>
    </template>
  </cart-side-drawer>
</template>

<script>
import { onMounted, ref } from '@nuxtjs/composition-api'
import { watch } from '@vue/composition-api'
import {
  INTERCEPTOR_KEYS,
  useCart,
  useIntercept,
  useUIState,
} from '~/composables'
import { CART_SIDEBAR_STATE } from '~/services/UiStates'
import MarketingFourForThreeAction from '~/components/marketing/MarketingFourForThreeAction'
import MarketingPromotionHint from '~/components/marketing/MarketingPromotionHint.vue'

export default {
  name: 'CartSideContainer',
  components: {
    MarketingPromotionHint,
    MarketingFourForThreeAction,
    CartSideDrawer: () =>
      import(
        /* webpackPrefetch: true */ '~/components/cart/side/CartSideDrawer'
      ),
  },
  setup(_, { root }) {
    const { isOpen: isSidebarOpen, switchState: toggleSidebar } = useUIState(
      root,
      CART_SIDEBAR_STATE
    )

    const { intercept } = useIntercept(root)
    const { refreshCart, cart } = useCart(root)
    const { broadcast } = useIntercept(root)

    const showAddMessage = ref(false)
    const drawerBooted = ref(false)

    watch(
      isSidebarOpen,
      (isSidebarOpen) => {
        if (isSidebarOpen) {
          drawerBooted.value = true

          refreshCart().then(() => {
            broadcast(INTERCEPTOR_KEYS.SIDE_CART_OPEN, {
              cart: cart.value,
            })
          })
        }
      },
      {
        immediate: true,
      }
    )

    onMounted(() => {
      intercept(INTERCEPTOR_KEYS.ADD_TO_CART, () => {
        toggleSidebar(true)
        showAddMessage.value = true

        setTimeout(() => {
          showAddMessage.value = false
        }, 2000)
      })
    })

    return {
      isSidebarOpen,
      toggleSidebar,
      showAddMessage,
      drawerBooted,
    }
  },
  data() {
    return {
      messageIntervalId: null,
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.isSidebarOpen
      },
      set(value) {
        this.toggleSidebar(value)
      },
    },
  },
}
</script>
