import { Order } from '~/commons/interfaces/models/checkout/order/Order'
import { getPaymentName } from '~/helpers/payment/getPaymentName'

/**
 * get payment method's id from open transaction
 *
 * @alpha
 */
export function getOrderPaymentMethodName(order: Order): string | undefined {
  const names = order?.transactions?.map(({ paymentMethod }) =>
    paymentMethod ? getPaymentName(paymentMethod) : undefined
  )
  return names?.join(', ') || ''
}
