<template>
  <div
    class="
      d-flex
      justify-space-between justify-sm-center
      footer-usp-icons
      px-1
      w-full
      mt-n1
    "
  >
    <div
      v-for="icon in icons"
      :key="icon.icon"
      class="grey-darken-3--text mx-sm-2"
    >
      <img
        :alt="icon.text"
        height="60"
        width="60"
        loading="lazy"
        :src="require(`~/assets/images/symbole/${icon.icon}`)"
      />
      <span class="text-body-4 mt-n1 text-center">{{ icon.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterUspIcons',
  computed: {
    icons() {
      return [
        {
          text: this.$t('usp.packedWithLove'),
          icon: 'packed_with_love.svg',
        },
        {
          text: this.$t('usp.securePayment'),
          icon: 'sichere_zahlung.svg',
        },
        {
          text: this.$t('commons.withdrawal'),
          icon: 'widerrufsrecht.svg',
        },
        {
          text: this.$t('usp.fastShipping'),
          icon: 'schnelle_lieferung.svg',
        },
      ]
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.footer-usp-icons {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 80px;

    > span {
      line-height: 15px !important;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    gap: 60px;
  }
}
</style>
