import { createInstance } from '~/shopware-6-client'
import {
  bsCrossSession,
  bsSyncCart,
} from '~/shopware-6-client/plugins/services/bsSyncCart'
import {
  ApplicationVueContext,
  getApplicationContext,
} from '~/composables/appContext'
import { useSentry } from '~/composables'

export function useCrossSession(rootContext: ApplicationVueContext) {
  const { config, apiInstance, contextName } = getApplicationContext(
    rootContext,
    'useCrossSession'
  )

  const { captureClientApiError } = useSentry(rootContext, {
    module: 'composable',
    name: contextName,
  })

  const crossInstance = createInstance({
    endpoint: config.crossDomain.url,
    accessToken: config.shopwareAccessToken,
  })

  async function fetchCrossSessionAndSyncCart() {
    try {
      const crossToken = await bsCrossSession(crossInstance)

      apiInstance.setExternContextTokens(
        `${config.crossDomain.name}-${crossToken}`
      )

      // sync den externen Warenkorb, nicht den eigenen
      await bsSyncCart({}, apiInstance)
    } catch (e) {
      apiInstance.setExternContextTokens()

      captureClientApiError(
        '[useCrossSession][fetchCrossSessionAndSyncCart] error',
        e
      )
    }
  }

  return {
    fetchCrossSessionAndSyncCart,
  }
}
