<template>
  <base-expansion-panel :title="title" fix-at-md class="footer-navigation-card">
    <ul class="pb-3">
      <li v-for="navigation in filteredCategory" :key="navigation.routePath">
        <nuxt-link class="text-body-3" :to="navigation.routePath">{{
          navigation.routeLabel
        }}</nuxt-link>
      </li>
    </ul>
  </base-expansion-panel>
</template>

<script>
import BaseExpansionPanel from '~/components/base/expansion/BaseExpansionPanel'
import { CATEGORY_HIDE_TYPE_ALL, isCategoryHidden } from '~/helpers'

export default {
  name: 'FooterNavigationCard',
  components: { BaseExpansionPanel },
  props: {
    title: {
      type: String,
      default: null,
    },
    navigations: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredCategory() {
      return this.navigations.filter(
        (cat) => !isCategoryHidden(cat, CATEGORY_HIDE_TYPE_ALL)
      )
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.footer-navigation-card {
  ul {
    list-style: none;
    padding: 0 !important;
  }

  a {
    color: map-get($shades, 'grey-darken-4');
    text-decoration: none;
    line-height: 26px !important;

    &:hover,
    &.nuxt-link-exact-active {
      color: map-get($shades, 'frontend-primary');
    }
  }
}
</style>
