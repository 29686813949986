<script>
import { genVCol } from '~/components/cms/blocks/CmsBlockBsGridRenderHelper'
import { findCmsSlots } from '~/helpers'

export default {
  name: 'CmsBlockBsGridThird',
  functional: true,
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, context) {
    const content = context.props.content
    const classes =
      content.type === 'bs-grid-third-fixed' ? 'col col-4' : 'col-lg-4 col-12'

    return h(
      'div',
      {
        staticClass: `row ${context.data.staticClass || ''}`.trim(),
      },
      [
        genVCol(h, classes, findCmsSlots(content, 'one')),
        genVCol(h, classes, findCmsSlots(content, 'two')),
        genVCol(h, classes, findCmsSlots(content, 'three')),
      ]
    )
  },
}
</script>
